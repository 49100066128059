import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
  validateRoutingNumber,
} from 'app/util/fieldValidations';

const BankAccountDetails = ({
  payout,
  setPayout,
}) => {
  const [checkImageOpen, setCheckImageOpen] = useState(false);

  const bankNameIsEmpty = payout.bankName === '';
  const accountNumberIsEmpty = payout.bankAccountNumber === '';

  const isRoutingNumberValid = validateRoutingNumber(payout.routingNumber);
  const accountConfirmationError = payout.accountNumberConfirmation?.length > 0 && payout.bankAccountNumber !== payout.accountNumberConfirmation;

  return (
    <Stack flex={1}>
      <Typography variant="subtitle1" my={1}>Bank Account Details</Typography>
      <TextField
        label="Bank Name"
        size="small"
        value={payout.bankName}
        onChange={(e) => setPayout({ ...payout, bankName: e.target.value })}
        error={bankNameIsEmpty}
        helperText={bankNameIsEmpty ? 'Bank Name can\'t be blank' : null}
      />

      <RadioGroup
        aria-labelledby=""
        value={payout.accountType}
        onChange={(e) => setPayout({ ...payout, accountType: e.target.value })}
        name="radio-buttons-group"
      >
        <FormControlLabel value="0" control={<Radio />} label="Checkings" />
        <FormControlLabel value="1" control={<Radio />} label="Savings" />
      </RadioGroup>

      <a role="link" tabIndex={0} style={{ color: '#06c', alignSelf: 'flex-end', marginRight: '1rem', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setCheckImageOpen(!checkImageOpen); }}>
        Where
        {' '}
        <ExpandMoreIcon sx={{ verticalAlign: 'middle', transform: checkImageOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </a>
      {
        checkImageOpen && (
          <img src="/plus_frontend/assets/txn/check.gif" alt="check" />
        )
      }

      <Stack flex={1} rowGap={1}>
        <Stack flexDirection="row" alignItems="center" columnGap={1}>
          <img style={{ width: 15, height: 20 }} src="/plus_frontend/assets/txn/routing_number_symbol.gif" alt="routing number" />
          <TextField
            sx={{ flex: 1 }}
            label="Routing Number"
            size="small"
            value={payout.routingNumber}
            onChange={(e) => setPayout({ ...payout, routingNumber: e.target.value.replace(/\D/g, '') })}
            autoComplete="off"
            autoCorrect="off"
            autoSave="off"
            error={!isRoutingNumberValid}
            helperText={!isRoutingNumberValid ? 'Routing Number is invalid' : null}
          />
          <img style={{ width: 15, height: 20 }} src="/plus_frontend/assets/txn/routing_number_symbol.gif" alt="routing number" />
        </Stack>
        <Stack flexDirection="row" alignItems="center" columnGap={1}>
          <TextField
            sx={{ flex: 1, marginLeft: 2.9 }}
            label="Bank Account #"
            size="small"
            type="password"
            value={payout.bankAccountNumber}
            onChange={(e) => setPayout({ ...payout, bankAccountNumber: e.target.value.replace(/\D/g, '') })}
            error={accountNumberIsEmpty}
            helperText={accountNumberIsEmpty ? 'Bank Account Number can\'t be blank' : null}
          />
          <img style={{ width: 15, height: 20 }} src="/plus_frontend/assets/txn/account_number_symbol.gif" alt="account number" />
        </Stack>
        <Stack flexDirection="row" alignItems="center" columnGap={1} flex={1}>
          <TextField
            sx={{ flex: 1, marginLeft: 2.9 }}
            label="Re-Type Bank Account #"
            size="small"
            type="password"
            value={payout.accountNumberConfirmation}
            onChange={(e) => setPayout({ ...payout, accountNumberConfirmation: e.target.value.replace(/\D/g, '') })}
            error={accountConfirmationError}
            helperText={accountConfirmationError ? 'Account number and Re-type account number don\'t match.' : null}
          />
          <img style={{ width: 15, height: 20 }} src="/plus_frontend/assets/txn/account_number_symbol.gif" alt="account number" />
        </Stack>
      </Stack>
    </Stack>
  );
};

BankAccountDetails.propTypes = {
  payout: PropTypes.object.isRequired,
  setPayout: PropTypes.func.isRequired,
};

export default BankAccountDetails;
