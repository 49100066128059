import { purple, green, yellow, orange, red } from '@mui/material/colors';

/* eslint-disable quote-props */
export const LAPSE_STATUS_COLORS = {
  'New': purple[800],
  'Active': green[800],
  'One day only': yellow[700],
  'Lapsing': orange[700],
  'Lapsed': red[400],
  'Unknown': '#EBEBEB',
};

export const EARNINGS_GROUP_COLORS = {
  'Highest': green[800],
  'High': green[500],
  'Medium': yellow[700],
  'Low': red[400],
};

// define one-off exceptions for certain background colors, and a default
export const TEXT_COLORS_FOR_BACKGROUND = {
  [yellow[700]]: '#333',
  '#EBEBEB': '#333',
  'default': 'white',
};
/* eslint-enable quote-props */
