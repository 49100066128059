import React from 'react';
import { transactionRoutes } from 'app/features/transactions';
import { personalInfoRoutes } from 'app/features/personal_info';
import { callNowRoutes } from 'app/features/call_now';
import { accountRoutes } from 'app/features/account';
import { mailRoutes } from 'app/features/mail';
import { filePlayerRoutes } from 'app/features/file_player';

const routes = (
  <>
    {accountRoutes}
    {callNowRoutes}
    {filePlayerRoutes}
    {mailRoutes}
    {personalInfoRoutes}
    {transactionRoutes}
  </>
);

export default routes;
