import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePubNub } from 'pubnub-react';
import { useDispatch } from 'react-redux';

import { featureFlag } from 'app/util/featureFlag';
import PhoneListingCard from 'app/components/PhoneListingCard';
import DeprecatedPhoneListingCard from 'app/components/DeprecatedPhoneListingCard';
import useListingDetailsAndFeedback from 'app/hooks/useListingDetailsAndFeedback';
import mainApi, { useGetAppConfigQuery } from 'app/api/mainApi';

const PhoneListingContainer = React.memo(({
  listing,
  headingSource,
  indexInPage,
  isQualifiableFeaturedListingContext,
  queryName,
  queryArgs,
  queryDataKey, // this helps us to find the data in the RTK cache if it's nested (i.e. data[listings])
  queryDataKeyTwo,
}) => {
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');
  const appConfig = useGetAppConfigQuery().data;
  const currentUser = appConfig?.current_user;
  const dispatch = useDispatch();
  const pubNubClient = usePubNub();

  const {
    onListingDetailsClick,
    onListingFeedbackClick,
  } = useListingDetailsAndFeedback({
    listingDetailsFlagEnabled: featureFlag.enabled('LISTING_DETAILS_20955'),
  });

  const handlePubNubMessage = (msg) => {
    const nubbyData = msg.message.split('<==>');
    const nubbyAvailability = nubbyData[1]; // 'taking' or 'away'
    const nubbyAdvisor = nubbyData[2];

    dispatch(
      mainApi.util.updateQueryData(queryName, queryArgs, (listingsData) => {
        let queryData = listingsData;
        if (queryDataKey) {
          queryData = listingsData[queryDataKey];
          if (queryDataKeyTwo) {
            queryData = queryData[queryDataKeyTwo];
          }
        }

        const listings = queryData.filter((l) => l.login === nubbyAdvisor);

        listings.forEach((listing) => {
          Object.assign(listing, {
            away: nubbyAvailability === 'away',
            takingCall: nubbyAvailability === 'taking',
            taking_call: nubbyAvailability === 'taking',
          });
        });
      }),
    );
  };

  useEffect(() => {
    // allow a PhoneListingContainer to bypass a lot of the search-related logic
    if (!queryName || !queryArgs) return () => {};

    pubNubClient.addListener({ message: handlePubNubMessage });
    pubNubClient.subscribe({ channels: [listing.push_channel] });

    return () => {
      if (!pubNubClient || !listing) return;

      pubNubClient.removeListener(handlePubNubMessage);
      pubNubClient.unsubscribe({ channels: [listing.push_channel] });
    };
  }, []);

  if (newCardsEnabled) {
    return (
      <PhoneListingCard
        listing={listing}
        headingSource={headingSource}
        onDetailsClick={onListingDetailsClick(listing)}
        onFeedbackClick={onListingFeedbackClick(listing)}
        indexInPage={indexInPage}
        isQualifiableFeaturedListingContext={isQualifiableFeaturedListingContext}
        currentUser={currentUser}
      />
    );
  }

  return (
    <DeprecatedPhoneListingCard
      listing={listing}
      headingSource={headingSource}
    />
  );
});

PhoneListingContainer.defaultProps = {
  headingSource: '',
  indexInPage: null,
  isQualifiableFeaturedListingContext: false,
  queryDataKey: null, // another possible value could be 'listings' here
  queryDataKeyTwo: null,
  queryName: null,
  queryArgs: null,
};

PhoneListingContainer.propTypes = {
  headingSource: PropTypes.string,
  listing: PropTypes.object.isRequired,
  indexInPage: PropTypes.number,
  isQualifiableFeaturedListingContext: PropTypes.bool,
  queryName: PropTypes.string,
  queryArgs: PropTypes.object,
  queryDataKey: PropTypes.string,
  queryDataKeyTwo: PropTypes.string,
};

export default PhoneListingContainer;
