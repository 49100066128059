import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetFavoriteListingsQuery, useRemoveFavoriteListingsMutation, useUpdateFavoriteListingMutation } from 'app/api/mainApi';
import BoxedPagination from 'app/components/shared/BoxedPagination';
import FavoriteListings from 'app/components/FavoriteListings';
import FavoriteListingsControlsHeader from 'app/components/favorite_listings/FavoriteListingsControlsHeader';
import { NoFavorites, NoFavoritesAvailable } from 'app/components/favorite_listings/FavoriteListingsTexts';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';

const MY_SORT = '1';

const FavoriteListingsContainer = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [sort, setSort] = useState(searchParams.get('sort') || MY_SORT);
  const [selectedListings, setSelectedListings] = useState({});
  const [updateFavoriteListing, { isLoading: isUpdatingFavoriteListing }] = useUpdateFavoriteListingMutation();
  const [removeFavoriteListings, { isLoading: isRemovingFavoriteListings }] = useRemoveFavoriteListingsMutation();
  const { data: favoriteListingsData } = useGetFavoriteListingsQuery({ page, sort });
  if (!favoriteListingsData) {
    return null;
  }
  const { favorite_listings: favoriteListings } = favoriteListingsData;

  const handlePageChange = (newPage) => {
    setSelectedListings({});
    setPage(newPage);

    // store sort/page params in the URL, but only if we're on the favorite listings page
    if (window.location.href.includes('/account/favorite')) {
      window.history.replaceState({}, '', `/account/favorite?sort=${sort}&page=${newPage}`);
    }
  };

  const handleSortChange = (newSort) => {
    setSelectedListings({});
    setSort(newSort);

    // store sort/page params in the URL, but only if we're on the favorite listings page
    if (window.location.href.includes('/account/favorite')) {
      window.history.replaceState({}, '', `/account/favorite?sort=${newSort}`);
    }
  };

  const handleListingCheckBox = (listingId) => {
    setSelectedListings(prevSelectedListings => {
      const newSelectedListings = {
        ...prevSelectedListings,
        [listingId]: !prevSelectedListings[listingId],
      };
      return newSelectedListings;
    });
  };

  const FavoriteListingsContextObj = {
    updateFavoriteListing,
    removeFavoriteListings,
    handleListingCheckBox,
    handleSortChange,
    setSelectedListings,
    selectedListings,
    favoriteListingsData,
    sort,
    isSortable: sort === MY_SORT,
    isUpdating: isUpdatingFavoriteListing || isRemovingFavoriteListings,
    debug: localStorage.getItem('debug') !== 'false',
  };

  return (
    <FavoriteListingsContext.Provider value={FavoriteListingsContextObj}>
      {favoriteListingsData.has_favorites ? (
        <>
          <FavoriteListingsControlsHeader />
          {favoriteListings.length ? (
            <FavoriteListings />
          ) : (
            <NoFavoritesAvailable />
          )}
        </>
      ) : (
        <NoFavorites />
      )}
      <div style={{ maxWidth: '870px', marginTop: '10px' }}>
        <BoxedPagination count={favoriteListingsData.pagination.total_pages} onPageChange={handlePageChange} />
      </div>
    </FavoriteListingsContext.Provider>
  );
};

export default FavoriteListingsContainer;
