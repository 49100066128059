import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const CardImageBox = styled(Box)({
  width: '150px',
  height: '180px',
  position: 'absolute',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1',
});

export default CardImageBox;
