import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useGetAppConfigQuery } from '../api/mainApi';

const CallFreeBox = styled('div')({
  margin: '10px',
  background: 'none',
  padding: '15px',
  backgroundColor: 'var(--body_background)',
  border: '1px solid var(--color_ddd)',
  textAlign: 'center',
  maxWidth: '326px',
});

const CallTollFree = styled('div')({
  background: '#63177a',
  color: 'white',
  fontSize: '13px',
  padding: '8px 10px 6px 10px',
  borderRadius: '2px',
  margin: '10px 13px',
  lineHeight: '1.5',
});

const TollFreeAdvertContainer = () => {
  const {
    data: {
      voice_portal_phone_number: voicePortalPhoneNumber,
      voice_portal_phone_number_mnemonic: voicePortalPhoneNumberMnemonic,
    },
  } = useGetAppConfigQuery();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <CallFreeBox>
          <Box id="awaybody">
            <h3 style={{ fontSize: '18px', color: 'var(--color_gray_five)' }}>
              Get the best phone sex anywhere, anytime!
            </h3>
            <CallTollFree>
              Call NiteFlirt toll-free
              <div className="number">
                <a
                  style={{ color: 'white', fontSize: '16px', fontWeight: '300' }}
                  href={`tel:${voicePortalPhoneNumber}`}
                >
                  {voicePortalPhoneNumber}
                </a>
              </div>
            </CallTollFree>
            <div className="number-small">
              (
              <a
                href={`tel:${voicePortalPhoneNumber}`}
                style={{ color: 'var(--color_gray_six)', fontSize: '13px' }}
              >
                {voicePortalPhoneNumberMnemonic}
              </a>
              )
            </div>
          </Box>
        </CallFreeBox>
      </Grid>
    </Grid>
  );
};

export default TollFreeAdvertContainer;
