import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogContent, Link, Stack, Typography, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { goHomeOrAction, navigateReplace, navigateTo } from 'app/util/navigation';
import { FormCheckbox, FormWrapper, FormLevelErrors, FormRecaptcha } from 'app/components/form_components';
import { useGetNewUserQuery, useCreateUserMutation, useGetAppConfigQuery } from 'app/api/mainApi';
import useServerErrors from 'app/hooks/form/useServerErrors';
import LoginFieldContainer from 'app/containers/account_info/LoginFieldContainer';
import DateOfBirth from 'app/components/account_info/DateOfBirth';
import EmailField from 'app/components/account_info/EmailField';
import PasswordFields from 'app/components/account_info/PasswordFields';
import AdvisorInfoCardContainer from 'app/containers/account_info/AdvisorInfoCardContainer';
import SignupStepper from 'app/components/account_info/SignupStepper';
import { productFromParams, loginParams, getAdditionalParams } from 'app/helpers/signinAndRegistrationHelpers';

const BoxFullPage = styled(Box)(() => ({
  '&.MuiBox-root': {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: '0px',
    left: '0px',
    backgroundColor: 'white',
    overflow: 'scroll', // Add overflow property
  },
}));

const BoxClosePlacement = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '600px',
  margin: '0 auto -20px auto',
  padding: '10px 10px 0 0',
});

const defaultValues = {
  // Set a default empty value
  login: '',
  email: '',
  password: '',
  memberagmt: false,
  enable_platphorm_offers: false,
  login_or_email_pending: false,
  recaptcha_token: '',
};

const getFieldValues = (record) => ({
  login: record.login,
  email: record.email,
  login_or_email_pending: record.login_or_email_pending,
  enable_platphorm_offers: record.enable_platphorm_offers,
});

const TOSLabel = (
  <>
    I agree to the
    {' '}
    <Link href="/help/tos" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </Link>
  </>
);

const SignupCTA = ({ phoneRegistered, product }) => {
  const { productType } = product || {};

  const headingTextMap = {
    call: {
      true: 'Complete your registration to start your call with...',
      false: 'Register to start your call with...',
    },
    chat: {
      true: 'Complete your registration to start your chat with...',
      false: 'Register to start your chat with...',
    },
    gb: {
      true: 'Complete your registration to buy...',
      false: 'Register to buy...',
    },
    default: {
      true: 'Complete Registration',
      false: 'Register to Get Started',
    },
  };

  const headingText = headingTextMap[productType || 'default']?.[phoneRegistered];

  return (
    <>
      <Typography variant="h3">
        {headingText}
      </Typography>
      <AdvisorInfoCardContainer
        product={product}
      />
      {phoneRegistered && (
        <Typography variant="body1">
          You have already registered on 1-800-TO-FLIRT.
          Please complete your online registration so you can take advantage of everything NiteFlirt has to offer.
        </Typography>
      )}
      {product && <SignupStepper activeStep={0} />}
    </>
  );
};
const SignupContainer = () => {
  const appConfig = useGetAppConfigQuery().data;
  const { current_user: currentUser } = appConfig || {};
  const [update, updateStatus] = useCreateUserMutation();
  const recordKey = 'user';
  const query = useGetNewUserQuery(undefined, { skip: updateStatus.isSuccess });
  const record = query.data && query.data[recordKey];
  const { recaptcha_site_key: recaptchaSiteKey, recaptcha_enabled: recaptchaEnabled } = query.data || {};
  const recaptchaRef = React.useRef();
  const product = productFromParams();

  // set up the form and provide some functions
  const form = useForm({
    mode: 'onBlur', // validate fields on blur
    defaultValues,
  });

  // handle server errors
  useServerErrors(updateStatus, recordKey, form);

  useEffect(() => {
    if (currentUser && currentUser.id && !query.isLoading && !record.login_or_email_pending) {
      navigateTo('/account');
    }
  }, [currentUser, record, query]);

  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (record) {
      const initialFieldValues = getFieldValues(record);
      setInitialValues(initialFieldValues);
      form.reset(initialFieldValues);
    }
  }, [record, form.reset]);

  const handleSignIn = () => {
    navigateReplace(`/login${loginParams(product)}`);
  };

  const sendSignupRequest = () => {
    const formData = form.getValues();
    update({
      user: formData,
      additionalParams: getAdditionalParams(),
    });
  };

  form.onSubmit = (formData) => {
    if (recaptchaEnabled) {
      form.clearErrors('recaptcha_token');
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    } else {
      sendSignupRequest();
    }
  };

  const handleFormSubmit = () => {
    form.handleSubmit(form.onSubmit)();
  };

  useEffect(() => {
    if (updateStatus.isSuccess) {
      const redirectUrl = updateStatus.data?.redirect_to || '/';
      console.log('Redirecting to:', redirectUrl);
      navigateTo(redirectUrl);
    }
  }, [updateStatus]);

  if (query.isLoading) {
    return null;
  }

  return (
    <BoxFullPage>
      <BoxClosePlacement>
        <IconButton
          aria-label="close"
          onClick={goHomeOrAction}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BoxClosePlacement>
      <DialogContent sx={{ maxWidth: '450px', margin: '0 auto' }}>
        <FormWrapper form={form}>
          <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" mb={2}>
            <a href="/">
              <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
            </a>
            <SignupCTA
              phoneRegistered={initialValues.login_or_email_pending}
              product={product}
            />
          </Stack>
          <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" mb={2}>
            <LoginFieldContainer initialValue={initialValues.login} required />
            <EmailField />
            <PasswordFields />
          </Stack>
          <Stack direction="column" spacing={1}>
            <DateOfBirth />
            <FormCheckbox
              id="user-accept-tos"
              name="memberagmt"
              label={TOSLabel}
              validation={{ required: 'To continue check the box to accept our Terms of Service.' }}
            />
            <FormCheckbox
              id="user-enable-platphorm-offers"
              name="enable_platphorm_offers"
              label="Yes! Send me NiteFlirt offers and news."
            />
          </Stack>
          <FormLevelErrors />
          <Stack direction="row" justifyContent="center" mb={2}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleFormSubmit}
              disabled={updateStatus.isLoading}
            >
              Register
            </Button>
          </Stack>
          {(recaptchaSiteKey && recaptchaEnabled) && (
            <FormRecaptcha
              recaptchaSiteKey={recaptchaSiteKey}
              recaptchaRef={recaptchaRef}
              onVerify={sendSignupRequest}
            />
          )}
        </FormWrapper>
        <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant="h5">
            Already have an account?
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={2}>
          <Button
            onClick={handleSignIn}
            variant="outlined"
            size="medium"
            fullWidth
          >
            Sign In
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="center">
          <a href="/flirt" style={{ fontSize: '0.875rem' }}>Become a Flirt</a>
        </Stack>
        {/* Spacer to add extra space */}
        <Box sx={{ height: 120 }} />
      </DialogContent>
    </BoxFullPage>
  );
};

export default SignupContainer;
