import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import {
  Box,
  Checkbox,
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

import DialogsContext from 'app/contexts/DialogsContext';
import CustomerSortDialog from 'app/components/customers/CustomerSortDialog';
import SearchResult from 'app/components/customers/SearchResult';
import NumberlessPagination from 'app/components/NumberlessPagination';
import CircularProgress from 'app/components/shared/CircularProgress';

import {
  setSort,
  sortSelector,
} from 'app/slices/customersSlice';

const BoxSort = styled(Box)(() => ({
  '&.MuiBox-root': {
    fontSize: '0.875em',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& span': {
      width: '20px',
      height: '20px',
      marginLeft: '5px',
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
      marginBottom: '-3px',
    },
  },
}));

const ClickableSortHeader = ({ label, onClick, showSortIcon, currentSortColumn, currentSortDirection }) => {
  let sortDirectionIcon = '';
  if (showSortIcon && currentSortColumn) {
    sortDirectionIcon = currentSortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
  }
  return (
    <BoxSort onClick={onClick}>
      <Typography
        variant="subtitle1"
        align="center"
        gutterBottom={false}
        paragraph={false}
        noWrap
      >
        {label}
        &nbsp;
        {sortDirectionIcon}
      </Typography>
    </BoxSort>
  );
};

ClickableSortHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showSortIcon: PropTypes.bool.isRequired,
  currentSortColumn: PropTypes.bool.isRequired,
  currentSortDirection: PropTypes.string.isRequired,
};

const SearchResults = ({
  selectAll,
  setSelectAll,
  searchResults,
  loading,
  selectedResults,
  setSelectedResults,
  pageSize,
  page,
  setPage,
  triggerSearch,
}) => {
  const { openDialog } = useContext(DialogsContext);
  const [showSortIcon, setShowSortIcon] = useState(false);
  const dispatch = useDispatch();

  // we request 1 more result than needed to determine if there is a next page
  const hasNext = searchResults.length > pageSize;

  const sort = useSelector(sortSelector);
  const { sortColumn, sortDirection } = sort;
  const [firstSortColumn] = sortColumn;
  const [firstSortDirection] = sortDirection;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const seekerIdsOnPage = searchResults?.slice(0, pageSize)?.map((result) => result.seeker_id);

  const dispatchSetSort = (value) => dispatch(setSort(value));

  const onPageChange = (_e, value) => {
    const bodyContents = document.getElementsByClassName('body-content');
    if (bodyContents.length > 0) {
      bodyContents[0].scrollTop = 0;
    }
    setPage(value);
  };

  // If you change the column, reset the direction to ascending
  const setSortColumn = (newSortColumn) => {
    dispatchSetSort({
      sortColumn: [newSortColumn, sortColumn[1]],
      sortDirection: ['asc', sortDirection[1]],
    });
  };

  const toggleSortDirection = () => {
    const newSortDirection = firstSortDirection === 'asc' ? 'desc' : 'asc';
    dispatchSetSort({
      sortColumn,
      sortDirection: [newSortDirection, sortDirection[1]],
    });
  };

  // Supports both changing the sort column, and toggling the direction
  const onSortColumnClick = (column) => () => {
    setShowSortIcon(true);

    if (firstSortColumn === column) {
      toggleSortDirection();
    } else {
      setSortColumn(column);
    }
    setPage(1);
  };

  const handleSortClick = () => (
    openDialog({
      component: CustomerSortDialog,
    })
  );

  const onSelectAll = () => {
    if (!selectAll) {
      setSelectAll('page');
      setSelectedResults(seekerIdsOnPage);
    } else {
      setSelectAll(false);
      setSelectedResults([]);
    }
  };

  const moreResultsThanDisplayed = searchResults.length > pageSize;

  useEffect(() => {
    const numResultsOnPage = Math.min(pageSize, searchResults.length);

    // To tell if everything on this page is selected, we can look at counts, but remember
    // we requested 1 additional result from the server that we aren't displaying, so subtract 1
    if (selectedResults.length === 0 || selectedResults.length < numResultsOnPage) {
      setSelectAll(false);
    } else if (selectedResults.length === numResultsOnPage) {
      setSelectAll('page');
    }
  }, [selectedResults]);

  if (loading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: { xs: '8px', sm: '16px' },
          justifyContent: 'space-between',
          pr: { xs: '8px', sm: '16px' },
          alignItems: 'center',
          borderTop: '1px solid #eee',
          borderBottom: '1px solid #eee',
        }}
      >
        <Checkbox
          checked={!!selectAll}
          onChange={onSelectAll}
          color="default"
        />
        {/* Nested Stack for grouping Value & Status */}
        <Stack sx={{ flexDirection: 'row', gap: { xs: '8px', sm: '16px' } }}>
          <ClickableSortHeader
            onClick={onSortColumnClick('Value')}
            label="Value"
            showSortIcon={showSortIcon}
            currentSortColumn={firstSortColumn === 'Value'}
            currentSortDirection={firstSortDirection}
          />
          <ClickableSortHeader
            onClick={onSortColumnClick('Status')}
            label="Status"
            showSortIcon={showSortIcon}
            currentSortColumn={firstSortColumn === 'Status'}
            currentSortDirection={firstSortDirection}
          />
        </Stack>
        {/* Push to the right */}
        <Box sx={{ flexGrow: { xs: 0, sm: 1 } }} />
        <ClickableSortHeader
          onClick={onSortColumnClick('Last Contact')}
          label="Last Contact"
          showSortIcon={showSortIcon}
          currentSortColumn={firstSortColumn === 'Last Contact'}
          currentSortDirection={firstSortDirection}
        />
        {/* Push to the right */}
        <Box sx={{ flexGrow: { xs: 0, sm: 1 } }} />
        <ClickableSortHeader
          onClick={onSortColumnClick('Last 90 Day Earnings')}
          label={isSmallScreen ? 'L90D $' : 'Last 90 Day Earnings $'}
          showSortIcon={showSortIcon}
          currentSortColumn={firstSortColumn === 'Last 90 Day Earnings'}
          currentSortDirection={firstSortDirection}
        />
        <SortIcon
          color="action"
          sx={{ cursor: 'pointer' }}
          onClick={handleSortClick}
        />
      </Stack>

      { moreResultsThanDisplayed && selectAll && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            px: 3,
            py: 1.5,
            backgroundColor: '#eee',
          }}
        >
          { selectAll === 'all' ? (
            <Typography variant="body2">
              All matching customers are selected.&nbsp;&nbsp;
              <Button
                variant="text"
                onClick={() => setSelectAll('page')}
                style={{ cursor: 'pointer', padding: '0px', fontSize: '0.938em', marginTop: '-2px' }}
              >
                Clear selection
              </Button>
            </Typography>
          ) : (
            <Typography variant="body2">
              All customers&nbsp;
              <strong>on this page only</strong>
              &nbsp;are selected.&nbsp;&nbsp;
              <Button
                variant="text"
                onClick={() => setSelectAll('all')}
                style={{ cursor: 'pointer', padding: '0px', fontSize: '0.938em', marginTop: '-2px' }}
              >
                Select all customers that match
              </Button>
            </Typography>
          )}
        </Stack>
      )}

      { searchResults?.slice(0, pageSize).map((customer) => (
        <SearchResult
          key={customer.seeker_id}
          customer={customer}
          selectedResults={selectedResults}
          setSelectedResults={setSelectedResults}
          selectAll={selectAll}
          searchResults={searchResults}
          seekerIdsOnPage={seekerIdsOnPage}
          triggerSearch={triggerSearch}
        />
      ))}
      {(page > 1 || hasNext) && (
        <Box sx={{ mt: 2 }}>
          <NumberlessPagination
            page={page}
            hasNext={hasNext}
            onChange={onPageChange}
          />
        </Box>
      )}
    </>
  );
};

SearchResults.defaultProps = {
  searchResults: [],
  pageSize: 10,
};

SearchResults.propTypes = {
  selectAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setSelectAll: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  selectedResults: PropTypes.array.isRequired,
  setSelectedResults: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
};

export default SearchResults;
