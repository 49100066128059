import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledChip = styled(Chip)(() => ({
  minWidth: '70px', // Default for screens above 600px
  '@media (max-width: 600px)': {
    '&.MuiChip-root': {
      minWidth: 'auto',
      fontSize: '0.75rem',
      padding: '0px 8px',
    },
    '& .MuiChip-label': {
      padding: '0px 4px',
    },
  },
}));

const HoverChip = ({ label, ...rest }) => {
  if (label.length <= 15) {
    return (
      <StyledChip
        label={label}
        size="small"
        {...rest}
      />
    );
  }

  return (
    <Tooltip title={label}>
      <StyledChip
        label={label}
        size="small"
        {...rest}
      />
    </Tooltip>
  );
};

HoverChip.propTypes = {
  label: PropTypes.string.isRequired,
};

export default HoverChip;
