import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import ListingShape from 'app/shapes/ListingShape';
import CallStatusShape from 'app/shapes/CallStatusShape';
import useFlirtActions from 'app/hooks/useFlirtActions';
import Disconnected from 'app/components/call/callInProgress/Icons/Disconnected';
import LeaveFeedbackButton from 'app/components/feedback/LeaveFeedbackButton';
import RoutingContext from 'app/contexts/RoutingContext';

const BillingEndedStep = ({
  listing,
  callStatus,
  feedback,
}) => {
  const generateUrl = useContext(RoutingContext);
  const { data: appConfig } = useGetAppConfigQuery();
  const isSimulatorEnabled = !!appConfig.simulateCallStates;
  const { onTributeButtonClick } = useFlirtActions({ advisor: { id: listing.advisor_id, login: listing.login } });

  return (
    <Box textAlign="center">
      <Typography variant="h3">
        Your call has ended.
      </Typography>
      <Box mt={3} mb={3}>
        <Disconnected />
      </Box>
      <Typography variant="body1" gutterBottom>
        We hope you enjoyed your call.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <a
          href={generateUrl('transactionDetails', { params: { id: callStatus.bucket_transaction_id } })}
          target="_blank"
          rel="noreferrer"
        >
          Call Details
        </a>
      </Typography>
      <Box mt={4} mb={3}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onTributeButtonClick}
          sx={{ width: { xs: '95%', sm: '260px' } }}
        >
          Send Tribute
        </Button>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2 }}
      >
        <a href={listing.listing_category_path}>
          <Button
            variant="outlined"
            size="medium"
          >
            Find a Flirt
          </Button>
        </a>
        { (isSimulatorEnabled && (
          <Button
            variant="outlined"
            size="medium"
          >
            Leave Feedback
          </Button>
        )) || (
          feedback && (
            <LeaveFeedbackButton
              feedback={feedback}
              size="medium"
              variant="outlined"
            />
          )
        )}
      </Stack>
    </Box>
  );
};

BillingEndedStep.defaultProps = {
  feedback: null,
};

BillingEndedStep.propTypes = {
  listing: ListingShape.isRequired,
  callStatus: CallStatusShape.isRequired,
  feedback: PropTypes.object,
};

export default BillingEndedStep;
