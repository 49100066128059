import React from 'react';

import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import CallListingShape from 'app/shapes/CallListingShape';

const ListingOffers = ({ listing }) => {
  const advisorFreeMinutes = (listing && parseInt(listing.advisor_free_minutes)) || 0;
  const systemFreeMinutes = (listing && parseInt(listing.system_free_minutes)) || 0;
  const hasFreeMinutes = advisorFreeMinutes > 0 || systemFreeMinutes > 0;

  return hasFreeMinutes ? (
    <Grid
      container
      spacing={2}
      maxWidth={550}
    >
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          style={{ textAlign: 'right' }}
        >
          Offer
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        { systemFreeMinutes > 0 && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="body1">
              {systemFreeMinutes}
              &nbsp;free&nbsp;
              {systemFreeMinutes === 1 ? 'minute' : 'minutes'}
              &nbsp;for new users
            </Typography>
          </Stack>
        )}
        { advisorFreeMinutes > 0 && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="body1">
              {advisorFreeMinutes}
              &nbsp;free&nbsp;
              {advisorFreeMinutes === 1 ? 'minute' : 'minutes'}
              &nbsp;from your Flirt
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  ) : null;
};

ListingOffers.propTypes = {
  listing: CallListingShape.isRequired,
};

export default ListingOffers;
