import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

// "sorts" prop is an array of { value, label } objects
const SortSelector = ({
  sorts,
  activeSort, handleSortChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (sort) => () => {
    handleSortChange(sort);
    handleSortClose();
  };

  return (
    <>
      <IconButton onClick={handleSortClick} data-test-id="sort-button">
        <SortIcon style={{ color: 'var(--color_gray_six)' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSortClose}
        data-test-id="sort-menu"
      >
        {sorts && sorts.map((sort) => (
          <MenuItem
            key={sort.value}
            value={sort.value}
            onClick={onMenuItemClick(sort)}
            selected={activeSort === sort.value}
            data-test-id={`sort-${sort.value}`}
          >
            {sort.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SortSelector.propTypes = {
  sorts: PropTypes.array.isRequired,
  activeSort: PropTypes.number.isRequired,
  handleSortChange: PropTypes.func.isRequired,
};

export default SortSelector;
