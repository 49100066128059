import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Unstable_Grid2';

import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import ChatListingContainer from 'app/containers/ChatListingContainer';
import SortSelector from 'app/components/shared/SortSelector';

const CategoryListings = ({
  currentTab,
  handleTabChange,
  handleSortChange,
  listingsData,
  sorts,
  activeSort,
  tabs,
  showHeader,
  categoryName,
}) => {
  return (
    <>
      {showHeader && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Tabs value={currentTab} onChange={handleTabChange} data-test-id="category-listings-tabs">
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} data-test-id={`tab-${tab.value}`} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={3} container justifyContent="flex-end">
            {currentTab !== 3 && (
              <SortSelector
                sorts={sorts}
                activeSort={activeSort}
                handleSortChange={handleSortChange}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Box
        px={{ xs: 0, sm: 1 }}
        pt={{ xs: 2.5, sm: 3.5 }}
        pb={{ xs: 4, sm: 4 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: { xs: '6px', sm: '10px' },
            justifyContent: { sm: 'flex-start' },
            paddingLeft: { sm: '16px' },
            '@media (max-width: 900px)': {
              justifyContent: 'center',
              paddingLeft: '0px',
            },
          }}
        >
          {listingsData?.listings.map((listing, i) => (
            <Box
              key={`listing_${listing.id}`}
              sx={{
                width: { xs: '330px', sm: '330px' },
                flex: { xs: '0 0 330px', sm: '0 0 330px' },
              }}
            >
              {listingsData.chat ? (
                <ChatListingContainer advisor={listing} />
              ) : (
                <PhoneListingContainer
                  listing={listing}
                  indexInPage={i}
                  isQualifiableFeaturedListingContext
                  queryName="getCategoryListings"
                  queryDataKey="listings"
                  queryArgs={{
                    category: categoryName,
                    page: listingsData.currentPage,
                    listingType: currentTab,
                    perPage: listingsData.perPage,
                    sort: activeSort,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

CategoryListings.defaultProps = {
  listingsData: null,
  showHeader: true,
  currentTab: 0,
  handleTabChange: () => {},
  handleSortChange: () => {},
  tabs: [],
  sorts: [],
};

CategoryListings.propTypes = {
  currentTab: PropTypes.number,
  handleTabChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  listingsData: PropTypes.object,
  sorts: PropTypes.array,
  activeSort: PropTypes.string.isRequired,
  tabs: PropTypes.array,
  showHeader: PropTypes.bool,
  categoryName: PropTypes.string.isRequired,
};

export default CategoryListings;
