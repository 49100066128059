import { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { userHasCard } from 'app/helpers/userHelper';
import DialogsContext from 'app/contexts/DialogsContext';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';

import {
  useGetActiveTributesForAdvisorQuery,
  useGetAppConfigQuery,
  useGetPublicPackagesForAdvisorsQuery,
} from 'app/api/mainApi';

import {
  toNumber,
  amountInvalid,
  amountOutOfRange,
  invalidAmountFormat,
} from 'app/helpers/currencyHelpers';

import {
  resetTributeSliceState,
  setAfterManageCardsStep,
  setCurrentStep,
  setDeclined,
  setRecurring,
  setRecurringInitialized,
  setPackageId,
  setPackageRate,
  setTributeAmount,
  setTributeMessage,
  setTributeDeficit,
} from 'app/slices/tributesSlice';

const useRecurringTributeManagement = ({ advisor }) => {
  const {
    afterManageCardsStep,
    packageId,
    packageRate,
    recurring,
    recurringInitialized,
    tributeAmount,
    tributeMessage,
  } = useSelector(state => state.tributes);

  const dispatch = useDispatch();
  const { closeDialog } = useContext(DialogsContext);
  const [amountError, setAmountError] = useState('');
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const currentUserHasCard = userHasCard(currentUser);
  const fromNfChat = window.location.pathname.includes('/chat');

  const { data: activeTributes } = useGetActiveTributesForAdvisorQuery(
    { advisorLogin: advisor?.login },
    { skip: advisor === undefined },
  );

  const { data: publicPackages } = useGetPublicPackagesForAdvisorsQuery(
    { advisorIds: advisor?.id },
    { skip: advisor === undefined },
  );

  const activeMonetaryTribute = activeTributes?.monetaryTribute;
  const activePackageSubscription = activeTributes?.packageSubscription;

  useEffect(() => {
    if (!recurringInitialized && publicPackages !== undefined) {
      dispatch(setRecurringInitialized({ recurring: publicPackages.length > 0 }));
    }
  }, [publicPackages, recurringInitialized]);

  const setAmount = (newAmount) => {
    if (amountOutOfRange(toNumber(newAmount))) {
      setAmountError('Tribute Amount should be from $1 to $999');
    } else if (invalidAmountFormat(newAmount)) {
      setAmountError('Please enter a valid amount without commas or currency symbols.');
    }

    dispatch(setTributeAmount({ tributeAmount: newAmount }));
  };

  const setMessage = (tributeMessage) => {
    dispatch(setTributeMessage({ tributeMessage }));
  };

  const setManageCardsStep = (afterManageCardsStep) => {
    dispatch(setAfterManageCardsStep({ afterManageCardsStep }));
  };

  const useDifferentCardClick = () => {
    dispatch(setDeclined({
      declined: false,
    }));
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
    }));
  };

  const showBillingError = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.BILLING_ERROR,
    }));
  };

  const closeDialogAndResetTributeSlice = () => {
    dispatch(resetTributeSliceState());
    closeDialog();
  };

  const subscribeToPackageClick = (packageId, packageRate) => {
    dispatch(setPackageId({ packageId }));
    dispatch(setPackageRate({ packageRate }));

    if (!currentUserHasCard) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
      }));
    }

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_PACKAGE_SUBSCRIPTION,
    }));
  };

  return {
    activeMonetaryTribute,
    activePackageSubscription,
    activeTributes,
    afterManageCardsStep,
    amountError,
    amountInvalid,
    closeDialog,
    closeDialogAndResetTributeSlice,
    currentUserHasCard,
    dispatch,
    fromNfChat,
    packageId,
    packageRate,
    publicPackages,
    recurring,
    recurringInitialized,
    resetTributeSliceState,
    setManageCardsStep,
    setAmount,
    setAmountError,
    setCurrentStep,
    setDeclined,
    setMessage,
    setPackageId,
    setPackageRate,
    setRecurring,
    setTributeDeficit,
    showBillingError,
    subscribeToPackageClick,
    tributeAmount,
    tributeMessage,
    useDifferentCardClick,
  };
};

export default useRecurringTributeManagement;
