import { styled } from '@mui/material/styles';

const StyledHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customPurple1.main,
  color: theme.palette.customPurple1.light,
  height: '44px',
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  position: 'sticky',
  top: 0,
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  fontWeight: '300',
  marginBottom: '-10px',
  '@media (max-width: 900px)': {
    height: '36px',
    fontSize: '0.875em',
  },
}));

export default StyledHeader;
