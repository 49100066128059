import React, { useState } from 'react';
import {
  FormLabel,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

const EINField = ({ value, onChange }) => {
  const [reveal, setReveal] = useState(false);

  const secondInputRef = React.useRef(null);

  const internalOnChange = (index, newValue) => {
    newValue = newValue.replace(/[^0-9]/g, '');
    let finalValue = '';

    if (index === 0) {
      finalValue = newValue + value.substring(2, 9);

      if (newValue.length === 2) {
        secondInputRef.current.focus();
      }
    } else {
      finalValue = value.substring(0, 2) + newValue;
    }

    onChange(finalValue);
  }

  return (
    <Stack>
      <FormLabel>
        Federal Employer ID# (EIN) for this company
      </FormLabel>
      <Stack direction="row" spacing={1} alignItems={'center'}>
        <TextField
          type={reveal ? 'text' : 'password'}
          size="small"
          value={value.substring(0,2)}
          onChange={(e) => internalOnChange(0, e.target.value)}
          sx={{ width: '60px'}}
          inputProps={{ maxLength: 2}}
          autoFocus={true}
          tabIndex={1}
        />
        <span style={{ fontSize: '1.5rem', fontWeight: 'lighter'}}>-</span>
        <TextField
          type={reveal ? 'text' : 'password'}
          size="small"
          value={value.substring(2,9)}
          onChange={(e) => internalOnChange(1, e.target.value)}
          sx={{width: '100px'}}
          inputProps={{ maxLength: 7 }}
          tabIndex={2}
          inputRef={secondInputRef}
        />
        <span style={{ fontSize: '1.5rem', fontWeight: 'lighter'}}>-</span>
        <a style={{color: 'var(--link_color)', cursor: 'pointer'}} onClick={(e) => { e.preventDefault(); setReveal(!reveal)}}>{ reveal ? 'Hide' : 'Reveal'}</a>
      </Stack>
    </Stack>
  )
}

EINField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  reveal: PropTypes.bool,
}

EINField.defaultProps = {
  reveal: false,
}

export default EINField;
