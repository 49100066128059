import React from 'react';
import PropTypes from 'prop-types';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import SearchControlsContainer from 'app/containers/SearchControlsContainer';
import SearchContainer from 'app/containers/SearchContainer';
import HomeFeedTabs from './nav/HomeFeedTabs';
import TitleHeader from 'app/components/shared/TitleHeader';

const Home = ({ children }) => {
  return (
    <AppBodyWithSidebar>
      <SearchContainer>
        <TitleHeader title="Feed" style={{ marginBottom: '0px' }} />
        <SearchControlsContainer />
      </SearchContainer>
      <HomeFeedTabs />
      {children}
    </AppBodyWithSidebar>
  );
};

Home.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Home;
