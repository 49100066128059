import PropTypes from 'prop-types';
import AvatarShape from 'app/shapes/AvatarShape';

const MessageSenderShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  login: PropTypes.string.isRequired,
  avatar: AvatarShape,
});

export default MessageSenderShape;
