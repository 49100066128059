import React from 'react';
import { Route } from 'react-router-dom';

import AccountContainer from './components/AccountContainer';

const routes = (
  <Route path="account">
    <Route index Component={AccountContainer} />
  </Route>
);

export default routes;
