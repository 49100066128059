import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import GoodyBagReviewShape from 'app/shapes/GoodyBagReviewShape';
import GoodyBagReviewCard from '../goodyBagDetailsDrawer/GoodyBagReviewCard';

const GoodyBagReviewsList = ({
  goodyBag,
  currentUser,
  isLoadingReviews,
  goodyBagReviews,
  isDrawerOpen,
  closeDrawer,
}) => {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={(e) => { closeDrawer(e); }}
      data-test-id="goody-bag-reviews-list"
    >
      <Grid
        container
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: 'var(--body_background)',
            padding: '12px',
            borderBottom: '1px solid var(--color_ccc)',
          }}
        >
          <Grid item xs={1}>
            <a
              role="link"
              tabIndex={0}
              onClick={closeDrawer}
              onKeyDown={closeDrawer}
            >
              <img src="/plus_frontend/assets/svg/ico-back-arrow-dark.svg" alt="Back" />
            </a>
          </Grid>
          <Grid item xs={11}>
            <span className="subhead">Ratings</span>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ width: '600px', p: 2, fontSize: '0.938em' }}
          px={{ xs: 3, sm: 4 }}
          pt={{ xs: 3.5, sm: 5 }}
          pb={{ xs: 10, sm: 10 }}
        >
          <Grid item xs={12} className="rating" pb={1}>
            {goodyBag.total_ratings > 0 ? (
              <Grid container>
                <Grid data-test-id="approval" item sx={{ marginRight: '1rem' }}>
                  {goodyBag.approval}
                  % Approval
                </Grid>
                <Grid item>
                  <img src="/plus_frontend/assets/svg/ico-thumb-up.svg" alt="" />
                </Grid>
                <Grid data-test-id="up_ratings" item sx={{ marginRight: '1rem' }}>
                  {goodyBag.up_ratings}
                </Grid>
                <Grid item>
                  <img src="/plus_frontend/assets/svg/ico-thumb-down.svg" alt="" />
                </Grid>
                <Grid data-test-id="down_ratings" item sx={{ marginRight: '1rem' }}>
                  {goodyBag.total_ratings - goodyBag.up_ratings}
                </Grid>
              </Grid>
            ) : (
              <p>
                Unrated
              </p>
            )}
          </Grid>
          {isLoadingReviews && (
            <Box>Loading...</Box>
          )}
          {goodyBagReviews.length > 0 ? (
            goodyBagReviews.map((review) => (
              <Grid
                key={`goodyBagReview_${review.id}`}
                item
                xs={12}
                sx={{ mb: 2 }}
              >
                <GoodyBagReviewCard
                  currentUser={currentUser}
                  goodyBagReview={review}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} style={{ marginTop: '16px', textAlign: 'center' }}>
              Not Yet Rated
            </Grid>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

GoodyBagReviewsList.defaultProps = {
  currentUser: {},
};

GoodyBagReviewsList.propTypes = {
  goodyBag: GoodyBagShape.isRequired,
  goodyBagReviews: PropTypes.arrayOf(GoodyBagReviewShape).isRequired,
  isLoadingReviews: PropTypes.bool.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default GoodyBagReviewsList;
