import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFilePlayerContext } from '../FilePlayerContext';

export const FileMasterCheckbox = () => {
  const { fileSelection } = useFilePlayerContext();
  const { isAllChecked, isSomeChecked, toggleAll } = fileSelection;

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={isAllChecked}
          indeterminate={isSomeChecked && !isAllChecked}
          onChange={toggleAll}
        />
      )}
      label="All"
    />
  );
};
