import React from 'react';
import PropTypes from 'prop-types';

const SendTextButton = ({
  onClick,
  href,
}) => {
  const className = 'call-button send-text-away';
  return (
    <div>
      <a
        href={href || '#'}
        onClick={onClick}
      >
        <div className={className}>
          <span>Send Text</span>
        </div>
      </a>
    </div>
  );
};

SendTextButton.defaultProps = {
  href: null,
};

SendTextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  href: PropTypes.string,
};

export default SendTextButton;
