import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { featureFlag } from 'app/util/featureFlag';
import { useParams } from 'react-router';

import {
  useGetAppConfigQuery,
  useGetDirectMessagesMailboxQuery,
  useGetSentMessagesMailboxQuery,
  useGetSystemMessagesMailboxQuery,
} from 'app/api/mainApi';

import {
  Stack,
} from '@mui/material';

import FullPageLoadingSpinner from 'app/features/mail/components/FullPageLoadingSpinner';
import PageFailedToLoad from 'app/features/mail/components/PageFailedToLoad';
import Mailboxes from 'app/features/mail/components/Mailboxes';
import MailboxPagination from 'app/features/mail/components/MailboxPagination';
import TitleHeader from 'app/components/shared/TitleHeader';

const MailboxesContainer = () => {
  if (!featureFlag.enabled('MAIL_21578')) {
    window.location.href = '/';
  }

  const { tabName } = useParams();
  // const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(tabName);
  const selectTab = (tabName) => {
    if (tabName !== selectedTab) {
      window.history.pushState(null, null, `/messages/${tabName}`);
      setSelectedTab(tabName);
    }
  };

  const [searchParams] = useSearchParams();
  const pageFromParams = searchParams.get('page');
  const [page, setPage] = useState((pageFromParams && parseInt(pageFromParams)) || 1);

  const {
    data: appConfig,
    isLoading: isAppConfigLoading,
    isSuccess: isAppConfigFetchSuccess,
    isError: isAppConfigFetchError,
  } = useGetAppConfigQuery();

  const navigate = useNavigate();
  let queryMethod = null;
  switch (selectedTab) {
  case 'inbox':
    queryMethod = useGetDirectMessagesMailboxQuery;
    break;
  case 'notifications':
    queryMethod = useGetSystemMessagesMailboxQuery;
    break;
  case 'sent':
    queryMethod = useGetSentMessagesMailboxQuery;
    break;
  default:
    setSelectedTab('inbox');
    navigate('/messages/inbox');
    queryMethod = useGetDirectMessagesMailboxQuery;
  }

  const {
    data: mailbox,
    isLoading: isMailboxLoading,
    isError: isMailboxFetchError,
  } = queryMethod({ page });

  let content = null;
  if (isAppConfigLoading) {
    content = <FullPageLoadingSpinner />;
  } else if (isMailboxFetchError || isAppConfigFetchError) {
    content = <PageFailedToLoad />;
  } else if (isAppConfigFetchSuccess) {
    content = (
      <Mailboxes
        isLoading={isMailboxLoading}
        mailbox={mailbox}
        selectedTab={selectedTab}
        selectTab={selectTab}
        currentUser={appConfig.current_user}
        setPage={setPage}
      />
    );
  } else {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={0}
      sx={{ width: '100%' }}
    >
      <TitleHeader title="Mail" />
      {content}
      { mailbox && mailbox.pages > 1 && (
        <MailboxPagination
          mailbox={mailbox}
          page={page}
          setPage={setPage}
        />
      )}
    </Stack>
  );
};

export default MailboxesContainer;
