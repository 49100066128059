import { DateTime } from 'luxon';

export const MONTH_DAY_FORMAT = 'LLL d';
export const DATE_FORMAT = 'MM/dd/yy';
export const TIME_FORMAT = 'h:mm a';
export const FULL_DATE_FORMAT = 'MM/dd/yyyy';
export const FULL_DATETIME_FORMAT = `${FULL_DATE_FORMAT} hh:mm a`;

export const isThisYear = (timestamp) => {
  const date = new Date(timestamp);
  const today = new Date();

  return date.getFullYear() === today.getFullYear();
};

export const isToday = (timestamp) => {
  const date = new Date(timestamp);
  const today = new Date();

  return date.toDateString() === today.toDateString();
};

export const timestampInTimezone = (iso8601Timestamp, ianaTimezone, format = 'LLL d hh:mm a') => {
  if (!iso8601Timestamp) return '';

  const options = {};
  if (ianaTimezone) options.zone = ianaTimezone;

  const date = DateTime.fromISO(iso8601Timestamp, options);

  return date.toFormat(format);
};
