import React from 'react';
import { PropTypes } from 'prop-types';
import { userInitials } from 'app/helpers/userInitials';
import SharedCenterNav from './SharedCenterNav';

import SeekerAvailabilityMenuContainer from 'app/containers/SeekerAvailabilityMenuContainer';

const SeekerExpandedNav = ({
  isExpanded,
  toggleExpandedNav,
  currentUser,
  closeAndNavigateTo,
  addMoneyUrl,
  balance,
}) => {
  const display = isExpanded ? 'block' : 'none';

  return (
    <>
      <div
        className="more-menu-bg"
        role="link"
        tabIndex={0}
        onClick={toggleExpandedNav}
        style={{ display }}
      >
        &nbsp;
      </div>
      <div className="more-menu" data-test-id="more-menu" style={{ display }}>
        <div className="row end-xs margin10 sticky">
          <a className="menu-close" role="link" tabIndex={0} onClick={toggleExpandedNav}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        <ul className="nav-top paddingtop0">
          <li>
            <div className="menu-initials">
              {userInitials(currentUser)}
            </div>
          </li>
          <li className="margintopminus10">
            <div data-test-id="menu-user-link">
              {currentUser?.login}
            </div>
          </li>
          <li>
            <SeekerAvailabilityMenuContainer onClick={toggleExpandedNav} />
          </li>
          <li className="margintopminus10">
            Balance:&nbsp;
            <span>
              $
              {balance}
            </span>
          </li>
          <SharedCenterNav
            balance={balance}
            closeAndNavigateTo={closeAndNavigateTo}
            currentUser={currentUser}
            addMoneyUrl={addMoneyUrl}
          />
        </ul>
      </div>
    </>
  );
};

SeekerExpandedNav.defaultProps = {
  currentUser: null,
  balance: null,
};

SeekerExpandedNav.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleExpandedNav: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  closeAndNavigateTo: PropTypes.func.isRequired,
  addMoneyUrl: PropTypes.string.isRequired,
  balance: PropTypes.string,
};

export default SeekerExpandedNav;
