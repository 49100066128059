import React from 'react';
import PropTypes from 'prop-types';

import CallListingShape from 'app/shapes/CallListingShape';

import { toNumber } from 'app/helpers/currencyHelpers';

import {
  Box,
  Typography,
} from '@mui/material';

const InsuffientFundsError = ({
  balance,
  listing,
  isLoading,
}) => {
  if (isLoading) { return null; }
  const listingCost = listing.recorded ? listing.price_per_minute * listing.recording_length : listing.price_per_minute;
  const needsLargerBalance = toNumber(balance.available_balance) < listingCost;

  if (needsLargerBalance) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 350, margin: 'auto', mt: 3 }}>
        <Typography variant="error">
          Please add money to your account.
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};

InsuffientFundsError.defaultProps = {
  balance: null,
  listing: null,
};

InsuffientFundsError.propTypes = {
  balance: PropTypes.object,
  listing: CallListingShape,
  isLoading: PropTypes.bool.isRequired,
};

export default InsuffientFundsError;
