import React, { useState, useEffect } from 'react';

import { Box, Stack, Typography, Button, DialogContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoFillableTextField from 'app/components/shared/AutoFillableTextField';
import { useVerifyAccountMutation } from 'app/api/accountApi';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const BoxFullPage = styled(Box)(() => ({
  '&.MuiBox-root': {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: '0px',
    left: '0px',
    backgroundColor: 'white',
    overflow: 'scroll', // Add overflow property
  },
}));

const BoxClosePlacement = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '600px',
  margin: '0 auto -20px auto',
  padding: '10px 10px 0 0',
});

const VerifyAccountDialog = ({ type, closeDialog }) => {
  const [data, setData] = useState('');

  const [verifyAccount, { data: verifyAccountData, error: verifyAccountError }  ] = useVerifyAccountMutation();

  // these params will be passed along unchanged with any form submission
  const queryParameters = new URLSearchParams(window.location.search);

  const navigate = useNavigate()
  const verificationType = queryParameters.get('type');

  const wording = verificationType === 'card' ?
                    'To verify your account, please provide us with the last 8 digits of the Credit Card you have on file.' :
                    'To verify your account, please provide us with the last 5 digits of your Social Security or Employer ID number.';

  const label = verificationType === 'card' ? 'Last 8 of Credit Card' : 'Social Security or Employer ID Number';

  useEffect(() => {
    if (verifyAccountData) {
      navigate('reset_password_complete');
    }
  }, [verifyAccountData]);

  return (
    <BoxFullPage>
      <BoxClosePlacement>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BoxClosePlacement>
      <DialogContent sx={{ maxWidth: '450px', margin: '0 auto' }}>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <a href="/">
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          </a>
          <Typography variant="h3">
            Verify Your Account
          </Typography>
          <Typography variant="body1" align="center">
            { wording }
          </Typography>
        </Stack>
        {
          verifyAccountError && (
            <Stack mb={3}>
              <Typography variant="body3" style={{color: '#d32f2f'}}>
                <div dangerouslySetInnerHTML={{__html: verifyAccountError.data.error}} />
              </Typography>
            </Stack>
          )
        }

        <Stack direction="column" spacing={3}>
          <AutoFillableTextField
            name="password"
            label={label}
            type="password"
            value={data}
            setValue={setData}
            onKeyPress={(e) => { if (e.key === 'Enter') verifyAccount({ data, type: verificationType}) }}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3} mt={2}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => verifyAccount({ data, type: verificationType})}
          >
            Verify Account
          </Button>
        </Stack>
      </DialogContent>
    </BoxFullPage>
  );
};

export default VerifyAccountDialog;
