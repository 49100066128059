import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { usePubNub } from 'pubnub-react';

import { featureFlag } from 'app/util/featureFlag';

import {
  useGetCallListingQuery,
  useGetPhonesQuery,
  useGetAvailableBalanceQuery,
  useGetAppConfigQuery,
  useGetCallSystemStatusQuery,
  useLazyGetCallStatusQuery,
  useCreateCallMutation,
  useGetConferenceFeedbackQuery,
} from 'app/api/mainApi';

import {
  Box,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import TitleHeader from 'app/components/shared/TitleHeader';

import HowItWorksAsideContainer from 'app/containers/HowItWorksAsideContainer';
import AppBody from 'app/components/layout/AppBody';
import AdvisorCallCard from 'app/components/AdvisorCallCard';
import CallInfo from 'app/components/call/CallInfo';
import SelectPhoneNumber from 'app/components/call/SelectPhoneNumber';
import AddPhoneLink from 'app/components/call/AddPhoneLink';
import ListingOffers from 'app/components/call/ListingOffers';
import CurrentBalance from 'app/components/call/CurrentBalance';
import ListenTime from 'app/components/call/ListenTime';
import TalkTime from 'app/components/call/TalkTime';
import CallNowActionButton from 'app/components/call/CallNowActionButton';
import HowItWorksToggle from 'app/components/call/HowItWorksToggle';

import CallInProgress from 'app/components/call/CallInProgress';

import BlockedByViewerError from 'app/components/call/errors/BlockedByViewerError';
import CannotCallSelfError from 'app/components/call/errors/CannotCallSelfError';
import ViewerBlockedError from 'app/components/call/errors/ViewerBlockedError';
import CallSystemDownError from 'app/components/call/errors/CallSystemDownError';

import SimulatedCallStates from 'app/components/call/SimulatedCallStates';

import { toNumber } from 'app/helpers/currencyHelpers';

const DEFAULT_IVR_ENTRY_NODE = 'recording';
const OUTBOUND_CALL_SIGNAL = 'outbound';
const DEFAULT_POLLING_INTERVAL = 8000;
const ONE_MINUTE_REMAINING_POLLING_INTERVAL = 35000;

// TODO: Do we need to use local storage or server storage to store ngtokens in case the user is on a call and refreshes the page.
// I believe the classic UI just says you are already busy on a call if you end up refreshing the page.
// TODO: We need to handle the case that a listing is not found. 404
const CallContainer = () => {
  const [serverCallError, setServerCallError] = useState(null);
  const callNowEanbled = featureFlag.enabled('CALL_NOW_20426');
  if (!callNowEanbled) {
    window.location.reload(); // Reload the page if the feature flag is not enabled and fall back on the controller to redirect us to the old UI.
    return null;
  }
  const [showHowItWorks, setShowHowItWorks] = useState(true);
  const pubNubClient = usePubNub();
  const { data: callSystemStatus, isLoading: isCallSystemStatusLoading } = useGetCallSystemStatusQuery();
  const { data: appConfig, isLoading: isAppConfigLoading } = useGetAppConfigQuery();
  const { current_user: currentUser } = appConfig || {};
  const { id: listingId, data: urlData } = useParams();
  if (!currentUser) {
    window.location = `/signup_listing/${listingId}`;
  }
  const { data: listing, isLoading: isListingLoading, refetch: refetchListing } = useGetCallListingQuery({ id: listingId });
  const { data: phones, isLoading: isPhonesLoading } = useGetPhonesQuery();
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const { data: balance, isLoading: isBalanceLoading } = useGetAvailableBalanceQuery();
  const isListingOwner = listing?.advisor_id === currentUser?.id;
  const isViewerBlocked = listing?.blocked;
  const isSimulatorEnabled = !!appConfig.simulateCallStates;

  const [simulatedCallType, setSimulatedCallType] = useState('live');
  const [simulatedCallStatus, setSimulatedCallStatus] = useState('created');

  const [createCall, {
    isLoading: isCreateCallLoading,
    isSuccess: isCreateCallSuccess,
    data: callData,
    isError: isCallError,
    error: callError,
  }] = useCreateCallMutation();
  const [callStatus, setCallState] = useState({
    conference_id: null,
    bucket_transaction_id: null,
    status: null,
    message: null,
    ngtoken: null,
  });

  // NOTE: The classic pages had the ability of supporting a URL data attribute to set the IVR entry node.
  const IvrEntryNode = urlData || DEFAULT_IVR_ENTRY_NODE;
  const createCallParams = {
    listingId,
    signal: OUTBOUND_CALL_SIGNAL,
    data: IvrEntryNode,
    selectedPhone: selectedPhone?.id,
  };
  const params = isSimulatorEnabled ? {
    ...createCallParams,
    callType: simulatedCallType,
    fakeStatus: simulatedCallStatus,
  } : createCallParams;
  const triggerCall = () => {
    createCall(params);
  };

  const [pollingInterval, setPollingInterval] = useState(DEFAULT_POLLING_INTERVAL);

  const [getCallStatusQuery, {
    isSuccess: isCurrentCallStatusSuccess,
    data: currentCallStatus,
    isError: isCurrentCallStatusError,
    error: callStatusError,
  }] = useLazyGetCallStatusQuery();
  const triggerGetCallStatus = () => {
    const timestamp = new Date().getTime();
    const baseParams = {
      listingId,
      ngtoken: callStatus.ngtoken,
      timestamp,
    };
    const params = isSimulatorEnabled ? {
      ...baseParams,
      callType: simulatedCallType,
      fakeStatus: simulatedCallStatus,
    } : baseParams;

    getCallStatusQuery(params);
  };

  // NOTE: This prepares the call to see if a caller can provide feedback on a call.
  // It will trigger when we have a conference ID and the call has ended
  const [feedbackFetchAttempts, setFeedbackFetchAttempts] = useState(0);
  const { data: feedback, refetch: refetchConferenceFeedback } = useGetConferenceFeedbackQuery(
    { conferenceId: currentCallStatus?.conferenceId },
    { skip: !currentCallStatus?.conferenceId && currentCallStatus?.status !== 'billing_ended' },
  );

  // Note: It can take a few seconds for the user transaction to be created and the feedback to be available after the
  // call ends. This allows us to retry in that case.
  if (currentCallStatus?.status === 'billing_ended' && !feedback && feedbackFetchAttempts < 5) {
    window.setTimeout(() => {
      refetchConferenceFeedback();
      setFeedbackFetchAttempts(feedbackFetchAttempts + 1);
    }, 2000);
  }

  useEffect(() => {
    if (phones) {
      const defaultPhone = phones.find((phone) => phone.default);
      setSelectedPhone(defaultPhone || phones[0]);
    }
  }, [phones]);

  useEffect(() => {
    if (isCreateCallSuccess) {
      setShowHowItWorks(false);
      setCallInProgress(true);
      setCallState(callData);
      setServerCallError(null);
    }
  }, [isCreateCallSuccess, callData]);

  useEffect(() => {
    if (isCallError) {
      setShowHowItWorks(true);
      setCallInProgress(false);
      setServerCallError(callError?.data?.caller_status);
      // NOTE: Sometimes pubnub doesn't update the advisor status quickly enough before the button gets clicked. So, if
      // we attempt to call when we cannot, we should refetch the listing to get the most up-to-date information ASAP.
      refetchListing();
    }
  }, [isCallError, callError]);

  useEffect(() => {
    if (isCurrentCallStatusSuccess) {
      if (currentCallStatus.status !== 'noop') { setCallState(currentCallStatus); }
      if (currentCallStatus.status === 'less_than_one_minute') {
        // NOTE: This is a hack so we can leave up the top up message for an extended amount of time for the user to
        // complete topping up.
        setPollingInterval(ONE_MINUTE_REMAINING_POLLING_INTERVAL);
      } else if (!isSimulatorEnabled) { setPollingInterval(DEFAULT_POLLING_INTERVAL); }
    } else if (isCurrentCallStatusError) {
      // We 404 if there is no longer a call, so we should do nothing and continue to show the billing_ended step.
      if (callStatusError.status !== 404) {
        setShowHowItWorks(true);
        setCallInProgress(false);
        console.error(callStatusError);
        // Set some sort of error message
      }
    }
  }, [currentCallStatus]);

  const handlePubNubMessage = (_msg) => {
    if (listing) { refetchListing(); }
  };

  useEffect(() => {
    if (!listing) return false;

    pubNubClient.addListener({ message: handlePubNubMessage });
    pubNubClient.subscribe({ channels: [listing.push_channel] });

    return () => {
      if (!pubNubClient || !listing) return;

      pubNubClient.removeListener(handlePubNubMessage);
      pubNubClient.unsubscribe({ channels: [listing.push_channel] });
    };
  }, [listing]);

  // See Listing#allow_to_call for more details.
  // Recordings can only be called if the account balance can cover the length of the entire recording.
  // Live calls can only be called if they have at least 1 minute of talk time covered by their balance or free minutes.
  const isLoading = isBalanceLoading || isListingLoading || isPhonesLoading;
  let passesCallCostConstraint = null;
  if (!isLoading && selectedPhone) {
    if (listing.recorded) {
      passesCallCostConstraint = toNumber(balance.available_balance) >= listing.price_per_minute * listing.recording_length;
    } else {
      passesCallCostConstraint = toNumber(balance.available_balance) >= listing.price_per_minute || listing.system_free_minutes > 0 || toNumber(listing.advisor_free_minutes) > 0;
    }
  }
  const canCall = !isLoading && selectedPhone && passesCallCostConstraint;

  const content = callInProgress ? (
    <CallInProgress
      triggerGetCallStatus={triggerGetCallStatus}
      callStatus={callStatus}
      isCreateCallLoading={isCreateCallLoading}
      pollingInterval={pollingInterval}
      selectedPhone={selectedPhone}
      listing={listing}
      balance={balance}
      isLoading={isLoading}
      triggerCall={triggerCall}
      currentUser={currentUser}
      feedback={feedback}
    />
  ) : (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      paddingTop={2}
    >
      <CallInfo />
      { phones && phones.length > 0 ? (
        <SelectPhoneNumber
          phones={phones}
          setSelectedPhone={setSelectedPhone}
          isLoading={isPhonesLoading}
        />
      ) : (
        <AddPhoneLink
          currentUser={currentUser}
          phones={phones}
          afterSaveCallBack={refetchListing}
        />
      ) }
      { !isLoading && !listing.recorded && <ListingOffers listing={listing} /> }
      <CurrentBalance
        balance={balance}
        listing={listing}
        isBalanceLoading={isBalanceLoading}
        isListingLoading={isListingLoading}
        currentUser={currentUser}
      />
      { listing?.recorded ? (
        <ListenTime
          listing={listing}
          balance={balance}
          isLoading={isBalanceLoading || isListingLoading}
          canCall={canCall}
        />
      ) : (
        <TalkTime
          listing={listing}
          balance={balance}
          isLoading={isBalanceLoading || isListingLoading}
          canCall={canCall}
        />
      )}
      <Box sx={{ marginTop: '16px !important' }}>
        <HowItWorksToggle />
      </Box>
      <ViewerBlockedError
        listing={listing}
        isLoading={isListingLoading}
      />
      <CannotCallSelfError
        listing={listing}
        viewer={currentUser}
        isLoading={isAppConfigLoading || isListingLoading}
      />
      { !isCallSystemStatusLoading && !callSystemStatus.up && (
        <CallSystemDownError />
      )}
      { listing?.blocked_by_viewer && (
        <BlockedByViewerError listing={listing} />
      )}
      { !isListingLoading && !(isViewerBlocked || isListingOwner) && (isCallSystemStatusLoading || callSystemStatus.up) && !listing?.blocked_by_viewer && (
        <>
          <CallNowActionButton
            selectedPhone={selectedPhone}
            listing={listing}
            balance={balance}
            canCall={canCall}
            triggerCall={triggerCall}
          />
          { serverCallError && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="error">
                {serverCallError}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Stack>
  );

  return (
    <>
      <AppBody>
        <TitleHeader title="Call" />
        <Box sx={{
          marginTop: '8px',
          paddingTop: '40px',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          mb: 1,
        }}
        >
          { !isListingLoading && (
            <AdvisorCallCard
              listing={listing}
            />
          )}
        </Box>
        <Divider
          sx={{
            mt: 3,
            mx: '32px',
          }}
        />
        { isSimulatorEnabled && (
          <SimulatedCallStates
            callInProgress={callInProgress}
            simulatedCallType={simulatedCallType}
            setSimulatedCallType={setSimulatedCallType}
            simulatedCallStatus={simulatedCallStatus}
            setSimulatedCallStatus={setSimulatedCallStatus}
            pollingInterval={pollingInterval}
            setPollingInterval={setPollingInterval}
          />
        )}
        { !isLoading && content }
      </AppBody>
      <HowItWorksAsideContainer showHowItWorks={showHowItWorks} />
    </>
  );
};

export default CallContainer;
