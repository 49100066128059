import React from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router';
import { useMatch } from 'react-router-dom';
import Tooltip from 'app/components/shared/Tooltip';
import PostComposeBox from './PostComposeBox';
import TitleWithBackNavigation from 'app/components/shared/TitleWithBackNavigation';

import { DateTime } from 'luxon';

// TODO: 2022-05-31 this should check the errors to see whether we complain about body, radio buttons, or media items
const CreatePost = ({
  body,
  setBody,
  paidBody,
  setPaidBody,
  freePost,
  freePostChanged,
  showPaidCaption,
  setShowPaidCaption,
  openSelectFilesModal,
  subscriberPost,
  subscriberPostChanged,
  errors,
  onSubmit,
  hasSelectedItems,
  SortableMediaUI,
  onboardingComplete,
  fetchingCreatePost,
  openSchedulingModal,
  scheduledAt,
  removeSchedule,
  timezone,
  hasPaymentButtons,
  openPaymentButtonsModal,
  hasGoodyBags,
  openGoodyBagsModal,
  currentUser,
}) => {
  const { login: advisorLogin } = useParams();
  const inStorefront = useMatch(`/${advisorLogin}/feed/create_post`);
  const postComposeBoxLabel = freePost ? 'Compose new caption' : 'Compose public caption';

  const openScheduler = () => {
    if (scheduledAt) return;

    openSchedulingModal();
  };

  const onboardingInstructions = (
    <div className="row margintop20 marginbot20">
      <div className="col-xs-12">
        <div className="container-fluid padding-0-8">
          <div className="row">
            <div className="col-xs-12">
              <h2 style={{ fontSize: '1.5em', fontWeight: '300' }}>
                Create Your First Post
              </h2>
            </div>
          </div>
          <div className="row middle-xs">
            <div className="col-xs-10">
              Your first Subscriber post will be visible to current &amp; future subscribers. We recommend you create at least 10 like this.
            </div>
            <div className="col-xs-2 center-xs">
              <img src="/plus_frontend/assets/svg/ico-present.svg" alt="Subscriber Post" style={{ width: '40px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const postTypeControls = (
    <>
      {
        scheduledAt && (
          <div className="body-alert general margintop20">
            <div className="container-fluid padding-0-8">
              <div className="row middle-xs">
                <div className="col-xs-11">
                  <div className="row middle-xs">
                    <div className="paddinglft5 paddingrgt10"><img src="/plus_frontend/assets/svg/ico-posts-queue.svg" alt="Posts Queue" /></div>
                    <div>
                      <a
                        href="#"
                        onClick={() => openSchedulingModal(scheduledAt)}
                      >
                        Scheduled for&nbsp;
                        {DateTime.fromISO(scheduledAt).setZone(timezone).toLocaleString(DateTime.DATETIME_MED)}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xs-1 end-xs padding0">
                  <a href="#" onClick={removeSchedule}>
                    <img src="/plus_frontend/assets/svg/ico-trashcan.svg" title="Remove Schedule" alt="Remove Schedule" className="ico-delete" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="row">
        <div className="col-xs-12 start-xs margintop20">
          <div className="subhead">
            Post Type
          </div>
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 start-xs">
          <div className="padding-0-8">
            <div>
              <div className="row">
                <div>
                  <label className={`radio-container marginbot10 ${!freePost && hasSelectedItems ? 'is-disabled' : ''}`}>
                    Free Post
                    <span style={{ color: '#666', marginLeft: '5px' }}>(No nudity)</span>
                    <input
                      type="radio"
                      checked={freePost}
                      name="freeOrSubscriber"
                      value="free"
                      onChange={freePostChanged}
                      disabled={!freePost && hasSelectedItems}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div>
                  <Tooltip>Free Posts are visible to everyone.</Tooltip>
                </div>
              </div>
            </div>
            <div className="row margintop20">
              <div>
                <label className={`radio-container marginbot10 ${freePost && hasSelectedItems ? 'is-disabled' : ''}`}>
                  Subscriber Post
                  <input
                    type="radio"
                    checked={!freePost}
                    name="freeOrSubscriber"
                    value="subscriber"
                    onChange={freePostChanged}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div>
                <Tooltip>Subscriber Posts are only visible to current subscribers.</Tooltip>
              </div>
            </div>
            {!freePost && (
              <div className="row marginlft25">
                <div>
                  <label className="checkbox-container marginbot10" style={{ color: (freePost ? '#999' : 'black') }}>
                    Hide from future subscribers
                    <input
                      type="checkbox"
                      name="subscriberPost"
                      checked={subscriberPost}
                      disabled={freePost}
                      onChange={subscriberPostChanged}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div>
                  <Tooltip>If checked, this post will be excluded from your back-catalog, meaning future subscribers won&apos;t see it.</Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const createPostButton = (
    <button
      type="submit"
      className={`btn btn-submit ${fetchingCreatePost ? 'submitting' : ''}`}
      onClick={onSubmit}
      disabled={(!body && !paidBody && !hasSelectedItems) || fetchingCreatePost}
    >
      {`${scheduledAt ? 'Schedule' : 'Post'}`}
    </button>
  );

  const composeCaptionInputs = (
    <>
      <div className="row margintop20">
        {!showPaidCaption && !freePost && (
          <div className="col-xs-12" style={{ textAlign: 'right' }}>
            <a role="link" tabIndex="0" onClick={() => { setShowPaidCaption(true); }}>
              Add Subscriber Caption
            </a>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xs-12">
          <PostComposeBox
            body={body}
            setBody={setBody}
            errors={errors?.body}
            label={postComposeBoxLabel}
            hasPaymentButtons={hasPaymentButtons}
            openPaymentButtonsModal={openPaymentButtonsModal}
            hasGoodyBags={hasGoodyBags}
            openGoodyBagsModal={openGoodyBagsModal}
            currentUser={currentUser}
          />
        </div>
      </div>
      {!freePost && showPaidCaption && (
        <div className="row margintop20">
          <div className="col-xs-12">
            <PostComposeBox
              body={paidBody}
              setBody={setPaidBody}
              isPaidBody
              errors={[errors?.paid_body, errors?.base].flatMap(i => i).filter(i => i)}
              label="Compose subscriber-only caption"
              openPaymentButtonsModal={openPaymentButtonsModal}
              hasPaymentButtons={hasPaymentButtons}
              hasGoodyBags={hasGoodyBags}
              openGoodyBagsModal={openGoodyBagsModal}
              currentUser={currentUser}
            />
          </div>
        </div>
      )}
    </>
  );

  const schedulingIcon = (
    <a href="#" onClick={openScheduler} className={`${scheduledAt ? 'disabled' : ''}`}>
      <img
        src="/plus_frontend/assets/svg/ico-posts-queue-20.svg"
        alt="Posts Queue"
        className={`marginlft10 ${scheduledAt ? 'disabled' : ''}`}
      />
    </a>
  );

  const selectFilesIcon = (
    <a href="#" onClick={openSelectFilesModal}>
      <img src="/plus_frontend/assets/svg/ico-image2.svg" alt="Add Content" className="marginrgt10" />
    </a>
  );

  return (
    <>
      {!inStorefront && (
        <TitleWithBackNavigation title="Add Post" />
      )}
      <div className="container-fluid padding20 paddingtop0">
        <div className="row">
          <div className="col-xs-12 start-xs">
            <div className="container-form">

              {onboardingComplete ? postTypeControls : onboardingInstructions}

              {composeCaptionInputs}

              <div className="row margintop10">
                <div className="col-xs-12">
                  <div className="delim" />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 start-xs">
                  {SortableMediaUI}
                </div>
              </div>
              <div className="row margintop20 marginbot300">
                <div className="col-sm-6 start-sm col-xs-6 start-xs margintop12">
                  {openSelectFilesModal && selectFilesIcon}
                  {onboardingComplete && schedulingIcon}
                </div>
                <div className="col-sm-6 end-sm col-xs-6 end-xs form-buttons">
                  {createPostButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CreatePost.propTypes = {
  body: PropTypes.string.isRequired,
  setBody: PropTypes.func.isRequired,
  paidBody: PropTypes.string.isRequired,
  setPaidBody: PropTypes.func.isRequired,
  freePost: PropTypes.bool.isRequired,
  freePostChanged: PropTypes.func.isRequired,
  showPaidCaption: PropTypes.bool.isRequired,
  setShowPaidCaption: PropTypes.func.isRequired,
  openSelectFilesModal: PropTypes.func.isRequired,
  subscriberPost: PropTypes.bool.isRequired,
  subscriberPostChanged: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  hasSelectedItems: PropTypes.bool.isRequired,
  SortableMediaUI: PropTypes.object.isRequired,
  onboardingComplete: PropTypes.bool.isRequired,
  fetchingCreatePost: PropTypes.bool,
  openSchedulingModal: PropTypes.func.isRequired,
  scheduledAt: PropTypes.string,
  removeSchedule: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  hasPaymentButtons: PropTypes.bool.isRequired,
  openPaymentButtonsModal: PropTypes.func.isRequired,
  hasGoodyBags: PropTypes.bool.isRequired,
  openGoodyBagsModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

CreatePost.defaultProps = {
  errors: {},
  scheduledAt: null,
  fetchingCreatePost: false,
};

export default CreatePost;
