import React from 'react';
import { PropTypes } from 'prop-types';

import {
  IconButton,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const ActiveMonetaryAndPackageSubscriptionDetail = ({
  advisor,
  activeMonetaryTribute,
  activePackageSubscription,
}) => {
  const activePackage = activePackageSubscription.package;
  const monetaryChargeFailed = activeMonetaryTribute?.status === 'charge_failed';
  const packagePendingCancel = activePackageSubscription?.status === 'pending_cancel';
  const packageChargeFailed = activePackageSubscription?.status === 'charge_failed';
  const retiredPackage = activePackageSubscription?.package?.status === 'retired_package';

  const {
    dispatch,
    setCurrentStep,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const editMonetaryTribute = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_MONETARY_TRIBUTE,
    }));
  };

  const editPackageSubscription = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_PACKAGE_SUBSCRIPTION,
    }));
  };

  return (
    <Grid container rowSpacing={2} mt={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1">
          {`You currently send the following to ${advisor.login}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} mt={1}>
        <Grid container alignItems="center">
          <Grid item xs={10} container>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {`${activeMonetaryTribute.rate} per month Recurring Tribute`}
              </Typography>
            </Grid>
            {monetaryChargeFailed && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    Renewal Failed
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    <Link
                      role="button"
                      tabIndex={0}
                      onClick={useDifferentCardClick}
                      style={{ cursor: 'pointer' }}
                    >
                      Update Card Now
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="body3" style={{ color: '#666' }}>
                {monetaryChargeFailed ? 'Expires ' : 'Renews '}
                {activeMonetaryTribute.renewalDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <IconButton onClick={editMonetaryTribute}>
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} mt={1}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={10} container>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {`${activePackage.rate} per month Package`}
              </Typography>
            </Grid>
            {packageChargeFailed && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    Renewal Failed
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    <Link
                      role="button"
                      tabIndex={0}
                      onClick={useDifferentCardClick}
                      style={{ cursor: 'pointer' }}
                    >
                      Update Card Now
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="body3" style={{ color: '#666' }}>
                {
                  packagePendingCancel || packageChargeFailed || retiredPackage ?
                    'Expires ' :
                    'Renews '
                }
                {activePackageSubscription.renewalDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <IconButton onClick={editPackageSubscription}>
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActiveMonetaryAndPackageSubscriptionDetail.propTypes = {
  advisor: PropTypes.object.isRequired,
  activeMonetaryTribute: PropTypes.object.isRequired,
  activePackageSubscription: PropTypes.object.isRequired,
};

export default ActiveMonetaryAndPackageSubscriptionDetail;
