import React from 'react';
import PropTypes from 'prop-types';

const SendMailButton = ({ onClick, status }) => {
  const className = `call-button send-mail-${status}`;
  return (
    <div>
      <a
        href="#"
        onClick={onClick}
      >
        <div className={className}>
          <span>Send Mail</span>
        </div>
      </a>
    </div>
  );
};

SendMailButton.defaultProps = {
  status: 'busy',
};

SendMailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string,
};

export default SendMailButton;
