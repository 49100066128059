import React from 'react';
import { PropTypes } from 'prop-types';

import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import ActiveMonetaryTributeDetail from 'app/components/modals/tributes/ActiveMonetaryTributeDetail';
import ActivePackageSubscriptionDetail from 'app/components/modals/tributes/ActivePackageSubscriptionDetail';
import ActiveMonetaryTributeAndPackageSubscriptionDetail from 'app/components/modals/tributes/ActiveMonetaryTributeAndPackageSubscriptionDetail';

import MonetaryFormContainer from 'app/containers/modals/tributes/MonetaryFormContainer';
import PackageFormContainer from 'app/containers/modals/tributes/PackageFormContainer';

const DividerWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
}));

const MonthlyTributes = ({
  advisor,
  activeTributes,
  packages,
}) => {
  const {
    monetaryTribute: activeMonetaryTribute,
    packageSubscription: activePackageSubscription,
  } = activeTributes;

  return (
    <>
      {!activeMonetaryTribute && <MonetaryFormContainer advisor={advisor} />}

      {!activePackageSubscription && packages?.length > 0 &&
        <PackageFormContainer advisor={advisor} />}

      {(activeMonetaryTribute || activePackageSubscription) && (
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
      )}

      {activeMonetaryTribute && activePackageSubscription ? (
        <ActiveMonetaryTributeAndPackageSubscriptionDetail
          advisor={advisor}
          activeMonetaryTribute={activeMonetaryTribute}
          activePackageSubscription={activePackageSubscription}
        />
      ) : (
        <>
          {activeMonetaryTribute && (
            <ActiveMonetaryTributeDetail
              advisor={advisor}
              activeMonetaryTribute={activeMonetaryTribute}
              rate={activeMonetaryTribute.rate}
            />
          )}
          {activePackageSubscription && (
            <ActivePackageSubscriptionDetail
              advisor={advisor}
              activePackageSubscription={activePackageSubscription}
            />
          )}
        </>
      )}
    </>
  );
};

MonthlyTributes.defaultProps = {
  packages: [],
};

MonthlyTributes.propTypes = {
  advisor: PropTypes.object.isRequired,
  activeTributes: PropTypes.object.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    rate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    feed_perk: PropTypes.shape({
      id: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
    call_perk: PropTypes.shape({
      id: PropTypes.number,
      perSessionAmount: PropTypes.number,
      limitByPeriod: PropTypes.bool,
      perPeriodAmount: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
    volley_perk: PropTypes.shape({
      id: PropTypes.number,
      perPeriodAmount: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
  })),
};

export default MonthlyTributes;
