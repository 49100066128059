import React from 'react';
import { PropTypes } from 'prop-types';
import { Stack, Typography } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import BackArrow from 'app/components/shared/BackArrow';
import StyledHeader from 'app/components/shared/StyledHeader';

import { featureFlag } from 'app/util/featureFlag';

const SubscriptionSettings = ({
  onSubmitClick,
  rate,
  setRate,
  onCancelClick,
  rangeError,
  saveDisabled,
  cancelDisabled,
}) => {
  const recurringPackageTribsEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');

  return (
    <>
      <StyledHeader data-test-id="template-shell-header">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <BackArrow />
            {/* when we remove this feature flag, "subscription settings" may not make a whole lot of sense anymore in our new world of package tributes. we should consider renaming this component/container to reflect this change at that time */}
            {recurringPackageTribsEnabled && (
              <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle">Set Price</Typography>
            )}
            {!recurringPackageTribsEnabled && (
              <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle">Subscription Settings</Typography>
            )}
          </Stack>
        </Stack>
      </StyledHeader>
      <div className="container-fluid padding20">
        <div className="row">
          <div className="col-xs-12 start-xs">
            <div className="container-form margintop20">
              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  <div className={`label-float ${rangeError ? 'has-error' : ''}`}>
                    <CurrencyInput
                      prefix="$"
                      id="input-example"
                      name="input-name"
                      placeholder="Please enter a number"
                      value={rate}
                      onValueChange={(value) => setRate(value)}
                    />
                    <label className="filled">Price per month</label>
                    <div
                      className="text-error"
                      style={{ display: rangeError === 'lo' ? 'block' : 'none' }}
                    >
                      Minimum $3.99 USD
                    </div>
                    <div
                      className="text-error"
                      style={{ display: rangeError === 'hi' ? 'block' : 'none' }}
                    >
                      Maximum $50.00 USD
                    </div>
                    <div
                      className="help-text"
                      style={{ display: rangeError ? 'none' : 'block' }}
                    >
                      Minimum $3.99 USD
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 end-sm col-xs-12 end-xs form-buttons">
                  <div className="row end-xs padding-0-8">
                    <a
                      role="button"
                      tabIndex={0}
                      className={`btn secondary ${cancelDisabled ? 'disabled' : ''}`}
                      onClick={onCancelClick}
                    >
                      Cancel
                    </a>
                    <a
                      className={`btn ${rangeError || saveDisabled ? 'disabled' : ''}`}
                      role="button"
                      tabIndex={0}
                      onClick={() => onSubmitClick(rate)}
                    >
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SubscriptionSettings.defaultProps = {
  rate: 0,
  rangeError: null,
};

SubscriptionSettings.propTypes = {
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setRate: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  rangeError: PropTypes.string,
  saveDisabled: PropTypes.bool.isRequired,
  cancelDisabled: PropTypes.bool.isRequired,
};

export default SubscriptionSettings;
