// Largely inspired by plus_frontend/src/javascripts/app/helpers/listingHelpers.js which is inspired by
// application_helper.rb#call_button_link. We diverge from this routine by passing forward a way to hijack clicks and
// forwarding a disabed class. What could go wrong?
import React from 'react';
import PropTypes from 'prop-types';

const CallButton = ({ onClick, disabled }) => {
  const className = disabled ? 'call-button call-now--disabled' : 'call-button call-now';
  return (
    <div>
      <a
        href="#"
        onClick={onClick}
      >
        <div className={className}>
          <span>Call Now</span>
        </div>
      </a>
    </div>
  );
};

CallButton.defaultProps = {
  disabled: false,
};

CallButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CallButton;
