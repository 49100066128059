/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lowestPricePackages: {},
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setLowestPricePackages: (state, action) => {
      state.lowestPricePackages = action.payload;
    },
  },
});

const selectors = {
  lowestPricePackageForAdvisorSelector: (state, advisorId) => state.feed.lowestPricePackages[advisorId],
};

export default feedSlice;

export const {
  setLowestPricePackages,
} = feedSlice.actions;

export const {
  lowestPricePackageForAdvisorSelector,
} = selectors;
