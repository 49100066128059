import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const DescBox = styled(Box)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  height: '3em',
  marginTop: '5px',
  fontSize: '0.813em',
  lineHeight: '1.5',
  '& a': {
    color: 'var(--card_secondary_txt)',
    cursor: 'pointer',
  },
  '& a:hover': {
    color: 'var(--link_color)',
    textDecoration: 'underline',
  },
});

export default DescBox;
