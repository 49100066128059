import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CheckboxChip from 'app/components/CheckboxChip';

// eslint-disable-next-line react/prop-types
const GenderSelectChips = ({ state, setState }) => {
  return (
    <Box display="flex" justifyContent="start" my={1.5}>
      <CheckboxChip label="Women" value="839" state={state} setState={setState} />
      <CheckboxChip label="Men" value="840" state={state} setState={setState} />
      <CheckboxChip label="Trans" value="770" state={state} setState={setState} />
    </Box>
  );
};

GenderSelectChips.propTypes = {
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  setState: PropTypes.func.isRequired,
};

export default GenderSelectChips;
