import React from 'react';
import { IconButton } from '@mui/material';
import { ViewList as ViewListIcon } from '@mui/icons-material';
import GridViewIcon from '@mui/icons-material/GridView';
import { useFilePlayerContext } from '../FilePlayerContext';

export const FileToggleViewButton = () => {
  const { listView, setListView } = useFilePlayerContext();

  return (
    <IconButton onClick={() => setListView(!listView)} color="primary">
      {listView ? <GridViewIcon /> : <ViewListIcon />}
    </IconButton>
  );
};
