import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  DialogContent,
} from '@mui/material';

import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';
import CreatableTagSelect from 'app/components/customers/CreatableTagSelect';

const AssignTagsDialog = ({ allTags, currentTags, seekerIds }) => {
  const { closeDialog } = useContext(DialogsContext);
  return (
    <>
      <DialogTitle>
        Assign Tags
      </DialogTitle>

      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <CreatableTagSelect
          label="Tags"
          options={allTags}
          seekerIds={seekerIds}
          value={currentTags.filter((x) => x)}
          closeDialog={closeDialog}
          useSaveButton
        />
      </DialogContent>
    </>
  );
};

AssignTagsDialog.defaultProps = {
  allTags: [],
};

AssignTagsDialog.propTypes = {
  allTags: PropTypes.arrayOf(PropTypes.string),
  currentTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  seekerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default AssignTagsDialog;
