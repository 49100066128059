import React from 'react';
import CallContainer from 'app/containers/CallContainer';

const CallNowLayout = () => {
  return (
    <CallContainer />
  );
};

export default CallNowLayout;
