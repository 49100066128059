import React from 'react';
import { PropTypes } from 'prop-types';

import {
  IconButton,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import PerkList from 'app/components/tributes/PerkList';

import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const ActiveTributeDetail = ({
  advisor,
  activePackageSubscription,
}) => {
  const activePackage = activePackageSubscription.package;
  const pendingCancel = activePackageSubscription?.status === 'pending_cancel';
  const chargeFailed = activePackageSubscription?.status === 'charge_failed';
  const retiredPackage = activePackageSubscription?.package?.status === 'retired_package';

  const {
    dispatch,
    setCurrentStep,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const editSubscription = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_PACKAGE_SUBSCRIPTION,
    }));
  };

  return (
    <Grid container rowSpacing={2} mt={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1">
          {`You also have the following Package with ${advisor.login}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} mt={1}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={10} container>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {`${activePackage.rate} per month`}
              </Typography>
            </Grid>
            {chargeFailed && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    Renewal Failed
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    <Link
                      role="button"
                      tabIndex={0}
                      onClick={useDifferentCardClick}
                      style={{ cursor: 'pointer' }}
                    >
                      Update Card Now
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="body3" style={{ color: '#666' }}>
                {
                  pendingCancel || chargeFailed || retiredPackage ?
                    'Expires ' :
                    'Renews '
                }
                {activePackageSubscription.renewalDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <IconButton onClick={editSubscription}>
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="name" mt={1} mb={-1} pl={1}>
              Perks:
            </Typography>
            <PerkList packageData={activePackage} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActiveTributeDetail.propTypes = {
  advisor: PropTypes.object.isRequired,
  activePackageSubscription: PropTypes.object.isRequired,
};

export default ActiveTributeDetail;
