import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Tabs,
  Tab,
  IconButton,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import BlockIcon from '@mui/icons-material/Block';
import ChatIconNF from 'app/components/customSvgs/ChatIconNF';
import MailIcon from '@mui/icons-material/Mail';
import { useConfirm } from 'material-ui-confirm';

import { LAPSE_STATUS_COLORS, EARNINGS_GROUP_COLORS } from 'app/constants/customers';
import ColorMappedChip from 'app/components/customers/ColorMappedChip';
import DialogsContext from 'app/contexts/DialogsContext';
import SendChatMessageDialog from 'app/components/customers/SendChatMessageDialog';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import StyledHeader from 'app/components/shared/StyledHeader';
import CircularProgress from 'app/components/shared/CircularProgress';
import CustomerDetailsOverview from 'app/components/customers/CustomerDetailsOverview';
import CustomerDetailsOffers from 'app/components/customers/CustomerDetailsOffers';
import CustomerDetailsFeedback from 'app/components/customers/CustomerDetailsFeedback';
import CustomerDetailsHistory from 'app/components/customers/CustomerDetailsHistory';

import {
  useCreateBlockMutation,
  useDestroyBlockMutation,
  useGetCustomerQuery,
  useSendChatMessageMutation,
} from 'app/api/customersApi';

const TABS = {
  overview: 0,
  offers: 1,
  feedback: 2,
  history: 3,
};

const CustomerDetails = () => {
  const { login, tabName } = useParams();
  const tabIndex = TABS[tabName] || 0;
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { openDialog } = useContext(DialogsContext);
  const { data: customer } = useGetCustomerQuery({ login });
  const [createBlock] = useCreateBlockMutation();
  const [destroyBlock] = useDestroyBlockMutation();
  const [sendChatMessage] = useSendChatMessageMutation();

  const systemTagList = (customer?.system_tag_list || '').split('^').filter((tag) => tag);
  const blocked = systemTagList.includes('Blocked');

  const handleTabChange = (_event, newValue) => {
    const tabKey = Object.keys(TABS).find(key => TABS[key] === newValue);
    navigate(`/my_customers/user/${login}/${tabKey}`);
  };

  const handleBack = () => navigate('/my_customers');

  const handleBlock = () => {
    if (blocked) {
      confirm({
        title: 'Confirm unblock',
        description: 'Are you sure you want to unblock this customer?',
      })
        .then(() => destroyBlock({ ids: [customer.seeker_id] }))
        .then(handleBack)
        .catch(() => {});
    } else {
      confirm({
        title: 'Confirm block',
        description: 'Blocked customers can’t contact you or buy from you. Blocking will also end any subscriptions.',
      })
        .then(() => createBlock({ ids: [customer.seeker_id] }))
        .then(handleBack)
        .catch(() => {});
    }
  };

  const handleChat = () => {
    openDialog({
      component: SendChatMessageDialog,
      props: {
        sendChatMessage,
        seekerIds: [customer.seeker_id],
      },
    });
  };

  const handleMail = () => {
    window.location.href = `/messages/compose?message[recipients_list]=${customer.seeker_name}`;
  };

  if (!customer) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="100%"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  return (
    <AppBodyWithSidebar>
      <StyledHeader>
        <Stack direction="row" alignItems="center" width="100%">
          <IconButton onClick={handleBack}>
            <ArrowBackIosSharpIcon fontSize="medium" htmlColor="white" />
          </IconButton>
          <Stack
            direction="row"
            spacing="16px"
            useFlexGap
            alignItems="center"
            justifyContent="flex-end"
            flexGrow={2}
          >
            <IconButton onClick={handleBlock} title="Block">
              <BlockIcon fontSize="medium" htmlColor="white" />
            </IconButton>
            <IconButton onClick={handleChat} title="Send chat message">
              <ChatIconNF fontSize="medium" htmlColor="white" />
            </IconButton>
            <IconButton onClick={handleMail} title="Mail">
              <MailIcon fontSize="medium" htmlColor="white" />
            </IconButton>
          </Stack>
        </Stack>
      </StyledHeader>

      <Stack direction="column" spacing={2} sx={{ mt: 1.5, mb: 0 }}>
        <Stack
          direction="row"
          sx={{ gap: 2, alignItems: 'center', p: 2, pl: { xs: 2, sm: 2.5 }, pb: 0 }}
        >
          <ColorMappedChip
            label={customer.interval_earnings_group}
            colorMap={EARNINGS_GROUP_COLORS}
          />
          <ColorMappedChip
            label={customer.lapse_status}
            colorMap={LAPSE_STATUS_COLORS}
          />
          <Typography
            variant="body1"
            sx={{ pl: 1 }}
          >
            { customer.seeker_name }
          </Typography>
        </Stack>

        <Box sx={{ maxWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="customer details tabs">
            <Tab sx={{ width: { xs: '90px', sm: '110px' } }} label="Overview" />
            <Tab sx={{ width: { xs: '90px', sm: '110px' } }} label="Offers" />
            <Tab sx={{ width: { xs: '90px', sm: '110px' } }} label="Feedback" />
            <Tab sx={{ width: { xs: '90px', sm: '110px' } }} label="History" />
          </Tabs>
        </Box>

        {tabIndex === 0 && (<CustomerDetailsOverview customer={customer} />)}
        {tabIndex === 1 && (<CustomerDetailsOffers />)}
        {tabIndex === 2 && (<CustomerDetailsFeedback seekerId={customer.seeker_id} />)}
        {tabIndex === 3 && (<CustomerDetailsHistory />)}
      </Stack>
    </AppBodyWithSidebar>
  );
};

export default CustomerDetails;
