import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';

import TitleBackArrowImport from 'app/components/shared/TitleBackArrowImport';

import {
  NEW,
  ACCEPTED_2257,
  HAS_OFFERING,
  HAS_PACKAGE,
  isLiabilityAccepted,
  isSubscriptionPriceSet,
  hasCreatedWelcomePost,
} from 'app/helpers/onboardingHelpers';

import { featureFlag } from 'app/util/featureFlag';

const Onboarding = ({
  openLegalLiabilityModal,
  onboardingStep,
  onSetPriceClick,
}) => {
  if (!onboardingStep) return null;

  const liabilityAccepted = isLiabilityAccepted(onboardingStep);
  const subscriptionPriceSet = isSubscriptionPriceSet(onboardingStep);
  const createdWelcomePost = hasCreatedWelcomePost(onboardingStep);
  const navigate = useNavigate();

  return (
    <>
      <TitleBackArrowImport />
      <div className="container-start-to-earn margintop0">
        <div className="row">
          <div className="col-sm-7 col-xs-12 padding30 paddingtop10">
            {!featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
              <>
                <h2>Earn more while being in control of your content.</h2>
                <h3 className="marginbot5">Start earning more with Feeds</h3>
                Expand your NiteFlirt business with our monthly subscription offering where you control who sees your content.
              </>
            )}
            {featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
              <>
                <h2>Expand your NiteFlirt business with Feeds</h2>
                <h3 className="marginbot5">Earn more and be in control</h3>
                With free posts and exclusive content only available to monthly subscriptions, Feeds enhances engagement and extends your reach to new customers.
              </>
            )}
            <br />
            <br />
            <h3 className="marginbot5">Get Started</h3>
            <ol>
              <li>Review and agree to our terms regarding the content you post.</li>
              <div>
                <div className="row marginlft0">
                  <div>
                    <a
                      className={`btn btn-sm margin0 marginbot30
                        ${onboardingStep !== NEW ? 'disabled' : ''}
                      `}
                      role="link"
                      tabIndex={0}
                      onClick={() => {
                        if (onboardingStep !== NEW) return;
                        openLegalLiabilityModal();
                      }}
                    >
                      Review and Agree
                    </a>
                  </div>
                  <div className="margin5">
                    {liabilityAccepted && (
                      <img src="/plus_frontend/assets/svg/ico-checkmark.svg" alt="checkmark" />
                    )}
                  </div>
                </div>
              </div>
              {/* set subscription price */}
              <li>Set a monthly subscription price.</li>
              <div>
                <div className="row marginlft0">
                  <div>
                    <a
                      role="link"
                      tabIndex={0}
                      onClick={onSetPriceClick}
                      className={`btn btn-sm margin0 marginbot30
                        ${onboardingStep !== ACCEPTED_2257 ? 'disabled' : ''}
                      `}
                    >
                      Set Price
                    </a>
                  </div>
                  <div className="margin5">
                    {subscriptionPriceSet && (
                      <img src="/plus_frontend/assets/svg/ico-checkmark.svg" alt="checkmark" />
                    )}
                  </div>
                </div>
              </div>
              {/* publish welcome kit */}
              {!featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
                <li>Create your first post. Your first post will be visible to all future subscribers. We suggest you create 10 posts like this so that your new subscribers get value right away. You control which posts new subscribers can access and this can be changed at any time.</li>
              )}
              {featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
                <li>Create your first post. Your first post will be visible to all future subscribers. You control which posts new subscribers can access and this can be changed at any time.</li>
              )}
              <div>
                <div className="row marginlft0">
                  <div>
                    {!featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
                      <a
                        role="link"
                        tabIndex={0}
                        onClick={() => {
                          if (onboardingStep !== HAS_OFFERING) return;
                          navigate('/feed/create_post');
                        }}
                        className={`btn btn-sm margin0 marginbot30
                          ${onboardingStep !== HAS_OFFERING ? 'disabled' : ''}`}
                      >
                        Create First Post
                      </a>
                    )}

                    {featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
                      <a
                        role="link"
                        tabIndex={0}
                        onClick={() => {
                          if (onboardingStep !== HAS_PACKAGE) return;
                          navigate('/feed/create_post');
                        }}
                        className={`btn btn-sm margin0 marginbot30
                          ${onboardingStep !== HAS_PACKAGE ? 'disabled' : ''}`}
                      >
                        Create First Post
                      </a>
                    )}
                  </div>
                  <div className="margin5">
                    {/* this should really never happen, we don't see the onboarding flow
                    if we've completed all the steps */}
                    {createdWelcomePost && (
                      <img src="/plus_frontend/assets/svg/ico-checkmark.svg" alt="checkmark" />
                    )}
                  </div>
                </div>
              </div>
            </ol>
            Have questions?&nbsp;
            <a href="https://support.niteflirt.com/hc/en-us/articles/8330585751309-NiteFlirt-Plus-NF-" target="_blank" rel="noreferrer">Check out the Help Center.</a>
          </div>
          <div className="col-sm-5 end-sm col-xs-12 center-xs padding30">
            <img src="/plus_frontend/assets/flirt/sample-plus2.png" className="img-sample" alt="Sample" />
          </div>
        </div>
      </div>
    </>
  );
};

Onboarding.defaultProps = {
  onboardingStep: null,
};

Onboarding.propTypes = {
  openLegalLiabilityModal: PropTypes.func.isRequired,
  onSetPriceClick: PropTypes.func.isRequired,
  onboardingStep: PropTypes.string,
};

export default Onboarding;
