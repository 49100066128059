import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useMediaQuery,
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  FormControl,
  InputLabel,
  Select,
  Stack,
  MenuItem,
} from '@mui/material';
import CircularProgress from 'app/components/shared/CircularProgress';

import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
  getGridNumericOperators,
  getGridDateOperators,
} from '@mui/x-data-grid';

import { timestampInTimezone, FULL_DATETIME_FORMAT } from 'app/helpers/timestampHelpers';
import { toCurrency } from 'app/helpers/currencyHelpers';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { useGetDetailsHistoryQuery } from 'app/api/customersApi';

const OVERFLOW_LENGTH = 100;
// this value is mentioned in DetailsHistoryController, if changing this, update the ruby one too
const HISTORY_LIMIT = 16;

const customStringFilter = (distinctValues) => ({
  ...getGridStringOperators().find((op) => op.value === 'equals'), // Use "is" (equals) as the operator
  InputComponent: ({ item, applyValue }) => ( // eslint-disable-line react/prop-types
    <FormControl variant="standard" margin="none">
      {/* An InputLabel with hidden text to maintain spacing */}
      <InputLabel shrink sx={{ visibility: 'hidden' }}>Placeholder</InputLabel>

      <Select
        value={item.value || ''} // eslint-disable-line react/prop-types
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        displayEmpty
        sx={{ width: '100%' }}
      >
        <MenuItem value="">All</MenuItem>
        { distinctValues.map((value) => (
          <MenuItem key={value} value={value}>{value}</MenuItem>
        )) }
      </Select>
    </FormControl>
  ),
});

const CustomerDetailsHistory = () => {
  const { login } = useParams(); // userId will capture the value of :userId in the URL
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const { data, isFetching } = useGetDetailsHistoryQuery({ login });
  const distinctDescriptions = data ? [...new Set(data.map(item => item.description))] : [];

  const urlFromTransaction = (transaction) => (
    `/transactions/details/${transaction?.id}?btids=${transaction?.btids}`
  );
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [preferLargeScreen, setPreferLargeScreen] = useState(false);

  if (isFetching) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="100%"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  if (!data) {
    return (
      <Typography variant="body1" fontWeight="bold">
        No history found.
      </Typography>
    );
  }

  const DATA_GRID_COLUMNS = [
    {
      field: 'created_at',
      headerName: 'Date',
      width: 180,
      renderCell: (params) => (
        <a href={urlFromTransaction(params.row)}>
          {timestampInTimezone(params.value, currentUser?.canonical_iana_timezone, FULL_DATETIME_FORMAT)}
        </a>
      ),
      filterOperators: getGridDateOperators(),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      filterOperators: [customStringFilter(distinctDescriptions)],
    },
    { field: 'title', headerName: 'Title', width: 240 },
    {
      field: 'amount',
      headerName: 'Earnings',
      width: 100,
      valueFormatter: (params) => toCurrency(params.value),
      filterOperators: getGridNumericOperators(),
    },
  ];

  if (!isSmallScreen || preferLargeScreen) {
    return (
      <Box sx={{ height: '80vh', px: { xs: 1, sm: 2 } }}>
        <DataGrid
          columns={DATA_GRID_COLUMNS}
          rows={data}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              overflow: 'visible',
            },
            '& .MuiDataGrid-columnHeader': {
              overflow: 'visible',
            },
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input': {
              display: 'none !important', // Hide "Rows per page" element
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal', // Allow text wrapping
              wordWrap: 'break-word',
              lineHeight: '1.5rem',
              py: '15px',
            },
          }}
          getRowHeight={() => 'auto'} // Dynamically adjust row height
        />
        <Typography sx={{ color: 'var(--color_gray_nine)', p: 2 }}>
          Note: Earnings history limited to&nbsp;
          {HISTORY_LIMIT}
          &nbsp;months
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: { xs: 'auto', sm: 650 } }} aria-label="simple table">
          <TableBody>
            {data?.slice(0, OVERFLOW_LENGTH)?.map((history, index) => (
              <TableRow
                key={history.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#F2F2F2' : 'white',
                }}
              >
                <TableCell sx={{ verticalAlign: 'top', width: '70px' }}>
                  <Typography variant="body1" sx={{ color: 'var(--color_gray_six)' }}>
                    Date
                    <br />
                    Description
                    <br />
                    Title
                    <br />
                    Earnings
                  </Typography>
                </TableCell>

                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography>
                    <a href={urlFromTransaction(history)}>
                      {timestampInTimezone(history.created_at, currentUser?.canonical_iana_timezone, FULL_DATETIME_FORMAT)}
                    </a>
                  </Typography>
                  <Typography>{history.description}</Typography>
                  <Typography>{history.title}</Typography>
                  <Typography>{toCurrency(history.amount)}</Typography>
                </TableCell>
              </TableRow>
            ))}
            { data?.length > OVERFLOW_LENGTH && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="body1" fontWeight="bold">
                    ... Data truncated.  To see more,&nbsp;
                    <Button variant="text" color="primary" onClick={() => setPreferLargeScreen(true)}>view desktop version.</Button>
                  </Typography>
                </TableCell>
              </TableRow>
            ) }
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ color: 'var(--color_gray_nine)', p: 2 }}>
        Note: Earnings history limited to&nbsp;
        {HISTORY_LIMIT}
        &nbsp;months
      </Typography>
    </Box>
  );
};

export default CustomerDetailsHistory;
