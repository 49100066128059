import React from 'react';
import { PropTypes } from 'prop-types';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';
import {
  Box,
  Button,
  Stack,
} from '@mui/material';

const PackageSubscriptionButton = ({
  advisorPackage,
  currentUser,
  subscribeToPackageClick,
}) => {
  return (
    <Box mt={3}>
      <AuthenticatedLink
        className="subscription-btn"
        role="link"
        tabIndex={0}
        currentUser={currentUser}
        onClick={subscribeToPackageClick}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {`Full Feed Access ${advisorPackage?.rate}/mo`}
          </Stack>
        </Button>
      </AuthenticatedLink>
    </Box>
  );
};

PackageSubscriptionButton.defaultProps = {
  advisorPackage: {},
  currentUser: null,
};

PackageSubscriptionButton.propTypes = {
  advisorPackage: PropTypes.object,
  currentUser: PropTypes.object,
  subscribeToPackageClick: PropTypes.func.isRequired,
};

export default PackageSubscriptionButton;
