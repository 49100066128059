export const COMBINED_FEED_CONTENT_AVAILABLE_TAG = 'CombinedFeedContentAvailable';
export const FLIRT_FEED_CONTENT_AVAILABLE_TAG = 'FlirtFeedContentAvailable';
export const AVAILABLE_BALANCE_TAG = 'AvailableBalance';
export const EXPLORE_FEED_TAG = 'ExploreFeed';
export const APP_CONFIG_TAG = 'AppConfig';
export const PACKAGES_TAG = 'Packages';
export const ADVISOR_TAG = 'Advisor';
export const FOLLOW_TAG = 'Follow';
export const POST_TAG = 'Post';
export const CUSTOMER_LISTS_TAG = 'CustomerLists';
export const CUSTOMER_SAVED_SEARCHES_TAG = 'CustomerSavedSearches';
export const CUSTOMER_TAG = 'Customer';
export const PAYOUT_REQUEST_TAG = 'PayoutRequest';
