import React, {useEffect, useState} from 'react';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import { usePerformForgotPinLoginMutation } from 'app/api/mainApi';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import AutofillableTextField from 'app/components/shared/AutoFillableTextField';
import useRecaptcha from 'app/hooks/useRecaptcha';
import {getAdditionalParams, loginParams, productFromParams} from "app/helpers/signinAndRegistrationHelpers";
import { navigateTo } from 'app/util/navigation';

const BoxFullPage = styled(Box)(() => ({
  '&.MuiBox-root': {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: '0px',
    left: '0px',
    backgroundColor: 'white',
    overflow: 'scroll', // Add overflow property
  },
}));

const BoxClosePlacement = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '600px',
  margin: '0 auto -20px auto',
  padding: '10px 10px 0 0',
});

const ForgotPin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [creditCard, setCreditCard] = useState('');
  const [zip, setZip] = useState('');
  const [error, setError] = useState('');

  const { executeRecaptcha, recaptchaComponent } = useRecaptcha();

  const [performForgotPinLogin, { data: loginData, error: loginError }] = usePerformForgotPinLoginMutation();

  const onChange = (token) => {
    const additionalParams = {
      ...getAdditionalParams(),
      'g-recaptcha-response': token,
    };

    performForgotPinLogin({
      phoneNumber,
      creditCard,
      zip,
      additionalParams,
    });
  };

  const onError = () => setError('reCAPTCHA verification failed. Please reload the page to try again.')

  useEffect(() => {
    if (loginData) {
      navigateTo(`signup${loginParams(productFromParams())}`);
    }
  }, [loginData]);

  const goToRegister = () => {
    navigateTo(`signup${loginParams(productFromParams())}`);
  };

  // Make sure the user cannot input more than expected
  let formattedPhone = phoneNumber;

  // Mask the number as the user types.
  if (phoneNumber.length > 3) {
    formattedPhone = `(${phoneNumber.substring(0, 3)}) `;

    if (phoneNumber.length < 6) {
      formattedPhone += phoneNumber.substring(3)
    } else {
      formattedPhone += phoneNumber.substring(3, 6);
    }

    if (phoneNumber.length > 6) {
      formattedPhone += ' - ' + phoneNumber.substring(6, 10);
    }
  }

  return (
    <BoxFullPage>
      <BoxClosePlacement>
        <IconButton
          aria-label="close"
          onClick={() => { window.location.href = '/login_by_phone'; }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BoxClosePlacement>
      <DialogContent sx={{ maxWidth: '450px', margin: '0 auto' }}>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <a href="/">
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          </a>
          <Typography variant="h3">
            Forgot PIN
          </Typography>
          <Typography variant="body1">
            Enter the details below to sign in and complete your account registration.
          </Typography>
          {(loginError || error) && (
          <Stack mb={3}>
            <Typography variant="body3" style={{ color: '#d32f2f' }} class="login-error">
              <SanitizedHTML html={loginError ? loginError.data.error : error } />
            </Typography>
          </Stack>
        )}
        </Stack>
        <Stack direction="column" spacing={3} mb={3}>
          <AutofillableTextField
            label="Phone Number"
            type="tel"
            value={formattedPhone}
            setValue={(value) => setPhoneNumber(value.replace(/\D/g, ""))}
            autoComplete={'off'}
            name="phone"
          />
          <AutofillableTextField
            label="Credit Card (Last 6 digits)"
            variant="outlined"
            size="small"
            type="password"
            name="last_cc_digits"
            value={creditCard}
            autoComplete={'off'}
            setValue={(value) => setCreditCard(value.replace(/\D/g, ""))}
          />
          <AutofillableTextField
            name="zip"
            label="Billing Zip"
            variant="outlined"
            size="small"
            type="password"
            value={zip}
            autoComplete={'off'}
            setValue={(value) => setZip(value)}
          />
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button
            onClick={executeRecaptcha}
            variant="contained"
            size="large"
            fullWidth
            disabled={!phoneNumber || !creditCard || !zip}
          >
            Sign In
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant="h5">
            New to NiteFlirt?
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button onClick={goToRegister} variant="outlined" size="medium" fullWidth>
            Register
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <a href="/login">Already have a Member Name?</a>
          </Typography>
        </Stack>
        { recaptchaComponent(onChange, onError) }
      </DialogContent>
    </BoxFullPage>
  )
}

export default ForgotPin;
