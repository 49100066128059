import React, { useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import { featureFlag } from 'app/util/featureFlag';

import withTooltip from 'app/hoc/withTooltip';
import RecordNameDrawer from 'app/components/modals/RecordNameDrawer';

const KebobMenu = ({
  advisor,
  currentUser,
  darkAssets,
  openRecordNameDrawer,
  closeRecordNameDrawer,
  handleToggleMemberSince,
  memberSinceToggleText,
  isRecordNameDrawerOpen,
  // withTooltip props
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
}) => {
  const anonymousUser = !currentUser;
  const loggedInVisitor = currentUser;
  const advisorIsVisitor = advisor?.id === currentUser?.id;
  const userHasListing = currentUser?.record_name_path;
  const [copyLinkText, setCopyLinkText] = useState('Copy Link');
  const isPackagesEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');

  // NOTE: React inline styles appear to not support the filter property, so we have to use a class
  const menuAssetClassName = darkAssets ? 'icon-more-hero--dark' : 'icon-more-hero';

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopyLinkText('Copied!');

    setTimeout(() => setCopyLinkText('Copy Link'), 1000);
  };

  const handleShareClick = () => navigator.share({
    url: window.location.href,
    text: 'Check out this Flirt on NF+: ',
  });

  return (
    <>
      <Tooltip
        content={(
          <div
            ref={(el) => setTipContentRef(el)}
            className="nfp-tooltip"
          >
            <div className="row nowrap-xs">
              <div className="col-xs-11">
                <div className="menu-links">
                  {anonymousUser && (
                    <>
                      <Link
                        to={`/login?return_to=${window.location.pathname}`}
                        title="Send Mail"
                        role="link"
                        reloadDocument
                        tabIndex={-11}
                      >
                        Send Mail
                      </Link>
                      <br />
                      <a
                        role="link"
                        title={copyLinkText}
                        onClick={handleCopyLinkClick}
                        tabIndex={-2}
                      >
                        {copyLinkText}
                      </a>
                      <br />
                      <Link
                        to={`/profile/${advisor?.login}`}
                        title="Flirt Profile"
                        rel="noreferrer"
                        tabIndex={-10}
                      >
                        Flirt Profile
                      </Link>
                      <br />
                      {navigator.share && (
                        <a
                          title="Share"
                          role="link"
                          onClick={handleShareClick}
                          tabIndex={-1}
                        >
                          Share
                        </a>
                      )}
                      <br />
                    </>
                  )}

                  {loggedInVisitor && !advisorIsVisitor && (
                    <>
                      <Link
                        to={`/messages/compose?message[recipients_list]=${advisor?.login}&return_to=${window.location.pathname}`}
                        title="Send Mail"
                        role="link"
                        reloadDocument
                        tabIndex={-9}
                      >
                        Send Mail
                      </Link>
                      <br />
                      <a
                        role="link"
                        title={copyLinkText}
                        onClick={handleCopyLinkClick}
                        tabIndex={-2}
                      >
                        {copyLinkText}
                      </a>
                      <br />
                      <Link
                        to={`/profile/${advisor?.login}`}
                        title="Flirt Profile"
                        rel="noreferrer"
                        tabIndex={-8}
                      >
                        Flirt Profile
                      </Link>
                      <br />
                      {navigator.share && (
                        <a
                          title="Share"
                          role="link"
                          onClick={handleShareClick}
                          tabIndex={-1}
                        >
                          Share
                        </a>
                      )}
                      <br />
                    </>
                  )}

                  {advisorIsVisitor && (
                    <>
                      <Link
                        to={`/profile/${advisor?.login}`}
                        title="Flirt Profile"
                        rel="noreferrer"
                        tabIndex={-7}
                      >
                        Flirt Profile
                      </Link>
                      <br />
                      <a
                        title={memberSinceToggleText()}
                        role="link"
                        onClick={debounce(handleToggleMemberSince, 500)}
                        tabIndex={-6}
                      >
                        {memberSinceToggleText()}
                      </a>
                      <br />
                      <Link
                        to="#"
                        title="Record Member Name"
                        tabIndex={-5}
                        reloadDocument={false}
                        onClick={openRecordNameDrawer}
                      >
                        Record Member Name
                      </Link>
                      <br />
                      {advisor?.onboarding_complete && (
                        <>
                          <Link
                            to="/feed/create_post"
                            title="New Post"
                            rel="noreferrer"
                            tabIndex={-4}
                          >
                            New Post
                          </Link>
                          <br />
                          <Link
                            to="/feed/queue/scheduled"
                            title="Posts Queue"
                            rel="noreferrer"
                            tabIndex={-3}
                          >
                            Posts Queue
                          </Link>
                          <br />
                          <Link
                            to="/feed/subscription_settings"
                            title="Subscription Settings"
                            rel="noreferrer"
                            tabIndex={-2}
                          >
                            Subscription Settings
                          </Link>
                          <br />
                        </>
                      )}
                      {isPackagesEnabled && (
                        <>
                          <Link
                            to={`/${advisor?.login}/packages`}
                            title="Manage Packages"
                            rel="noreferrer"
                            tabIndex={-1}
                          >
                            Manage Packages
                          </Link>
                          <br />
                        </>
                      )}
                      <Link
                        to="/feed/subscriptions"
                        title="Subscriptions"
                        rel="noreferrer"
                        tabIndex={0}
                      >
                        Subscriptions
                      </Link>
                    </>
                  )}

                </div>
              </div>
              <div className="col-xs-1 end-xs">
                <a
                  role="link"
                  tabIndex={1}
                  title="Close Profile Menu"
                  onClick={toggleTip}
                >
                  <img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" />
                </a>
              </div>
            </div>
          </div>
        )}
        isOpen={tipOpen}
        tagName="span"
        direction="down"
        forceDirection
      >
        <a
          role="link"
          tabIndex={0}
          title="Toggle Profile Menu"
          ref={(el) => setButtonRef(el)}
          onClick={toggleTip}
        >
          <img src="/plus_frontend/assets/svg/ico-kebob.svg" alt="More" className={menuAssetClassName} />
        </a>
      </Tooltip>
      {userHasListing && (
        <RecordNameDrawer
          anchor="right"
          isOpen={isRecordNameDrawerOpen}
          closeDrawer={closeRecordNameDrawer}
          currentUser={currentUser}
        />
      )}
    </>
  );
};

KebobMenu.defaultProps = {
  advisor: null,
  currentUser: null,
  darkAssets: false,
};

KebobMenu.propTypes = {
  advisor: PropTypes.object,
  currentUser: PropTypes.object,
  darkAssets: PropTypes.bool,
  openRecordNameDrawer: PropTypes.func.isRequired,
  closeRecordNameDrawer: PropTypes.func.isRequired,
  handleToggleMemberSince: PropTypes.func.isRequired,
  memberSinceToggleText: PropTypes.func.isRequired,
  isRecordNameDrawerOpen: PropTypes.bool.isRequired,
  // withTooltip props
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
};

export default withTooltip(KebobMenu);
