import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#06c',
      light: '#fff',
      // dark: '#63177a',
      // contrastText: '#ffcc00',
    },
    secondary: {
      main: '#63177a',
      light: '#fff',
      // dark: '#500e64',
      // contrastText: '#ffcc00',
    },
    customPurple1: { main: '#500e64', light: '#fff' }, // 1st Purple
    customPurple2: { main: '#63177a' }, // 2nd Purple
    customPurple3: { main: '#82468b' }, // Muted Purple
    customPurple4: { main: '#f5f5fe' }, // Active purple
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: { fontSize: '1.625em', fontWeight: '300' },
    h2: { fontSize: '1.500em', fontWeight: '300' },
    h3: { fontSize: '1.375em', fontWeight: '300' },
    h4: { fontSize: '1.250em', fontWeight: '300' },
    h5: { fontSize: '1.125em', fontWeight: '300', lineHeight: '1.5em' },
    h6: { fontSize: '1.063em', fontWeight: '500' },
    subtitle1: { fontSize: '1.000em', fontWeight: '500' },
    subtitle2: { fontSize: '0.938em', fontWeight: '500' },
    subtitle3: { fontSize: '0.875em', fontWeight: '500', display: 'block' },
    body1: { fontSize: '1.000em' },
    body2: { fontSize: '0.938em' },
    body3: { fontSize: '0.875em' },
    caption: { fontSize: '0.875em', color: '#666' },
    error: { color: '#d32f2f', fontSize: '0.875em' },
    // More custom variants
    purpleHeaderTitle: { fontSize: '1em', fontWeight: '300' },
  },
  components: {
    // *************** Typography ***************
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiDialogTitle-root': {
            fontSize: '1.125em',
          },
        },
        h6: {
          '&.MuiTypography-gutterBottom': {
            marginBottom: 5,
          },
        },
      },
    },
    // *************** Alerts ***************
    MuiAlert: {
      maxWidth: '800px',
      styleOverrides: {
        root: {
          '&.MuiPaper-root': {
            boxShadow: 'none',
          },
        },
        standardSuccess: {
          backgroundColor: '#c7eec7',
        },
        standardInfo: {
          backgroundColor: '#e5f6fd',
        },
        standardWarning: {
          backgroundColor: '#f9ec9b',
        },
        standardError: {
          backgroundColor: '#ffcdcc',
        },
      },
    },
    // *************** Autocomplete ***************
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          '& .MuiAutocomplete-listbox': {
            borderRadius: '0px',
            maxHeight: '40vh',
            padding: '0px',
            overflowX: 'hidden',
            overflowY: 'scroll',
            '@media (max-width: 600px)': {
              maxHeight: '200px',
            },
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#B9B9B9',
            },
            '&::-webkit-scrollbar-track': {
              background: '#DDDDDD',
            },
          },
        },
        paper: {
          borderRadius: '0px',
        },
      },
    },
    // *************** Button and Button Groups ***************
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
          textTransform: 'none',
          borderRadius: '6px',
          boxShadow: 'none',
          fontWeight: '400',
          '&:hover': {
            boxShadow: 'none', // Disable shadow on hover
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #ccc',
          borderRadius: '6px',
          '& .MuiButtonBase-root': {
            backgroundColor: 'white',
            color: '#666',
          },
          '& .MuiButtonGroup-grouped:not(:last-of-type)': {
            borderColor: '#ccc',
          },
          '& .active': {
            backgroundColor: '#e8e9fd',
            color: '#333',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: '38px',
          boxShadow: 'none',
          borderRadius: '6px',
          '& .MuiToggleButton-root': {
            padding: '0 15px',
            fontWeight: '400',
            color: '#06c',
            borderColor: '#ccc',
            textTransform: 'none',
            '&.Mui-selected': {
              backgroundColor: '#e8e9fd',
              color: '#333',
              borderColor: '#ccc',
              '&:hover': {
                backgroundColor: '#e8e9fd',
              },
            },
          },
        },
      },
    },
    // *************** Checkbox ***************
    MuiCheckbox: {
      defaultProps: {
        // The default props to change
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#ccc',
          '&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked:not(.Mui-disabled)': {
            color: '#63177a',
          },
        },
      },
    },
    // *************** Chip ***************
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '& .MuiChip-deleteIcon': {
            color: '#40567c',
            fontSize: '1.063em',
          },
        },
      },
    },
    // *************** Dialog ***************
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTop: 'none',
          borderBottom: 'none',
          '@media (max-width:600px)': {
            paddingBottom: '60px !important',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            marginBottom: '60px',
          },
        },
      },
    },
    // *************** Drawer **************/
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '1000', /* need this so our media modal shows above drawer */
          '& .MuiDrawer-paper': {
            backgroundColor: '#eee',
          },
        },
      },
    },
    // *************** ImageListItem ***************
    MuiImageListItem: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
    // *************** ListItemButton ***************
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '0.3em 1.5em',
          backgroundColor: 'white',
          '&.Mui-selected': {
            backgroundColor: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'white',
          },
          '&:hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
    // *************** Radio ***************
    MuiRadio: {
      defaultProps: {
        // The default props to change
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#ccc',
          '&.MuiButtonBase-root.MuiRadio-root.Mui-checked': {
            color: '#63177a',
          },
        },
      },
    },
    // *************** Tabs ***************
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--body_background)',
          '& .MuiButtonBase-root': {
            textTransform: 'uppercase',
            fontWeight: '400',
            padding: '0em 1.5em',
            color: 'var(--summary_tabs_color_link)',
            borderRadius: '0px',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'var(--summary_tabs_line_active)',
            height: '3px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'var(--body_color)',
          },
        },
      },
    },
    // *************** Table Data Table ***************
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            fontWeight: '500',
          },
        },
      },
    },
    // *************** Textfield > MuiFormControl ***************
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--body_background)',
          '& .MuiSvgIcon-root': {
            color: 'var(--color_gray_six)',
          },
        },
      },
    },
    // *************** Textfield ***************
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: '3em', // Set the minHeight of the input field
          '& fieldset': {
            borderColor: 'var(--border_color)', // Default border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--border_color_hover) !important', // Border color on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--link_color) !important', // Border color when focused
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: '3px',
          color: '#666', // Change this to your desired text color for the label
        },
        shrink: {
          marginTop: '0px',
          '&.MuiInputLabel-shrink': { // Adjust specificity to target shrink state
            fontSize: '1.188em',
            paddingRight: '6px',
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

export default theme;
