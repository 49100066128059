import React, { useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';

import {
  Box,
  Stack,
  Grid,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import IconLeaveFeedback from 'app/components/customSvgs/IconLeaveFeedback';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ForumIcon from '@mui/icons-material/Forum';
import IconAcctFeeds from 'app/components/customSvgs/IconAcctFeeds';
import IconSubscriptions from 'app/components/customSvgs/IconSubscriptions';
import IconFilePlayer from 'app/components/customSvgs/IconFilePlayer';
import IconAffiliateProgram from 'app/components/customSvgs/IconAffiliateProgram';

import { toNumber, toCurrency } from 'app/helpers/currencyHelpers';

import TabContent from 'app/components/TabContent';
import { userInitials } from 'app/helpers/userInitials';
import camelizeKeys from 'app/util/camelizeKeys';
import IconCard from './IconCard';
import SeekerForYou from './SeekerForYou';
import StickySeekerHeader from './StickySeekerHeader';
import FavoriteListingsContainer from 'app/containers/FavoriteListingsContainer';

const CustomTabs = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    borderBottom: '1px solid var(--color_ddd)',
    display: 'flex',
    '& .MuiTab-root': {
      flexGrow: '1',
      minWidth: 'auto',
    },
    '& .MuiButtonBase-root': {
      padding: '0px',
    },
  },
}));

const SeekerAccount = ({ appConfig }) => {
  const {
    currentUser,
    unreadMailCount,
    affiliateForumUrl,
    affiliateUrl,
    affiliateSeeker,
    txnUrl,
    availableBalance: rawAvailableBalance,
  } = useMemo(() => camelizeKeys(appConfig), [appConfig]);

  const availableBalance = toNumber(rawAvailableBalance);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (_e, newValue) => {
    setSelectedTab(newValue);
  };

  // pass `href` if we want an <a href> because it's not part of our react app
  // pass `to` if we want a <Link to> because it's a react-routed page
  // If you use href when you shouldn't, then you'll clobber the redux store and make some unneccessary requests
  // If you use to when you shouldn't, then you get weird unpredictable behavior
  const shortcuts = [
    {
      icon: <MailIcon />,
      label: 'Mail',
      badge: unreadMailCount,
      href: '/messages/inbox',
    },
    {
      icon: <IconFilePlayer />,
      label: 'File Player',
      href: '/account/fileplayer',
    },
    {
      icon: <SettingsIcon />,
      label: 'Contact Settings',
      to: '/availability',
    },
    {
      icon: <IconLeaveFeedback />,
      label: 'Leave Feedback',
      to: '/feedbacks',
    },
    {
      icon: <CreditCardIcon />,
      label: 'Manage Credit Cards',
      href: `${txnUrl}/cards`,
    },
    {
      icon: <IconAcctFeeds />,
      label: 'Feeds',
      to: '/feed/explore',
    },
    {
      icon: <IconSubscriptions />,
      label: 'Subscriptions',
      to: '/feed/subscriptions',
    },
  ];
  if (affiliateSeeker) {
    shortcuts.push(
      {
        icon: <IconAffiliateProgram />,
        label: 'Affiliate Program',
        href: `${affiliateUrl}/creative`,
      },
      {
        icon: <ReceiptLongIcon />,
        label: 'Request Payout',
        href: `${txnUrl}/account/payout`,
      },
      {
        icon: <ForumIcon />,
        label: 'Affiliate Forum',
        href: affiliateForumUrl,
      },
    );
  }

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [viewAllShortcuts, setViewAllShortcuts] = useState(false);
  const visibleShortcuts = (isSmallScreen && !viewAllShortcuts) ? shortcuts.slice(0, 3) : shortcuts;

  return (
    <>
      <StickySeekerHeader appConfig={appConfig} />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="seeker-account-container"
        sx={{ p: 2, my: 2, borderBottom: '1px solid #ddd' }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          { isSmallScreen ? (
            <>
              <Box className="menu-initials">
                {userInitials(currentUser)}
              </Box>
              <Box>
                <Typography variant="h4">
                  {currentUser.login}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant="h4" sx={{ pl: 1 }}>
              Welcome&nbsp;
              {currentUser.login}
            </Typography>
          )}
        </Stack>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => { window.location.href = `${appConfig.txn_url}/purchase/new`; }}
            display="flex"
            flexDirection="row"
          >
            <Stack
              direction="column"
              spacing={0}
              alignItems="flex-start"
              sx={{ mr: 3 }}
            >
              <Box sx={{ color: 'var(--color_gray_nine)', fontSize: '0.875em' }}>
                Balance
              </Box>
              <Box>
                {toCurrency(availableBalance)}
              </Box>
            </Stack>
            <Box sx={{ mx: 1 }} className="account-shortcuts">
              <img src="/plus_frontend/assets/svg/ico-add-money.svg" alt="Add Money" style={{ filter: 'grayscale(100%) invert(70%)' }} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid
        container
        spacing={1.75}
        justifyContent="flex-start"
        sx={{
          px: 2,
          mb: 2,
          maxWidth: 900,
          mx: 'auto',
          '@media (max-width: 1200px)': { maxWidth: 800 },
          '@media (max-width: 900px)': { maxWidth: 800 },
          '@media (max-width: 600px)': { maxWidth: 360, px: 0 },
        }}
      >
        {visibleShortcuts.map((shortcut) => (
          <Grid item key={shortcut.label}>
            <IconCard {...shortcut} />
          </Grid>
        ))}
      </Grid>

      { isSmallScreen && (
        <Typography
          variant="body1"
          align="center"
          sx={{ pb: 1, cursor: 'pointer', color: 'var(--link_color)' }}
          onClick={() => setViewAllShortcuts(!viewAllShortcuts)}
        >
          { viewAllShortcuts ? 'View less' : 'View more' }
        </Typography>
      )}

      <Box sx={{ mt: 0.5 }}>
        <CustomTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="Account Tabs"
        >
          <Tab label="For You" id="simple-tab-0" aria-controls="simple-tabpanel-0" sx={{ maxWidth: '150px' }} />
          <Tab label="Favorites" id="simple-tab-1" aria-controls="simple-tabpanel-1" sx={{ maxWidth: '150px' }} />
        </CustomTabs>

        <TabContent value={selectedTab} index={0} padding={0}>
          <SeekerForYou currentUser={currentUser} availableBalance={availableBalance} />
        </TabContent>
        <TabContent value={selectedTab} index={1} padding={0}>
          <FavoriteListingsContainer />
        </TabContent>
      </Box>
    </>
  );
};

SeekerAccount.propTypes = {
  appConfig: PropTypes.object.isRequired,
};

export default SeekerAccount;
