import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { useConfirm } from 'material-ui-confirm';

import {
  useCreateBlockMutation,
  useDestroyBlockMutation,
  useGetCustomTagsQuery,
  useSendChatMessageMutation,
} from 'app/api/customersApi';

import DialogsContext from 'app/contexts/DialogsContext';
import SendChatMessageDialog from './SendChatMessageDialog';
import AssignTagsDialog from './AssignTagsDialog';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const MoreMenu = ({ customer, triggerSearch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { data: allTagObjects } = useGetCustomTagsQuery();
  const allTags = allTagObjects?.map((tag) => tag.name) || [];
  const open = Boolean(anchorEl);
  const confirm = useConfirm();
  const { openDialog } = useContext(DialogsContext);

  const systemTagList = (customer?.system_tag_list || '').split('^').filter((tag) => tag);
  const blocked = systemTagList.includes('Blocked');

  const [createBlock] = useCreateBlockMutation();
  const [destroyBlock] = useDestroyBlockMutation();
  const [sendChatMessage] = useSendChatMessageMutation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleBlock = () => {
    if (blocked) {
      confirm({
        title: 'Confirm unblock',
        description: 'Are you sure you want to unblock this customer?',
      })
        .then(() => destroyBlock({ ids: [customer.seeker_id] }))
        .then(triggerSearch)
        .then(closeMenu)
        .catch(closeMenu);
    } else {
      confirm({
        title: 'Confirm block',
        description: 'Blocked customers can’t contact you or buy from you. Blocking will also end any subscriptions.',
      })
        .then(() => createBlock({ ids: [customer.seeker_id] }))
        .then(triggerSearch)
        .then(closeMenu)
        .catch(closeMenu);
    }
  };

  const handleChat = () => {
    openDialog({
      component: SendChatMessageDialog,
      props: {
        sendChatMessage,
        seekerIds: [customer.seeker_id],
      },
    });

    closeMenu();
  };

  const handleMail = () => {
    window.location.href = `/messages/compose?message[recipients_list]=${customer.seeker_name}`;
  };

  const handleAddTags = () => {
    openDialog({
      component: AssignTagsDialog,
      props: {
        allTags,
        currentTags: (customer.tag_list || '').split('^').filter((x) => x),
        seekerIds: [customer.seeker_id],
      },
    });

    closeMenu();
  };

  return (
    <div>
      <MoreHorizIcon
        id="more-menu-button"
        aria-controls={open ? 'more-menu' : undefined}
        aria-haspopup="menu"
        aria-expanded={open}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      />

      <StyledMenu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="more-menu"
        MenuListProps={{
          'aria-labelledby': 'more-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleBlock} disableRipple>
          { blocked ? 'Unblock' : 'Block' }
        </MenuItem>
        <MenuItem onClick={handleChat} disableRipple>
          Chat
        </MenuItem>
        <MenuItem onClick={handleMail} disableRipple>
          Mail
        </MenuItem>
        <MenuItem onClick={handleAddTags} disableRipple>
          Add Tags
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

MoreMenu.propTypes = {
  customer: PropTypes.object.isRequired,
  triggerSearch: PropTypes.func.isRequired,
};

export default MoreMenu;
