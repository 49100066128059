import React from 'react';
import { IconButton } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

export const FileSortButton = () => {
  return (
    <IconButton>
      <SortIcon />
    </IconButton>
  );
};
