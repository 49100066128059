import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

import {
  clearFilters,
  filterOptions,
  filtersSelector,
  setFilters,
  setOmnibox,
} from 'app/slices/customersSlice';
import {
  useGetCustomTagsQuery,
  useGetPackagesQuery,
  useCreateSearchMutation,
  useGetSavedSearchesQuery,
} from 'app/api/customersApi';
import { useGetAppConfigQuery } from 'app/api/mainApi';

import { DialogTitle, DialogContent, Button, Box, Checkbox, Typography, TextField, FormControlLabel } from '@mui/material';
import { equal } from 'app/util/objectComparisons';
import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';
import AutoCompleteMultiCheckbox from '../form_components/AutoCompleteMultiCheckbox';
import ToggleSwitch from '../form_components/ToggleSwitch';
import RangeSlider from '../form_components/RangeSlider';
import NumberField from '../form_components/NumberField';

const CustomerSearchFilters = () => {
  const filters = useSelector(filtersSelector);
  const confirm = useConfirm();

  // we won't call setLocalFilters directly-
  // instead, we curry it into a single-key setter
  const [localFilters, setLocalFilters] = useState(filters);
  const createSetter = (key) => (value) => {
    let newValue;
    // it's hard to test if a value exists in an object in a way that respects nulls and booleans!
    if (value?.target?.type === 'checkbox') {
      newValue = value.target.checked;
    } else if (value?.target?.type === 'text') {
      newValue = value.target.value;
    } else if (Array.isArray(value)) {
      newValue = value;
    } else {
      newValue = value;
    }

    setLocalFilters({ ...localFilters, [key]: newValue });
  };
  const toggleEarningDetails = () => {
    setLocalFilters({ ...localFilters, earningsDetails: !localFilters.earningsDetails });
  };
  const [performSaveSearch] = useCreateSearchMutation();

  const { data: tags } = useGetCustomTagsQuery();
  const tagNames = tags?.map((tag) => tag.name);

  const { data: packageNames } = useGetPackagesQuery();

  const { data: allSavedSearches } = useGetSavedSearchesQuery();

  const appConfig = useGetAppConfigQuery().data;
  const systemSavedSearches = allSavedSearches?.filter(x => x.user_id !== appConfig?.current_user?.id);
  const mySavedSearches = allSavedSearches?.filter(x => x.user_id === appConfig?.current_user?.id);
  const localFiltersUnmodified = equal(localFilters, filters);

  const dispatch = useDispatch();
  const { closeDialog } = useContext(DialogsContext);

  const dispatchClearFilters = () => {
    dispatch(clearFilters());
  };
  const dispatchSetOmnibox = (value) => dispatch(setOmnibox(value));

  const saveFiltersAndClose = () => {
    dispatch(setFilters(localFilters));
    closeDialog();
  };

  const savedSearchIncludesName = (savedSearches) => (
    savedSearches.map((s) => s.name.toLowerCase()).includes(localFilters.nameForSavedSearch.toLowerCase())
  );
  const saveable = localFilters.saveThisSearch && !localFiltersUnmodified;

  // If they're not trying to save the search, just apply it.
  // But if they ARE trying to save it, we need to validate a few things first.
  const onApply = async () => {
    if (!saveable) {
      saveFiltersAndClose();
      return;
    }

    if (!localFilters.nameForSavedSearch) {
      confirm({
        hideCancelButton: true,
        title: 'Name required',
        description: 'Enter a name for your saved search.',
      }).then(() => null);
    } else if (savedSearchIncludesName(mySavedSearches)) {
      confirm({
        title: 'Search already exists',
        description: 'You already have a saved search with that name. OK to overwrite the old one?',
      })
        .then(() => {
          performSaveSearch({
            name: localFilters.nameForSavedSearch,
            search: localFilters,
          });
          dispatchSetOmnibox([{ type: 'user_saved_search', name: localFilters.nameForSavedSearch, filters: localFilters }]);
          saveFiltersAndClose();
        })
        .catch(() => null);
    } else if (savedSearchIncludesName(systemSavedSearches)) {
      confirm({
        hideCancelButton: true,
        title: 'Try a different name',
        description: 'System searches cannot be overwritten. Please use a different name.',
      }).then(() => null);
    } else {
      performSaveSearch({
        name: localFilters.nameForSavedSearch,
        search: localFilters,
      });
      dispatchSetOmnibox([{ type: 'user_saved_search', name: localFilters.nameForSavedSearch, filters: localFilters }]);
      saveFiltersAndClose();
    }
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <>
      <DialogTitle>
        Search Customers
      </DialogTitle>

      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <AutoCompleteMultiCheckbox
          label="Customer Value"
          options={filterOptions.value}
          value={localFilters.value}
          setValue={createSetter('value')}
        />

        <AutoCompleteMultiCheckbox
          label="Status"
          options={filterOptions.status}
          value={localFilters.status}
          setValue={createSetter('status')}
        />

        <AutoCompleteMultiCheckbox
          label="Main Spend"
          options={filterOptions.mainSpend}
          value={localFilters.mainSpend}
          setValue={createSetter('mainSpend')}
        />

        <RangeSlider
          label="Contacted Days Ago"
          value={localFilters.contactedDaysAgo}
          setValue={createSetter('contactedDaysAgo')}
        />

        <RangeSlider
          label="Earned Days Ago"
          value={localFilters.earnedDaysAgo}
          setValue={createSetter('earnedDaysAgo')}
        />

        <AutoCompleteMultiCheckbox
          label="Other"
          options={filterOptions.other}
          value={localFilters.other}
          setValue={createSetter('other')}
        />

        <AutoCompleteMultiCheckbox
          label="Recurring Tributes"
          options={filterOptions.recurringTributes}
          value={localFilters.recurringTributes}
          setValue={createSetter('recurringTributes')}
        />

        { packageNames && packageNames.length > 0 && (
          <AutoCompleteMultiCheckbox
            label="Packages"
            options={packageNames}
            value={localFilters.packages}
            setValue={createSetter('packages')}
          />
        )}

        <AutoCompleteMultiCheckbox
          allowTyping
          label="Has Tags"
          options={tagNames}
          value={localFilters.hasTags}
          setValue={createSetter('hasTags')}
        />

        <AutoCompleteMultiCheckbox
          allowTyping
          label="Exclude Tags"
          options={tagNames}
          value={localFilters.excludeTags}
          setValue={createSetter('excludeTags')}
        />

        <Box display="flex" justifyContent="flex-end" mt={2} mr={1}>
          <Link to="/my_customers/tags" onClick={closeDialog}>
            Manage Tags
          </Link>
        </Box>

        <ToggleSwitch
          label="Earning Details"
          value={localFilters.earningsDetails}
          setValue={toggleEarningDetails}
        />

        { localFilters.earningsDetails && (
          <>
            <NumberField
              label="Min Amount"
              value={localFilters.minAmount}
              setValue={createSetter('minAmount')}
              min={0}
              max={9999}
            />

            <AutoCompleteMultiCheckbox
              label="Earnings Channel"
              options={filterOptions.earningsChannel}
              value={localFilters.earningsChannel}
              setValue={createSetter('earningsChannel')}
            />

            <AutoCompleteMultiCheckbox
              single
              label="Earnings Date Window"
              options={filterOptions.earningsDateWindow}
              value={localFilters.earningsDateWindow}
              setValue={createSetter('earningsDateWindow')}
            />
          </>
        )}

        <Box display="flex" mt={2}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!localFilters.saveThisSearch}
                disabled={localFiltersUnmodified}
                onChange={createSetter('saveThisSearch')}
              />
            )}
            label={(
              <Typography
                sx={{
                  mr: 1,
                  display: 'flex',
                  alignItems: 'center', // Vertically center the text
                }}
                variant="body2"
              >
                Save this search
              </Typography>
            )}
          />
        </Box>

        <TextField
          value={localFilters.nameForSavedSearch}
          onChange={createSetter('nameForSavedSearch')}
          disabled={localFiltersUnmodified}
          fullWidth
        />

        <Box display="flex" justifyContent="flex-end" mt={2} mr={1}>
          <Link to="/my_customers/saved_searches" onClick={closeDialog}>
            Manage Saved Searches
          </Link>
        </Box>

        <Box display="flex" justifyContent="right" mt={4}>
          <Button variant="text" onClick={dispatchClearFilters} sx={{ mr: 2 }}>Reset</Button>
          <Button variant="outlined" onClick={closeDialog} sx={{ mr: 2 }}>Cancel</Button>
          <Button variant="contained" onClick={onApply}>Apply</Button>
        </Box>

      </DialogContent>
    </>
  );
};

export default CustomerSearchFilters;
