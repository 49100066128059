import React from 'react';
import {Box} from '@mui/material';

const NoSearchResultsFound = () => {
  return (
    <Box sx={{ textAlign: 'center', mt: 2, mb: 8, p: 2 }} data-testid="no-search-results">
      <h2>No results found</h2>
      <p>
        We can&apos;t find any results based on your search.
        <br />
        {' '}
        Please try again.
      </p>
    </Box>
  )
}

export default NoSearchResultsFound;
