import React from 'react';
import { useNavigate } from 'react-router';
import { PropTypes } from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

const FlirtFeedTabs = ({ advisor, viewingMedia }) => {
  const navigate = useNavigate();
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);

  // TODO: simplify this once we remove FEED_TO_STOREFRONT_20571
  // matches /feed/login/media and /login/feed/media, with both encoded and non-encoded login
  const viewingMediaTab = new RegExp(`/(?:feed/)?(?:${advisor?.login}|${encodedAdvisorLogin})/(?:feed/)?media`).test(document.location.href) || viewingMedia;

  const feedToStoreFrontEnabled = featureFlag.enabled('FEED_TO_STOREFRONT_20571');

  return (
    <div className="panel no-before">
      <div className="container-summary">
        <div className="row middle-xs">
          <div
            className={`col-sm-4 col-sm-offset-2 col-xs-5 col-xs-offset-1 center-xs summary-tab ${!viewingMediaTab ? 'active' : ''}`}
            role="link"
            tabIndex={0}
            onClick={feedToStoreFrontEnabled ?
              () => { navigate(`/${encodedAdvisorLogin}/feed`); }
              :
              () => { navigate(`/feed/${encodedAdvisorLogin}`); }}
          >
            Posts
          </div>
          <div
            className={`col-sm-4 col-xs-5 center-xs summary-tab ${viewingMediaTab ? 'active' : ''}`}
            role="link"
            tabIndex={0}
            onClick={feedToStoreFrontEnabled ?
              () => { navigate(`/${encodedAdvisorLogin}/feed/media`); }
              :
              () => { navigate(`/feed/${encodedAdvisorLogin}/media`); }}
          >
            Media
          </div>
        </div>
      </div>
    </div>
  );
};

FlirtFeedTabs.propTypes = {
  advisor: PropTypes.object.isRequired,
  viewingMedia: PropTypes.bool,
};

export default FlirtFeedTabs;
