import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import GoodyBagReviewShape from 'app/shapes/GoodyBagReviewShape';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { timestampInTimezone } from 'app/helpers/timestampHelpers';

const GoodyBagReviewCard = ({ currentUser, goodyBagReview }) => {
  const [showReadMoreLink, setShowReadMoreLink] = useState(false);
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const ref = useRef(null);

  const obsfucatedReviewerName = `${goodyBagReview.login.charAt(0)}...${goodyBagReview.login.charAt(goodyBagReview.login.length - 1)}`;
  const reviewerName = currentUser ? goodyBagReview.login : obsfucatedReviewerName;

  useEffect(() => {
    if (ref.current) {
      setShowReadMoreLink(
        ref.current.scrollHeight !== ref.current.clientHeight,
      );
    }
  }, []);

  return (
    <Card sx={{ p: 3, pr: 4 }} elevation={0}>
      <Grid
        container
        alignItems="center"
        className="goodies-rating"
      >
        <Grid item xs={8}>
          <Grid
            container
            alignItems="center"
          >
            <Grid item xs={2} className="review-icon-width">
              {goodyBagReview.approve ? <img src="/plus_frontend/assets/svg/ico-thumb-up.svg" alt="" /> : <img src="/plus_frontend/assets/svg/ico-thumb-down.svg" alt="" />}
            </Grid>
            <Grid item xs={10} className="ellipsis">
              {reviewerName}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className="pull-right">
          {timestampInTimezone(goodyBagReview.created_at, currentUser?.canonical_iana_timezone, 'LLL d yyyy')}
        </Grid>
        <Grid
          xs={12}
          className="review-txt"
          item
          style={isReadMoreOpen ? null : { WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-webkit-box' }}
          ref={ref}
        >
          {goodyBagReview.review}
        </Grid>
        {showReadMoreLink && (
          <Grid
            className="div-read-more"
            item
            xs={12}
          >
            <a
              href="#"
              onClick={() => setIsReadMoreOpen(!isReadMoreOpen)}
            >
              {isReadMoreOpen ? 'See less' : 'See more' }
            </a>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

GoodyBagReviewCard.defaultProps = {
  currentUser: {},
};

GoodyBagReviewCard.propTypes = {
  goodyBagReview: GoodyBagReviewShape.isRequired,
  currentUser: PropTypes.object,
};

export default GoodyBagReviewCard;
