import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, Button, Checkbox, FormControl, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {
  useAssignTagsMutation,
} from 'app/api/customersApi';
import {
  touchFilterTimestamp,
} from 'app/slices/customersSlice';

export const CreatableTagSelect = ({ label, options, seekerIds, value, useSaveButton, closeDialog }) => {
  const dispatch = useDispatch();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const filter = createFilterOptions();

  // Saving these to a local state allows us to update the UI immediately without waiting for
  // the API call to finish and then trigger a re-fetch of the tags and THEN reload.
  // It also allows us to defer/delay the save action in the case of the modal version.
  const [localOptions, setLocalOptions] = useState(options);
  // similarly here, we can update the UI immediately without waiting for the API call to finish
  const [selectedTags, setSelectedTags] = useState(value);
  const [assignTags] = useAssignTagsMutation();

  // temporary holding spot for free-typed text
  const [inputValue, setInputValue] = useState('');

  const onChange = (_event, newValue) => {
    const tags = [...newValue];
    // Extract the newly typed tag from string `Create New Tag "newTag"`,
    //   but only if the last entry in newValue matches that string format
    if (newValue.length > 0 && newValue[newValue.length - 1].startsWith('Create New Tag "')) {
      const newTag = newValue[newValue.length - 1].slice(16, -1);
      setLocalOptions([...localOptions, newTag]);
      tags[tags.length - 1] = newTag;
    }
    setSelectedTags(tags);
    setInputValue('');
    if (!useSaveButton) {
      assignTags({ seekerIds, tags });
    }
  };

  const onClickSaveTags = async () => {
    await assignTags({ seekerIds, tags: selectedTags });
    // don't execute this line until the assignTags mutation is complete
    if (useSaveButton) {
      dispatch(touchFilterTimestamp());
    }
    closeDialog();
  };

  const filterOptions = (opts, params) => {
    const filtered = filter(opts, params);
    const { inputValue } = params;

    // Suggest the creation of a new value
    const isExisting = localOptions.some((o) => inputValue === o);

    if (inputValue !== '' && inputValue.match(/^[\x20-\x5d\x5f-\x7E]*$/i) && !isExisting) {
      filtered.push(`Create New Tag "${inputValue}"`);
    }
    return filtered;
  };

  const getOptionLabel = (option) => (
    option || ''
  );

  const renderOption = (properties, option, { selected }) => {
    const { key, ...optionProps } = properties;
    return (
      <li key={key} {...optionProps}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option}
      </li>
    );
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      const newOption = inputValue.trim();

      if (newOption && !selectedTags.includes(newOption)) {
        if (!useSaveButton) {
          assignTags({ tags: [...selectedTags, newOption], seekerIds });
        }

        if (newOption.match(/^[\x20-\x5d\x5f-\x7E]*$/i)) {
          const newTags = [...selectedTags, newOption];
          setLocalOptions([...localOptions, newOption]);
          setSelectedTags(newTags);
          setInputValue(''); // clear the input field
        }
      }
    }
  };

  const onInputChange = (_event, newInputValue) => {
    // Filter out the "^" character silently
    const sanitizedInput = newInputValue.replace(/[^\x20-\x5d\x5f-\x7E]/g, '');
    setInputValue(sanitizedInput);
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="normal">
        <Autocomplete
          size="small"
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          multiple
          disableCloseOnSelect
          options={localOptions}
          value={selectedTags}
          onChange={onChange}
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) => value === option}
          clearIcon={value && value.length > 0 ? <ClearIcon /> : null}
          onInputChange={onInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              autoComplete="off"
              autoCapitalize="none"
              autoCorrect="off"
              spellCheck="false"
              inputProps={{
                ...params.inputProps,
                readOnly: false,
                inputMode: 'search',
                autoComplete: 'off',
                autoCapitalize: 'none',
                autoCorrect: 'off',
                spellCheck: 'false',
                onKeyDown,
              }}
            />
          )}
          renderOption={renderOption}
        />
      </FormControl>
      {useSaveButton && (
        <Box display="flex" justifyContent="right" mt={2}>
          <Button variant="contained" onClick={closeDialog} sx={{ mr: 2 }}>Cancel</Button>
          <Button variant="contained" onClick={onClickSaveTags}>OK</Button>
        </Box>
      )}
    </>
  );
};

CreatableTagSelect.defaultProps = {
  options: [], // briefly, while loading
  closeDialog: null,
};

CreatableTagSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  seekerIds: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  useSaveButton: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func,
};

export default CreatableTagSelect;
