import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';

const ListingSearchFilters = ({
  allPriceOptions,
  selectedPrices,
  handlePriceChange,
  checkboxValue,
  handleCheckboxChange,
  handleReset,
  handleApply,
  closeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Filter
        <IconButton
          aria-label="close"
          onClick={closeClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormControl fullWidth variant="outlined" margin="normal">
          <Autocomplete
            id="ppm"
            multiple
            disableCloseOnSelect
            disableClearable
            options={allPriceOptions}
            getOptionLabel={(option) => option}
            value={selectedPrices}
            onChange={handlePriceChange}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={selected}
                  style={{ marginRight: 8 }}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Price Per Minute"
                autoComplete="off"
                autoCapitalize="none"
                autoCorrect="off"
                spellCheck="false"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  name: 'price-per-minute-search',
                  inputMode: 'search',
                  autoComplete: 'off',
                  autoCapitalize: 'none',
                  autoCorrect: 'off',
                  spellCheck: 'false',
                }}
              />
            )}
          />
        </FormControl>

        <FormControlLabel
          control={(
            <Checkbox
              checked={checkboxValue}
              onChange={handleCheckboxChange}
            />
          )}
          label="Limit to Available Flirts"
        />
      </DialogContent>
      <DialogActions sx={{ mt: 15 }}>
        <Button onClick={handleReset} color="secondary">
          Reset
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </>
  );
};

ListingSearchFilters.propTypes = {
  allPriceOptions: PropTypes.array.isRequired,
  selectedPrices: PropTypes.array.isRequired,
  handlePriceChange: PropTypes.func.isRequired,
  checkboxValue: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
};

export default ListingSearchFilters;
