import PropTypes from 'prop-types';

const AvatarShape = PropTypes.shape({
  encodingStatus: PropTypes.string,
  rating: PropTypes.string,
  url: PropTypes.string,
  moderationStatus: PropTypes.string,
});

export default AvatarShape;
