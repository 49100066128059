import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Badge, Box, Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIconSpan = styled('span')(() => ({
  '& .MuiSvgIcon-root': {
    fontSize: '1.813em',
  },
}));

const IconCard = ({ icon, hoverIcon, label, badge, href, to }) => {
  const [isHovered, setIsHovered] = useState(false);

  const Wrapper = href ? 'a' : Link;
  const wrapperProps = href ? { href } : { to };

  const displayedIcon = isHovered && hoverIcon ? hoverIcon : icon;

  let content = displayedIcon;
  if (badge > 0) {
    content = (
      <Badge
        badgeContent={badge}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        color="warning"
        overlap="circular"
        style={{ height: '23px' }}
      >
        {displayedIcon}
      </Badge>
    );
  }

  return (
    <Wrapper {...wrapperProps} style={{ textDecoration: 'none' }}>
      <Card
        className="account-shortcuts"
        sx={{
          width: 80,
          height: 80,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 1,
          cursor: 'pointer',
          color: 'var(--color_gray_nine)',
          padding: '10px',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Stack
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ height: '100%', width: '100%' }}
        >
          <Box>
            <StyledIconSpan style={{ color: isHovered ? '#63177a' : '#999' }}>
              {content}
            </StyledIconSpan>
          </Box>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontSize: '0.875em', color: isHovered ? '#63177a' : '#666' }}
          >
            {label}
          </Typography>
        </Stack>
      </Card>
    </Wrapper>
  );
};

IconCard.defaultProps = {
  badge: null,
  href: null,
  to: null,
  hoverIcon: null,
};

IconCard.propTypes = {
  icon: PropTypes.object.isRequired,
  hoverIcon: PropTypes.object,
  label: PropTypes.string.isRequired,
  badge: PropTypes.number,
  href: PropTypes.string,
  to: PropTypes.string,
};

export default IconCard;
