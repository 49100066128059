import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Box,
  Typography,
} from '@mui/material';

import camelizeKeys from 'app/util/camelizeKeys';
import PerkList from 'app/components/tributes/PerkList';
import SubscriptionDetails from './shared/SubscriptionDetails';

const PackageDetails = ({
  currentUser,
  onEditClick,
  packageData,
}) => {
  const camelizedPackage = camelizeKeys(packageData.package);
  const { feedPerk, callPerk, volleyPerk } = camelizedPackage;
  const hasPerks = feedPerk || callPerk || volleyPerk;

  return (
    <Box mt={1}>
      <Typography variant="h4" pl={1}>Packages</Typography>
      <SubscriptionDetails
        status={packageData.status}
        rate={packageData.rate}
        renewalDate={packageData.renewalDate}
        onEditClick={onEditClick}
        currentUser={currentUser}
        editTitle="Edit Package"
      />
      {hasPerks && (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" mt={2} mb={-1} pl={1}>Perks:</Typography>
          <PerkList packageData={camelizedPackage} />
        </Box>
      )}
    </Box>
  );
};

PackageDetails.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  packageData: PropTypes.object.isRequired,
};

export default PackageDetails;
