import React from 'react';
import { Route } from 'react-router-dom';
import TransactionDetails from 'app/components/account/TransactionDetails';

const routes = (
  <Route path="transactions">
    <Route path="details/:id?" element={<TransactionDetails />} id="transactionDetails" />
    <Route path="detail/:id?" element={<TransactionDetails />} />
  </Route>
);

export default routes;
