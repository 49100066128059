import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { DialogContent, Button, Box, TextField, Stack } from '@mui/material';
import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const RenameTagDialog = ({ performUpdateTag, tagName }) => {
  const { closeDialog } = useContext(DialogsContext);
  const [newName, setNewName] = useState(tagName);

  const onClickSave = () => {
    performUpdateTag({ tagName, newName });
    closeDialog();
  };

  return (
    <>
      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <Stack direction="column">
          <TextField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Stack>
        <Box display="flex" justifyContent="right" mt={2}>
          <Button variant="contained" onClick={closeDialog} sx={{ mr: 2 }}>Cancel</Button>
          <Button variant="contained" onClick={onClickSave}>Save</Button>
        </Box>
      </DialogContent>
    </>
  );
};

RenameTagDialog.propTypes = {
  performUpdateTag: PropTypes.func.isRequired,
  tagName: PropTypes.string.isRequired,
};

export default RenameTagDialog;
