import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import { ConfirmProvider } from 'material-ui-confirm';

import PubNubContainer from 'app/containers/PubNubContainer';
import Header from './Header';
import UpdateServiceWorkerButton from 'app/components/shared/UpdateServiceWorkerButton';
import Chat from './Chat';
import CreatePostContainer from 'app/containers/CreatePostContainer';
import TransientNavContainer from 'app/containers/TransientNavContainer';
import Home from 'app/components/Home';
import CombinedFeedContainer from 'app/containers/CombinedFeedContainer';
import ExploreFeedContainer from 'app/containers/ExploreFeedContainer';
import ExpandedNavContainer from 'app/containers/ExpandedNavContainer';
import PostDetailContainer from 'app/containers/PostDetailContainer';
import MySubscriptionsContainer from 'app/containers/MySubscriptionsContainer';
import PackageManagementContainer from 'app/containers/PackageManagementContainer';
import PostsQueueContainer from 'app/containers/PostsQueueContainer';
import NavContainerMobile from 'app/containers/NavContainerMobile';
import CreatePackageContainer from 'app/containers/CreatePackageContainer';
import SubscriptionSettingsContainer from 'app/containers/SubscriptionSettingsContainer';
import SocialShoutsContainer from 'app/containers/SocialShoutsContainer';
import MissingProfile from './MissingProfile';
import FlashContainer from 'app/containers/FlashContainer';
import RestrictedRoute from './RestrictedRoute';
import OnboardingContainer from 'app/containers/OnboardingContainer';
import FlirtContainer from 'app/containers/FlirtContainer';
import FlirtRedirect from './FlirtRedirect';
import SnackbarMessageContainer from 'app/containers/SnackbarMessageContainer';
import ModalsContainer from 'app/containers/ModalsContainer';
import DialogsContainer from 'app/containers/DialogsContainer';
import AvailabilityContainer from 'app/containers/AvailabilityContainer';
import TaxFormsContainer from 'app/containers/TaxFormsContainer';
import TaxFormContainer from 'app/containers/TaxFormContainer';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import WindowEventsListener from 'app/windowevents/WindowEventsListener';
import SoundEffects from 'app/components/SoundEffects';
import Support, { SUPPORT_TABS } from 'app/components/Support';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line no-unused-vars
import { ToastContainer, _cssTransition } from 'react-toastify';

// Redesign
import ProfilePageContainer from 'app/containers/ProfilePageContainer';
import AdvisorHomepageContainer from 'app/containers/AdvisorHomepageContainer';
import BehindSecondaryAuth from 'app/containers/BehindSecondaryAuth';
import AdvisorFeedbacksContainer from 'app/containers/AdvisorFeedbacksContainer';
import FeedbacksContainer from 'app/containers/FeedbacksContainer';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'app/theme';
import NetworkStatusContainer from 'app/containers/NetworkStatusContainer';
import FavoriteListingsLayout from 'app/components/favorite_listings/FavoriteListingsLayout';
import ChatBrowseContainer from 'app/containers/ChatBrowseContainer';
import TagInvalidationEventListener from 'app/redux/TagInvalidationEventListener';
import SignInLauncher from 'app/containers/SignInLauncher';
import MainHomeContainer from 'app/containers/MainHomeContainer';
import ListingSearchContainer from 'app/containers/ListingSearchContainer';
import MyCustomersContainer from 'app/containers/MyCustomersContainer';
import CustomerDetails from 'app/components/customers/CustomerDetails';
import ManageSavedSearches from 'app/components/customers/ManageSavedSearches';
import ManageTags from 'app/components/customers/ManageTags';
import SignOutLauncher from 'app/containers/SignOutLauncher';
import ForgotPinLauncher from 'app/containers/ForgotPinLauncher';
import CategoryListingsContainer from 'app/containers/CategoryListingsContainer';
import SignupLauncher from 'app/components/SignupLauncher';
import PasswordResetContainer from 'app/containers/account_info/PasswordResetContainer';
import PasswordResetConfirmation from 'app/components/account_info/PasswordResetConfirmation';
import VerifyAccountLauncher from 'app/containers/VerifyAccountLauncher';
import GoodySearchContainer from 'app/containers/GoodySearchContainer';
import ForgotPasswordLauncher from 'app/containers/ForgotPasswordLauncher';
import ResetPasswordCompleteLauncher from 'app/containers/ResetPasswordCompleteLauncher';
import ListingDetailDrawerContainer from 'app/containers/ListingDetailDrawerContainer';
import ListingDetailRedirector from './ListingDetailRedirector';
import ListingFeedbackDrawerContainer from 'app/containers/ListingFeedbackDrawerContainer';
import SuperBannerContainer from '../containers/SuperBannerContainer';
import PayoutInfoContainer from 'app/containers/payout/PayoutInfoContainer';

// Routing
import RoutingContext from 'app/contexts/RoutingContext';
import routes from 'app/routes';
import generateUrl from 'app/util/generateUrl';

TimeAgo.addDefaultLocale(en);

// Volley Price as it appears in the VOLLEY_PRICE const
window.VOLLEY_PRICE = 0.99;

const ToastContainerComponent = () => (
  <ToastContainer
    hideProgressBar
    closeOnClick={false}
    autoClose={false}
  // This is commented out because adding a custom transition somehow breaks our ability to call
  //  toast.dismiss. For now we're going to ditch the ToastMessageWrapper and just use the
  //  default toast message format, and default transition, which makes everything easy.
  // transition={cssTransition({
  //   enter: 'zoomIn',
  //   exit: 'zoomOut',
  //   collapseDuration: 1,
  //   collapse: true,
  // })}
  />
);

// the mode argument lets us pick between the equivalent of different "layouts".
// mode == "modal" renders nothing except a placeholder and the plumbing to launch modals.
// mode == "signin" renders a bare page that can stand alone, and _also_ serve modals.
// mode == "main" (or undefined) is the default and renders the entire plus application.
const App = ({ mode }) => {
  const appConfig = useGetAppConfigQuery().data;

  if (!appConfig) { return null; }

  // this is a stripped-down version of the app that
  // only renders the modals. It's embedded inside main.
  if (mode === 'modal') {
    return (
      <ThemeProvider theme={theme}>
        <ModalsContainer>
          <DialogsContainer>
            <TagInvalidationEventListener />
            <WindowEventsListener />
            <NetworkStatusContainer />
            <div className="App" />
          </DialogsContainer>
        </ModalsContainer>
        <SnackbarMessageContainer />
      </ThemeProvider>
    );
  }

  if (mode === 'signin') {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ModalsContainer>
            <DialogsContainer>
              <TagInvalidationEventListener />
              <WindowEventsListener suppressPushNotificationDialog />
              <div className="App">
                <Routes>
                  <Route path="login" exact element={<SignInLauncher type="web" />} />
                  <Route path="login_by_phone" exact element={<SignInLauncher type="phone" />} />
                  <Route path="change_availability" exact element={<SignOutLauncher type="full_page" />} />
                  <Route path="forgot_pin" exact element={<ForgotPinLauncher />} />
                  <Route path="signup" exact element={<SignupLauncher />} />
                  <Route path="verify_account" exact element={<VerifyAccountLauncher />} />
                  <Route path="forgot_password" exact element={<ForgotPasswordLauncher />} />
                  <Route path="new_password" exact element={<PasswordResetContainer />} />
                  <Route path="new_password/confirm" exact element={<PasswordResetConfirmation />} />
                  <Route path="reset_password_complete" exact element={<ResetPasswordCompleteLauncher />} />
                </Routes>
              </div>
            </DialogsContainer>
          </ModalsContainer>
          <SnackbarMessageContainer />
        </ThemeProvider>
      </BrowserRouter>
    );
  }

    // mode === 'main', also just the default case
    return (
      <RoutingContext.Provider value={generateUrl}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <PubNubContainer>
              <SuperBannerContainer />
              <FlashContainer />
              <ToastContainerComponent />
              <SoundEffects />
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: {
                    autoFocus: true,
                    variant: 'contained',
                    sx: { mr: 2, mb: 1 },
                  },
                  confirmationText: 'OK',
                  // dialogProps: { sx: { marginBottom: 0 } },
                  // dialogActionsProps: { sx: { marginBottom: 0 } },
                }}
              >
                <ModalsContainer>
                  <DialogsContainer>
                    <TagInvalidationEventListener />
                    <WindowEventsListener />
                    <div className="App">
                      <ListingDetailDrawerContainer />
                      <ListingFeedbackDrawerContainer />
                      <ExpandedNavContainer>
                        <UpdateServiceWorkerButton />
                        <Header />
                        <section className="main">
                          <TransientNavContainer />
                          <Routes>
                            {/* redesigned "main" homepage in react app
                              these can be removed when we deprecate the FF
                              ROOT_TO_V2_HOMEPAGE_21059 */}
                            <Route path="v2">
                              <Route path="home" element={<MainHomeContainer />} />
                              <Route
                                path="categories/:category"
                                element={<CategoryListingsContainer />}
                              />
                            </Route>

                            {/* this route is protected on the backend with a FF
                              the above route (v2/home) is not, however
                              ROOT_TO_V2_HOMEPAGE_21059 */}
                            <Route path="/" element={<MainHomeContainer />} />

                            {/* this route is protected on the backend with a FF
                              the above route (v2/categories/:category) is not, however
                              ROOT_TO_V2_HOMEPAGE_21059 */}
                            <Route path="/categories/:category" element={<CategoryListingsContainer />} />

                            <Route path="search" element={<ListingSearchContainer />} />
                            <Route path="my_customers">
                              <Route index element={<MyCustomersContainer />} />
                              <Route path="user/:login" element={<CustomerDetails />} />
                              <Route path="user/:login/:tabName" element={<CustomerDetails />} />
                              <Route path="tags" element={<ManageTags />} />
                              <Route path="saved_searches" element={<ManageSavedSearches />} />
                            </Route>

                            { routes }

                            <Route path="feed">
                              <Route path="" exact element={<Navigate to="/feed/home" />} />
                              <Route
                                path="home"
                                element={(
                                  <Home>
                                    <CombinedFeedContainer />
                                  </Home>
                            )}
                          />
                              <Route
                                path="explore"
                                element={(
                                  <Home>
                                    <ExploreFeedContainer />
                                  </Home>
                            )}
                          />
                              <Route path="chat" element={<Chat />} />
                              <Route
                                path="create_post"
                                element={(
                                  <RestrictedRoute>
                                    <CreatePostContainer />
                                  </RestrictedRoute>
                            )}
                          />
                              <Route
                                path="queue/:tabName"
                                element={(
                                  <RestrictedRoute>
                                    <PostsQueueContainer />
                                  </RestrictedRoute>
                            )}
                          />
                              <Route path="subscriptions" element={<MySubscriptionsContainer />} />
                              <Route path="packages">
                                <Route path="" element={<PackageManagementContainer />} />
                                <Route path="new" element={<CreatePackageContainer />} />
                              </Route>
                              <Route path="queue" element={<>Coming Soon</>} />
                              <Route path="following" element={<>Coming Soon</>} />
                              <Route path="add_money" element={<>Coming Soon</>} />
                              <Route
                                path="subscription_settings"
                                element={(
                                  <RestrictedRoute>
                                    <SubscriptionSettingsContainer />
                                  </RestrictedRoute>
                            )}
                          />
                              <Route
                                path="missing/profile"
                                element={(
                                  <Home>
                                    <MissingProfile />
                                  </Home>
                            )}
                          />
                              <Route path=":login">
                                <Route path="media" element={<FlirtContainer />} />
                                <Route path="onboarding" element={<OnboardingContainer />} />
                                <Route path=":postId/*" element={<FlirtRedirect />} />
                                <Route path=":postId" element={<PostDetailContainer />} />
                                <Route index element={<FlirtContainer />} />
                              </Route>
                            </Route>

                            {/* Main Site Redesign Routes BEGIN */}
                            {/* the /:login version is deprecated, and kept for backwards compatiblity.
                            Use /profile/:login, and in rails you can reference it as public_user_path */}
                            {/* These are the routes you get from clicking "...More" and then "About" */}
                            <Route exact path="account">
                              <Route exact path="feedbacks" element={<AdvisorFeedbacksContainer />} />
                              <Route exact path="favorite" element={<FavoriteListingsLayout />} />
                              <Route
                                exact
                                path="payout"
                                element={(
                                  <BehindSecondaryAuth>
                                    <PayoutInfoContainer />
                                  </BehindSecondaryAuth>
                            )}
                          />
                              <Route exact path="payment_history">
                                <Route exact path="tax" element={<TaxFormsContainer />} />
                              </Route>
                              <Route exact path="tax_forms/:id" element={<TaxFormContainer />} />
                            </Route>
                            <Route exact path="signup" element={<SignupLauncher />} />
                            <Route exact path="help">
                              {SUPPORT_TABS.map((tab) => (
                                <Route
                                  key={tab.path}
                                  path={tab.path}
                                  element={(
                                    <Support title={tab.title} index={tab.index}>
                                      <tab.component />
                                    </Support>
                              )}
                            />
                          ))}
                            </Route>

                            <Route exact path="feedbacks">
                              <Route index element={<FeedbacksContainer />} />
                              <Route exact path="new" element={<FeedbacksContainer />} />
                            </Route>

                            <Route exact path="chat">
                              <Route exact path="browse" element={<ChatBrowseContainer />} />
                            </Route>

                            <Route
                              path="/availability"
                              element={(
                                <BehindSecondaryAuth>
                                  <AvailabilityContainer />
                                </BehindSecondaryAuth>
                          )}
                        />

                            <Route
                              exact
                              path="/social_shouts"
                              element={<SocialShoutsContainer />}
                        />

                            <Route exact path="profile/:login">
                              <Route index element={<AdvisorHomepageContainer />} />
                              <Route path="gb">
                                <Route index element={<AdvisorHomepageContainer tab="goodies" />} />
                                <Route path=":goodyBagId">
                                  <Route index element={<AdvisorHomepageContainer tab="goodies" />} />
                                  <Route path="buy" element={<AdvisorHomepageContainer tab="goodies" action="buy" />} />
                                </Route>
                              </Route>
                            </Route>

                            <Route exact path="users/:login">
                              <Route index element={<ProfilePageContainer />} />
                            </Route>

                            <Route exact path="goody/search">
                              <Route index element={<GoodySearchContainer />} />
                            </Route>

                            <Route
                              exact
                              path="listings/show/:listingIdAndTitle"
                              element={<ListingDetailRedirector />}
                        />

                            <Route path=":login">
                              <Route index element={<ProfilePageContainer />} />
                              <Route path="feed">
                                <Route index element={<ProfilePageContainer tab="feed" />} />
                                <Route path="media" element={<ProfilePageContainer tab="feed" />} />
                                <Route path="subscriptions" element={<ProfilePageContainer tab="feed" />} />
                                <Route
                                  path="create_post"
                                  element={(
                                    <RestrictedRoute>
                                      <ProfilePageContainer tab="feed" />
                                    </RestrictedRoute>
                              )}
                            />
                                <Route
                                  path="queue/:tabName"
                                  element={(
                                    <RestrictedRoute>
                                      <ProfilePageContainer tab="feed" />
                                    </RestrictedRoute>
                              )}
                            />
                                <Route path=":postId" element={<ProfilePageContainer tab="feed" />} />
                              </Route>
                              <Route path="packages">
                                <Route index element={<ProfilePageContainer tab="packages" />} />
                                <Route path=":packageId" element={<ProfilePageContainer tab="packages" />} />
                              </Route>
                              {/* /goodies is the new/correct path.  /gb is for backwards compatibility with links that may have been bookmarked */}
                              {/* So we have to duplicate this paragraph of routes since router v6 removed support for multiple paths. */}
                              {/* If you change anything here, make sure to change it in the next paragraph as well. */}
                              <Route path="goodies">
                                <Route index element={<ProfilePageContainer tab="goodies" />} />
                                <Route path=":goodyBagId">
                                  <Route index element={<ProfilePageContainer tab="goodies" />} />
                                </Route>
                              </Route>
                              <Route path="gb">
                                <Route index element={<ProfilePageContainer tab="goodies" />} />
                                <Route path=":goodyBagId">
                                  <Route index element={<ProfilePageContainer tab="goodies" />} />
                                </Route>
                              </Route>
                            </Route>
                            {/* Main Site Redesign Routes ENDS */}

                          </Routes>
                        </section>
                        <NavContainerMobile />
                      </ExpandedNavContainer>
                    </div>
                  </DialogsContainer>
                </ModalsContainer>
              </ConfirmProvider>
              <SnackbarMessageContainer />
            </PubNubContainer>
          </ThemeProvider>
        </BrowserRouter>
      </RoutingContext.Provider>
    );
  };

App.defaultProps = {
  mode: 'main',
};

App.propTypes = {
  mode: PropTypes.string,
};

export default App;
