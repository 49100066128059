import React from 'react';
import { PropTypes } from 'prop-types';

import SubscriptionButtonContainer from 'app/containers/SubscriptionButtonContainer';
import PackageSubscriptionButtonContainer from 'app/containers/PackageSubscriptionButtonContainer';

import { featureFlag } from 'app/util/featureFlag';

const counterSection = (imageCount, videoCount, audioCount) => {
  const hasImages = imageCount > 0;
  const hasVideos = videoCount > 0;
  const hasAudios = audioCount > 0;

  return (
    <div className="row center-xs">
      <div className="slick-counter">
        {hasImages && (
          <div data-test-id="image-counter">
            <img src="/plus_frontend/assets/svg/ico-image.svg" alt="Images" />
            <span className="current">
              {imageCount}
            </span>
          </div>
        )}
        {hasVideos && (
          <div data-test-id="video-counter">
            {hasImages && <span className="padding10" />}
            <>
              <img src="/plus_frontend/assets/svg/ico-video.svg" alt="Video" />
              <span className="current">
                {videoCount}
              </span>
            </>
          </div>
        )}
        {hasAudios && (
          <div data-test-id="audio-counter">
            {(hasImages || hasVideos) && <span className="padding10" />}
            <>
              <img src="/plus_frontend/assets/svg/ico-audio.svg" alt="Audio Files" />
              <span className="current">
                {audioCount}
              </span>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

const HiddenSubscriptionPost = ({ advisor, post }) => {
  const packagesEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');

  return (
    <div className="slick-container">
      <div className="container-subscribe">
        <div className="row">
          {packagesEnabled ? (
            <div className="col-xs-12">
              <a href="#">
                <img
                  src="/plus_frontend/assets/svg/ico-lock.svg"
                  alt="Subscribe to Feed Access To Unlock"
                  className="ico-lock-big"
                />
              </a>
              <br />
              <PackageSubscriptionButtonContainer
                advisor={advisor}
              />
            </div>
          ) : (

            <div className="col-xs-12">
              <a href="#">
                <img
                  src="/plus_frontend/assets/svg/ico-lock.svg"
                  alt="Subscribe to Unlock"
                  className="ico-lock-big"
                />
              </a>
              <br />
              <SubscriptionButtonContainer
                fetchStateFromApi
                advisorLogin={advisor?.login}
                displayOnHiddenPost
              />
            </div>
          )}
        </div>
      </div>
      {
        ((post.image_count > 0) || (post.video_count > 0) || (post.audio_count > 0)) &&
          counterSection(post.image_count, post.video_count, post.audio_count)
      }
    </div>
  );
};

HiddenSubscriptionPost.defaultProps = {
  advisor: null,
};

HiddenSubscriptionPost.propTypes = {
  advisor: PropTypes.object,
  post: PropTypes.object.isRequired,
};

export default HiddenSubscriptionPost;
