import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  useGetAdvisorsQuery,
  useGetPublicPackagesForAdvisorsQuery,
} from 'app/api/mainApi';
import PostContainer from 'app/containers/PostContainer';
import PostContentPlaceholder from 'app/components/posts/PostContentPlaceholder';
import ModalContext from 'app/contexts/ModalsContext';
import PremierListFollowedModal from 'app/components/modals/PremierListFollowedModal';
import FeedPlaceholder from 'app/components/FeedPlaceholder';

import { setLowestPricePackages } from 'app/slices/feedSlice';

// the parent container will supply the cursor arg, which we'll use to
// fetch this page's-worth of data. after fetching we'll tell the parent
// container what the oldest cursor from the batch of posts was with
// the setOldestCursor function
const PostsPageContainer = ({
  setOldestCursor,
  setNewestCursor,
  feedQuery,
  showPin,
  setMoreRecords,
}) => {
  const dispatch = useDispatch();
  const { data } = feedQuery();
  const premierListFollowed = data?.premier_list_followed;
  const posts = data?.records;
  const moreRecords = data?.moreRecords;
  const advisorIds = posts?.map((p) => p.main_user_id);
  const uniqueAdvisorIds = [...new Set(advisorIds)];
  const { openModal } = useContext(ModalContext);

  const { data: advisors } = useGetAdvisorsQuery(
    { ids: uniqueAdvisorIds },
    { skip: (!uniqueAdvisorIds || uniqueAdvisorIds.length === 0) },
  );

  const { data: packages } = useGetPublicPackagesForAdvisorsQuery(
    { advisorIds: uniqueAdvisorIds },
    { skip: (!uniqueAdvisorIds || uniqueAdvisorIds.length === 0) },
  );

  useEffect(() => { setMoreRecords(moreRecords); }, [moreRecords]);

  useEffect(() => {
    if (packages && packages.length > 0) {
      const lowestPackagesByAdvisor = packages.reduce((acc, pkg) => {
        if (!pkg.feedPerk) return acc;
        const parseRate = (rateString) => parseFloat(rateString.replace(/[^\d.]/g, ''));
        const { advisorId, rate } = pkg;
        const numericRate = parseRate(rate);
        if (!acc[advisorId] || numericRate < parseRate(acc[advisorId].rate)) {
          acc[advisorId] = pkg;
        }
        return acc;
      }, {});

      dispatch(setLowestPricePackages(lowestPackagesByAdvisor));
    }
  }, [packages, dispatch]);

  useEffect(() => {
    if (premierListFollowed) {
      openModal({ component: PremierListFollowedModal });
    }
  }, [premierListFollowed]);

  useEffect(() => {
    if (posts?.length > 0) {
      const newestPost = posts[0];
      const oldestPost = posts[posts.length - 1];

      setOldestCursor(oldestPost.published_at);

      // setting newest cursor is only necessary on the home
      // (combined) feed. the flirt feed doesn't check for
      // newer posts at this time (09/29/2022) - jr
      if (setNewestCursor) {
        setNewestCursor(newestPost.published_at);
      }
    }
  }, [posts]);

  if (!posts) {
    return (
      <PostContentPlaceholder />
    );
  }

  return (
    <>
      {(posts.length == 0) && !moreRecords && (
        <FeedPlaceholder />
      )}

      {posts.map((post) => {
        // consider making this a hash indexed on id for efficiency, no?
        // and maybe store in a slice that can be shared accross comps
        const advisor = advisors?.find((a) => a.id === post.main_user_id);

        return (
          <PostContainer
            post={post}
            key={post.id}
            advisor={advisor}
            showPin={showPin}
            showComments={false}
          />
        );
      })}
    </>
  );
};

PostsPageContainer.defaultProps = {
  setNewestCursor: null,
  showPin: false,
};

PostsPageContainer.propTypes = {
  setOldestCursor: PropTypes.func.isRequired,
  setNewestCursor: PropTypes.func,
  feedQuery: PropTypes.func.isRequired,
  showPin: PropTypes.bool,
  setMoreRecords: PropTypes.func.isRequired,
};

export default PostsPageContainer;
