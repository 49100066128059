import React from 'react';
import {Button, DialogTitle, Stack} from '@mui/material';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';

import {
  DialogContent,
  Box,
  Typography,
} from '@mui/material';

const PwaInstallInstructions = ({ closeDialog }) => {

  const isAppMobile = navigator.userAgent.match(/mobile|ipad/ig);

  let step2Icon = '/plus_frontend/assets/pwa/icon-dock.png';
  let step2Text = 'Click Add to Dock';
  let secondImage = '/plus_frontend/assets/pwa/add-to-dock.png';

  if (navigator.userAgent.match(/iphone|ipad/ig)) {
    step2Icon = '/plus_frontend/assets/pwa/icon-add-app.png';
    step2Text = 'Swipe up and tap Add to Home Screen';
    secondImage = '/plus_frontend/assets/pwa/pwa-ios-instructions.png';
  }

  return (
    <>
      <DialogTitle>
        Install to home screen
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Box mx={{ xs: 0, sm: 12, md: 17 }}>
          <Stack direction="row" alignItems="center" spacing={1} mb={2}>
            <img id="pwa-install-button" src="/plus_frontend/assets/pwa/icon-share.png" alt="Install"
                 style={{width: '25px', height: '25px'}}
            />
            <Typography variant="body1">
              1. {isAppMobile ? 'Tap' : 'Click'} Share
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} mb={2}>
            <img src={ step2Icon } alt="Step 2 icon"
                 style={{width: '25px', height: '25px'}}
            />
            <Typography variant="body1">
              2. { step2Text }
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} mb={2}>
            <img src={ secondImage } alt="Step 2 icon"
                 style={{width: '100%', maxWidth: '300px'}}
            />
          </Stack>
        </Box>
      </DialogContent>
      <Stack direction="row" alignItems="center" justifyContent={'flex-end'} mb={2} px={2}>
        <Button
          variant="contained"
          fullWidth={false}
          onClick={closeDialog}
        >Close</Button>
      </Stack>
    </>
  )
}

export default PwaInstallInstructions;