import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGetNewUserQuery } from 'app/api/mainApi';
import ReCAPTCHA from 'react-google-recaptcha';

const useRecaptcha = () => {
  const query = useGetNewUserQuery();
  const { recaptcha_site_key: recaptchaSiteKey, recaptcha_enabled: recaptchaEnabled } = query.data || {};
  const recaptchaRef = useRef();
  const onChangeRef = useRef(null);

  const recaptchaComponent = useCallback((onChange, onError) => {
    onChangeRef.current = onChange;

    if (!recaptchaEnabled) return null;

    if (!recaptchaSiteKey && query.isSuccess) {
      console.error("Missing recaptcha_site_key");
      return null;
    }

    return (
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaSiteKey}
        size="invisible"
        onChange={onChange}
        onError={onError}
      />
    );
  }, [recaptchaEnabled, recaptchaSiteKey, query.isSuccess]);

  const executeRecaptcha = useCallback(() => {
    if (recaptchaEnabled && recaptchaRef.current) {
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    } else if (onChangeRef.current) {
      onChangeRef.current('mock-token');
    } else {
      console.error('onChange callback not set. Make sure recaptchaComponent is rendered first.');
    }
  }, [recaptchaEnabled]);

  return { executeRecaptcha, recaptchaComponent };
};

useRecaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default useRecaptcha;
