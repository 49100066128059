import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CallListingShape from 'app/shapes/CallListingShape';

import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import {
  toNumber,
  toCurrency,
} from 'app/helpers/currencyHelpers';

import ModalsContext from 'app/contexts/ModalsContext';
import useCreditCardManagement from 'app/hooks/useCreditCardManagement';
import { userHasCard } from 'app/helpers/userHelper';

import InsuffientFundsError from 'app/components/call/errors/InsufficientFundsError';

const CurrentBalance = ({
  balance,
  listing,
  isBalanceLoading,
  isListingLoading,
  currentUser,
}) => {
  const availableBalance = !isBalanceLoading && toNumber(balance.available_balance);
  const currentUserHasCard = userHasCard(currentUser);
  const { openModal, closeModal } = useContext(ModalsContext);
  const onTryAgainSuccess = closeModal;
  const onManageCardsSuccess = closeModal;
  const onAddToBalanceSuccess = closeModal;

  const {
    openManageCardsModal,
    openAddToBalanceModal,
  } = useCreditCardManagement({
    openModal,
    currentUser,
    guard: null,
    setGuard: null,
    onTryAgainSuccess,
    onManageCardsSuccess,
    onAddToBalanceSuccess,
  });

  const resolveMoneyManagementFlow = () => {
    closeModal();

    if (currentUserHasCard) {
      const currentBalance = toNumber(balance.available_balance);
      const listingCost = listing.recorded ? listing.price_per_minute * listing.recording_length : listing.price_per_minute;
      const deficit = toNumber(listingCost - currentBalance).toPrecision(2);
      const context = null;
      const assetName = 'call';
      const customCloseFunc = () => {};
      const showError = deficit > 0;

      openAddToBalanceModal(deficit, context, assetName, customCloseFunc, showError);
    } else {
      openManageCardsModal();
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: 550,
        // margin: 'auto',
        alignItems: 'flex-start', // Align items to the top
      }}
    >
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          style={{ textAlign: 'right' }}
        >
          Balance
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
        >
          { isBalanceLoading || isListingLoading ? '-' : (
            <>
              <Typography variant="body1">
                {toCurrency(availableBalance)}
              </Typography>
              <InsuffientFundsError
                balance={balance}
                listing={listing}
                isLoading={isListingLoading}
                currentUser={currentUser}
              />
              <Typography
                variant="body2"
                paddingRight={1}
              >
                <a
                  onClick={resolveMoneyManagementFlow}
                  href="#"
                >
                  Add
                </a>
              </Typography>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

CurrentBalance.defaultProps = {
  balance: null,
  listing: null,
};

CurrentBalance.propTypes = {
  balance: PropTypes.object,
  listing: CallListingShape,
  isBalanceLoading: PropTypes.bool.isRequired,
  isListingLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default CurrentBalance;
