import React, { useState } from 'react';
import {
  FormLabel,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

const SocialSecurityField = ({ value, onChange }) => {
  const [reveal, setReveal] = useState(false);

  const secondInputRef = React.useRef(null);
  const thirdInputRef = React.useRef(null);

  const internalOnChange = (index, newValue) => {
    newValue = newValue.replace(/[^0-9]/g, '');
    let finalValue = '';
    switch (index) {
      case 0:
        finalValue = newValue + value.substring(3, 5) + value.substring(5, 9);

        if (newValue.length === 3) {
          secondInputRef.current.focus();
        }
        break;
      case 1:
        finalValue = value.substring(0, 3) + newValue + value.substring(5, 9);
        if (newValue.length === 2) {
          thirdInputRef.current.focus();
        }
        break;
      default:
        finalValue = value.substring(0, 5) + newValue;
    }

    onChange(finalValue);
  }

  return (
    <Stack>
      <FormLabel>
        Social Security Number
      </FormLabel>
      <Stack direction="row" spacing={1} alignItems={'center'}>
        <TextField
          type={reveal ? 'text' : 'password'}
          size="small"
          value={value.substring(0,3)}
          onChange={(e) => internalOnChange(0, e.target.value)}
          sx={{ width: '60px'}}
          inputProps={{ maxLength: 3}}
          autoFocus={true}
          tabIndex={1}
        />
        <span style={{ fontSize: '1.5rem', fontWeight: 'lighter'}}>-</span>
        <TextField
          type={reveal ? 'text' : 'password'}
          size="small"
          value={value.substring(3,5)}
          onChange={(e) => internalOnChange(1, e.target.value)}
          sx={{width: '60px'}}
          inputProps={{ maxLength: 2 }}
          tabIndex={2}
          inputRef={secondInputRef}
        />
        <span style={{ fontSize: '1.5rem', fontWeight: 'lighter'}}>-</span>
        <TextField
          type={reveal ? 'text' : 'password'}
          size="small"
          value={value.substring(5,9)}
          onChange={(e) => internalOnChange(2, e.target.value)}
          sx={{ width: '70px'}}
          inputProps={{ maxLength: 4 }}
          tabIndex={3}
          inputRef={thirdInputRef}
        />
        <a style={{color: 'var(--link_color)', cursor: 'pointer'}} onClick={(e) => { e.preventDefault(); setReveal(!reveal)}}>{ reveal ? 'Hide' : 'Reveal'}</a>
      </Stack>
    </Stack>
  )
}

SocialSecurityField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  reveal: PropTypes.bool,
}

SocialSecurityField.defaultProps = {
  reveal: false,
}

export default SocialSecurityField;
