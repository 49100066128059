import React from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Box,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import PhoneShape from 'app/shapes/PhoneShape';

import MenuAvailabilityList from 'app/components/availabilityMenu/MenuAvailabilityList';
import SeekerPhone from 'app/components/availabilityMenu/SeekerPhone';

const SeekerPhones = ({ phones, onClick }) => {
  if (phones?.length === 0) {
    return (
      <Grid data-test-id="seeker-phones" container sx={{ mb: 1, alignItems: 'center' }}>
        <Box sx={{ mr: 2 }}>
          <a className="bluelink" href="/availability" onClick={onClick}>
            Add Phone Number
          </a>
        </Box>
      </Grid>
    );
  }

  return (
    <>
      <Grid data-test-id="seeker-phones" container sx={{ mb: 1, alignItems: 'center' }}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            Call Me At:
          </Typography>
        </Grid>
        <Grid item xs={6} className="pull-right hide-manage-link">
          <Typography variant="body3">
            <a href="/availability" className="bluelink" onClick={onClick}>
              Manage
            </a>
          </Typography>
        </Grid>
      </Grid>

      {phones.length > 0 && (
        <Paper sx={{ p: 0 }} variant="outlined">
          <MenuAvailabilityList aria-label="section1">
            {phones.map((phone, index) => (
              <React.Fragment key={`phone_${phone.id}`}>
                <SeekerPhone phone={phone} />
                {(index !== phones.length - 1) && <Divider />}
              </React.Fragment>
            ))}
          </MenuAvailabilityList>
        </Paper>
      )}
    </>
  );
};

SeekerPhones.defaultProps = {
  phones: [],
  onClick: () => { },
};

SeekerPhones.propTypes = {
  phones: PropTypes.arrayOf(PhoneShape),
  onClick: PropTypes.func,
};

export default SeekerPhones;
