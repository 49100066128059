import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material';
import CircularProgress from 'app/components/shared/CircularProgress';
import { DataGrid, GridToolbar, getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid';

import { timestampInTimezone, FULL_DATE_FORMAT } from 'app/helpers/timestampHelpers';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { useGetDetailsOffersQuery } from 'app/api/customersApi';

const OVERFLOW_LENGTH = 100;

/* eslint-disable react/prop-types */
const customYesNoFilter = {
  ...getGridStringOperators().find((op) => op.value === 'equals'), // Use "is" (equals) as the operator
  InputComponent: ({ item, applyValue }) => (
    <FormControl variant="standard" margin="none">
      {/* An InputLabel with hidden text to maintain spacing */}
      <InputLabel shrink sx={{ visibility: 'hidden' }}>Placeholder</InputLabel>

      <Select
        value={item.value || ''}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        displayEmpty
        sx={{ width: '100%' }}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </Select>
    </FormControl>
  ),
};
/* eslint-enable react/prop-types */

const CustomerDetailsOffers = () => {
  const { login } = useParams(); // userId will capture the value of :userId in the URL
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const { data, isFetching } = useGetDetailsOffersQuery({ login });

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [preferLargeScreen, setPreferLargeScreen] = useState(false);

  if (isFetching) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="100%"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  if (!data) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="20vh"
        width="100%"
      >
        <Typography variant="subtitle1">
          No offers found.
        </Typography>
      </Stack>
    );
  }

  const DATA_GRID_COLUMNS = [
    {
      field: 'created_at',
      headerName: 'Date',
      width: 110,
      valueFormatter: (params) => (
        timestampInTimezone(params.value, currentUser?.canonical_iana_timezone, FULL_DATE_FORMAT)
      ),
      filterOperators: getGridDateOperators(),
    },
    {
      field: 'free_minutes',
      headerName: 'Details',
      width: 200,
      valueFormatter: (params) => `${params.value} minutes`,
    },
    {
      field: 'redeemed',
      headerName: 'Redeemed',
      width: 100,
      valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
      filterOperators: [customYesNoFilter],
    },
    {
      field: 'expired',
      headerName: 'Expired',
      width: 100,
      valueFormatter: (params) => (params.value ? 'Yes' : 'No'),
      filterOperators: [customYesNoFilter],
    },
  ];

  if (!isSmallScreen || preferLargeScreen) {
    return (
      <Box sx={{ height: '80vh', px: { xs: 1, sm: 2 } }}>
        <DataGrid
          columns={DATA_GRID_COLUMNS}
          rows={data}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input': {
              display: 'none !important', // Hide "Rows per page" element
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal', // Allow text wrapping
              wordWrap: 'break-word',
              lineHeight: '1.5rem',
              py: '15px',
            },
          }}
          getRowHeight={() => 'auto'} // Dynamically adjust row height
        />
      </Box>
    );
  }

  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: { xs: 'auto', sm: 650 } }} aria-label="simple table">
        <TableBody>
          {data?.slice(0, OVERFLOW_LENGTH)?.map((offer, index) => (
            <TableRow
              key={offer.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 0 ? '#F2F2F2' : 'white',
              }}
            >
              <TableCell width="70">
                <Typography variant="body1" sx={{ color: 'var(--color_gray_six)' }}>
                  Date
                  <br />
                  Details
                  <br />
                  Redeemed
                  <br />
                  Expired
                </Typography>
              </TableCell>
              <TableCell>
                {timestampInTimezone(offer.created_at, currentUser?.canonical_iana_timezone, FULL_DATE_FORMAT)}
                <br />
                {offer.free_minutes}
                &nbsp;minutes
                <br />
                {offer.redeemed ? 'Yes' : 'No'}
                <br />
                {offer.expired ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>
          ))}
          { data?.length > OVERFLOW_LENGTH && (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="body1">
                  ... Data truncated. To see more,
                  <Button variant="text" color="primary" sx={{ fontSize: '1em', mt: '-2px' }} onClick={() => setPreferLargeScreen(true)}>view desktop version.</Button>
                </Typography>
              </TableCell>
            </TableRow>
          ) }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerDetailsOffers;
