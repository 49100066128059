import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMatch } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import PostsQueueTabs from 'app/components/nav/PostsQueueTabs';
import StyledHeader from 'app/components/shared/StyledHeader';
import BackArrow from 'app/components/shared/BackArrow';
import SearchControlsContainer from 'app/containers/SearchControlsContainer';
import SearchToggle from 'app/components/SearchToggle';
import SearchContainer from 'app/containers/SearchContainer';
import ScheduledPostsContainer from './posts_queue/ScheduledPostsContainer';
import ProcessingPostsContainer from './posts_queue/ProcessingPostsContainer';
import RemovedPostsContainer from './posts_queue/RemovedPostsContainer';
import { SCHEDULED_TAB, PROCESSING_TAB, REMOVED_TAB } from 'app/constants/tabNames';

const PostsQueueContainer = () => {
  const { tabName, login: advisorLogin } = useParams();
  const currentTab = tabName;
  const navigate = useNavigate();
  const viewingScheduled = useMatch(`/${advisorLogin}/feed/queue/scheduled`);
  const viewingProcessing = useMatch(`/${advisorLogin}/feed/queue/processing`);
  const viewingRejected = useMatch(`/${advisorLogin}/feed/queue/removed`);
  const inStorefront = viewingScheduled || viewingProcessing || viewingRejected;

  const setCurrentTab = (tabName) => {
    if (inStorefront) {
      navigate(`/${advisorLogin}/feed/queue/${tabName}`);
    } else {
      navigate(`/feed/queue/${tabName}`);
    }
  };

  return (
    <AppBodyWithSidebar>
      {!inStorefront && (
        <SearchContainer data-test-id="search-container">
          <StyledHeader data-test-id="template-shell-header" style={{ marginBottom: '0px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Stack direction="row" alignItems="center" spacing={2}>
                <BackArrow />
                <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle">Posts Queue</Typography>
              </Stack>
              <SearchToggle />
            </Stack>
          </StyledHeader>
          <SearchControlsContainer />
        </SearchContainer>
      )}
      <PostsQueueTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />

      {currentTab === SCHEDULED_TAB && (
        <ScheduledPostsContainer />
      )}

      {currentTab === PROCESSING_TAB && (
        <ProcessingPostsContainer />
      )}

      {currentTab === REMOVED_TAB && (
        <RemovedPostsContainer />
      )}
    </AppBodyWithSidebar>
  );
};

export default PostsQueueContainer;
