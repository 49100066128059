/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { notEqual } from 'app/util/objectComparisons';

export const filterOptions = {
  value: ['Highest', 'High', 'Medium', 'Low'],
  status: ['Active', 'Lapsing', 'Lapsed', 'One day only', 'New'],
  mainSpend: ['Calls', 'Chat', 'Tributes', 'Paid Mail', 'Recurring', 'Goodies'],
  recurringTributes: ['Recurring Tributes', 'Subscribers', 'Pending Expirations', 'Expired Subscribers'],
  other: ['Blocked', 'Free Followers', 'Affiliate Referral'],
  earningsChannel: ['Calls', 'Chat', 'Tributes', 'Paid Mail', 'Goodies', 'Recurring'],
  earningsDateWindow: ['Last 7 days', 'Last 14 days', 'Last 30 days', 'Last 60 days', 'Last 90 days', 'Last 180 days', 'Last 365 days', 'Lifetime'],
};

export const sortOptions = [
  'Value',
  'Status',
  'Last Contact',
  'Last 90 Day Earnings',
  'Last 365 Day Earnings',
  'Lifetime Earnings',
  'Main Spend',
  'Name',
];

const initialState = {
  filters: {
    text: '',
    value: [],
    status: [],
    mainSpend: [],
    contactedDaysAgo: [0, 1090],
    earnedDaysAgo: [0, 1090],
    recurringTributes: [],
    packages: [],
    other: [],
    hasTags: [],
    excludeTags: [],
    earningsDetails: false,
    minAmount: 50,
    earningsChannel: [],
    earningsDateWindow: '',
    saveThisSearch: false,
    nameForSavedSearch: '',
    filterTimestamp: null, // touch this to trigger the useEffect and re-run the search
  },
  omnibox: [],
  resultCounts: {},
  customTags: [],
  sortColumn: ['Last Contact', 'Value'],
  sortDirection: ['asc', 'asc'],
};

const customersSlice = createSlice({
  name: 'customersSlice',
  initialState,
  reducers: {
    clearFilters: () => ({ ...initialState }),

    // accepts ['tagName'] as action.payload, and returns initialState but with
    // filters.hasTags set to that parameter
    setFiltersToTag: (_state, action) => (
      {
        ...initialState,
        filters: {
          ...initialState.filters,
          hasTags: action.payload,
        },
      }
    ),

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setOmnibox: (state, action) => {
      state.omnibox = action.payload;
    },

    setSort: (state, action) => {
      state.sortColumn = action.payload.sortColumn;
      state.sortDirection = action.payload.sortDirection;
    },

    touchFilterTimestamp: (state) => {
      state.filters.filterTimestamp = Date.now();
    },
  },
});

const selectors = {
  filtersSelector: (state) => state.customers.filters,
  anyFiltersSelector: (state) => {
    if (state.customers?.filters) {
      return Object
        .keys(state.customers.filters)
        .filter(x => notEqual(state.customers.filters[x], initialState.filters[x])) // non-default values
        .length !== 0;
    } else {
      return false;
    }
  },

  advancedFilterCountSelector: (state) => {
    if (state?.customers?.filters) {
      return Object
        .keys(state.customers.filters)
        .filter(x => !['nameForSavedSearch', 'text', 'saveThisSearch'].includes(x)) // exclude these from the count
        .filter(x => notEqual(state.customers.filters[x], initialState.filters[x])) // non-default values
        .length;
    } else {
      return 0;
    }
  },

  sortSelector: (state) => ({
    sortColumn: state.customers.sortColumn,
    sortDirection: state.customers.sortDirection,
  }),

  customTagsSelector: (state) => state.customers.customTags,

  omniboxSelector: (state) => state.customers.omnibox,
};

export default customersSlice;

export const {
  clearAllButText,
  clearFilters,
  setFilters,
  setFiltersToTag,
  setText,
  setOmnibox,
  setSort,
  touchFilterTimestamp,
} = customersSlice.actions;

export const {
  filtersSelector,
  advancedFilterCountSelector,
  anyFiltersSelector,
  omniboxSelector,
  sortSelector,
} = selectors;
