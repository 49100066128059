/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// This is a versatile reusable dropdown-
//   it can have checkboxes or not , based on the single prop (default is false => has checkboxes)
//   it can allow autocomplete or not, based on the allowTyping prop (default is false => no typing)
export const AutoCompleteMultiCheckbox = ({ label, options, value, setValue, allowTyping, single, showCount, counts, onDropdownOpenChange }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const multiple = !single;
  const onChange = (_event, newValue) => {
    setValue(newValue);
  };
  // Dynamically set listbox height for specific labels
  const listboxHeight = ['Media Types', 'Rating', 'Age'].includes(label) ? '145px' : '200px';

  let finalOptions = options;
  if (showCount) {
    // This needs to be done so the select shows the "No Options" message when there are "no options"
    // but for the filters there are always options, they just have the count = 0 so the component will appear empty.
    if (Object.values(counts).reduce((total, v) => total + v, 0) === 0) {
      finalOptions = [];
    } else {
      for (const [key, value] of Object.entries(counts)) {
        counts[key] = value > 999 ? '999+' : value;
      }
    }
  }

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <Autocomplete
        size="small"
        multiple={multiple}
        disableCloseOnSelect={multiple}
        options={finalOptions}
        value={value || []}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => value === option}
        clearIcon={value && value.length > 0 ? <ClearIcon /> : null}
        disablePortal
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'window',
                },
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: [],
                },
              },
            ],
            sx: {
              '& .MuiAutocomplete-listbox': {
                maxHeight: listboxHeight || '200px',
                overflowY: 'auto',
              },
            },
          },
        }}
        onOpen={() => onDropdownOpenChange(true)}
        onClose={() => onDropdownOpenChange(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            inputProps={{
              ...params.inputProps,
              readOnly: !allowTyping,
              inputMode: 'search',
              autoComplete: 'off',
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          if (showCount && counts[option] !== '999+' && counts[option] === 0) {
            return null;
          }

          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              { multiple &&
                  (
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  )}
              {option}
              { showCount && (
                <span>
                  &nbsp;(
                  {counts[option]}
                  )
                </span>
              ) }
            </li>
          );
        }}
      />
    </FormControl>
  );
};

AutoCompleteMultiCheckbox.defaultProps = {
  options: [], // briefly, while loading
  allowTyping: false,
  counts: {},
  showCount: false,
};

AutoCompleteMultiCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
  allowTyping: PropTypes.bool,
  showCount: PropTypes.bool,
  counts: PropTypes.object,
};

export default AutoCompleteMultiCheckbox;
