import React from 'react';
import PropTypes from 'prop-types';

const ListenButton = ({ onClick, disabled }) => {
  const className = disabled ? 'call-button listen-now--disabled' : 'call-button listen-now';
  return (
    <div>
      <a
        href="#"
        onClick={onClick}
      >
        <div className={className}>
          <span>Call Now</span>
        </div>
      </a>
    </div>
  );
};

ListenButton.defaultProps = {
  disabled: false,
};

ListenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ListenButton;
