import { useState, useEffect } from 'react';

export const useFileSelection = (items) => {
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    setCheckedItems((prevChecked) => {
      // Avoid unnecessary updates by checking if there's a real change
      const newCheckedItems = Object.fromEntries(
        items.filter((item) => prevChecked[item.id]).map((item) => [item.id, true]),
      );

      return JSON.stringify(prevChecked) === JSON.stringify(newCheckedItems) ? prevChecked : newCheckedItems;
    });
  }, [JSON.stringify(items)]); // Ensure deep comparison without causing infinite loops

  const isChecked = (id) => !!checkedItems[id];

  const toggleItem = (id) => {
    setCheckedItems((prev) => {
      const newCheckedState = {
        ...prev,
        [id]: !prev[id],
      };
      return newCheckedState;
    });
  };

  const toggleAll = () => {
    const selectedCount = Object.values(checkedItems).filter(Boolean).length;
    const shouldCheckAll = selectedCount !== items.length; // If not all are checked, check all; otherwise, clear all
    const newCheckedState = items.reduce((acc, item) => {
      acc[item.id] = shouldCheckAll;
      return acc;
    }, {});

    setCheckedItems(newCheckedState);
  };

  const clearAll = () => setCheckedItems({});

  const selectedCount = Object.values(checkedItems).filter(Boolean).length;
  const isSomeChecked = selectedCount > 0;
  const isAllChecked = items.length > 0 && items.every((item) => checkedItems[item.id]) && selectedCount > 0;

  return {
    checkedItems,
    isChecked,
    toggleItem,
    toggleAll,
    clearAll,
    selectedCount,
    isSomeChecked,
    isAllChecked,
  };
};
