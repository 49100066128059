import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import ConfirmCancelPackageSubscription from 'app/components/modals/tributes/ConfirmCancelPackageSubscription';
import { useDestroyRecurringPackageSubscriptionMutation } from 'app/api/mainApi';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const ConfirmCancelPackageSubscriptionContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const {
    activePackageSubscription,
    closeDialogAndResetTributeSlice,
  } = useRecurringTributeManagement({ advisor });

  const [
    destroyRecurringPackageSubscription,
    destroyRecurringPackageSubscriptionResult,
  ] = useDestroyRecurringPackageSubscriptionMutation();

  const onCancelSubscriptionClick = () => {
    destroyRecurringPackageSubscription({ advisorId: advisor.id });
  };

  const onKeepSubscriptionClick = () => {
    closeDialogAndResetTributeSlice();
  };

  useEffect(() => {
    if (destroyRecurringPackageSubscriptionResult.isSuccess) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'Your Package has been cancelled.',
        severity: 'success',
      }));
    }

    if (destroyRecurringPackageSubscriptionResult.isError) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'We\'re sorry, we encountered a system error.',
        severity: 'error',
      }));
    }
  }, [destroyRecurringPackageSubscriptionResult]);

  const closeDialog = () => {
    closeDialogAndResetTributeSlice();
  };

  return (
    <ConfirmCancelPackageSubscription
      activePackageSubscription={activePackageSubscription}
      closeDialog={closeDialog}
      onCancelSubscriptionClick={onCancelSubscriptionClick}
      onKeepSubscriptionClick={onKeepSubscriptionClick}
    />
  );
};

ConfirmCancelPackageSubscriptionContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ConfirmCancelPackageSubscriptionContainer;
