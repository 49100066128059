/* eslint-disable react/prop-types */
import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import BankAccountDetails from './BankAccountDetails';
import Divider from '@mui/material/Divider';
import NumberField from 'app/components/form_components/NumberField';
import PropTypes from 'prop-types';

import {
  validateEmailFormat,
} from 'app/util/fieldValidations';

const RequestPayout = ({
  payout,
  setPayout,
}) => {

  const emailIsEmpty = payout.email === '';
  const emailFormatInvalid = !validateEmailFormat(payout.email);

  let emailErrorText = null;
  if (emailIsEmpty) {
    emailErrorText = 'Email can\'t be blank';
  } else if (emailFormatInvalid) {
    emailErrorText = 'Invalid email format';
  }

  return (
    <Stack direction="column" spacing={3} sx={{ marginTop: 3 }}>
      <Typography variant="subtitle1">
        Request Payout
        <Typography variant="body3" sx={{ paddingLeft: 2 }}>
          <a className="bluelink" href="https://support.niteflirt.com/hc/en-us/articles/212830838-Flirt-Payout" target="_blank" rel="noreferrer">Learn More</a>
        </Typography>
      </Typography>

      <RadioGroup
        aria-labelledby=""
        value={payout.paymentPeriod}
        onChange={(e) => setPayout({ ...payout, paymentPeriod: e.target.value })}
        name="radio-buttons-group"
        sx={{ marginTop: '0!important' }}
      >
        <FormControlLabel value="1" control={<Radio />} label="Express Pay" />
        <Box sx={{ mx: 4 }}>
          <Typography variant="body2">Payout is deducted every day.</Typography>
          {
            payout.paymentPeriod === '1' && (
              <BankAccountDetails payout={payout} setPayout={setPayout} />
            )
          }
        </Box>

        <FormControlLabel value="6" control={<Radio />} label="Semimonthly transfer to Paxum" />
        <Box sx={{ mx: 4 }}>
          <Typography variant="body2">Payout is deducted on the 1st and 15th of the month and transferred to your Paxum account. $5 fee applied for each transfer.</Typography>
          {
            payout.paymentPeriod === '6' && (
              <TextField
                sx={{ width: '50%', marginTop: '1rem' }}
                label="Paxum Account Email"
                size="small"
                value={payout.email}
                onChange={(e) => setPayout({ ...payout, email: e.target.value })}
                error={emailIsEmpty || emailFormatInvalid}
                helperText={emailErrorText}
              />
            )
          }
        </Box>

        <FormControlLabel value="5" control={<Radio />} label="Monthly transfer to MassPay" />
        <Box sx={{ mx: 4 }}>
          <Typography variant="body2">Payout is deducted on the 1st of the month and transferred to your MassPay Account. $5 fee applied.</Typography>
          {
            payout.paymentPeriod === '5' && (
              <>
                <br />
                <Typography variant="body2">
                  MassPay is a third-party payment service that allows you to be paid in a variety of different ways, from direct bank transfers to crypto currency, in all countries NiteFlirt supports.
                </Typography>
                <br />
                <Typography variant="body2">
                  By choosing to be paid via MassPay, your funds will be sent to the MassPay account associated with the email address noted below which will allow you to manage and pay for the transfer of your funds. You will receive an email from MassPay after your NiteFlirt payout is sent to MassPay. &nbsp;
                  <a href="https://support.niteflirt.com/hc/en-us/articles/212830838-Flirt-Payout#MassPay" target="_blank" rel="noreferrer">Learn more</a>
                </Typography>
                <br />
                <Typography variant="body2">
                  All correspondences from MassPay will be sent to
                  {' '}
                  { payout.email }
                  , the email address that was associated with this NiteFlirt account at the time of your first MassPay payout.
                </Typography>
              </>
            )
          }
        </Box>

        <FormControlLabel value="2" control={<Radio />} label="Monthly Direct Deposit" />
        <Box sx={{ mx: 4 }}>
          <Typography variant="body2">
            Payout is deducted on the first of every month.
          </Typography>
          {
            payout.paymentPeriod === '2' && (
              <BankAccountDetails payout={payout} setPayout={setPayout} />
            )
          }
        </Box>

        <FormControlLabel value="0" control={<Radio />} label="Monthly Check" />
        <Box sx={{ mx: 4 }}>
          <Typography variant="body2">
            Payout is deducted on the first of the month, and a check is mailed to you. $5 fee applied.
          </Typography>
        </Box>

        <FormControlLabel value="4" control={<Radio />} label="Pause Payout" />
        <Box sx={{ mx: 4 }}>
          <Typography variant="body2">
            Payout will not be deducted and funds will remain in your account.
          </Typography>
        </Box>
      </RadioGroup>

      <Typography variant="subtitle1">
        Balance to keep in account
      </Typography>
      <Typography variant="body2" sx={{ marginTop: '0!important' }}>
        Enter an amount that will remain in your account after payout is deducted.
      </Typography>

      <NumberField
        label=""
        value={payout.amountLeftInAccount}
        setValue={(value) => setPayout({ ...payout, amountLeftInAccount: value })}
        min={0}
        max={99999}
        width="15ch"
        margin={0}
        boxSx={{ marginTop: '0.5rem!important' }}
      />

      <Stack justifyContent="flex-end" flexDirection="row">
        <a href="#" className="btn secondary btn-cancel" onClick={() => {}}>Cancel</a>
        <button
          type="button"
          className="btn"
          onClick={() => {}}
        >
          Save
        </button>
      </Stack>
      <Divider />

      <Typography variant="body1">
        Payment Eligibility:
      </Typography>
      <Typography variant="body2">
        You are eligible to receive payment for monies generated through your services on Niteflirt when your payable balance is at least $10.00 and your account is in good standing.
      </Typography>
      <Typography variant="body2">
        * Under penalty of perjury, I certify that the number entered onto this form is my correct taxpayer identification number.
      </Typography>
    </Stack>
  );
}

RequestPayout.propTypes = {
  payout: PropTypes.object.isRequired,
  setPayout: PropTypes.func.isRequired,
}

export default RequestPayout;