import React from 'react';

import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import { FavoritesHeading } from 'app/components/favorite_listings/FavoriteListingsTexts';
import FavoriteListingsContainer from 'app/containers/FavoriteListingsContainer';

const FavoriteListingsLayout = () => {
  return (
    <AppBody>
      <TemplateShell2 header="Favorites" goBackPath="/account">
        <FavoritesHeading />
        <FavoriteListingsContainer />
      </TemplateShell2>
    </AppBody>
  );
};

export default FavoriteListingsLayout;
