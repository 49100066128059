import PropTypes from 'prop-types';
import MessageShape from 'app/shapes/MessageShape';

const MailboxShape = PropTypes.shape({
  page: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(MessageShape).isRequired,
});

export default MailboxShape;
