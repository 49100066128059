import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TEXT_COLORS_FOR_BACKGROUND } from 'app/constants/customers';

const StyledChip = styled(Chip)(() => ({
  minWidth: '70px', // Default for screens above 600px
  '@media (max-width: 600px)': {
    '&.MuiChip-root': {
      minWidth: 'auto',
      fontSize: '0.75rem',
      padding: '0px 8px',
    },
    '& .MuiChip-label': {
      padding: '0px 4px',
    },
  },
}));

const ColorMappedChip = ({ label, colorMap, ...rest }) => {
  const backgroundColor = colorMap ? (colorMap[label] || colorMap[Object.keys(colorMap)[0]]) : undefined;
  const color = TEXT_COLORS_FOR_BACKGROUND[backgroundColor] || TEXT_COLORS_FOR_BACKGROUND.default;
  return (
    <StyledChip
      label={label}
      sx={{
        color,
        backgroundColor,
      }}
      size="small"
      {...rest}
    />
  );
};

ColorMappedChip.defaultProps = {
  colorMap: undefined,
};

ColorMappedChip.propTypes = {
  label: PropTypes.string.isRequired,
  colorMap: PropTypes.object,
};

export default ColorMappedChip;
