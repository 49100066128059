import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';

export const FormRecaptcha = ({ recaptchaSiteKey, recaptchaRef, onVerify }) => {
  const form = useFormContext();

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaSiteKey}
        size="invisible"
        onChange={(token) => {
          form.setValue('recaptcha_token', token, { shouldValidate: true });
          onVerify(token);
        }}
        onErrored={() => {
          form.setError('recaptcha_token', {
            type: 'manual',
            message: 'reCAPTCHA verification failed. Please try again.',
          });
        }}
      />
      {form.formState.errors.recaptcha_token && (
        <Typography color="error">
          {form.formState.errors.recaptcha_token.message}
        </Typography>
      )}
    </>
  );
};

export default FormRecaptcha;
