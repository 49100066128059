import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

const PayoutOnHoldWarning = () => (
  <Alert severity="warning">
    Your payments are on hold because we could not verify your Payee Information. Please edit your Payee Information. (
    <a href="https://support.niteflirt.com/articles/FAQ/Payee-Verification" target="_blank" rel="noreferrer">Learn More</a>
    )
  </Alert>
);
const SaveSuccessAlert = () => <Alert severity="success">Information saved successfully.</Alert>;
const ErrorAlert = ({ errors = [] }) => (
  <Alert severity="error">
    <AlertTitle>
      {errors.length}
      {' '}
      error(s) prohibited this payout from being saved.
    </AlertTitle>
    <ul>
      { errors.map((error) => <li key={error}>{error}</li>) }
    </ul>
  </Alert>
);

ErrorAlert.propTypes = {
  errors: PropTypes.array.isRequired,
};

export { PayoutOnHoldWarning, SaveSuccessAlert, ErrorAlert };
