// IMPORTANT - do not import directly, use RoutingContext to gain access to this function

import buildRouteMap from './buildRouteMap';

const generateUrl = (routeId, { params = {}, query = {} } = {}) => {
  const routeMapping = buildRouteMap();
  const pathTemplate = routeMapping[routeId];
  if (!pathTemplate) {
    throw new Error(`No route found for id: ${routeId}`);
  }

  let url = pathTemplate;
  // Filter out null values from params
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== null),
  );

  Object.keys(filteredParams).forEach((key) => {
    url = url.replace(`:${key}`, filteredParams[key]);
  });

  url = url.replace(/\/:([^/]+)\?/g, '');

  // Filter out null values from query
  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([_, value]) => value !== null),
  );

  // Append query string parameters only if provided.
  const queryKeys = Object.keys(filteredQuery);
  if (queryKeys.length > 0) {
    const searchParams = new URLSearchParams(query);
    url += `?${searchParams.toString()}`;
  }

  return url;
};

export default generateUrl;
