import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Typography,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import CircularProgress from 'app/components/shared/CircularProgress';
import { DataGrid, GridToolbar, getGridDateOperators, getGridStringOperators } from '@mui/x-data-grid';

import { timestampInTimezone, FULL_DATE_FORMAT } from 'app/helpers/timestampHelpers';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { useGetAdvisorFeedbacksQuery } from 'app/api/accountApi';

import { SummaryBox, FeedbackRating } from 'app/containers/AdvisorFeedbacksContainer';

// make sure to get them all in one slurp, because we're using data grid with built-in pagination for large viewports, and
// manually hard-limiting to first XXX rows for smaller viewports
const perPage = 100000;
const OVERFLOW_LENGTH = 100;

const customStringFilter = (distinctValues) => ({
  ...getGridStringOperators().find((op) => op.value === 'equals'), // Use "is" (equals) as the operator
  InputComponent: ({ item, applyValue }) => ( // eslint-disable-line react/prop-types
    <FormControl variant="standard" margin="none">
      {/* An InputLabel with hidden text to maintain spacing */}
      <InputLabel shrink sx={{ visibility: 'hidden' }}>Placeholder</InputLabel>

      <Select
        value={item.value || ''} // eslint-disable-line react/prop-types
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        displayEmpty
        sx={{ width: '100%' }}
      >
        <MenuItem value="">All</MenuItem>
        { distinctValues.map((value) => (
          <MenuItem key={value} value={value}>{value}</MenuItem>
        )) }
      </Select>
    </FormControl>
  ),
});

const dataGridColumns = (currentUser, uniqueTypes) => ([
  {
    field: 'created_at',
    headerName: 'Date',
    width: 110,
    valueFormatter: (params) => (
      timestampInTimezone(params.value, currentUser?.canonical_iana_timezone, FULL_DATE_FORMAT)
    ),
    filterOperators: getGridDateOperators(),
  },
  {
    field: 'rating',
    headerName: 'Rating',
    width: 110,
    renderCell: (feedback) => (<FeedbackRating data-test-id="rating" feedback={feedback.row} />),
    filterable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 250,
    renderCell: (feedback) => {
      return (
        <Stack
          direction="column"
          spacing={0.2}
          // TODO: Matt.  Uncomment this and we get word wrapping - but the grid cell doesn't get any taller!  You might have to play with this.
          // sx={{ whiteSpace: 'normal !important', wordWrap: 'break-word !important' }}
        >
          <Box data-test-id="title">{feedback.row.title}</Box>
          { feedback.row.category_name && (
            <Typography data-test-id="category-name" variant="body3" pt={0.3} sx={{ color: '#999' }}>
              {feedback.row.category_name}
            </Typography>
          )}
        </Stack>
      );
    },
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 250,
  },
  {
    field: 'type',
    headerName: 'Type',
    hide: true,
    filterOperators: [customStringFilter(uniqueTypes)],
  },
]);

const CustomerDetailsFeedback = ({ seekerId }) => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const page = 1;
  const { data: feedbackData, isFetching } = useGetAdvisorFeedbacksQuery({ page, perPage, seekerId });
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [preferLargeScreen, setPreferLargeScreen] = useState(false);
  const uniqueTypes = [...new Set(feedbackData?.feedbacks?.map((feedback) => feedback.type))];

  if (isFetching) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="100%"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  if (!(feedbackData?.feedbacks)) {
    return (
      <Typography variant="body1" fontWeight="bold">
        No feedback found.
      </Typography>
    );
  }

  if (!isSmallScreen || preferLargeScreen) {
    return (
      <Box sx={{ height: '80vh', px: { xs: 1, sm: 2 } }}>
        <SummaryBox feedbackData={feedbackData} />
        <DataGrid
          columns={dataGridColumns(currentUser, uniqueTypes)}
          rows={feedbackData.feedbacks}
          loading={isFetching}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input': {
              display: 'none !important', // Hide "Rows per page" element
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal', // Allow text wrapping
              wordWrap: 'break-word',
              lineHeight: '1.5rem',
              py: '15px',
            },
          }}
          getRowHeight={() => 'auto'} // Dynamically adjust row height
        />
      </Box>
    );
  }

  return (
    <Box>
      <SummaryBox feedbackData={feedbackData} />
      <TableContainer component={Box}>
        <Table sx={{ minWidth: { xs: 'auto', sm: 650 } }} aria-label="simple table">
          <TableBody>
            {feedbackData?.feedbacks?.slice(0, OVERFLOW_LENGTH)?.map((feedback, index) => (
              <TableRow
                key={feedback.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#F2F2F2' : 'white',
                }}
              >
                <TableCell sx={{ verticalAlign: 'top', width: '70px' }}>
                  <Typography variant="body1" sx={{ color: 'var(--color_gray_six)' }}>
                    Date
                    <br />
                    Rating
                    <br />
                    Title
                    <br />
                    Comment
                  </Typography>
                </TableCell>

                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography>
                    {timestampInTimezone(feedback.created_at, currentUser?.canonical_iana_timezone, FULL_DATE_FORMAT)}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <FeedbackRating data-test-id="rating" feedback={feedback} />
                    <Typography data-test-id="category-name" variant="body3" pt={0.3} sx={{ color: '#999' }}>
                      {feedback.category_name || ' '}
                    </Typography>
                  </Stack>
                  <Typography>{feedback.title}</Typography>
                  <Typography data-test-id="comment">
                    {feedback.comment}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
            { feedbackData?.feedbacks?.length > OVERFLOW_LENGTH && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="body1" fontWeight="bold">
                    ... Data truncated.  To see more,&nbsp;
                    <Button variant="text" color="primary" onClick={() => setPreferLargeScreen(true)}>view desktop version.</Button>
                  </Typography>
                </TableCell>
              </TableRow>
            ) }

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

CustomerDetailsFeedback.propTypes = {
  seekerId: PropTypes.number.isRequired,
};

export default CustomerDetailsFeedback;
