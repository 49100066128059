import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { useGetAdvisorListingsQuery } from 'app/api/mainApi';
import { useParams } from 'react-router';

import { featureFlag } from 'app/util/featureFlag';

import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import ChatListingContainer from 'app/containers/ChatListingContainer';

const AdvisorListingsContainer = React.memo(({ advisor, headingSource }) => {
  const { login: advisorLogin } = useParams();

  const {
    data: callListings,
    isLoading: listingsLoading,
  } = useGetAdvisorListingsQuery({ login: advisorLogin });

  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  // const listings = React.useMemo(() => {
  //   // NOTE: We need to slice the array because the original array from RTK is immutable.
  //   const listings = callListings?.slice();

  //   if (
  //     !advisor.explicitly_unenrolled_in_chat &&
  //     advisor.enrolled_chat &&
  //     advisor.chat_eligible &&
  //     listings?.push
  //   ) {
  //     listings.push({ type: 'chat' });
  //   }

  //   return listings;
  // }, [callListings]);

  const listings = callListings?.slice();

  if (
    !advisor.explicitly_unenrolled_in_chat &&
    advisor.enrolled_chat &&
    advisor.chat_eligible &&
    listings?.push
  ) {
    listings.push({
      type: 'chat',
    });
  }

  if (listingsLoading) return <div data-test-id="loading-text">Loading...</div>;

  if (newCardsEnabled) {
    return (
      <Box
        px={{ xs: 0, sm: 0 }}
        pb={{ xs: 10, sm: 10 }}
        mr={{ xs: 0, sm: -3 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: { xs: '6px', sm: '10px' },
            justifyContent: { sm: 'flex-start' },
            '@media (max-width: 900px)': {
              paddingLeft: '0px',
              maxWidth: '685px',
              margin: '0 auto',
            },
            '@media (max-width: 700px)': {
              justifyContent: 'center',
              maxWidth: '400px',
            },
          }}
        >
          {listings.map((listing) => (
            <Box
              key={`listing_${listing.id}`}
              sx={{
                width: { xs: '330px', sm: '330px' },
                flex: '0 0 auto',
              }}
            >
              {listing.type === 'chat' ? (
                <ChatListingContainer advisor={advisor} />
              ) : (
                <PhoneListingContainer
                  listing={listing}
                  headingSource={headingSource}
                  queryName="getAdvisorListings"
                  queryArgs={{ login: advisorLogin }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  // Split listings into rows of 2, because our layout system can't do that automatically
  const rows = Array.from(
    { length: listings?.length ? Math.ceil(listings.length / 2) : 0 },
    (_, i) => listings?.slice(i * 2, i * 2 + 2),
  );

  return (
    <div
      className="container-fluid padding-0-8"
      data-testid="listings-container"
      style={{ maxWidth: '757px', marginLeft: '0px' }}
    >
      {rows.map((row) => (
        <div key={`row_${row[0].id}`} className="row">
          {row.map((listing) => (
            <div key={`listing_${listing.id}`} className="col-sm-6 col-xs-12">
              {listing.type === 'chat' ? (
                <ChatListingContainer advisor={advisor} />
              ) : (
                <PhoneListingContainer
                  listing={listing}
                  headingSource="category"
                  queryName="getAdvisorListings"
                  queryArgs={{ login: advisorLogin }}
                  queryDataKey="listings"
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
});

AdvisorListingsContainer.defaultProps = {
  advisor: {},
  headingSource: '',
};

AdvisorListingsContainer.propTypes = {
  advisor: PropTypes.object,
  headingSource: PropTypes.string,
};

export default AdvisorListingsContainer;
