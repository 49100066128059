import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { useGetFeedbacksQuery } from 'app/api/mainApi';
import RoutingContext from 'app/contexts/RoutingContext';

import {
  Box,
  Divider,
  Typography,
} from '@mui/material';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import FeedbackRow from 'app/components/feedback/FeedbackRow';

const FeedbacksHeading = () => {
  const generateUrl = useContext(RoutingContext);

  return (
    <>
      <Box px={{ xs: 3, sm: 0 }}>
        <Typography>
          All transactions eligible for feedback over the past 7 days are shown below.
          You can also leave feedback by going to&nbsp;
          <Link to={generateUrl('transactionDetails')}>
            Transaction Details.
          </Link>
          &nbsp;
          <a href="https://support.niteflirt.com/hc/en-us/articles/212830298-Giving-Ratings-Leaving-Feedback">
            Learn more.
          </a>
        </Typography>
      </Box>
      <Divider sx={{ mt: 3 }} />
    </>
  );
};

function FeedbacksContainer() {
  const { data: feedbackData } = useGetFeedbacksQuery();

  if (!feedbackData) {
    return null;
  }

  return (
    <AppBodyWithSidebar>
      <TemplateShell2 header="Leave Feedback">
        <Box px={{ xs: 0, sm: 4 }} pt={{ xs: 4, sm: 4 }} pb={{ xs: 10, sm: 10 }}>
          <FeedbacksHeading />
          {feedbackData.length > 0 ? (
            <Box>
              {feedbackData?.map((feedback, _index) => (
                <FeedbackRow key={feedback.object_id} feedback={feedback} />
              ))}
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography>
                No Feedback To Leave
              </Typography>
            </Box>
          )}
        </Box>
      </TemplateShell2>
    </AppBodyWithSidebar>
  );
}

export default FeedbacksContainer;
