import React from 'react';
import PropTypes from 'prop-types';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import GoodyBagReviewShape from 'app/shapes/GoodyBagItemShape';
import GoodyBagReviewCard from './GoodyBagReviewCard';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

const GoodyBagReviews = ({ goodyBag, goodyBagReviews, currentUser, openRatingsDrawer, showMore }) => {
  return (
    <Box data-test-id="goody-bag-reviews" px={{ xs: 0.5, sm: 2 }} style={{ borderTop: '10px solid var(--color_ddd)' }}>
      <Accordion
        defaultExpanded
        className="container-goodies-included"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`goody-bag-${goodyBag.id}-content`}
          id={`goody-bag-${goodyBag.id}-header`}
        >
          <span className="subhead">Ratings</span>
        </AccordionSummary>
        <AccordionDetails className="included-scroll">
          <Grid container>
            <Grid item xs={12} className="rating" pb={1}>
              {goodyBag.total_ratings > 0 ? (
                <Grid container>
                  <Grid data-test-id="approval" item sx={{ marginRight: '1rem' }}>
                    {goodyBag.approval}
                    % Approval
                  </Grid>
                  <Grid item>
                    <img src="/plus_frontend/assets/svg/ico-thumb-up.svg" alt="" />
                  </Grid>
                  <Grid data-test-id="up_ratings" item sx={{ marginRight: '1rem' }}>
                    {goodyBag.up_ratings}
                  </Grid>
                  <Grid item>
                    <img src="/plus_frontend/assets/svg/ico-thumb-down.svg" alt="" />
                  </Grid>
                  <Grid data-test-id="down_ratings" item sx={{ marginRight: '1rem' }}>
                    {goodyBag.total_ratings - goodyBag.up_ratings}
                  </Grid>
                </Grid>
              ) : (
                <p>
                  Unrated
                </p>
              )}
            </Grid>
            {goodyBagReviews.map((review) => (
              <Grid
                key={`goodyBagReview_${review.id}`}
                item
                xs={12}
                sx={{ mb: 2 }}
              >
                <GoodyBagReviewCard
                  currentUser={currentUser}
                  goodyBagReview={review}
                />
              </Grid>
            ))}
            {showMore && (
              <a href="#" onClick={openRatingsDrawer}>See More</a>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

GoodyBagReviews.defaultProps = {
  currentUser: {},
};

GoodyBagReviews.propTypes = {
  goodyBag: GoodyBagShape.isRequired,
  goodyBagReviews: PropTypes.arrayOf(GoodyBagReviewShape).isRequired,
  currentUser: PropTypes.object,
  openRatingsDrawer: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
};

export default GoodyBagReviews;
