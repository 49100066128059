import React from 'react';

import PropTypes from 'prop-types';
import SentMessageShape from 'app/shapes/MessageShape';

import {
  Avatar,
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GroupIcon from '@mui/icons-material/Group';

import MessageChip from 'app/features/mail/components/MessageChip';

import {
  DATE_FORMAT,
  TIME_FORMAT,
  MONTH_DAY_FORMAT,
  isThisYear,
  isToday,
  timestampInTimezone,
} from 'app/helpers/timestampHelpers';
import { truncateTextWithEllipses } from 'app/helpers/stringHelpers';
import { toCurrency } from 'app/helpers/currencyHelpers';
import { userInitials } from 'app/helpers/userInitials';

const SentMessage = ({
  message,
  selected,
  onClick,
  currentUser,
}) => {
  let timestampFormat = DATE_FORMAT;
  if (isToday(message.created_at)) {
    timestampFormat = TIME_FORMAT;
  } else if (isThisYear(message.created_at)) {
    timestampFormat = MONTH_DAY_FORMAT;
  }

  const localizedTimestamp = timestampInTimezone(
    message.createdAt,
    currentUser?.canonical_iana_timezone,
    timestampFormat,
  );

  const isPaymentRequest = message.message_type === 'Payment Request';
  const isTribute = message.message_type === 'Tribute';
  const isPayToView = message.message_type === 'Pay-to-View';
  const involvesMoney = isPaymentRequest || isTribute || isPayToView;
  const isPayable = isPaymentRequest || isPayToView;
  const recipientsToShowCount = 2;
  const maxRecipientLoginLength = 15;
  const listOfRecipients = message.recipients.slice(0, recipientsToShowCount).map((recipient) => truncateTextWithEllipses(recipient.login, maxRecipientLoginLength)).join(', ');
  const surplusRecipients = message.recipients.length - 2;
  const multipleRecipientsToCopy = `${listOfRecipients} ${surplusRecipients > 0 ? `+${surplusRecipients}` : ''}`;

  return (
    <>
      <ListItem
        onClick={onClick}
        sx={{ p: 1 }}
      >
        <ListItemButton
          {...(selected && {
            selected: true,
          })}
          sx={{ p: 1 }}
        >
          <ListItemIcon
            sx={{
              alignSelf: 'flex-start',
            }}
          >
            { message.recipients.length > 1 && (
              <GroupIcon
                color="white"
                sx={{
                  width: 46,
                  height: 46,
                  color: 'white',
                  backgroundColor: '#bdbdbd',
                  borderRadius: '50%',
                  padding: '7px',
                }}

              />
            )}
            {message.recipients.length === 1 && (
              <Avatar
                key={message.recipients[0].id}
                alt={message.recipients[0].login}
                src={message.recipients[0].avatar?.url}
                sx={{
                  width: 60,
                  height: 60,
                }}
              >
                { userInitials(message.recipients[0]) }
              </Avatar>
            )}
          </ListItemIcon>
          <Box
            sx={{
              pl: 2,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 0.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >

                <Typography variant="body2">
                  {message.recipients.length > 1 ? multipleRecipientsToCopy : message.recipients[0].login}
                </Typography>

              </Box>
              <Stack
                alignItems="flex-end"
                spacing={1}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  { localizedTimestamp }
                </Typography>
                { message.has_attachments && <AttachFileIcon /> }
              </Stack>
            </Box>
            <Box
              sx={{
                wordBreak: 'break-all',
                paddingRight: '5px',
              }}
            >
              <Typography
                variant="body2"
                sx={{ mb: 0.5 }}
              >
                {truncateTextWithEllipses(message.subject, 150)}
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={4}>
                <MessageChip
                  copy={message.message_type}
                  xs={{ backgroundColor: '#999' }}
                />
              </Grid>
              <Grid item xs={4}>
                {involvesMoney && (
                  <MessageChip
                    copy={toCurrency(message.payment_amount)}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                { message.recipients.length === 1 && isPayable && (
                  <MessageChip
                    copy={message.paid ? 'Paid' : 'Not Paid'}
                    xs={{
                      backgroundColor: message.paid ? '#4caf50;' : '#63177a',
                      color: 'white',
                    }}
                  />
                )}
                { message.recipients.length > 1 && isPayable && (
                  <MessageChip
                    copy="Multiple Recipients"
                    xs={{
                      color: 'black',
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </ListItemButton>
      </ListItem>
      <Divider sx={{ m: 0 }} />
    </>
  );
};

SentMessage.propTypes = {
  message: SentMessageShape.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
};

SentMessage.defaultProps = {
  selected: false,
  onClick: () => {},
};

export default SentMessage;
