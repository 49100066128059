import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useNavigate, useMatch } from 'react-router-dom';

import FlirtContentContainer from 'app/containers/FlirtContentContainer';
import MySubscriptionsContainer from 'app/containers/MySubscriptionsContainer';
import CreatePostContainer from 'app/containers/CreatePostContainer';
import MobileSubscriptions from 'app/components/subscriptions/MobileSubscriptions';
import PostsQueueContainer from './PostsQueueContainer';
import FeedPackagesContainer from './FeedPackagesContainer';
import { useGetSubscriptionQuery } from 'app/api/mainApi';

import { featureFlag } from 'app/util/featureFlag';

const StorefrontFeedContainer = ({ advisor, currentUser }) => {
  const packagesEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const subData = useGetSubscriptionQuery({ advisorId: advisor?.id })?.data;
  const activeSub = subData?.subscription?.advisor_active;

  // TODO: simplify this once we remove FEED_TO_STOREFRONT_20571
  // matches /feed/login/media and /login/feed/media, with both encoded and non-encoded login
  const viewingMedia = new RegExp(`/(?:feed/)?(?:${advisor?.login}|${encodedAdvisorLogin})/(?:feed/)?media`).test(document.location.href);

  const viewingSubscriptions = useMatch(`/${encodedAdvisorLogin}/feed/subscriptions`);
  const viewingNewPost = useMatch(`/${encodedAdvisorLogin}/feed/create_post`);
  const navigate = useNavigate();

  const queueRoutes = [
    useMatch(`/${encodedAdvisorLogin}/feed/queue/scheduled`),
    useMatch(`/${encodedAdvisorLogin}/feed/queue/processing`),
    useMatch(`/${encodedAdvisorLogin}/feed/queue/removed`),
  ];

  const viewingQueue = queueRoutes.filter((val) => val !== null).length > 0;
  const { postId } = useParams();

  if (advisor?.login && Number.isInteger(parseInt(postId))) {
    navigate(`/feed/${encodedAdvisorLogin}/${postId}`);
  }

  if (viewingSubscriptions) {
    return <MySubscriptionsContainer />;
  }

  if (viewingNewPost) {
    return <CreatePostContainer />;
  }

  if (viewingQueue) {
    return <PostsQueueContainer />;
  }

  return (
    <>
      {packagesEnabled && !activeSub ? (
        <FeedPackagesContainer advisor={advisor} currentUser={currentUser} />
      ) : (
        <MobileSubscriptions advisorLogin={advisor?.login} />
      )}
      <FlirtContentContainer showMediaTab={viewingMedia} />
    </>
  );
};

StorefrontFeedContainer.defaultProps = {
  advisor: {},
  currentUser: {},
};

StorefrontFeedContainer.propTypes = {
  advisor: PropTypes.object,
  currentUser: PropTypes.object,
};

export default StorefrontFeedContainer;
