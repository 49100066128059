import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutoFillableTextField from 'app/components/shared/AutoFillableTextField';
import { styled } from '@mui/material/styles';
import {
  useGetCaptchaStatusQuery,
  usePerformLoginMutation,
  usePerformPhoneLoginMutation,
} from 'app/api/mainApi';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { MuiTelInput } from 'mui-tel-input';
import { Link } from 'react-router-dom';
import { purge } from 'app/redux/store';
import useRecaptcha from 'app/hooks/useRecaptcha';
import { featureFlag } from 'app/util/featureFlag';
import { v4 as uuid } from 'uuid';
import { goHomeOrAction, navigateTo } from 'app/util/navigation';
import { getAdditionalParams, productFromParams, loginParams } from 'app/helpers/signinAndRegistrationHelpers';
import AdvisorInfoCardContainer from 'app/containers/account_info/AdvisorInfoCardContainer';

const BoxFullPage = styled(Box)(() => ({
  '&.MuiBox-root': {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: '0px',
    left: '0px',
    backgroundColor: 'white',
    overflow: 'scroll', // Add overflow property
  },
}));

const BoxClosePlacement = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '600px',
  margin: '0 auto -20px auto',
  padding: '10px 10px 0 0',
});

const ErrorMessage = ({ error, textAlign = 'left' }) => {
  return (
    <Stack mb={3}>
      <Typography variant="body3" style={{ color: '#d32f2f', textAlign }}>
        <SanitizedHTML html={error} />
      </Typography>
    </Stack>
  );
};

// eslint-disable-next-line react/prop-types
const WebForm = ({ login, password, setLogin, setPassword, handleLogin }) => (
  <>
    <Stack direction="column" spacing={3}>
      <AutoFillableTextField
        label="Email Address or Member Name"
        name="login"
        value={login}
        setValue={setLogin}
        onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}
      />

      <AutoFillableTextField
        name="password"
        label="Password"
        type="password"
        value={password}
        setValue={setPassword}
        onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}
      />
    </Stack>
    <Stack mt={1} mb={2} px={1.5}>
      <Typography variant="body2">
        <Link to={`/forgot_password${loginParams(productFromParams())}`}>
          Forgot Password?
        </Link>
      </Typography>
    </Stack>
    <Stack direction="row" justifyContent="center" mb={3}>
      <Button
        variant="contained"
        size="large"
        fullWidth
        onClick={handleLogin}
      >
        Sign In
      </Button>
    </Stack>
  </>
);

// eslint-disable-next-line react/prop-types
const PhoneForm = ({ login, password, setLogin, setPassword, handleLogin }) => (
  <>
    <Stack direction="column" spacing={3}>
      <MuiTelInput
        id="digits"
        label="Phone Number"
        defaultCountry="US"
        forceCallingCode
        disableDropdown
        focusOnSelectCountry
        preferredCountries={['US', 'CA']}
        onChange={setLogin}
        error={false}
        value={login}
        size="small"
      />
      <AutoFillableTextField
        name="pin"
        label="PIN"
        type="password"
        value={password}
        setValue={setPassword}
        onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}
        inputProps={{ maxLength: 4 }}
      />
    </Stack>
    <Stack mt={1} mb={2} px={1.5}>
      <Typography variant="body2">
        <Link to={`/forgot_pin${loginParams(productFromParams())}`}>
          Forgot PIN?
        </Link>
      </Typography>
    </Stack>
    <Stack direction="row" justifyContent="center" mb={3}>
      <Button
        variant="contained"
        size="large"
        fullWidth
        onClick={handleLogin}
      >
        Sign In
      </Button>
    </Stack>
  </>
);

const SignInCTA = ({ product }) => {
  const message = {
    chat: 'Sign In to start your chat with...',
    call: 'Sign In to start your call with...',
    gb: 'Sign In to buy...',
    default: 'Welcome Back',
  }[product?.productType ?? 'default'];

  return (
    <>
      <Typography variant="h3">
        {message}
      </Typography>
      {product && (
        <div data-test-id="listing">
          <AdvisorInfoCardContainer product={product} />
        </div>
      )}
    </>
  );
};

// This currently relies on the `type` prop to determine which form to render which depends
// on a full page reload to re-render. It will need a refactor to switch between the two forms
// without a full page reload. See the links: Registered on the web? and Registered on the phone?
// I propose to get rid of the type and read the window.location.pathname to determine which form to render.
// Then in the app just render the component in the two routes /login and /login_by_phone without the type prop.
const SignInDialog = ({ type }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [error, setError] = useState(null);

  // Mutating this doesn't need to trigger a re-render, that's why I am using useRef.
  const deviceIdRef = useRef(null);

  const pushNotificationsEnabled = featureFlag.enabled('PUSH_NOTIFICATIONS_20261');

  // these params will be passed along unchanged with any form submission
  const product = productFromParams();
  const additionalParams = getAdditionalParams();
  const mutation = (type === 'phone' && usePerformPhoneLoginMutation) ||
                   (type === 'web' && usePerformLoginMutation);
  const [performLogin, { data: loginData, error: loginError }] = mutation();

  const handleLogin = (tok = null) => {
    // Important check because it is a synthetic event when the form is submitted without captcha
    if (typeof tok === 'string') {
      additionalParams['g-recaptcha-response'] = tok;
    }

    if (pushNotificationsEnabled) {
      additionalParams.device_id = deviceIdRef.current;
    }

    performLogin({ login, password, additionalParams });
  };

  const FormRenderer = type === 'phone' ? PhoneForm : WebForm;

  // if we have a loginData object, we should redirect to the URL it contains
  useEffect(() => {
    if (loginData) {
      purge();
      navigateTo(loginData.redirectTo);
    }
  }, [loginData]);

  useEffect(() => {
    if (loginError?.data?.show_captcha) {
      setShowCaptcha(true);
    }
  }, [loginError]);

  useEffect(() => {
    if (pushNotificationsEnabled) {
      const newBrowserIdentifier = uuid();
      let currentIdentifier = localStorage.getItem('nf-browser-id');
      if (!currentIdentifier) {
        localStorage.setItem('nf-browser-id', newBrowserIdentifier);
        currentIdentifier = newBrowserIdentifier;
      }
      deviceIdRef.current = currentIdentifier;
    }
  }, []);

  const { data: captchaStatusData } = useGetCaptchaStatusQuery(type);
  useEffect(() => {
    if (captchaStatusData) {
      if (captchaStatusData.show_captcha) {
        setShowCaptcha(true);
      }
    }
  }, [captchaStatusData]);

  // at some point this will become a function that is passed in,
  //  and swaps out the SignIn modal for the Register modal
  // TODO: consider sending the other params as well
  const goToRegister = () => {
    navigateTo(`signup${loginParams(product)}`)
  };

  const queryParameters = new URLSearchParams(window.location.search);
  let userPrompt = null;
  const promptParam = queryParameters.get('prompt');

  // We do not want to mix them up. When the user gets a login error we should clear up the prompt.
  if (!loginError && promptParam) {
    switch (promptParam) {
    case 'web_account':
      userPrompt = 'Please sign in using your Member Name or email address and password.';
      break;
    default:
      userPrompt = null;
    }
  }

  const onChange = (token) => {
    handleLogin(token);
  };
  const onError = () => {
    setError('There was an error with the reCAPTCHA. Please try again.');
  };

  const { executeRecaptcha, recaptchaComponent } = useRecaptcha();

  const loginByPhoneUrl = `/login_by_phone${loginParams(product)}`;
  const loginByWebUrl = `/login${loginParams(product)}`;

  return (
    <BoxFullPage>
      <BoxClosePlacement>
        <IconButton
          aria-label="close"
          onClick={goHomeOrAction}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BoxClosePlacement>
      <DialogContent sx={{ maxWidth: '450px', margin: '0 auto' }}>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <a href="/">
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          </a>
          <SignInCTA product={product} />
        </Stack>
        {(error || loginError) && <ErrorMessage error={error || loginError.data.error} />}
        {userPrompt && <ErrorMessage error={userPrompt} textAlign="center" />}
        <FormRenderer
          login={login}
          password={password}
          setLogin={setLogin}
          setPassword={setPassword}
          handleLogin={showCaptcha ? executeRecaptcha : handleLogin}
        />
        <Stack direction="row" justifyContent="center" mb={3}>
          { showCaptcha && recaptchaComponent(onChange, onError) }
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant="h5">
            New to NiteFlirt?
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button onClick={goToRegister} variant="outlined" size="medium" fullWidth>
            Register
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            { type === 'phone' ? (
              <a href={loginByWebUrl}>Registered on the web?</a>
            ) : (
              <a href={loginByPhoneUrl}>Registered on the phone?</a>
            ) }
          </Typography>
        </Stack>
        <Box sx={{ height: '100px' }} />
      </DialogContent>
    </BoxFullPage>
  );
};

SignInDialog.defaultProps = {
  type: 'web',
};

SignInDialog.propTypes = {
  type: PropTypes.string,
};

export default SignInDialog;
