import PropTypes from 'prop-types';
import MessageSenderShape from 'app/shapes/MessageSenderShape';

const MessageShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  subject: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  sender: MessageSenderShape.isRequired,
  messageType: PropTypes.string.isRequired,
  paymentAmount: PropTypes.number,
  paid: PropTypes.bool,
  hasAttachments: PropTypes.bool,
});

export default MessageShape;
