import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import DialogsContext from 'app/contexts/DialogsContext';
import ListingSearch from 'app/components/ListingSearch';
import ListingSearchFiltersContainer from 'app/containers/modals/ListingSearchFiltersContainer';

import {
  useGetTopLevelCategoriesQuery,
  useLazyGetListingSearchResultsQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

const TABS = {
  live: 1,
  recorded: 2,
  chat: 3,
};

const CATEGORY_MAP = {
  Women: 'Find Women',
  Men: 'Find Men',
  Trans: 'Find Transgender',
};

const PRICE_MAPPING = {
  'Under $1': 'price_0',
  '$1-$1.99': 'price_1',
  '$2-$2.99': 'price_2',
  '$3-$3.99': 'price_3',
  '$4 or more': 'price_4',
};

const ListingSearchContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Search query text state
  const [searchQueryText, setSearchQueryText] = useState('');
  const [searchError, setSearchError] = useState('');

  // Filter state
  const [filters, setFilters] = useState([]);
  const allPriceOptions = Object.keys(PRICE_MAPPING);
  const [selectedPrices, setSelectedPrices] = useState(allPriceOptions);
  const [limitToAvailable, setLimitToAvailable] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  // Category state
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryId, setCategoryId] = useState(null);

  // Advanced search state
  const [showTabs, setShowTabs] = useState(false);
  const [typeId, setTypeId] = useState(TABS.live);
  const [sort, setSort] = useState(1);

  // Force reset if clearing all query params
  const [forceReset, setForceReset] = useState(0);

  // Pagination state
  const [page, setPage] = useState(1);

  // Scroll to top on page change
  const topRef = useRef(null);

  const { openDialog } = useContext(DialogsContext);

  // Get top level categories from API
  const { data: topLevelCategoriesResponse } = useGetTopLevelCategoriesQuery();
  const topLevelCategories = topLevelCategoriesResponse?.categories || [];

  // Tally active filters for use in the filters badge
  const activeFilterCount = useMemo(() => (
    filtersApplied ? (filters.length + (limitToAvailable ? 1 : 0)) : 0
  ), [filtersApplied, filters.length, limitToAvailable]);

  // Set the advancedSearch query param based on active filters
  const advancedSearch = () => (activeFilterCount > 0 ? 1 : 0);

  // Get results from API when search is triggered
  const [trigger, { data: listingSearchResponse, isLoading }] =
    useLazyGetListingSearchResultsQuery();

  const { data } = useGetAppConfigQuery();
  const currentUser = data?.current_user;

  // Update the URL's query params based on the search state (to allow for
  // bookmarking and sharing search results)
  const updateURL = () => {
    const params = new URLSearchParams();

    if (advancedSearch() === 1) {
      params.set('advanced_search', '1');
    }

    const trimmedText = searchQueryText.trim();
    if (trimmedText.length > 0) {
      params.set('text', trimmedText);
    }

    if (categoryId) {
      params.set('category_id', categoryId);
    }

    if (typeId !== TABS.live) {
      params.set('type', typeId);
    }

    if (sort !== 1) {
      params.set('sort', sort);
    }

    if (page > 1) {
      params.set('page', page);
    }

    if (filtersApplied) {
      const finalFilters = filters.filter((f) => f !== 'available');
      finalFilters.forEach((f) => {
        params.append('filters[]', f);
      });
      if (limitToAvailable) {
        params.append('filters[]', 'available');
      }
    }

    setSearchParams(params);
  };

  // Determine if the clear icon (X) should be shown
  const showClearIcon = searchQueryText.trim().length > 0 || filtersApplied;

  const queryParams = () => {
    return {
      searchQueryText: searchQueryText.trim(),
      advancedSearch: advancedSearch(),
      filters: filtersApplied ? filters : [],
      limitToAvailable: filtersApplied ? limitToAvailable : false,
      categoryId,
      type: typeId,
      sort,
      page,
    };
  };

  // Validate the search state, set UI state for tabs, and trigger the search
  const triggerSearch = () => {
    if (!categoryId) return;
    const trimmedSearchQuery = searchQueryText.trim();

    const hasSearchText = trimmedSearchQuery.length > 0;
    const hasFilters = filtersApplied && filters.length > 0;

    // Clear any previous search errors
    if (searchError) {
      setSearchError('');
    }

    // Show tabs if there is search text or filters applied
    // NOTE: (This combo is synonymous with advanced search, a term which maybe
    // should go away following the new search implementation)
    const shouldShowTabs = hasSearchText || hasFilters;
    setShowTabs(shouldShowTabs);

    // Push the search state to the URL
    updateURL();

    // Fire the torpedoes!
    trigger(queryParams());
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchQueryText(value);

    if (searchError && value.trim().length >= 3) {
      setSearchError('');
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.key !== 'Enter') return;

    const trimmedSearchQuery = searchQueryText.trim();

    if (trimmedSearchQuery.length > 0 && trimmedSearchQuery.length < 3) {
      setSearchError('Please enter at least 3 characters.');
      return;
    }

    setSearchError('');
    setTypeId(TABS.live);
    setPage(1);

    triggerSearch();

    // Blur the input to close the keyboard on mobile devices
    if (event.target && typeof event.target.blur === 'function') {
      event.target.blur();
    }
  };

  // ---------------------------------------------------------------------------
  // Begin: Handlers
  // ---------------------------------------------------------------------------

  const handleCategoryClick = (categoryValue) => {
    setPage(1);
    setSelectedCategory(categoryValue);
  };

  const resetFilters = () => {
    setFilters([]);
    setSelectedPrices(allPriceOptions);
    setLimitToAvailable(false);
    setFiltersApplied(false);
  };

  const handleFiltersApplied = (newSelectedPrices, newLimitToAvailable) => {
    setSelectedPrices(newSelectedPrices);
    setLimitToAvailable(newLimitToAvailable);

    const mappedPrices = newSelectedPrices.map(price => PRICE_MAPPING[price]);

    setFilters(mappedPrices);
    setFiltersApplied(true);
    setPage(1);
  };

  const handleClearClick = () => {
    setSearchQueryText('');
    resetFilters();
    setPage(1);
    // Incrementing provides a state change to trigger the useEffect without the
    // need to care about resetting a boolen value.
    setForceReset(prev => prev + 1);
  };

  const handleFilterClick = () => {
    openDialog({
      component: ListingSearchFiltersContainer,
      props: {
        allPriceOptions,
        initialSelectedPrices: selectedPrices,
        initialLimitToAvailable: limitToAvailable,
        resetFilters,
        onFiltersApplied: handleFiltersApplied,
      },
    });
  };

  const handleSortChange = (sort) => {
    setSort(Number(sort));
    setPage(1);
  };

  // Handle pagination changes
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // ---------------------------------------------------------------------------
  // End: Handlers
  // ---------------------------------------------------------------------------

  // Parse URL on mount, set initial state, no immediate search
  useEffect(() => {
    const textFromUrl = searchParams.get('text') || '';
    const catIdFromUrl = searchParams.get('category_id');
    const typeFromUrl = searchParams.get('type');
    const pageFromUrl = searchParams.get('page');
    const sortFromUrl = searchParams.get('sort');
    const filterParams = searchParams.getAll('filters[]');

    if (textFromUrl) {
      setSearchQueryText(textFromUrl);
      setShowTabs(true);
    }

    if (catIdFromUrl) {
      setCategoryId(Number(catIdFromUrl));
    }

    if (typeFromUrl) {
      setTypeId(Number(typeFromUrl));
    }

    if (sortFromUrl) {
      setSort(Number(sortFromUrl));
    }

    if (pageFromUrl) {
      setPage(Number(pageFromUrl));
    }

    if (filterParams.length > 0) {
      // Price filters only (exclude 'available')
      const priceFilters = filterParams.filter((param) => param !== 'available');
      setFilters(priceFilters);
      setFiltersApplied(true);

      // If 'available' was in the URL, set the boolean
      if (filterParams.includes('available')) {
        setLimitToAvailable(true);
      }

      // Determine which price labels are selected
      const matchedPrices = Object.entries(PRICE_MAPPING)
        .filter(([_label, value]) => priceFilters.includes(value))
        .map(([label]) => label);
      setSelectedPrices(matchedPrices.length ? matchedPrices : allPriceOptions);
    }

    if (textFromUrl || catIdFromUrl || filterParams.length > 0) {
      triggerSearch();
    }
  }, []);

  // ---------------------------------------------------------------------------
  // Begin: Manage category selection
  // ---------------------------------------------------------------------------

  // Set the default category to Women if none is selected or provided in the
  // query params
  useEffect(() => {
    if (!topLevelCategories.length) return;
    if (categoryId == null) {
      const womenLabel = CATEGORY_MAP.Women;
      const womenCat = topLevelCategories.find(cat => cat.name === womenLabel);
      if (womenCat) {
        setCategoryId(womenCat.id);
      }
    }
  }, [topLevelCategories, categoryId]);

  // When the category ID changes, either by supplied query param or UI click,
  // update the selected category label
  useEffect(() => {
    if (!topLevelCategories.length) return;
    if (categoryId == null) return;

    const matchedCat = topLevelCategories.find(cat => cat.id === categoryId);
    if (!matchedCat) {
      return;
    }

    const foundEntry = Object.entries(CATEGORY_MAP).find(([, val]) => val === matchedCat.name);
    if (foundEntry) {
      const [key] = foundEntry;
      if (selectedCategory !== key) {
        setSelectedCategory(key);
      }
    }
  }, [categoryId, topLevelCategories]);

  // When the selected category label changes, update the category ID
  useEffect(() => {
    if (!topLevelCategories.length) return;
    if (!selectedCategory) return;

    const catName = CATEGORY_MAP[selectedCategory];
    if (!catName) return;

    const matchedCat = topLevelCategories.find(cat => cat.name === catName);
    if (matchedCat && matchedCat.id !== categoryId) {
      setCategoryId(matchedCat.id);
    }
  }, [selectedCategory, topLevelCategories]);
  // ---------------------------------------------------------------------------
  // End: Manage category selection
  // ---------------------------------------------------------------------------

  // Trigger search when the search state changes
  useEffect(() => {
    if (categoryId !== null) {
      triggerSearch();
    }
  }, [
    categoryId,
    typeId,
    sort,
    page,
    filtersApplied,
    forceReset,
    filters,
  ]);

  // Scroll to top when getting new results or the page changes
  useEffect(() => {
    if (listingSearchResponse && topRef.current) {
      topRef.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [page, listingSearchResponse]);

  return (
    <ListingSearch
      ref={topRef}
      activeFilterCount={activeFilterCount}
      searchQueryText={searchQueryText}
      searchError={searchError}
      selectedCategory={selectedCategory}
      showClearIcon={showClearIcon}
      typeId={typeId}
      setTypeId={setTypeId}
      page={page}
      setPage={setPage}
      handleSearchChange={handleSearchChange}
      handleSearchSubmit={handleSearchSubmit}
      handleClearClick={handleClearClick}
      handleFilterClick={handleFilterClick}
      handleCategoryClick={handleCategoryClick}
      handleSortChange={handleSortChange}
      sort={sort}
      isLoading={isLoading}
      TABS={TABS}
      showTabs={showTabs}
      searchResults={listingSearchResponse?.search_results}
      handlePageChange={handlePageChange}
      listingsPerPage={listingSearchResponse?.listings_per_page}
      currentUser={currentUser}
      queryParams={queryParams}
    />
  );
};

export default ListingSearchContainer;
