import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const FilePlayerContext = createContext();

export const FilePlayerProvider = ({ children, value }) => {
  return (
    <FilePlayerContext.Provider value={value}>
      {children}
    </FilePlayerContext.Provider>
  );
};

FilePlayerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    filesQuery: PropTypes.object.isRequired,
    listView: PropTypes.bool.isRequired,
    setListView: PropTypes.func.isRequired,
  }).isRequired,
};
export const useFilePlayerContext = () => useContext(FilePlayerContext);
