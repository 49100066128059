import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PhoneShape from 'app/shapes/PhoneShape';
import CallListingShape from 'app/shapes/CallListingShape';
import CallStatusShape from 'app/shapes/CallStatusShape';

import {
  Box,
} from '@mui/material';

import Connecting from 'app/components/call/callInProgress/Icons/Connecting';

import CreatedStep from 'app/components/call/callInProgress/CreatedStep';
import DeclinedStep from 'app/components/call/callInProgress/DeclinedStep';
import AdvisorTimeoutStep from 'app/components/call/callInProgress/AdvisorTimeoutStep';
import SeekerAnsweredStep from 'app/components/call/callInProgress/SeekerAnsweredStep';
import AdvisorAnsweredStep from 'app/components/call/callInProgress/AdvisorAnsweredStep';
import BillingStartedStep from 'app/components/call/callInProgress/BillingStartedStep';
import LessThanOneMinuteLeft from 'app/components/call/callInProgress/LessThanOneMinuteLeft';
import BillingEndedStep from 'app/components/call/callInProgress/BillingEndedStep';
import ErrorStep from 'app/components/call/callInProgress/ErrorStep';
import GenericStep from 'app/components/call/callInProgress/GenericStep';

const selectStatusFromCallState = ({
  callStatus,
  selectedPhone,
  listing,
  balance,
  isLoading,
  triggerCall,
  currentUser,
  feedback,
  triggerGetCallStatus,
}) => {
  switch (callStatus.status) {
  case 'created':
    return <CreatedStep selectedPhone={selectedPhone} />;
  case 'declined_by_advisor':
    return <DeclinedStep callStatus={callStatus} selectedPhone={selectedPhone} listing={listing} balance={balance} isLoading={isLoading} triggerCall={triggerCall} />;
  case 'timeout_by_advisor':
    return <AdvisorTimeoutStep callStatus={callStatus} selectedPhone={selectedPhone} listing={listing} balance={balance} isLoading={isLoading} triggerCall={triggerCall} />;
  case 'seeker_answered':
    return <SeekerAnsweredStep listing={listing} />;
  case 'advisor_answered':
    return <AdvisorAnsweredStep listing={listing} />;
  case 'billing_started':
    return <BillingStartedStep />;
  case 'less_than_one_minute':
    return <LessThanOneMinuteLeft currentUser={currentUser} triggerGetCallStatus={triggerGetCallStatus} />;
  case 'billing_ended':
    return <BillingEndedStep listing={listing} callStatus={callStatus} feedback={feedback} />;
  case 'error':
    return (
      <ErrorStep
        callStatus={callStatus}
        selectedPhone={selectedPhone}
        listing={listing}
        balance={balance}
        isLoading={isLoading}
        triggerCall={triggerCall}
      />
    );
  default:
    return <GenericStep callStatus={callStatus} />;
  }
};

const CallInProgress = ({
  triggerGetCallStatus,
  callStatus,
  isCreateCallLoading,
  pollingInterval,
  selectedPhone,
  listing,
  balance,
  isLoading,
  triggerCall,
  currentUser,
  feedback,
}) => {
  // NOTE: Every 8 seconds, attempt to refetch the call status
  useEffect(() => {
    const interval = setInterval(() => {
      triggerGetCallStatus();
    }, pollingInterval);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval);
    };
  }, [triggerGetCallStatus]);

  return (
    <>
      { isCreateCallLoading && (
        <Box textAlign="center">
          <Connecting />
        </Box>
      )}
      { !isCreateCallLoading && (
        <Box mt={3}>
          { selectStatusFromCallState({
            callStatus,
            selectedPhone,
            listing,
            balance,
            isLoading,
            triggerCall,
            currentUser,
            feedback,
            triggerGetCallStatus,
          }) }
        </Box>
      )}
    </>
  );
};

CallInProgress.defaultProps = {
  pollingInterval: 8000,
  feedback: null,
};

CallInProgress.propTypes = {
  pollingInterval: PropTypes.number,
  triggerGetCallStatus: PropTypes.func.isRequired,
  callStatus: CallStatusShape.isRequired,
  isCreateCallLoading: PropTypes.bool.isRequired,
  selectedPhone: PhoneShape.isRequired,
  listing: CallListingShape.isRequired,
  balance: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  triggerCall: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  feedback: PropTypes.object,
};

export default CallInProgress;
