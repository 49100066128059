import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const StyledCheckboxChip = styled(Chip)(({ selected }) => ({
  marginRight: '8px',
  backgroundColor: selected ? '#e8e9fd' : '#eee',
  color: selected ? '#333' : '#333',
  '&:hover': {
    backgroundColor: '#63177a',
    color: '#fff',
  },
  '&:hover:active': {
    backgroundColor: '#63177a',
    color: '#fff',
  },
}));

const CheckboxChip = ({ label, value, state, setState }) => {
  const selected = Array.isArray(state) ? state.includes(value) : state === value;

  return (
    <StyledCheckboxChip
      label={label}
      onClick={() => setState(value)}
      selected={selected}
      data-testid={label}
      data-testselected={selected ? 'true' : 'false'}
    />
  );
};

CheckboxChip.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  setState: PropTypes.func.isRequired,
};

export default CheckboxChip;
