import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import KebobMenu from './KebobMenu';
import StyledHeader from 'app/components/shared/StyledHeader';

const StyledKebobBox = styled(Box)(() => ({
  '& img': {
    filter: 'brightness(0) invert(1) !important',
  },
  '& a': {
    filter: 'brightness(0) invert(1) !important',
  },
}));

const StickySeekerHeader = ({ appConfig }) => {
  const [stickyNavVisible, setStickyNavVisible] = useState(false);

  useEffect(() => {
    const onScroll = (e) => setStickyNavVisible(e.target.scrollTop > 70);

    const bodyContent = document.getElementById('body-content');
    if (bodyContent) bodyContent.addEventListener('scroll', onScroll);

    return () => bodyContent.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      {/* NON-sticky version that scrolls off the top */}
      <StyledHeader>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle" sx={{ pl: 2 }}>Account</Typography>
          </Stack>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <StyledKebobBox>
            <KebobMenu />
          </StyledKebobBox>
        </Box>
      </StyledHeader>

      {/* Sticky version that stays on top, but only if we've scrolled past the non-sticky version */}
      <StyledHeader
        data-test-id="template-shell-header"
        id="sticky-nav"
        style={{
          display: stickyNavVisible ? 'flex' : 'none',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle" sx={{ pl: 2 }}>Account</Typography>
          </Stack>
        </Stack>
        {/* Right Side - Add Money and Kebob */}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <Box
            sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            onClick={() => { window.location.href = `${appConfig.txn_url}/purchase/new`; }}
          >
            <Box sx={{ mx: 1 }} className="account-shortcuts">
              <img src="/plus_frontend/assets/svg/ico-add-money.svg" alt="Add Money" style={{ filter: 'brightness(0) invert(1)' }} />
            </Box>
          </Box>

          {/* Kebob Menu */}
          <StyledKebobBox>
            <KebobMenu />
          </StyledKebobBox>
        </Box>
      </StyledHeader>
    </>
  );
};

StickySeekerHeader.propTypes = {
  appConfig: PropTypes.object.isRequired,
};

export default StickySeekerHeader;
