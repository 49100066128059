import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import ReactTimeAgo from 'react-time-ago';

import { featureFlag } from 'app/util/featureFlag';

import { postTypeLabel } from 'app/helpers/postHelpers';
import PostItemArea from './PostItemArea';
import CommentsContainer from 'app/containers/CommentsContainer';
import PostActionMenu from 'app/components/shared/PostActionMenu';
import PostFooter from './postFooter/PostFooter';
import ScheduleBanner from './ScheduleBanner';
import PostBody from './PostBody';
import useProfileImages from 'app/hooks/useProfileImages';
import { useGetAppConfigQuery } from 'app/api/mainApi';

const Post = ({
  post,
  advisor,
  actions,
  showComments,
  showPin,
  viewingSelf,
  isScheduled,
}) => {
  const feedToStoreFrontEnabled = featureFlag.enabled('FEED_TO_STOREFRONT_20571');
  const packagesEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');
  const pinIconClass = viewingSelf ? 'active' : 'disabled';
  const { acceptedAvatarImage: avatarImage } = useProfileImages(advisor);
  const { data: { current_user: currentUser } = {} } = useGetAppConfigQuery();

  return (
    <div className="panel noline" data-post-id={post.id}>
      {
        viewingSelf && isScheduled && (
          <ScheduleBanner
            scheduledAt={post.scheduled_at}
            timezone={currentUser.canonical_iana_timezone}
          />
        )
      }
      {/* start post header */}
      <div className="container-post-header">
        <div className="row middle-xs nowrap-xs">
          <div className="col-xs-10 start-xs">
            <div className="container-post-by">
              <div className="row middle-xs nowrap-xs">
                <div className="col-xs-3 nfp-avatar-post" style={{ backgroundImage: `url('${avatarImage}')` }} />
                <div className="col-xs-9">
                  <div className="post-name">
                    {feedToStoreFrontEnabled ? (
                      <Link to={`/${post.account.login}`} reloadDocument>
                        {post.account.login}
                      </Link>
                    ) : (
                      <Link to={`/feed/${post.account.login}`}>
                        {post.account.login}
                      </Link>
                    )}
                  </div>
                  <div className="post-summary">
                    <Link to={`/feed/${post.account.login}/${post.id}`}>
                      {post?.published_at && !isScheduled && (
                        <ReactTimeAgo date={post ? DateTime.fromISO(post.published_at).valueOf() : null} />
                      )}
                    </Link>
                    {(!packagesEnabled && !post.unlocked) && (
                      <>
                        &nbsp;&nbsp;•&nbsp;
                        <span>{postTypeLabel(post)}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-2">
            <div className="row nowrap-xs middle-xs end-xs">
              {post.pinned && showPin && (
                <img src="/plus_frontend/assets/svg/ico-pin.svg" title="Pinned" alt="Pinned" className={`icon-pin-post ${pinIconClass}`} />
              )}
              <PostActionMenu actions={actions} />
            </div>
          </div>
        </div>
      </div>
      {/* start post text */}
      <div className="container-post-text">
        <div className="row">
          <div className="col-xs-12 start-xs">
            <PostBody post={post} currentUserId={currentUser?.id} />
          </div>
        </div>
      </div>
      <div className="container-post-item">
        <div className="row">
          <div className="col-xs-12 center-xs">
            <PostItemArea post={post} advisor={advisor} />
          </div>
        </div>
      </div>
      <PostFooter
        advisor={advisor}
        post={post}
        showComments={showComments}
      />
      {showComments && <CommentsContainer post={post} advisor={advisor} />}
    </div>
  );
};

Post.defaultProps = {
  post: null,
  advisor: null,
  actions: [],
  showPin: false,
  viewingSelf: false,
};

Post.propTypes = {
  post: PropTypes.object,
  advisor: PropTypes.object,
  actions: PropTypes.array,
  showPin: PropTypes.bool,
  viewingSelf: PropTypes.bool,
  isScheduled: PropTypes.bool.isRequired,
  showComments: PropTypes.bool.isRequired,
};

export default Post;
