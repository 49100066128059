import React from 'react';

import PropTypes from 'prop-types';
import MessageShape from 'app/shapes/MessageShape';

import {
  Avatar,
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import SeekerAvatar from 'app/features/mail/components/SeekerAvatar';
import MessageChip from 'app/features/mail/components/MessageChip';

import {
  DATE_FORMAT,
  TIME_FORMAT,
  MONTH_DAY_FORMAT,
  isThisYear,
  isToday,
  timestampInTimezone,
} from 'app/helpers/timestampHelpers';
import {
  truncateTextWithEllipses,
} from 'app/helpers/stringHelpers';
import { toCurrency } from 'app/helpers/currencyHelpers';

const Message = ({
  message,
  selected,
  onClick,
  currentUser,
}) => {
  let timestampFormat = DATE_FORMAT;
  if (isToday(message.created_at)) {
    timestampFormat = TIME_FORMAT;
  } else if (isThisYear(message.created_at)) {
    timestampFormat = MONTH_DAY_FORMAT;
  }

  const localizedTimestamp = timestampInTimezone(
    message.createdAt,
    currentUser?.canonical_iana_timezone,
    timestampFormat,
  );

  const isPaymentRequest = message.message_type === 'Payment Request';
  const isTribute = message.message_type === 'Tribute';
  const isPayToView = message.message_type === 'Pay-to-View';
  const involvesMoney = isPaymentRequest || isTribute || isPayToView;
  const isPayable = isPaymentRequest || isPayToView;
  const fontWeight = message.readAt ? 'normal' : 'bold';

  return (
    <>
      <ListItem
        onClick={onClick}
        sx={{ p: 1 }}
      >
        <ListItemButton
          {...(selected && {
            selected: true,
          })}
          sx={{ p: 1 }}
        >
          <ListItemIcon
            sx={{
              alignSelf: 'flex-start',
            }}
          >
            { (message.sender.avatar?.url && (
              <Avatar
                alt={message.sender.login}
                src={message.sender.avatar.url}
                sx={{ width: 60, height: 60 }}
              />
            )) || (
              <SeekerAvatar
                seeker={message.sender}
                sx={{ width: 60, height: 60 }}
              />
            )}
          </ListItemIcon>
          <Box
            sx={{
              pl: 2,
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 0.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={fontWeight}
                >
                  {message.sender.login}
                </Typography>
              </Box>
              <Stack
                alignItems="flex-end"
                spacing={1}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontWeight={fontWeight}
                >
                  { localizedTimestamp }
                </Typography>
                { message.has_attachments && <AttachFileIcon /> }
              </Stack>
            </Box>
            <Box
              sx={{
                wordBreak: 'break-all',
                paddingRight: '5px',
              }}
            >
              <Typography
                variant="body2"
                fontWeight={fontWeight}
                marginBottom={0.5}
              >
                {truncateTextWithEllipses(message.subject, 150)}
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={4}>
                <MessageChip
                  copy={message.message_type}
                  xs={{ backgroundColor: '#999' }}
                />
              </Grid>
              <Grid item xs={4}>
                {involvesMoney && (
                  <MessageChip
                    copy={toCurrency(message.payment_amount)}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                { isPayable && (
                  <MessageChip
                    copy={message.paid ? 'Paid' : 'Not Paid'}
                    xs={{
                      backgroundColor: message.paid ? '#4caf50;' : '#63177a',
                      color: 'white',
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </ListItemButton>
      </ListItem>
      <Divider sx={{ m: 0 }} />
    </>
  );
};

Message.propTypes = {
  message: MessageShape.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
};

Message.defaultProps = {
  selected: false,
  onClick: () => {},
};

export default Message;
