import React from 'react';
import AutoCompleteMultiCheckbox from '../form_components/AutoCompleteMultiCheckbox';

const RelatedTagsFilter = ({
  resultCounts,
  filters,
  onChange,
}) => {

  // This data massage needs to happen because the kink_tags arrive from the backend in a completely
  // different format than the other filters.
  let kink_tags = (resultCounts.kink_tags || []).map((element) => Object.keys(element)).flat();
  let kinkCounts = {};
  (resultCounts.kink_tags || []).forEach((obj) => {
    kinkCounts[Object.keys(obj)[0]] = Object.values(obj)[0] > 999 ? '999+' : Object.values(obj)[0];
  });

  return <AutoCompleteMultiCheckbox
    label={'Related Tags'}
    options={kink_tags}
    filters={filters}
    value={filters.kink_tags}
    setValue={onChange}
    allowTyping={true}
    counts={kinkCounts}
    showCount={true}
  />;
}

export default RelatedTagsFilter;