import React from 'react';
import { Badge, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { Clear as ClearIcon, Search as SearchIcon, Tune as TuneIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '90%',
    right: 0,
  },
}));

export const FileSearchBox = () => {
  const doNothing = () => {};
  const anyFilters = false;
  return (
    <Grid item xs={12}>
      <TextField
        variable="outlined"
        autoComplete="off"
        placeholder="Find Files"
        fullWidth
        size="small"
        value=""
        onChange={doNothing}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              { anyFilters && (
                <IconButton
                  edge="end"
                  onClick={doNothing}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                edge="end"
                onClick={doNothing}
                size="small"
              >
                <StyledBadge
                  color="secondary"
                  badgeContent={0}
                  max={99}
                >
                  <TuneIcon />
                </StyledBadge>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
