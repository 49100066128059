import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import StyledHeader from 'app/components/shared/StyledHeader';
import { Stack, Typography } from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';

export default function TemplateShell2({ header, children, goBackPath, showBackArrow }) {
  const goBack = (evt) => {
    evt.preventDefault();
    if (goBackPath) {
      window.location.href = goBackPath;
      return;
    }
    window.history.back(); // This will go back one step in the browsing history
  };
  return (
    <>
      <StyledHeader data-test-id="template-shell-header">
        <Stack direction="row" spacing={2} alignItems="center">
          {
            showBackArrow && (
              <a href="#" onClick={goBack} data-test-id="ico-back-arrow">
                <ArrowBackIosSharpIcon htmlColor="white" sx={{ ml: 0.5, mt: 0.5 }} />
              </a>
            )
          }
          <Typography
            variant="purpleHeaderTitle"
            data-test-id="template-shell-header-title"
            sx={{ pl: showBackArrow ? 0 : 2 }}
          >
            {header}
          </Typography>
        </Stack>
      </StyledHeader>
      {children}
    </>
  );
}

// Define PropTypes for the props
TemplateShell2.propTypes = {
  header: PropTypes.string.isRequired, // Header should be a required string
  children: PropTypes.node.isRequired, // Children should be a required React node
  goBackPath: PropTypes.string, // goBackPath should be a string
  showBackArrow: PropTypes.bool,
};

TemplateShell2.defaultProps = {
  goBackPath: null, // goBackPath default value is null
  showBackArrow: true,
}
