import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';

const ActiveSubscriptionDetails = ({
  currentUser,
  onSubscriptionEditClick,
  packageSubscription,
}) => {
  const subscriptionStatus = () => {
    if (packageSubscription.status === 'pending_cancel') {
      return `Expires ${packageSubscription.renewalDate}`;
    } else if (packageSubscription.status === 'charge_failed') {
      return (
        <>
          Renewal Failed.
          {' '}
          <AuthenticatedLink
            role="link"
            tabIndex={0}
            title="Update Card"
            currentUser={currentUser}
            onClick={() => onSubscriptionEditClick(packageSubscription.id, packageSubscription.rate)}
          >
            Update Card
          </AuthenticatedLink>
          <br />
          or Expires
          {' '}
          {packageSubscription.renewalDate}
        </>
      );
    }
    return `Renews ${packageSubscription.renewalDate}`;
  };

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <Box>
        <AuthenticatedLink
          role="link"
          tabIndex={0}
          title="Edit Subscription"
          currentUser={currentUser}
          onClick={() => onSubscriptionEditClick(packageSubscription.id, packageSubscription.rate)}
        >
          {packageSubscription.rate}
          {' '}
          per month
        </AuthenticatedLink>
      </Box>
      <Box>
        {subscriptionStatus()}
      </Box>
    </Box>
  );
};

ActiveSubscriptionDetails.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSubscriptionEditClick: PropTypes.func.isRequired,
  packageSubscription: PropTypes.object.isRequired,
};

export default ActiveSubscriptionDetails;
