import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Box, Slider, Typography } from '@mui/material';

// Define the discrete values
const MARKS = [
  { value: 0, label: '0' },
  { value: 1, label: '7' },
  { value: 2, label: '14' },
  { value: 3, label: '30' },
  { value: 4, label: '60' },
  { value: 5, label: '90' },
  { value: 6, label: '120' },
  { value: 7, label: '180' },
  { value: 8, label: '365' },
  { value: 9, label: '545' },
  { value: 10, label: '730' },
  { value: 11, label: '∞' }, // "infinity" represented as 1000
];

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#007bff',
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
    '&:before': {
      boxShadow:
        '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: 'inset 0px 0px 4px -2px #000',
    backgroundColor: '#d0d0d0',
  },
}));

const RangeSlider = ({ label, value, setValue, min, max }) => {
  const sliderValues = value.map(val => MARKS.findIndex(mark => (mark.label === '∞' ? 11 : (mark.label === val.toString()))));

  const onChange = (_event, newValues) => {
    // Convert the slider's internal values (0-11) to the mapped values
    const mappedValues = newValues.map(val => (MARKS[val].label === '∞' ? 1090 : parseInt(MARKS[val].label, 10)));
    setValue(mappedValues);
  };

  const valuetext = (value) => {
    return `${value}`;
  };

  return (
    <Box sx={{ px: 2 }}>
      <Typography sx={{ my: 2 }}>{label}</Typography>
      <StyledSlider
        getAriaLabel={() => (['180', '545'].includes(label) ? '' : label)}
        value={sliderValues}
        onChange={onChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={1} // No steps between the marks
        marks={MARKS}
        min={min}
        max={max}
        valueLabelFormat={(val) => (val === 11 ? '∞' : MARKS[val].label)} // Replace 11 with "infinity" symbol
      />
    </Box>
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 11,
  value: [0, 1090],
};

RangeSlider.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array,
  setValue: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default RangeSlider;
