const normalizeUrlOrPath = (input) => {
  const [p1, p2] = input.split('://');
  const [prefix, rest] = p2 ? [`${p1}://`, p2] : ['', p1];

  const [path, query] = rest.split('?');
  const normalizedPath = path.replaceAll('//', '/');
  const queryString = query ? `?${query}` : '';

  return `${prefix}${normalizedPath}${queryString}`;
};

export const navigateTo = (url) => {
  window.location.href = normalizeUrlOrPath(url);
};

export const navigateReplace = (url) => {
  window.location.replace(normalizeUrlOrPath(url));
};

export const goHomeOrAction = () => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.has('listing_id')) {
    return navigateTo(`/listings/show/${queryParams.get('listing_id')}`);
  }

  if (queryParams.has('user_login')) {
    return navigateTo(`/${queryParams.get('user_login')}`);
  }

  return navigateTo('/');
};
