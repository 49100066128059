import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { styled } from '@mui/material/styles';

import {
  Box,
  TextField,
  Chip,
  InputAdornment,
  IconButton,
  Badge,
  Autocomplete,
  Typography,
  Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import BlockIcon from '@mui/icons-material/Block';
import ChatIconNF from 'app/components/customSvgs/ChatIconNF';
import MailIcon from '@mui/icons-material/Mail';
import { useConfirm } from 'material-ui-confirm';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import StyledHeader from 'app/components/shared/StyledHeader';
import DialogsContext from 'app/contexts/DialogsContext';
import CustomerSearchFilters from 'app/components/modals/CustomerSearchFilters';
import SearchResults from 'app/components/customers/SearchResults';
import SendChatMessageDialog from 'app/components/customers/SendChatMessageDialog';
import AssignTagsDialog from 'app/components/customers/AssignTagsDialog';

import {
  useGetSearchResultsMutation,
  useGetAllSearchResultsMutation,
  useGetCustomTagsQuery,
  useGetLoginsQuery,
  useGetSavedSearchesQuery,
  useCreateBlockMutation,
  useDestroyBlockMutation,
  useSendChatMessageMutation,
} from 'app/api/customersApi';
import {
  setFilters,
  setFiltersToTag,
  clearFilters,
  filtersSelector,
  anyFiltersSelector,
  advancedFilterCountSelector,
  setOmnibox,
  omniboxSelector,
  sortSelector,
} from 'app/slices/customersSlice';

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(2, 3, 3, 3), // top, right, bottom, left
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3, 20, 3, 3), // top, right, bottom, left
  },
}));

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '90%',
    right: 0,
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-tag': {
    maxWidth: 'calc(62% - 6px)',
  },
}));

const goBack = (evt) => {
  evt.preventDefault();
  window.history.back(); // Go back one step
};

const submitRecipientsList = (selectedNames) => {
  // Create a form element
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = '/messages/compose'; // Replace with the target path

  // Add authenticity token if required (for Rails)
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const csrfInput = document.createElement('input');
  csrfInput.type = 'hidden';
  csrfInput.name = 'authenticity_token';
  csrfInput.value = csrfToken;
  form.appendChild(csrfInput);

  // Create the nested message[recipients_list] field
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'message[recipients_list]';
  input.value = selectedNames.join(',');
  form.appendChild(input);

  // Append the form to the body
  document.body.appendChild(form);

  // Submit the form
  form.submit();
};

const MyCustomersContainer = () => {
  const [getSearchResults, { data, isLoading: searchResultsLoading }] = useGetSearchResultsMutation();
  const searchResults = data?.search_results || [];
  const pageSize = data?.page_size || 10;
  const [getAllSearchResults, { data: allData, isLoading: allSearchResultsLoading }] = useGetAllSearchResultsMutation();
  const allSearchResults = allData?.search_results;
  const sort = useSelector(sortSelector);
  const { data: tags } = useGetCustomTagsQuery();
  const { data: allSavedSearches } = useGetSavedSearchesQuery();
  const [createBlock] = useCreateBlockMutation();
  const [destroyBlock] = useDestroyBlockMutation();
  const [sendChatMessage] = useSendChatMessageMutation();
  const confirm = useConfirm();
  const location = useLocation();
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const { openDialog } = useContext(DialogsContext);
  const anyFilters = !!useSelector(anyFiltersSelector);
  const filters = useSelector(filtersSelector);
  const advancedFilterCount = useSelector(advancedFilterCountSelector);
  const omnibox = useSelector(omniboxSelector);
  const dispatchSetOmnibox = (value) => dispatch(setOmnibox(value));
  const [partialSearchTerm, setPartialSearchTerm] = useState('');
  const includeAdditionalSearchCorpus = partialSearchTerm.length >= 3;
  const { data: nameResults } = useGetLoginsQuery(
    { login: partialSearchTerm },
    { skip: !includeAdditionalSearchCorpus },
  );

  const handleFilterClick = () => {
    openDialog({ component: CustomerSearchFilters });
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedResults, setSelectedResults] = useState([]);
  const clearSelectedResults = () => setSelectedResults([]);

  // This ref is synchronous and stable across renders, so we can have two async useEffects
  // that react to filter/sort/page AND filters/sort, without them stepping on each other
  // or triggering a double-search.
  const filtersSortChanged = useRef(false);

  const triggerSearch = () => {
    getSearchResults({ ...filters, sort, page: 1 });
  };

  const allSelectedCustomersAreBlocked = () => {
    const blockedCount = selectedResults.filter((id) => {
      const result = searchResults.find((result) => result.seeker_id === id);
      const systemTagList = (result?.system_tag_list || '').split('^').filter((tag) => tag);

      return systemTagList.includes('Blocked');
    }).length;
    const totalCount = selectedResults.length;

    return blockedCount === totalCount;
  };

  const handleBlock = () => {
    if (allSearchResultsLoading) return;
    const blocked = allSelectedCustomersAreBlocked();

    if (blocked) {
      confirm({
        title: 'Confirm unblock',
        description: 'Are you sure you want to unblock this customer?',
      })
        .then(() => destroyBlock({ ids: selectedResults }))
        .then(clearSelectedResults)
        .then(triggerSearch)
        .catch(() => {});
    } else {
      confirm({
        title: 'Confirm block',
        description: 'Blocked customers can’t contact you or buy from you. Blocking will also end any subscriptions.',
      })
        .then(() => createBlock({ ids: selectedResults }))
        .then(clearSelectedResults)
        .then(triggerSearch)
        .catch(() => {});
    }
  };

  const handleChat = () => {
    if (allSearchResultsLoading) return;

    openDialog({
      component: SendChatMessageDialog,
      props: {
        sendChatMessage,
        seekerIds: selectedResults,
      },
    });
  };

  const handleMail = () => {
    if (allSearchResultsLoading) return;

    // if we're doing the mass select-all, then get seeker_names out of the allSearchResults data,
    //  otherwise, get them from the already-loaded local searchResults data
    if (selectAll === 'all') {
      const selectedNames = allSearchResults?.map((result) => result.seeker_name);
      submitRecipientsList(selectedNames);
    } else {
      const selectedNames = searchResults?.filter((result) => selectedResults.includes(result.seeker_id))?.map((result) => result.seeker_name);
      window.location.href = `/messages/compose?message[recipients_list]=${selectedNames.join(',')}`;
    }
  };

  const handleAddTags = () => {
    if (allSearchResultsLoading) return;

    // In the case that they are using the mass-assign feature, but have only selected a single user,
    // we can pre-fill the tags input with that user's tags
    let currentTags = [];
    if (selectedResults.length === 1) {
      const searchResult = searchResults.find((result) => result.seeker_id === selectedResults[0]);
      currentTags = searchResult.tagList?.split('^').filter((tag) => tag);
    }

    openDialog({
      component: AssignTagsDialog,
      props: {
        allTags: tags.map((tag) => tag.name),
        seekerIds: selectedResults,
        currentTags,
      },
    });
  };

  // This is a handler on the omnibox, and indicates a clicked choice from the dropdown, or a clear, or pressing enter on a free-typed value
  const onChange = (_evt, newValue, reason) => {
    if (reason === 'clear' || newValue.length === 0) {
      dispatchSetOmnibox([]);
    } else if (reason === 'selectOption') { // they clicked on something from the dropdown
      const newTag = newValue[newValue.length - 1];
      dispatchSetOmnibox([newTag]); // they can only have one click-selected value at a time
      if (newTag.type === 'user_saved_search' || newTag.type === 'system_saved_search') {
        dispatch(setFilters(newTag.filters));
      } else if (newTag.type === 'tag') {
        dispatch(setFiltersToTag([newTag.name]));
      } else if (newTag.type === 'customer') {
        dispatch(setFilters({ text: newTag.name }));
      }
    } else if (reason === 'createOption') { // they pressed enter on a free-typed value
      dispatchSetOmnibox([newValue[newValue.length - 1]]);
    }
  };

  // This is a handler on the omnibox, and indicates typing in progress
  const onInputChange = (_evt, newValue, reason) => {
    if (reason === 'input') {
      setPartialSearchTerm(newValue);
    }
  };

  // format the Autocomplete's value into our preferred combination of Chips and plain text
  const renderTags = (value, getTagProps) => {
    return value
      .map((chosenOptions) => (
        chosenOptions.map((option, index) => {
          if (option.type) { // all saved search, tags, etc have a type, but the Chip will look the same for all
            return (
              <Chip
                key={option.name}
                label={option.name}
                {...getTagProps({ index })}
                onDelete={() => {
                  dispatchSetOmnibox([]);
                  dispatch(clearFilters());
                }}
              />
            );
          } else { // the fallback case of free-typed text, is not an object and has no id
            return (
              <span style={{ marginLeft: '5px' }} key={Math.random()}>{option}</span>
            );
          }
        })));
  };

  // generate the textfield with the search and the filter icons, to be used inside the Autocomplete
  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        ref={params.InputProps.ref}
        label="Search Customers"
        // variable="outlined"
        fullWidth
        InputProps={{
          ...params.InputProps,
          style: { paddingRight: '10px' },
          autoComplete: 'off',
          autoCapitalize: 'none',
          autoCorrect: 'off',
          spellCheck: 'false',
          startAdornment: (
            <>
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ),
          endAdornment: (
            <InputAdornment position="end">
              { ((omnibox?.length > 0) || anyFilters) && (
                <IconButton
                  edge="end"
                  onClick={() => dispatch(clearFilters())}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                edge="end"
                onClick={handleFilterClick}
                size="small"
              >
                <StyledBadge
                  color="secondary"
                  badgeContent={advancedFilterCount}
                  max={99}
                >
                  <TuneIcon />
                </StyledBadge>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const options = [
    ...(allSavedSearches?.filter((ss) => !ss.do_not_auto_suggest) || []),
    ...((includeAdditionalSearchCorpus && tags) || []),
    ...((includeAdditionalSearchCorpus && nameResults) || []),
  ];

  // format the items in the Autocomplete's dropdown
  const renderOption = (properties, option) => {
    const { key, ...optionProps } = properties;

    const optionIcon = {
      system_saved_search: <SavedSearchIcon sx={{ color: '#63177a' }} />,
      user_saved_search: <SavedSearchIcon />,
      tag: <LocalOfferIcon />,
    }[option.type] || <PersonIcon />;

    // support for bolding the part of the option that matches the search term
    const highlight = AutosuggestHighlightMatch(option.name, partialSearchTerm, { insideWords: true });
    const parsedName = AutosuggestHighlightParse(option.name, highlight);
    const highlightedName = parsedName.map((part) => (part.highlight ? <strong key={part.text}>{part.text}</strong> : part.text));

    return (
      <li key={key} {...optionProps}>
        {optionIcon}
        <Typography sx={{ ml: 1 }}>
          {highlightedName}
        </Typography>
      </li>
    );
  };

  // onComponentMount, if there's a query param called "filters", then open the filter dialog
  // and remove the query param from the URL
  useEffect(() => {
    const search = new URLSearchParams(location.search);

    if (search.get('filters')) {
      handleFilterClick();
      search.delete('filters');
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    const customerListId = search.get('customer_list');
    if (customerListId) {
      const newTag = tags?.find((tag) => tag.id === parseInt(customerListId, 10));
      if (newTag) {
        dispatchSetOmnibox([newTag]);
        dispatch(setFilters({ hasTags: [newTag.name] }));
      }

      search.delete('customer_list');
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [tags]);

  // we want to re-fetch the search results if the filters or sort have changed,
  // OR if the page changes- but we only want to reset the page number if it was
  // the filters or sort that started this.
  useEffect(() => {
    filtersSortChanged.current = true;
    setPage(1);
  }, [filters, sort.sortColumn, sort.sortDirection]);

  // fetch the actual search results
  useEffect(() => {
    if (filtersSortChanged.current) {
      filtersSortChanged.current = false;
      triggerSearch();
    } else {
      getSearchResults({ ...filters, sort, page });
    }
    clearSelectedResults();
    setSelectAll(false);
  }, [filters, sort.sortColumn, sort.sortDirection, page]);

  useEffect(() => {
    if (selectAll === 'all') {
      getAllSearchResults({ ...filters });
    } else if (selectAll === 'page') {
      setSelectedResults(searchResults.slice(0, pageSize).map((result) => result.seeker_id));
    }
  }, [selectAll]);

  // if we've triggered a search for ALL matching seeker ids, and we have them, then load up the selectedResults
  useEffect(() => {
    if (selectAll === 'all' && !allSearchResultsLoading && allSearchResults) {
      setSelectedResults(allSearchResults.map((result) => result.seeker_id));
    }
  }, [allSearchResultsLoading]);

  return (
    <AppBodyWithSidebar>
      {selectedResults.length > 0 ? (
        <StyledHeader>
          <Stack direction="row" alignItems="center" width="100%">
            <IconButton onClick={clearSelectedResults} sx={{ ml: -0.5, mr: 1 }}>
              <ArrowBackIosSharpIcon fontSize="medium" htmlColor="white" />
            </IconButton>
            <Typography variant="purpleHeaderTitle">{selectedResults.length}</Typography>
            <Stack
              direction="row"
              spacing="16px"
              useFlexGap
              alignItems="center"
              justifyContent="flex-end"
              flexGrow={2}
            >
              <IconButton title="Block" onClick={handleBlock}>
                <BlockIcon fontSize="medium" htmlColor="white" />
              </IconButton>
              <IconButton title="Send Chat Message" onClick={handleChat}>
                <ChatIconNF fontSize="medium" htmlColor="white" />
              </IconButton>
              <IconButton title="Mail" onClick={handleMail}>
                <MailIcon fontSize="medium" htmlColor="white" />
              </IconButton>
              <IconButton title="Add Tags" onClick={handleAddTags}>
                <LocalOfferIcon fontSize="medium" htmlColor="white" />
              </IconButton>
            </Stack>
          </Stack>
        </StyledHeader>
      ) : (
        <StyledHeader data-test-id="template-shell-header">
          <Stack direction="row" spacing={2} alignItems="center">
            <a href="#" onClick={goBack} data-test-id="ico-back-arrow">
              <ArrowBackIosSharpIcon htmlColor="white" sx={{ ml: 0.5, mt: 0.5 }} />
            </a>
            <Typography
              variant="purpleHeaderTitle"
              data-test-id="template-shell-header-title"
              sx={{ pl: 0 }}
            >
              Customers
            </Typography>
          </Stack>
        </StyledHeader>
      )}
      <StyledBox>
        <StyledAutocomplete
          freeSolo
          multiple
          openOnFocus
          size="small"
          fullWidth
          options={options}
          isOptionEqualToValue={(option, value) => option.name === value}
          value={(!omnibox || omnibox.length === 0) ? [] : [omnibox]}
          getOptionLabel={(option) => ((typeof option === 'string') ? option : (option.name || ''))}
          onChange={onChange}
          onInputChange={onInputChange}
          renderTags={renderTags}
          renderInput={renderInput}
          renderOption={renderOption}
        />
      </StyledBox>
      <Box id="search-results" sx={{ px: { xs: 0, sm: 1.5 } }}>
        <SearchResults
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          searchResults={searchResults}
          pageSize={pageSize}
          loading={searchResultsLoading || allSearchResultsLoading}
          selectedResults={selectedResults}
          setSelectedResults={setSelectedResults}
          page={page}
          setPage={setPage}
          triggerSearch={triggerSearch}
        />
      </Box>
    </AppBodyWithSidebar>
  );
};

export default MyCustomersContainer;
