import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { debounce, map } from 'lodash';

import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import GenderSelectChips from 'app/components/GenderSelectChips';
import ChatTabs from 'app/components/chat/ChatTabs';
import ChatBrowseResults from 'app/components/chat/ChatBrowseResults';
import CircularProgress from 'app/components/shared/CircularProgress';
import NumberlessPagination from 'app/components/NumberlessPagination';
import NoSearchResultsFound from 'app/components/NoSearchResultsFound';
import useDarkerBodyClass from 'app/hooks/useDarkerBodyClass';

import { useChatProfileSearchMutation, useGetAppConfigQuery } from 'app/api/mainApi';
import {
  setCategory,
  setText,
  clearAllButText,
  clearAllButCategory,
  categorySelector,
  filtersSelector,
  setResultCounts,
} from 'app/slices/chatProfileFilterSlice';

const ChatTabsPos = styled(Box)(() => ({
  '&.MuiBox-root': {
    borderTop: '1px solid var(--color_eee)',
    borderBottom: '1px solid var(--color_eee)',
    marginTop: '16px',
  },
}));

const ChatBrowseContainer = () => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const gender = searchParams.get('gender');
  const [page, setPage] = useState(1);
  const category = useSelector(categorySelector);
  const filters = useSelector(filtersSelector);
  const { text } = filters;

  const [
    chatProfileSearch,
    {
      data: chatProfileSearchResults,
      isSuccess: chatProfileSearchResultsSuccess,
      isLoading: chatProfileSearchResultsLoading,
    },
  ] = useChatProfileSearchMutation();

  const genderCategoryMap = {
    0: '839',
    1: '840',
    2: '770',
  };

  const mapGenderCategory = (value) => {
    // Check if the value is a gender
    if (genderCategoryMap[value] !== undefined) {
      return genderCategoryMap[value];
    }
    // Check if the value is a category
    const categoryGenderMap = Object.entries(genderCategoryMap).reduce((acc, [key, val]) => {
      acc[val] = key;
      return acc;
    }, {});
    return categoryGenderMap[value] || '0';
  };

  const triggerChatProfileSearch = () => {
    if (text.length > 0 && text.length < 3) {
      return;
    }

    chatProfileSearch({ ...filters, category, page });
  };

  const handleCategoryChange = (category) => {
    setPage(1);
    dispatch(clearAllButText());
    dispatch(setCategory(category));

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('gender', mapGenderCategory(category));
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleTextChange = (event) => {
    dispatch(setText(event.target.value));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useDarkerBodyClass(); // This applies the body-content-darkbg class

  useEffect(() => {
    if (gender) {
      dispatch(setCategory(mapGenderCategory(gender)));
    } else {
      dispatch(setCategory('839'));
    }
  }, [gender, dispatch]);

  useEffect(() => {
    if (chatProfileSearchResultsSuccess && chatProfileSearchResults) {
      dispatch(setResultCounts(chatProfileSearchResults.facet));
    }
  }, [chatProfileSearchResultsSuccess]);

  // clear filters on page load except for category
  useEffect(() => {
    dispatch(clearAllButCategory());
  }, []);

  useEffect(() => {
    const debouncedFunction = debounce(triggerChatProfileSearch, 500, { trailing: true });
    if (text.length >= 3) {
      setPage(1);
      debouncedFunction();
    }

    return debouncedFunction.cancel;
  }, [text]);

  // trigger a search when the filters change
  useEffect(() => {
    const debouncedFunction = debounce(triggerChatProfileSearch, 500);
    debouncedFunction();
    return debouncedFunction.cancel;
  }, [filters, category, page]);

  // save facet/filter count data when it is returned along with results
  useEffect(() => {
    if (chatProfileSearchResultsSuccess && chatProfileSearchResults) {
      dispatch(setResultCounts(chatProfileSearchResults.facet));
    }
  }, [chatProfileSearchResultsSuccess]);

  return (
    <AppBody>
      <TemplateShell2 header="Chat" goBackPath="/" showBackArrow={false}>
        <Box style={{ backgroundColor: 'var(--body_background)' }}>
          <Grid container sx={{ pt: 2, px: 3, mt: 1.5 }} md={6} sm={12} xs={12}>
            <Grid item xs={12} data-testid="search-input">
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Find Flirts to chat with"
                variant="outlined"
                size="small"
                value={text}
                onChange={handleTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: text.length > 0 && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => dispatch(setText(''))}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} data-testid="gender-select">
              <GenderSelectChips state={category} setState={handleCategoryChange} />
            </Grid>
          </Grid>
          <ChatTabsPos>
            <Grid container md={6} sm={12} xs={12}>
              <ChatTabs activeTab="browse" currentUser={currentUser} />
            </Grid>
          </ChatTabsPos>
        </Box>
        {chatProfileSearchResults?.chat_profiles && (
          <>
            <ChatBrowseResults results={chatProfileSearchResults.chat_profiles} />
            {(chatProfileSearchResults?.has_next || chatProfileSearchResults?.has_previous) && (
              <NumberlessPagination
                hasNext={chatProfileSearchResults?.has_next}
                page={chatProfileSearchResults?.current_page}
                onChange={handlePageChange}
              />
            )}
          </>
        )}
        {chatProfileSearchResultsLoading && (
          <Box sx={{ textAlign: 'center', mt: 5 }} data-test-id="loading-spinner">
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading...
            </Typography>
          </Box>
        )}
        {text && chatProfileSearchResults?.chat_profiles.length < 1 && !chatProfileSearchResultsLoading && (
          <NoSearchResultsFound />
        )}

      </TemplateShell2>
    </AppBody>
  );
};

export default ChatBrowseContainer;
