import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Box,
  Grid,
  Stack,
  Button,
  Typography,
  DialogContent,
} from '@mui/material';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const AllowPushNotifications = ({
  yesDefinitelyHandler,
  closeAndRemindLaterHandler,
  closeAndSetToNeverHandler,
}) => {

  return (
    <>
      <CloseDialogButton closeDialog={closeAndRemindLaterHandler} />
      <DialogContent>
        <Box mx={{ xs: 0, sm: 12, md: 17 }}>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
            <Typography variant="h3">
              Never Miss a Connection!
            </Typography>
            <Typography gutterBottom textAlign="center">
              Get notified when you receive a call or chat.
            </Typography>
            <img src="/plus_frontend/assets/svg/pwa-graphic.svg" alt="" style={{ width: '200px' }} />
          </Stack>
          <Stack mt={3}>
            <Grid container spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="center">
              <Grid item xs={12} sm="auto" sx={{ order: { xs: 1, sm: 2 } }}>
                <Button
                  variant="contained"
                  fullWidth={false}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                  onClick={yesDefinitelyHandler}
                >
                  Yes, Definitely!
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto" sx={{ order: { xs: 2, sm: 1 } }}>
                <Button
                  variant="outlined"
                  fullWidth={false}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                  onClick={closeAndRemindLaterHandler}
                >
                  Remind Me Later
                </Button>
              </Grid>
            </Grid>
          </Stack>
          <Stack mt={3} mb={2} sx={{ alignItems: 'center' }}>
            <a className="blue-link" onClick={closeAndSetToNeverHandler}>
              Don't show this again
            </a>
          </Stack>
        </Box>
      </DialogContent>
    </>
  );
};

AllowPushNotifications.propTypes = {
  yesDefinitelyHandler: PropTypes.func.isRequired,
  closeAndRemindLaterHandler: PropTypes.func.isRequired,
  closeAndSetToNeverHandler: PropTypes.func.isRequired,
};

export default AllowPushNotifications;
