import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { Link, useMatch } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import StyledHeader from 'app/components/shared/StyledHeader';

import { useGetAppConfigQuery, useGetAdvisorQuery } from 'app/api/mainApi';
import AppBody from 'app/components/layout/AppBody';
import ProfileTabsWithContent from 'app/components/ProfileTabsWithContent';
import Homepage from 'app/components/Homepage';
import HeaderContactButtons from 'app/components/HeaderContactButtons';
import StickyProfilePageControls from 'app/components/StickyProfilePageControls';
import ProfileContext from 'app/contexts/ProfileContext';
import ProfileMenu from 'app/components/ProfileMenu';

function AdvisorHomepageContainer(props) {
  const { login: advisorLogin } = useParams();
  const { data: advisor, isLoading: advisorLoading } = useGetAdvisorQuery({ login: advisorLogin });
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const viewingSelf = (currentUser?.id === advisor?.id);
  const viewingProfile = useMatch(`/profile/${encodedAdvisorLogin}`);
  const [stickyNavVisible, setStickyNavVisible] = useState(false);

  // we don't need to avoid an expensive calculation- instead we're just trying to avoid excessive
  // re-renders by turning this string that is passed in to a child component into a persistent object
  const contextProps = useMemo(
    () => ({
      ...props,
      returnUrlTemplate: `/profile/${advisorLogin}/gb/:goodyBagId?action=buy`,
    }),
    [props, advisorLogin],
  );

  useEffect(() => {
    if (window.location.hash === '#chat' && advisor?.slug) {
      window.location.href = `/chat/${advisor.slug}`;
    }
  }, [advisor?.slug]);

  useEffect(() => {
    const onScroll = (e) => {
      setStickyNavVisible(e.target.scrollTop > 180);
    };

    const bodyContent = document.getElementById('body-content');
    if (bodyContent) { bodyContent.addEventListener('scroll', onScroll); }

    return () => {
      bodyContent.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <AppBody>
      <StyledHeader data-test-id="template-shell-header">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link
              role="link"
              to={`/${advisor?.login}`}
            >
              <Box data-test-id="ico-back-arrow" sx={{ margin: '3px 0 0 4px' }}>
                <ArrowBackIosSharpIcon
                  htmlColor="white"
                />
              </Box>
            </Link>
            <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle">{advisor?.login}</Typography>
          </Stack>
        </Stack>
        <Box sx={{ mr: 1 }}>
          <ProfileMenu advisor={advisor} currentUser={currentUser} />
        </Box>
      </StyledHeader>
      <Box style={{ backgroundColor: 'var(--body_background)', border: '1px dotted transparent' }}>
        <div className="container-fluid container-profile-info">
          <div className="row nowrap-sm">
            <div className="col-sm-9 col-xs-9 start-xs" />
            <div className="col-sm-3 hidden-xs">
              <HeaderContactButtons advisor={advisor} currentUser={currentUser} mobileVersion={false} />
            </div>
          </div>
        </div>
        <HeaderContactButtons advisor={advisor} currentUser={currentUser} mobileVersion />
        <StickyProfilePageControls
          advisor={advisor}
          currentUser={currentUser}
          stickyNavVisible={stickyNavVisible}
          viewingProfile={viewingProfile}
        />
      </Box>

      <Homepage advisorLogin={advisorLogin} />

      <ProfileContext.Provider value={contextProps}>
        <ProfileTabsWithContent
          stickyNavVisible
          advisor={advisor}
          advisorLoading={advisorLoading}
          viewingSelf={viewingSelf}
          currentUser={currentUser}
        />
      </ProfileContext.Provider>
    </AppBody>
  );
}

export default AdvisorHomepageContainer;
