import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
  Box,
  Stack,
  Checkbox,
  Typography,
  Link,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { LAPSE_STATUS_COLORS, EARNINGS_GROUP_COLORS } from 'app/constants/customers';
import ColorMappedChip from 'app/components/customers/ColorMappedChip';
import HoverChip from 'app/components/customers/HoverChip';
import MoreMenu from 'app/components/customers/MoreMenu';
import { toCurrency } from 'app/helpers/currencyHelpers';

const StyledLink = styled(Link)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  display: 'inline-block',

  '@media (max-width: 600px)': {
    maxWidth: '120px',
  },
}));

const SearchResult = ({
  customer,
  selectedResults,
  setSelectedResults,
  selectAll,
  seekerIdsOnPage,
  triggerSearch,
}) => {
  const checked = selectedResults.includes(customer.seeker_id);

  const [showAllTags, setShowAllTags] = useState(false);
  const tagNames = customer?.tagList?.split('^')?.filter((x) => x) || [];
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const numberOfTagsShown = isSmallScreen ? 2 : 4;
  const showMoreTags = tagNames.length > numberOfTagsShown ? tagNames.length - numberOfTagsShown : 0;
  const firstTwoTags = tagNames.slice(0, numberOfTagsShown);

  // exactly identical to the paragraph above, except for system-created tags instead of user tags
  const [showAllSystemTags, setShowAllSystemTags] = useState(false);
  const systemTagNames = customer?.systemTagList?.split('^')?.filter((x) => x) || [];
  const showMoreSystemTags = systemTagNames.length > 2 ? systemTagNames.length - 2 : 0;
  const firstTwoSystemTags = systemTagNames.slice(0, 2);

  const handleShowMoreClick = () => {
    setShowAllTags(true);
  };

  const handleShowMoreSystemClick = () => {
    setShowAllSystemTags(true);
  };

  const onChange = (_event, newValue) => {
    if (newValue && !selectedResults.includes(customer.seeker_id)) {
      // Add seeker_id to selectedResults if not already included
      setSelectedResults((prevResults) => [...prevResults, customer.seeker_id]);
    } else if (selectAll) {
      setSelectedResults(seekerIdsOnPage.filter((seekerId) => customer.seeker_id !== seekerId));
    } else {
      setSelectedResults(selectedResults.filter((id) => id !== customer.seeker_id));
    }
  };

  return (
    <Box
      sx={{
        color: 'var(--color_gray_eight)',
        borderBottom: '1px solid var(--color_ddd)',
        pt: 1,
        pb: 0.5,
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Checkbox
            checked={checked}
            onChange={onChange}
          />
          <ColorMappedChip
            label={customer.interval_earnings_group}
            colorMap={EARNINGS_GROUP_COLORS}
          />
          <ColorMappedChip
            label={customer.lapse_status}
            colorMap={LAPSE_STATUS_COLORS}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          useFlexGap={false}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            pr: 2,
          }}
        >
          <StyledLink
            variant="body1"
            href={`/my_customers/user/${customer.seeker_name}`}
          >
            {customer.seeker_name}
          </StyledLink>
          <MoreMenu customer={customer} triggerSearch={triggerSearch} />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          pl: 6,
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: 2,
          pb: '10px',
          pt: '10px',
        }}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ alignItems: 'center' }}
        >
          <Typography
            variant="body1"
            fontSize={14}
          >
            Mostly
          </Typography>
          <Typography
            variant="body1"
            fontSize={14}
            sx={{ color: 'var(--color_gray_three)' }}
          >
            {customer.main_spend}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={0.5}
        >
          <Tooltip title="Days since last contact">
            <Typography
              variant="body1"
              fontSize={14}
            >
              { isSmallScreen ? 'L/C' : 'Last Contact' }
            </Typography>
          </Tooltip>
          <Typography
            variant="body1"
            fontSize={14}
            sx={{ color: 'var(--color_gray_three)' }}
          >
            {customer.days_since_last_contact}
            &nbsp;
            {customer.days_since_last_contact > 1 ? 'days' : 'day'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing="4px"
          alignItems="center"
        >
          <Tooltip title="Last 90 days earnings">
            <Typography
              variant="body1"
              fontSize={14}
            >
              { isSmallScreen ? 'L90D' : 'Last 90 Day $' }
            </Typography>
          </Tooltip>
          <Typography
            variant="body1"
            fontSize={14}
            sx={{ color: 'var(--color_gray_three)' }}
          >
            {toCurrency(customer.skr_90D_earnings)}
          </Typography>
        </Stack>
      </Stack>
      {/* System-created tags (customer lists) */}
      { (systemTagNames.length > 0) && (
        <Stack
          direction="row"
          spacing={2}
          useFlexGap
          sx={{
            pl: 6,
            pb: 2,
            alignItems: 'center',
            justifyContent: 'flex-start',
            pr: '20px',
            pt: '6px',
            flexWrap: 'wrap',
          }}
        >
          { showAllSystemTags && (systemTagNames.map((tag) => (
            <HoverChip
              key={`all_system_${customer.seeker_id}_${tag}`}
              label={tag}
            />
          )))}
          { !showAllSystemTags && firstTwoSystemTags.map((tag) => (
            <HoverChip
              key={`first_two_system_${customer.seeker_id}_${tag}`}
              label={tag}
            />
          ))}
          { !showAllSystemTags && showMoreSystemTags > 0 && (
            <Typography
              variant="body3"
              align="center"
              onClick={handleShowMoreSystemClick}
            >
              +
              {showMoreSystemTags}
            </Typography>
          )}
        </Stack>
      )}
      {/* User-created tags (customer lists) */}
      { (tagNames.length > 0) && (
        <Stack
          direction="row"
          spacing={2}
          useFlexGap
          sx={{
            pl: 6,
            pb: 2,
            alignItems: 'center',
            justifyContent: 'flex-start',
            pr: '20px',
            pt: '6px',
            flexWrap: 'wrap',
          }}
        >
          { showAllTags && (tagNames.map((tag) => (
            <HoverChip
              key={`all_customer_${customer.seeker_id}_${tag}`}
              label={tag}
              sx={{ backgroundColor: 'white', border: '1px solid #bdbdbd' }}
            />
          )))}
          { !showAllTags && firstTwoTags.map((tag) => (
            <HoverChip
              key={`first_two_customer_${customer.seeker_id}_${tag}`}
              label={tag}
              sx={{ backgroundColor: 'white', border: '1px solid #bdbdbd' }}
            />
          ))}
          { !showAllTags && showMoreTags > 0 && (
            <Typography
              variant="body2"
              align="center"
              onClick={handleShowMoreClick}
            >
              +
              {showMoreTags}
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};

SearchResult.propTypes = {
  customer: PropTypes.object.isRequired,
  selectedResults: PropTypes.array.isRequired,
  setSelectedResults: PropTypes.func.isRequired,
  selectAll: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  seekerIdsOnPage: PropTypes.array.isRequired,
  triggerSearch: PropTypes.func.isRequired,
};

export default SearchResult;
