import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ModalsContext from 'app/contexts/ModalsContext';
import useCreditCardManagement from 'app/hooks/useCreditCardManagement';
import { userHasCard } from 'app/helpers/userHelper';

import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import Connected from 'app/components/call/callInProgress/Icons/Connected';

const LessThanOneMinuteLeft = ({ currentUser, triggerGetCallStatus }) => {
  const currentUserHasCard = userHasCard(currentUser);
  const { openModal, closeModal } = useContext(ModalsContext);
  const onTryAgainSuccess = closeModal;
  const onManageCardsSuccess = closeModal;
  const onAddToBalanceSuccess = () => {
    closeModal();
    triggerGetCallStatus();
  };

  const {
    openManageCardsModal,
    openAddToBalanceModal,
  } = useCreditCardManagement({
    openModal,
    currentUser,
    guard: null,
    setGuard: null,
    onTryAgainSuccess,
    onManageCardsSuccess,
    onAddToBalanceSuccess,
  });

  const resolveMoneyManagementFlow = () => {
    closeModal();

    if (currentUserHasCard) {
      openAddToBalanceModal();
    } else {
      openManageCardsModal();
    }
  };

  const onAddMoneyClick = () => {
    resolveMoneyManagementFlow();
  };

  return (
    <Box
      textAlign="center"
      mt={3}
    >
      <Typography variant="h3">
        You are now connected.
      </Typography>
      <Box mt={3} mb={3}>
        <Connected />
      </Box>
      <Typography variant="body1" gutterBottom>
        You only have 1 minute of talk time remaining.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Add to your balance now, to continue your conversation.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 350, margin: '20px auto' }}>
        <Button
          variant="contained"
          size="medium"
          sx={{ margin: '20px auto' }}
          onClick={onAddMoneyClick}
        >
          Add Money
        </Button>
      </Box>
    </Box>
  );
};

LessThanOneMinuteLeft.propTypes = {
  currentUser: PropTypes.object.isRequired,
  triggerGetCallStatus: PropTypes.func.isRequired,
};

export default LessThanOneMinuteLeft;
