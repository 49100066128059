import React, { useState } from 'react';

import PropTypes from 'prop-types';
import MailboxShape from 'app/shapes/MailboxShape';

import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import {
  Stack,
  Button,
  List,
} from '@mui/material';

import Message from 'app/features/mail/components/Message';
import SentMessage from 'app/features/mail/components/SentMessage';

const Mailbox = ({
  mailbox,
  currentUser,
  selectedTab,
}) => {
  const [selectedMessage, setSelectedMessage] = useState(null);

  return (
    <>
      <Stack direction="row-reverse">
        <Button
          size="sm"
          onClick={() => {}}
          sx={{ ml: 'auto' }}
        >
          <CreateRoundedIcon />
        </Button>
      </Stack>
      <List
        sx={{
          '& .Mui-selected': {
            borderLeft: '2px solid',
            borderLeftColor: 'primary.main',
          },
        }}
      >
        {mailbox.messages.map((message) => (
          selectedTab === 'sent' ? (
            <SentMessage
              currentUser={currentUser}
              key={message.id}
              message={message}
              selected={selectedMessage?.id === message.id}
              onClick={() => setSelectedMessage(message)}
            />
          ) : (
            <Message
              currentUser={currentUser}
              key={message.id}
              message={message}
              selected={selectedMessage?.id === message.id}
              onClick={() => setSelectedMessage(message)}
            />
          )
        ))}
      </List>
    </>
  );
};

Mailbox.propTypes = {
  mailbox: MailboxShape.isRequired,
  selectedTab: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default Mailbox;
