import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const MessageChip = ({
  copy,
  xs,
}) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        padding: '5px 10px',
        borderRadius: '5px',
        ...xs,
      }}
    >
      {copy}
    </Box>
  );
};

MessageChip.defaultProps = {
  xs: {},
};

MessageChip.propTypes = {
  copy: PropTypes.string.isRequired,
  xs: PropTypes.object,
};

export default MessageChip;
