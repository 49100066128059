import { createRoutesFromChildren } from 'react-router-dom';
import routes from 'app/routes';

const routeConfig = createRoutesFromChildren(routes);

const buildRouteMap = (routes = routeConfig, parentPath = '') => {
  const routeMap = {};

  routes.forEach(route => {
    // Determine the full path:
    // If a route has a path, combine it with the parent's path.
    // Otherwise, just use the parent path.
    let fullPath = parentPath;
    if (route.path) {
      fullPath = route.path.startsWith('/')
        ? route.path
        : `${parentPath}/${route.path}`.replace(/\/+/g, '/');
    }

    // Only add to the map if the route has an id.
    if (route.id) {
      routeMap[route.id] = fullPath;
    }

    // Process children if any.
    if (route.children) {
      Object.assign(routeMap, buildRouteMap(route.children, fullPath));
    }
  });

  return routeMap;
};

export default buildRouteMap;
