export const TRIBUTE_WORKFLOW_STEPS = {
  ADD_TO_BALANCE: 'ADD_TO_BALANCE',
  BILLING_ERROR: 'BILLING_ERROR',
  CHANGE_PACKAGE_SUBSCRIPTION: 'CHANGE_PACKAGE_SUBSCRIPTION',
  CONFIRM_CANCEL_PACKAGE_SUBSCRIPTION: 'CONFIRM_CANCEL_PACKAGE_SUBSCRIPTION',
  CONFIRM_CANCEL_MONETARY_TRIBUTE: 'CONFIRM_CANCEL_MONETARY_TRIBUTE',
  CONFIRM_CHANGE_MONETARY_TRIBUTE: 'CONFIRM_CHANGE_MONETARY_TRIBUTE',
  CONFIRM_CHANGE_PACKAGE_SUBSCRIPTION: 'CONFIRM_CHANGE_PACKAGE_SUBSCRIPTION',
  CONFIRM_CREATE_MONETARY_TRIBUTE: 'CONFIRM_CREATE_MONETARY_TRIBUTE',
  CONFIRM_CREATE_PACKAGE_SUBSCRIPTION: 'CONFIRM_CREATE_PACKAGE_SUBSCRIPTION',
  CONFIRM_EDIT_TRIBUTE: 'CONFIRM_EDIT_TRIBUTE',
  EDIT_MONETARY_TRIBUTE: 'EDIT_MONETARY_TRIBUTE',
  EDIT_PACKAGE_SUBSCRIPTION: 'EDIT_PACKAGE_SUBSCRIPTION',
  EXISTING_TRIBUTE: 'EXISTING_TRIBUTE',
  EXIT_TRIBUTE_WORKFLOW: 'EXIT_TRIBUTE_WORKFLOW',
  INITIALIZING: 'INITIALIZING',
  MANAGE_CARDS: 'MANAGE_CARDS',
  NEW_TRIBUTE: 'NEW_TRIBUTE',
  SHOW_INDIVIDUAL_PACKAGE: 'SHOW_INDIVIDUAL_PACKAGE',
  TRIBUTE_SUCCESS: 'TRIBUTE_SUCCESS',
  USE_DIFFERENT_CARD: 'USE_DIFFERENT_CARD',
};
