import React, { useEffect, useState } from 'react';
import { DarkMode, LightMode } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

function DarkModeToggle() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('selectedTheme') === 'dark';
  });

  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    localStorage.setItem('selectedTheme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <IconButton
      onClick={toggleTheme}
      disableRipple
      sx={{
        color: isDarkMode ? '#a0a0a0' : '#999',
        width: 'auto', // Ensures the button doesn't inherit unwanted width
        '&:hover': { color: isDarkMode ? '#fff' : '#63177a' },
        borderRadius: '0px',
        padding: '0px',
      }}
    >
      {isDarkMode ? <LightMode /> : <DarkMode />}
      <Typography
        sx={{
          marginLeft: -3,
          fontSize: '0.688em',
          width: '100px',
          paddingLeft: 5,
          textAlign: 'left',
          color: isDarkMode ? '#666' : '#666',
          '&:hover': { color: isDarkMode ? '#fff' : '#63177a' },
        }}
      >
        {isDarkMode ? 'Light Mode' : 'Dark Mode'}
      </Typography>
    </IconButton>
  );
}

export default DarkModeToggle;
