import React from 'react';
import PropTypes from 'prop-types';

import {
  Stack, Typography,
} from '@mui/material';
import StyledHeader from 'app/components/shared/StyledHeader';

const TitleHeader = ({ title }) => {
  return (
    <StyledHeader data-test-id="template-shell-header">
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography
          data-test-id="template-shell-header-title"
          variant="purpleHeaderTitle"
          sx={{ pl: 2 }}
        >
          { title }
        </Typography>
      </Stack>
    </StyledHeader>
  );
};

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleHeader;
