import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconFilePlayer = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="42px" height="32px" viewBox="0 0 42 32">
    {/* Your SVG Path */}
    <path d="M17.0245328,27.1301567 C20.7352969,24.7801126 23.9305807,22.7935326 27.0842095,20.7410301 C28.4353927,19.861768 27.8652403,19.0902979 26.8091131,18.4248381 C24.3532051,16.875662 21.9129178,15.3006515 19.4483317,13.7666197 C18.7558177,13.3354517 18.0008213,13.0111848 17.0245328,12.5096401 L17.0245328,27.1301567 Z M21.2342888,31.8667693 C15.0927833,31.8667693 8.95040994,31.7990652 2.81150787,31.9041847 C0.760868114,31.9389276 -0.0262373683,31.2601052 0.000664803639,29.1131734 C0.0857103795,22.3641462 0.0726931995,15.6133374 0.00847511163,8.86431027 C-0.0114845643,6.77261072 0.75045437,5.98866882 2.82278942,6.00012353 C15.1049326,6.06528132 27.3888115,6.06171795 39.6718225,6.00012353 C41.5081127,5.9904505 42.0157827,6.69867075 41.9975587,8.48392027 C41.9272659,15.6035381 41.9272659,22.7249377 41.9996548,29.8445555 C42.0157827,31.5104321 41.4690612,32.0182126 39.8748905,31.9995049 C33.6622244,31.925565 27.4478227,31.9692163 21.2342888,31.9692163 C21.2342888,31.9344734 21.2342888,31.9006213 21.2342888,31.8667693 L21.2342888,31.8667693 Z" id="Fill-4" />
    <path d="M21,4 C21.189364,0.673367815 21.9308174,0.0058553723 25.2709137,0.00273250708 C29.1915486,-0.00117107446 33.1130724,-0.000390358154 37.0337072,0.00195179077 C40.4066978,0.00429393969 41.0645821,0.648384893 40.9952375,4 L21,4 Z" id="Fill-6" />
  </SvgIcon>
);

export default IconFilePlayer;
