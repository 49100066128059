import React from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import MailboxShape from 'app/shapes/MailboxShape';

import {
  Stack,
  Pagination,
  PaginationItem,
} from '@mui/material';

const MailboxPagination = ({ mailbox, setPage, page }) => {
  const navigate = useNavigate();
  const handlePageChange = (_event, value) => {
    setPage(value);
    navigate(`?page=${value}`);
  };
  /*
  Styles have to be applies directly to the pagination component to fight off the css written directly onto the
  nav element by nfp-skeleton1.scss
  */
  return (
    <Stack
      direction="row-reverse"
      sx={{
        padding: '8px 0',
      }}
    >
      <Pagination
        sx={{
          display: 'flex',
          maxWidth: '100%',
          width: 'auto',
          marginX: '10px',
          height: 'auto',
          border: 'none',
        }}
        siblingCount={0}
        count={mailbox.pages}
        page={page}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              borderRadius: '4px',
              margin: '0 3px',
            }}
          />
        )}
      />
    </Stack>
  );
};

MailboxPagination.propTypes = {
  mailbox: MailboxShape.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

export default MailboxPagination;
