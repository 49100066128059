import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

const ListingOwnerDetailKebabMenu = ({ listing }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClick = (evt) => {
    evt.preventDefault();
    setAnchorEl(evt.currentTarget);
  };

  const onEditClick = () => {
    window.location = `/listings/${listing.slug}/edit`;
  };

  const onGetCallButtonClick = () => {
    window.location = `/documents/my_call_button?listing_id=${listing.id}`;
  };

  const onRecordGreetingClick = () => {
    window.location = `/listings/${listing.slug}/record_greeting`;
  };

  const oneViewPoliciesClick = () => {
    window.location = 'https://support.niteflirt.com/hc/en-us/articles/212830688-NiteFlirt-Rules';
  };

  return (
    <>
      <a
        onClick={onClick}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <MoreVert sx={{ color: 'var(--color_gray_nine)', mt: 1, display: 'inline-block' }} />
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        data-testid="menu-popover"
      >
        <MenuItem onClick={onEditClick}>Edit</MenuItem>
        <MenuItem onClick={onGetCallButtonClick}>Get Call Button</MenuItem>
        <MenuItem onClick={onRecordGreetingClick}>Record Greeting</MenuItem>
        <MenuItem onClick={oneViewPoliciesClick}>View Policies</MenuItem>
      </Menu>
    </>
  );
};

ListingOwnerDetailKebabMenu.propTypes = {
  listing: PropTypes.object.isRequired,
};

export default ListingOwnerDetailKebabMenu;
