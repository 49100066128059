import React, { useContext } from 'react';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withTooltip from 'app/hoc/withTooltip';
import RoutingContext from 'app/contexts/RoutingContext';

const KebobMenu = ({
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
}) => {
  const generateUrl = useContext(RoutingContext);

  return (
    <Tooltip
      content={(
        <div
          ref={(el) => setTipContentRef(el)}
          className="nfp-tooltip"
        >
          <div className="row nowrap-xs">
            <div className="col-xs-11">
              <div className="menu-links">
                <Link
                  to="/account/account_info?deepLinked=true"
                  title="Account Info"
                  role="link"
                  tabIndex={-1}
                >
                  Account Info
                </Link>
                <br />
                <Link
                  to={generateUrl('transactionDetails')}
                  title="Transaction Details"
                  role="link"
                  tabIndex={-2}
                >
                  Transaction Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        title="Toggle Profile Menu"
        ref={(el) => setButtonRef(el)}
        onClick={toggleTip}
        style={{ marginLeft: '10px', filter: 'grayscale(100%) invert(70%)' }}
      >
        <img src="/plus_frontend/assets/svg/ico-kebob.svg" alt="More" />
      </a>
    </Tooltip>
  );
};

KebobMenu.defaultProps = {
};

KebobMenu.propTypes = {
  // withTooltip props
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
};

export default withTooltip(KebobMenu);
