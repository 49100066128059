import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, Typography, Button, DialogContent, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoFillableTextField from 'app/components/shared/AutoFillableTextField';
import { useCreatePasswordResetMutation } from 'app/api/mainApi';
import CloseIcon from '@mui/icons-material/Close';
import useRecaptcha from 'app/hooks/useRecaptcha';
import { navigateReplace } from 'app/util/navigation';

const BoxFullPage = styled(Box)(() => ({
  '&.MuiBox-root': {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: '0px',
    left: '0px',
    backgroundColor: 'white',
    overflow: 'scroll', // Add overflow property
  },
}));

const BoxClosePlacement = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '600px',
  margin: '0 auto -20px auto',
  padding: '10px 10px 0 0',
});

const ForgotPasswordDialog = ({ type, closeDialog }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetStatus = searchParams.get('status');
  const [sendPasswordReset, { data: passwordResetData, error: passwordResetError }] = useCreatePasswordResetMutation();

  const { executeRecaptcha, recaptchaComponent } = useRecaptcha();

  const label = 'Email Address';

  const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

  const displayError = error || passwordResetError?.data?.error;
  const validateEmailAndSend = (captchaToken) => {
    if (!email.match(emailRegex)) {
      setError('Please enter a valid email address.');
    } else {
      sendPasswordReset({ email, recaptcha_token: captchaToken });
    }
  };

  const onChange = (token) => {
    validateEmailAndSend(token);
  };

  const onError = () => {
    setError('There was an error with the reCAPTCHA. Please try again.');
  };

  // if we have a loginData object, we should redirect to the URL it contains
  useEffect(() => {
    if (passwordResetData) {
      let redirectTo = '/login';
      switch (passwordResetData.redirectTo) {
      case 'verify_payer_id':
        redirectTo = 'verify_account?type=payer_id';
        break;
      case 'verify_card':
        redirectTo = 'verify_account?type=card';
        break;
      case 'reset_password_complete':
        redirectTo = 'reset_password_complete';
        break;
      default:
        redirectTo = 'login';
      }
      navigate(redirectTo);
    }
  }, [passwordResetData]);

  const title = 'Forgot Password';
  const wording = 'We will send you an email with instructions on how to reset your password.';
  const resetErrorMessage = resetStatus &&
    (resetStatus === 'expired' ?
      'Sorry, but your request has been expired. Please request your password once again.' :
      'Sorry, but your account is inactive. Please contact the administrator.');

  return (
    <BoxFullPage>
      <BoxClosePlacement>
        <IconButton
          aria-label="close"
          onClick={() => { window.location.href = '/login'; }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </BoxClosePlacement>
      <DialogContent sx={{ maxWidth: '450px', margin: '0 auto' }}>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <a href="/">
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          </a>
          <Typography variant="h3">
            { title }
          </Typography>
          { resetErrorMessage &&
            (
              <Typography variant="body1" style={{ color: '#d32f2f' }}>
                { resetErrorMessage }
              </Typography>
            )
          }
          <Typography variant="body1" align="center">
            { wording }
          </Typography>
        </Stack>
        {
          displayError && (
            <Stack mb={3}>
              <Typography variant="body3" style={{ color: '#d32f2f' }}>
                <div dangerouslySetInnerHTML={{ __html: displayError }} />
              </Typography>
            </Stack>
          )
        }

        <Stack direction="column" spacing={3}>
          <AutoFillableTextField
            name="email"
            label={label}
            type="email"
            value={email}
            setValue={setEmail}
            onKeyPress={(e) => { if (e.key === 'Enter') executeRecaptcha(); }}
          />
          <Stack direction="row" justifyContent="center" mb={3} mt={2}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={executeRecaptcha}
              disabled={!email}
            >
              Send
            </Button>
          </Stack>
        </Stack>
        { recaptchaComponent(onChange, onError) }
      </DialogContent>
    </BoxFullPage>
  );
};

export default ForgotPasswordDialog;
