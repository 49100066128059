import React, { useEffect } from 'react';
import { Box, styled, Grid, Button, Typography, ListItem, List } from '@mui/material';
import { useGetAccountClosureQuery, useCloseAccountMutation } from 'app/api/accountApi';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { toCurrency } from 'app/helpers/currencyHelpers';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const BoxSupportContent = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    flexGrow: 1,
    minHeight: '100vh',
    fontSize: '0.875em',
    lineHeight: '1.625em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.938em',
      lineHeight: '1.688em',
    },
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  variant: 'body1',
}));

const RefundableDepositsList = ({ refundableDeposits }) => {
  return (
    <List sx={{ listStyleType: 'none' }}>
      {refundableDeposits.map((rd, index) => (
        <ListItem key={index} sx={{ marginLeft: '30px' }}>
          <Typography variant="body1">
            {`${toCurrency(rd.amount)} will be credited back to your ${rd.type} ending in ${rd.lastFour}`}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

const CloseMessages = ({ accountClosure }) => {
  const {
    payoutableBalance,
    depositBalance,
    minimumRefundAmount,
    refundableDeposits,
    nonPayableMarketingBalance,
  } = accountClosure;

  return (
    <>
      <BodyText>
        Aw, we&apos;re sorry to see you go!
      </BodyText>

      {payoutableBalance > 0 && (
        <BodyText>
          {`Your final payout of ${toCurrency(payoutableBalance)} will be processed during our next payment run.`}
        </BodyText>
      )}

      {depositBalance > minimumRefundAmount && (
        <>
          <BodyText>
            {`Your balance of ${toCurrency(depositBalance)} will be refunded and should post in about 5 business days.`}
          </BodyText>
          <RefundableDepositsList refundableDeposits={refundableDeposits} />
        </>
      )}

      {nonPayableMarketingBalance > 0 && (
        <BodyText>
          {`You have ${toCurrency(nonPayableMarketingBalance)} in site credit, and it will be forfeited if you close your account. If you'd like to spend this credit before closing your account, click "No."`}
        </BodyText>
      )}

      <BodyText>
        Are you sure you want to close your account?
      </BodyText>
    </>
  );
};

const CannotCloseMessages = ({ accountClosure }) => {
  const { reasonNotClosable } = accountClosure;

  let message;
  switch (reasonNotClosable) {
  case 'account_already_closed':
    message = <BodyText>Your account is already closed.</BodyText>;
    break;
  case 'user_on_call':
    message = (
      <>
        <BodyText>Your account cannot be closed.</BodyText>
        <BodyText>You are currently on a call! You may close your account once your call is completed.</BodyText>
      </>
    );
    break;
  case 'user_with_no_payout':
    message = (
      <>
        <BodyText>Your account cannot be closed.</BodyText>
        <BodyText>To close your account, please enter your Payout information and Request Payout.</BodyText>
      </>
    );
    break;
  case 'user_with_payout_paused':
    message = (
      <>
        <BodyText>Your account cannot be closed.</BodyText>
        <BodyText>Your payouts are currently paused. To close your account, please request payout.</BodyText>
      </>
    );
    break;
  case 'user_with_payment_hold':
  default:
    message = (
      <>
        <BodyText>Aw, we&apos;re sorry to see you go!</BodyText>
        <BodyText>Please submit a request to close your account and Customer Support will assist you.</BodyText>
      </>
    );
    break;
  }

  return message;
};

const CloseAccountContainer = () => {
  const { data: { txn_url: txnUrl } = {} } = useGetAppConfigQuery();
  const { data: accountClosure } = useGetAccountClosureQuery();
  const [closeAccount, closeAccountStatus] = useCloseAccountMutation();
  const { cannotBeClosed, reasonNotClosable } = accountClosure || {};
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  useEffect(() => {
    if (closeAccountStatus.isSuccess) {
      console.log('----- CloseAccountStatus.isSuccess', closeAccountStatus);

      const timer = setTimeout(() => {
        console.log('----- dispatchInvalidateCacheTagsEvent');
        dispatchInvalidateCacheTagsEvent(['*']);
        console.log('----- window.location.href = /');
        window.location.href = '/';
      }, 5000);

      console.log('----- Timer set for 5 seconds');

      return () => {
        clearTimeout(timer);
        console.log('----- Timer cleared');
      };
    }
  }, [closeAccountStatus, dispatchInvalidateCacheTagsEvent]);

  const handleCancel = () => {
    window.location.href = '/';
  };

  if (!accountClosure) {
    return null;
  }

  const canRequestPayout = cannotBeClosed &&
    (reasonNotClosable === 'user_with_payout_paused' || reasonNotClosable === 'user_with_no_payout');
  const canBeClosedWithSupport = cannotBeClosed && reasonNotClosable === 'user_with_payment_hold';

  const handleCloseAccount = () => {
    console.log('----- closeAccount()');
    closeAccount();
  };

  const handleRequestSupport = () => {
    window.location.href = 'https://support.niteflirt.com/hc/en-us/requests/new';
  };

  const handleRequestPayout = () => {
    window.location.href = `${txnUrl}/account/payout`;
  };

  const disableButtons = closeAccountStatus.isLoading || closeAccountStatus.isSuccess;

  return (
    <BoxSupportContent>
      { accountClosure.cannotBeClosed ? (
        <CannotCloseMessages accountClosure={accountClosure} />
      ) : (
        <CloseMessages accountClosure={accountClosure} />
      )}

      {/* Buttons */}
      <Box sx={{ marginLeft: -2, marginTop: 5 }}>
        <Grid container spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end">

          {/* Submit Button */}
          { (!accountClosure.cannotBeClosed) && (
            <Grid item xs={12} sm="auto" sx={{ order: { xs: 1, sm: 2 } }}>
              <Button
                variant="contained"
                fullWidth={false}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                onClick={handleCloseAccount}
                disabled={disableButtons}
              >
                Yes, Close my NiteFlirt Account
              </Button>
            </Grid>
          )}

          {/* Request Payout */}
          { canRequestPayout && (
            <Grid item xs={12} sm="auto" sx={{ order: { xs: 1, sm: 2 } }}>
              <Button
                variant="contained"
                fullWidth={false}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                onClick={handleRequestPayout}
              >
                Request Payout
              </Button>
            </Grid>
          )}

          {/* Get Help Button */}
          { canBeClosedWithSupport && (
            <Grid item xs={12} sm="auto" sx={{ order: { xs: 1, sm: 2 } }}>
              <Button
                variant="contained"
                fullWidth={false}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                onClick={handleRequestSupport}
              >
                Yes, Close my NiteFlirt Account
              </Button>
            </Grid>
          )}

          {/* Cancel Button */}
          <Grid item xs={12} sm="auto" sx={{ order: { xs: 2, sm: 1 } }}>
            <Button
              variant="outlined"
              fullWidth={false}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              onClick={handleCancel}
              disabled={disableButtons}
            >
              No, Keep Account
            </Button>
          </Grid>

        </Grid>
      </Box>
    </BoxSupportContent>
  );
};

export default CloseAccountContainer;
