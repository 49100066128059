import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, CircularProgress, Button } from '@mui/material';
import { useFilePlayerContext } from '../FilePlayerContext';
import { FileMasterCheckbox } from './FileMasterCheckBox';
import { FileToggleViewButton } from './FileToggleViewButton';
import { FileSortButton } from './FileSortButton';
import { FileListGroup } from './FileListGroup';
import { FileGridGroup } from './FileGridGroup';

const LoadingState = () => (
  <Stack direction="row" justifyContent="center" alignItems="center" mt={2}>
    <CircularProgress size={24} />
    <Typography variant="body2" ml={1}>
      Loading files...
    </Typography>
  </Stack>
);

const ErrorState = ({ error }) => (
  <Typography variant="body2" color="error" mt={2}>
    Error loading files:
    {' '}
    {error?.data?.message || 'Unexpected error.'}
  </Typography>
);

ErrorState.propTypes = {
  error: PropTypes.shape({
    data: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
};

const EmptyState = () => (
  <Typography variant="body2" color="text.secondary" mt={2}>
    No files available. Mail attachments you have opened or purchased will show here. Right now, there&apos;s nothing to see!
  </Typography>
);

const LoadMoreButton = ({ setPage, isLoading }) => (
  <Stack alignItems="center" mt={2}>
    <Button onClick={() => setPage((prev) => prev + 1)} disabled={isLoading}>
      {isLoading ? 'Loading more...' : 'Load More'}
    </Button>
  </Stack>
);

LoadMoreButton.propTypes = {
  setPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const FilesTab = () => {
  const { filesQuery, listView, setPage } = useFilePlayerContext();
  const { data: filesData, error: filesError, isLoading: filesLoading, isError: filesHasError } = filesQuery;

  if (filesLoading) return <LoadingState />;
  if (filesHasError) return <ErrorState error={filesError} />;

  const allFiles = filesData?.pages?.flatMap((p) => p.items) || [];
  const hasMoreFiles = !!filesData?.pages.at(-1)?.nextPage;

  if (!allFiles.length) return <EmptyState />;

  return (
    <Stack spacing={2} mt={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FileMasterCheckbox />
        <Stack direction="row" spacing={1}>
          <FileToggleViewButton />
          <FileSortButton />
        </Stack>
      </Stack>

      {listView ? <FileListGroup files={allFiles} /> : <FileGridGroup files={allFiles} />}

      {hasMoreFiles && <LoadMoreButton setPage={setPage} isLoading={filesLoading} />}
    </Stack>
  );
};
