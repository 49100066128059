import React from 'react';

const TributeCardPlaceholder = () => {
  return (
    <div className="card">
      <div className="container-card-placeholder">
        <div className="row nowrap-xs">
          <div className="loading shape7">
            <div className="shape8" />
            <div className="shape9" />
            <div className="shape10" />
          </div>
        </div>
        <div className="row">
          <div className="loading shape11" />
        </div>
      </div>
    </div>
  );
};

export default TributeCardPlaceholder;
