import React, { useEffect, useRef } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import PropTypes from 'prop-types';

// The purpose of this list is to mimic what google address autocomplete does:
// It shows a list of options that the user can select from.
// If the options.length === 0 the list is hidden by default.
const ActionList = ({ options, onSelect, showIcon, icon, component, onOutsideClick }) => {
  const listRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div style={{ width: '100%', position: 'relative' }} ref={listRef}>
      { component }
      <Paper sx={{ position: 'absolute', zIndex: 1000, top: 55, left: 0 }}>
        <List disablePadding sx={{ backgroundColor: 'white' }}>
          {
            options.map((option, index) => (
              <ListItem key={option.label} disablePadding divider>
                <ListItemButton onClick={() => onSelect(index)}>
                  { showIcon && icon && (
                    <ListItemIcon>
                      { icon }
                    </ListItemIcon>
                  )}
                  <ListItemText primary={option.label} />
                </ListItemButton>
              </ListItem>
            ))
          }
        </List>
      </Paper>
    </div>
  );
};

ActionList.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  icon: PropTypes.node,
  component: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
};

ActionList.defaultProps = {
  showIcon: false,
  icon: null,
};

export default ActionList;
