import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Lightbox from 'lightbox-react';
import { usePubNub } from 'pubnub-react';
import Box from '@mui/material/Box';

import ListingDetailDrawer from 'app/components/ListingDetailDrawer';

import {
  useGetListingQuery,
  useGetAppConfigQuery,
  LISTING_TAG,
} from 'app/api/mainApi';

import ModalsContext from 'app/contexts/ModalsContext';
import ReportContentModal from 'app/components/modals/ReportContentModal';
import { callButton } from 'app/helpers/listingHelpers';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const ListingDetailDrawerContainer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listing_id');
  const { openModal } = useContext(ModalsContext);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const pubNubClient = usePubNub();
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  const {
    data: listing,
    isSuccess,
  } = useGetListingQuery({ id: listingId }, { skip: !listingId });

  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.currentUser;
  const voicePortalPhoneNumber = appConfig?.voicePortalPhoneNumber;

  const onDrawerClose = () => {
    navigate(window.location.pathname);
  };

  const onFeedbackClick = () => {
    navigate(
      `${window.location.pathname}?listing_id=${listingId}&feedback_listing_id=${listingId}`,
    );
  };

  const onReportListingClick = () => {
    // this closes the drawer, which doesn't play very nicely with our
    // custom modal system. Using a MUI Portal partially solves the
    // z-index issues I encountered, but even then I couldn't get the
    // textarea to gain focus. This is the best workaround (possibly for now)
    navigate(window.location.pathname);

    openModal({
      component: ReportContentModal,
      props: {
        contentId: listingId,
        contentType: 'listing',
      },
    });
  };

  const onImageClick = () => {
    setLightboxIsOpen(true);
  };

  const onLightboxClose = () => {
    setLightboxIsOpen(false);
  };

  const handlePubNubMessage = (_msg) => {
    dispatchInvalidateCacheTagsEvent([{ type: LISTING_TAG, id: listingId }]);
  };

  const myCallButton = (listing) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: 'calc(50% - 70px)',
        }}
      >
        {callButton({ listing, currentUser })}
      </Box>
    );
  };

  useEffect(() => {
    if (isSuccess) { setOpen(true); }
  }, [listing]);

  useEffect(() => {
    if (!listingId) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [listingId]);

  useEffect(() => {
    if (!listing) return false;

    pubNubClient.addListener({ message: handlePubNubMessage });
    pubNubClient.subscribe({ channels: [listing.push_channel] });

    return () => {
      if (!pubNubClient || !listing) return;

      pubNubClient.removeListener(handlePubNubMessage);
      pubNubClient.unsubscribe({ channels: [listing.push_channel] });
    };
  }, [listing]);

  return (
    <Box>
      <ListingDetailDrawer
        open={open}
        onClose={onDrawerClose}
        listing={listing}
        onFeedbackClick={onFeedbackClick}
        onReportListingClick={onReportListingClick}
        currentUser={currentUser}
        onImageClick={onImageClick}
        voicePortalPhoneNumber={voicePortalPhoneNumber}
        isOwnedByViewer={listing?.is_owned_by_viewer}
      />
      {lightboxIsOpen && (
        <Box>
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 9000 } }}
            mainSrc={listing.photo_url}
            onCloseRequest={onLightboxClose}
            imageTitle={listing.title}
            toolbarButtons={[myCallButton(listing)]}
          />
        </Box>
      )}
    </Box>
  );
};

export default ListingDetailDrawerContainer;
