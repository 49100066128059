// Simple one-level deep test for array inequality
export const arraysNotEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return true;
  for (let i = 0; i < arr1.length; i++) if (arr1[i] !== arr2[i]) return true;
  return false;
};

export const notEqual = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return arraysNotEqual(a, b);
  }
  return a !== b;
};

export const equal = (a, b) => !notEqual(a, b);
