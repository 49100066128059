import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BoxBreadcrumb = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.customPurple1.main,
  color: theme.palette.customPurple1.light,
  height: '44px',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(1),
  position: 'sticky',
  top: 0,
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  fontWeight: '300',
  a: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '@media (max-width: 900px)': {
    height: '36px',
    fontSize: '0.875em',
    paddingLeft: theme.spacing(2),
  },
}));

const CategoryBreadcrumbs = ({ breadcrumbs }) => {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <BoxBreadcrumb
      data-test-id="box-breadcrumb"
      dangerouslySetInnerHTML={{ __html: breadcrumbs }}
    />
  );
};

CategoryBreadcrumbs.defaultProps = {
  breadcrumbs: '',
};

CategoryBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.string,
};

export default CategoryBreadcrumbs;
