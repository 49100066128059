/* WARNING!!!  WARNING!!!  WARNING!!!  WARNING!!!  WARNING!!!  WARNING!!!  WARNING!!!
  This is JS-based security, and a clever user can absolutely tamper with this.
  Any page or functionality that is protected by this, should also have backend protection from the Security::SecondaryAuth module.
*/

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Cookie from 'app/util/cookie';

import DialogsContext from 'app/contexts/DialogsContext';
import SecondaryAuthDialog from 'app/components/modals/SecondaryAuthDialog';
import {
  useGetSecondaryAuthQuery,
  useCreateSecondaryAuthMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

// If we session request params - if we try to redirect you to a page you came from we shouldn't
const SecondaryAuthDialogContainer = ({ navMethod, hideCancel }) => {
  const { closeDialog } = useContext(DialogsContext);

  const goToDestination = () => {
    closeDialog();
    navMethod();
    return null;
  };

  // pass straight through if they have a cookie; if they're lying, they'll be redirected
  useEffect(() => {
    if (Cookie.hasCookie('valid_secondary_auth')) {
      goToDestination();
    }
  }, []);

  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;

  // this will fail and redirect them to login, while setting a proper return_to param
  useEffect(() => {
    if (!currentUser) {
      goToDestination();
    }
  }, [currentUser]);

  const { data: secondaryAuth, isLoading: secondaryAuthLoading } = useGetSecondaryAuthQuery();
  const [createSecondaryAuthResult, setCreateSecondaryAuthResult] = useState('');

  const [createSecondaryAuth] = useCreateSecondaryAuthMutation();

  // callback for the Continue button
  const checkPassword = (password) => {
    createSecondaryAuth({ password })
      .then((result) => {
        setCreateSecondaryAuthResult(result.data.message);
      });
  };

  const createSecondaryAuthCookie = () => {
    Cookie.setCookie('valid_secondary_auth', 'true', 1, { path: '/' });
  };

  // pass straight through if they have the auth
  useEffect(() => {
    if (secondaryAuth) {
      createSecondaryAuthCookie();
      goToDestination();
    }
  }, [secondaryAuth]);

  // make the form invisible, briefly
  if (secondaryAuthLoading) return null;

  return (
    <SecondaryAuthDialog
      checkPassword={checkPassword}
      closeDialog={closeDialog}
      hideCancel={hideCancel}
      createSecondaryAuthResult={createSecondaryAuthResult}
    />
  );
};

SecondaryAuthDialogContainer.defaultProps = {
  navMethod: () => {},
  hideCancel: false,
};

SecondaryAuthDialogContainer.propTypes = {
  navMethod: PropTypes.func,
  hideCancel: PropTypes.bool,
};

export default SecondaryAuthDialogContainer;
