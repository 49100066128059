import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const FileListItemOptions = ({ file }) => (
  /* eslint-disable  no-console */
  <IconButton size="small" onClick={() => console.log(`Open menu for file ${file.id}`)}>
    <MoreVertIcon />
  </IconButton>
);

FileListItemOptions.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
