import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'app/util/cookie';

import DialogsContext from 'app/contexts/DialogsContext';
import SecondaryAuthDialogContainer from 'app/containers/modals/SecondaryAuthDialogContainer';

const BehindSecondaryAuth = ({ children }) => {
  const { openDialog } = useContext(DialogsContext);
  const [validated, setValidated] = useState(false);
  const [dialogShown, setDialogShown] = useState(false);
  const intervalIdRef = useRef(null);

  // Memoized navMethod to update state when the dialog completes secondary auth
  const handleNavMethod = useCallback(() => {
    setValidated(true);
    setDialogShown(false);
  }, []);

  // Memoized function to open the secondary auth dialog
  const showDialog = useCallback(() => {
    openDialog({
      component: SecondaryAuthDialogContainer,
      zIndex: 1000000,
      props: {
        destination: '',
        navMethod: handleNavMethod,
        hideCancel: true,
      },
    });
    setDialogShown(true);
  }, [openDialog, handleNavMethod]);

  // Poll for cookie changes. When "validated" changes, clear and restart the interval.
  useEffect(() => {
    const checkCookie = () => {
      const hasValidAuth = Cookie.hasCookie('valid_secondary_auth');
      if (hasValidAuth !== validated) {
        setValidated(hasValidAuth);
      }
    };

    // Run immediately, then start polling
    checkCookie();
    intervalIdRef.current = setInterval(checkCookie, 2500);

    return () => clearInterval(intervalIdRef.current);
  }, [validated]);

  // When not validated and no dialog is shown, display the secondary auth dialog.
  useEffect(() => {
    if (!validated && !dialogShown) {
      showDialog();
    }
  }, [validated, dialogShown, showDialog]);

  return validated ? children : null;
};

BehindSecondaryAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BehindSecondaryAuth;
