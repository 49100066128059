import React from 'react';
import PropTypes from 'prop-types';

import { authenticatedFunction } from 'app/helpers/userHelper';

const AuthenticatedLink = ({ onClick, children, currentUser, returnUrl, overrideLoginUrl, ...aProps }) => {
  // eslint-disable-next-line react/prop-types
  const authenticatedOnClick = authenticatedFunction({
    returnUrl,
    currentUser,
    overrideLoginUrl,
    action: onClick,
  });

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable react/jsx-props-no-spreading */
    <a {...aProps} onClick={authenticatedOnClick}>
      {children}
    </a>
  );
};

AuthenticatedLink.defaultProps = {
  currentUser: null,
  returnUrl: null,
  overrideLoginUrl: null,
};

AuthenticatedLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object,
  returnUrl: PropTypes.string,
  overrideLoginUrl: PropTypes.string,
};

export default AuthenticatedLink;
