import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { userInitials } from 'app/helpers/userInitials';

// NOTE: Seeker should be an object with a login attribute
const SeekerAvatar = ({ seeker, sx }) => {
// This is based off of the css written in nfp-skeleton1.scss for menu-initials
  const styles = {
    marginLeft: 0,
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#999',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#63177a',
    },
    ...sx,
  };
  return (
    <Box
      sx={styles}
    >
      {userInitials(seeker)}
    </Box>
  );
};

SeekerAvatar.defaultProps = {
  sx: {},
};

SeekerAvatar.propTypes = {
  seeker: PropTypes.object.isRequired,
  sx: PropTypes.object,
};

export default SeekerAvatar;
