import React from 'react';
import { PropTypes } from 'prop-types';

import { userInitials } from 'app/helpers/userInitials';
import SeekerAvailabilityMenuContainer from 'app/containers/SeekerAvailabilityMenuContainer';
import SharedCenterNav from './SharedCenterNav';

const TransientSeekerNav = ({
  currentUser,
  navigateTo,
  addMoneyUrl,
  balance,
}) => {
  const display = { display: 'block' };

  return (
    <div className="more-menu more-menu-in-nav" style={{ display }}>
      <ul className="nav-top paddingtop0">
        <li>
          <div className="menu-initials">
            {userInitials(currentUser)}
          </div>
        </li>
        <li className="margintopminus10">
          <div data-test-id="menu-user-link">
            {currentUser?.login}
          </div>
          <div
            className="small-text"
            style={{ fontColor: '#999', fontSize: '0.875em' }}
          >
            Balance:&nbsp;
            <span>
              $
              {balance}
            </span>
          </div>
        </li>
        <li>
          <SeekerAvailabilityMenuContainer />
        </li>
        <SharedCenterNav
          balance={balance}
          closeAndNavigateTo={navigateTo}
          currentUser={currentUser}
          addMoneyUrl={addMoneyUrl}
        />
      </ul>
    </div>
  );
};

TransientSeekerNav.defaultProps = {
  currentUser: null,
  balance: null,
};

TransientSeekerNav.propTypes = {
  currentUser: PropTypes.object,
  navigateTo: PropTypes.func.isRequired,
  addMoneyUrl: PropTypes.string.isRequired,
  balance: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default TransientSeekerNav;
