import React from 'react';
import {
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

// This field flips between being a select or a field with a max length of 50 characters
// and depends on whether the country that we have has states or not.
const StateField = ({ value, states, onChange }) => {
  const isEmpty = value === '';

  if (!states) {
    return (
      <TextField
        label="State"
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        error={isEmpty}
        helperText={isEmpty ? 'State can\'t be blank' : null}
      />
    )
  }
  const currentValue = states.find((state) => state[0] === value);

  return (
    <Autocomplete
      id="state-select"
      sx={{ width: 300 }}
      options={states}
      value={currentValue || ''}
      getOptionLabel={(option) => {
        if (option.length === 0) return '';
        return option[1];
      }}
      onChange={(e, value) => onChange(value[0])}
      autoHighlight
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option[1]}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a state"
          error={isEmpty}
          helperText={isEmpty ? 'State can\'t be blank' : null}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
};

StateField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  states: PropTypes.array,
}

StateField.defaultProps = {
  states: [],
}

export default StateField;