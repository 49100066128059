import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Box, Checkbox } from '@mui/material';
import { FULL_DATE_FORMAT, timestampInTimezone } from 'app/helpers/timestampHelpers';
import { useFilePlayerContext } from '../FilePlayerContext';
import { FileListItemOptions } from './FileListItemOptions';

const FileListItem = ({ file }) => {
  if (!file || typeof file !== 'object') {
    return null;
  }

  const { currentUser, fileSelection } = useFilePlayerContext();
  const { isChecked, toggleItem } = fileSelection;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={2} sx={{ flex: 1, minWidth: 0 }}>
        <Checkbox
          checked={isChecked(file.id)}
          onChange={() => toggleItem(file.id)}
        />
        {file.thumbnailUrl ? (
          <Box
            component="img"
            src={file.thumbnailUrl}
            alt={file.fileName}
            sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '4px' }}
          />
        ) : (
          <Box sx={{ width: 40, height: 40, backgroundColor: 'grey.300', borderRadius: '4px' }} />
        )}
        <Stack sx={{ flex: 1, minWidth: 0 }}>
          <Typography
            variant="body1"
            sx={{
              flexGrow: 1,
              minWidth: 0,
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '0.5ch',
            }}
          >
            {file.fileName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {file.contentType?.split('/')[0]}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ flexShrink: 0, whiteSpace: 'nowrap' }}
      >
        {timestampInTimezone(file.acquiredAt, currentUser?.canonicalIanaTimezone, FULL_DATE_FORMAT)}
      </Typography>
      <FileListItemOptions file={file} />
    </Stack>
  );
};

FileListItem.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    contentType: PropTypes.string,
    acquiredAt: PropTypes.string,
  }).isRequired,
};

export const FileListGroup = ({ files }) => (
  <Stack spacing={2} mt={2}>
    {files.map((file) => (
      <FileListItem key={file.id} file={file} />
    ))}
  </Stack>
);

FileListGroup.propTypes = {
  files: PropTypes.arrayOf(FileListItem.propTypes.file).isRequired,
};
