import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconLeaveFeedback = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="32px" height="32px" viewBox="0 0 32 32">
    {/* Your SVG Path */}
    <path d="M16.4395678,0 L24.6253474,9.31654685 L24.542712,11.3535531 L19.2904264,24.3318105 L20.0038911,30.1286646 L24.6280978,26.0033957 L24.6280978,31.0038997 L0,31.0038997 L0,0 L16.4395678,0 Z M16.1270285,25.1282857 L4.00050319,25.1282857 L4.00050319,27.0033965 L15.3769341,27.0033965 L16.1270285,25.1282857 Z M17.8772486,21.0026417 L4.00050319,21.0026417 L4.00050319,22.8780026 L17.1271543,22.8780026 L17.8772486,21.0026417 Z M19.6274688,17.1272793 L4.00050319,17.1272793 L4.00050319,19.0023901 L18.8773744,19.0023901 L19.6274688,17.1272793 Z M21.3776889,13.0016354 L4.00050319,13.0016354 L4.00050319,14.8769962 L20.5650867,14.8769962 L21.3776889,13.0016354 Z M16.3770599,2.25028304 L16.3770599,9.12627291 L22.5028304,9.12627291 L16.3770599,2.25028304 Z" id="Combined-Shape" />
    <polygon id="pencil" points="30.5038368 8.00268159 23.508957 25.4146217 20.8788762 27.6285252 20.5014537 24.439249 27.5050846 6.87591486" />
    <path d="M30.8684827,7.2419609 L28.0142487,6.23920978 C27.8188491,5.96617543 28.914987,3.72351835 29.010374,3.25295916 L32,4.43698309 L30.8684827,7.2419609 Z" id="eraser" />
  </SvgIcon>
);

export default IconLeaveFeedback;
