import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery } from '@mui/material';

import CategoryBreadcrumbs from 'app/components/categories/CategoryBreadcrumbs';
import SortSelector from 'app/components/shared/SortSelector';

const CustomStickyTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '&.MuiTabs-root': {
      '& .MuiButtonBase-root': {
        padding: '0px 15px',
        minWidth: 'auto',
      },
      '& .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    },
  },
}));

const StickyHeader = ({
  breadcrumbs,
  tabs,
  currentTab,
  handleTabChange,
  sorts,
  activeSort,
  handleSortChange,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const tabLabels = tabs.map((tab) => {
    if (isMobile) {
      if (tab.label === 'Live Phone Sex') return 'Phone Sex';
      if (tab.label === 'Recorded Phone Sex') return 'Recorded';
    }
    return tab.label;
  });

  return (
    <Box
      sx={{
        position: 'sticky',
        top: '0',
        zIndex: 2,
      }}
    >
      <CategoryBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container sx={{ backgroundColor: 'var(--body_background)', borderBottom: '1px solid var(--color_ddd)' }}>
        <Grid item xs={9} sm={9}>
          <CustomStickyTabs data-test-id="sticky-tabs" value={currentTab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab key={tab.value} label={tabLabels[index]} value={tab.value} data-test-id={`tab-${tab.value}`} />
            ))}
          </CustomStickyTabs>
        </Grid>
        <Grid item xs={3} sm={3} container justifyContent="flex-end">
          {currentTab !== 3 && (
            <SortSelector
              sorts={sorts}
              activeSort={activeSort}
              handleSortChange={handleSortChange}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

StickyHeader.propTypes = {
  breadcrumbs: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  currentTab: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  sorts: PropTypes.array.isRequired,
  activeSort: PropTypes.number.isRequired,
  handleSortChange: PropTypes.func.isRequired,
};

export default StickyHeader;
