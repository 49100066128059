import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import DialogsContext from 'app/contexts/DialogsContext';
import { useFavoriteListingMutation } from 'app/api/mainApi';
import { authenticatedFunction } from 'app/helpers/userHelper';

const ListingViewerDetailKebabMenu = ({ listing, closeDrawer, currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { openDialog } = useContext(DialogsContext);
  const [favoriteListing, { isSuccess: favoriteSuccess }] = useFavoriteListingMutation();
  const whereWeAt = window.location.pathname + window.location.search;

  useEffect(() => {
    // this is not a completely react-based page, so we need to reload to update the UI
    if (favoriteSuccess) {
      document.location.reload();
    }
  }, [favoriteSuccess]);

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClick = (evt) => {
    evt.preventDefault();
    setAnchorEl(evt.currentTarget);
  };

  const onChatClick = () => {
    const action = () => { window.location = `/chat/${listing.advisorSlug}`; };
    authenticatedFunction({ currentUser, action, returnUrl: whereWeAt })();
  };

  const onSendTributeClick = () => {
    setAnchorEl(null);
    closeDrawer();

    const action = () => {
      openDialog({
        component: TributeWorkflowContainer,
        props: { advisorLogin: listing.login },
      });
    };

    authenticatedFunction({ currentUser, action, returnUrl: whereWeAt })();
  };

  const onSendMailClick = () => {
    const action = () => {
      window.location = `messages/compose?message[recipients_list]=${listing.login}&return_to=${whereWeAt}`;
    };

    authenticatedFunction({ currentUser, action, returnUrl: whereWeAt })();
  };

  const oneOfFavoritesClick = () => {
    const action = () => {
      window.location = '/account/favorite';
    };

    authenticatedFunction({ currentUser, action, returnUrl: whereWeAt })();
  };

  const onAddToFavoritesClick = async () => {
    const action = async () => {
      await favoriteListing({ listing: listing.id });
    };

    authenticatedFunction({ currentUser, action, returnUrl: whereWeAt })();
  };

  return (
    <>
      <a
        onClick={onClick}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
      >
        <MoreVert sx={{ color: 'var(--color_gray_nine)', mt: 1, display: 'inline-block' }} />
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        data-testid="menu-popover"
      >
        <MenuItem onClick={onChatClick}>Chat</MenuItem>
        <MenuItem onClick={onSendTributeClick}>Send Tribute</MenuItem>
        <MenuItem onClick={onSendMailClick}>Send Mail</MenuItem>
        {listing.isFavoriteForViewer && (
          <MenuItem onClick={oneOfFavoritesClick}>One of Favorites</MenuItem>
        )}
        {!listing.isFavoriteForViewer && (
          <MenuItem onClick={onAddToFavoritesClick}>Add to Favorites</MenuItem>
        )}
      </Menu>
    </>
  );
};

ListingViewerDetailKebabMenu.defaultProps = {
  currentUser: null,
};

ListingViewerDetailKebabMenu.propTypes = {
  listing: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default ListingViewerDetailKebabMenu;
