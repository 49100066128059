import React from 'react';

export const starRating = (rating) => (
  <div className="stars" title={`${rating} stars`}>
    <div className={(rating - 0 > 0) ? 'on' : 'off'}>&nbsp;</div>
    <div className={(rating - 1 > 0) ? 'on' : 'off'}>&nbsp;</div>
    <div className={(rating - 2 > 0) ? 'on' : 'off'}>&nbsp;</div>
    <div className={(rating - 3 > 0) ? 'on' : 'off'}>&nbsp;</div>
    <div className={(rating - 4 > 0) ? 'on' : 'off'}>&nbsp;</div>
  </div>
);

// translated from the ruby in application_helper.rb#call_button_link
/* eslint-disable camelcase, react/no-invalid-html-attribute, react/jsx-props-no-spreading */
// NOTE: onClickAction typically uses the react router to navigate users.
export const callButton = ({ listing, onClickAction }) => {
  const onClick = (e) => {
    e.preventDefault();

    if (onClickAction) {
      onClickAction(e);
    } else {
      window.location.href = e.currentTarget.href;
    }
  };

  const defaultLinkOptions = {
    title: listing.availability_status_text_for_hover,
    'click-listing-data': listing.id,
  };

  const messageUrl = `/messages/compose?message%5Brecipients_list%5D=${listing.login}`;
  const callUrl = `/call/listing/${listing.id}`;
  const chatUrl = `/chat/${listing.advisor_slug}`;

  if (listing.recorded) {
    return (
      <a href={callUrl} {...defaultLinkOptions} onClick={onClick}>
        <div className="call-button listen-now">
          <span>Listen Now</span>
        </div>
      </a>
    );
  }

  // NOTE: We won't use an AuthenticatedLink here. We don't use the react router to navigate to chat since it is an
  // entirely different app.  We rely on the rails router to handle user authentication states.
  if (listing.inherentlyAway) {
    return (
      <a href={messageUrl} {...defaultLinkOptions}>
        <div className="call-button send-mail-away">
          <span>Away - Mail Only</span>
        </div>
      </a>
    );
  }

  if (listing.live) {
    const { busy, can_text, away, taking_call } = listing;

    // NOTE: We won't use an AuthenticatedLink here. We rely on the rails router to handle user authentication states
    // since mail still lives in Rails.
    if (busy) {
      return (
        <div rel={`user-button-${listing.user_id}`} data-type="busy">
          <a href={messageUrl} {...defaultLinkOptions}>
            <div className="call-button send-mail-busy">
              <span>Busy - Mail Only</span>
            </div>
          </a>
        </div>
      );
    }

    if (taking_call) {
      return (
        <div
          rel={`user-button-${listing.user_id}`}
          data-type="taking"
          data-user-id={listing.user_id}
        >
          <a href={callUrl} {...defaultLinkOptions} onClick={onClick}>
            <div className="call-button call-now">
              <span>Call Now</span>
            </div>
          </a>
        </div>
      );
    }

    if (away && !can_text) {
      // NOTE: We won't use an AuthenticatedLink here. We rely on the rails router to handle user authentication states
      // since mail still lives in Rails.
      return (
        <div rel={`user-button-${listing.user_id}`} data-type="away">
          <a href={messageUrl} {...defaultLinkOptions}>
            <div className="call-button send-mail-away">
              <span>Away - Mail Only</span>
            </div>
          </a>
        </div>
      );
    }

    if (away && can_text) {
      // NOTE: We won't use an AuthenticatedLink here. We don't use the react router to navigate to chat since it is an
      // entirely different app.  We rely on the rails router to handle user authentication states.
      return (
        <div rel={`user-button-${listing.user_id}`} data-type="away_chat">
          <a href={chatUrl} {...defaultLinkOptions}>
            <div className="call-button send-text-away">
              <span>Away - Send Text</span>
            </div>
          </a>
        </div>
      );
    }
  }

  return (<div> </div>);
};
