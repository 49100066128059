import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import ModalsContext from 'app/contexts/ModalsContext';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import AllowPushNotificationsDialogContainer from 'app/containers/dialogs/AllowPushNotificationsDialogContainer';
import SecondaryAuthDialogContainer from 'app/containers/modals/SecondaryAuthDialogContainer';
import LeaveFeedbackModalContainer from 'app/containers/modals/LeaveFeedbackModalContainer';
import BuyGoodyBagModalContainer from 'app//containers/BuyGoodyBagModalContainer';

import {
  currentSystemPushPermissions,
} from 'app/helpers/pushNotificationsHelpers';

const WindowEventsListener = ({ suppressPushNotificationDialog }) => {
  const { openModal } = useContext(ModalsContext);
  const { openDialog } = useContext(DialogsContext);

  const searchParams = new URLSearchParams(window.location.search);
  const authTo = searchParams.get('auth_to');
  const httpMethod = (searchParams.get('http_method') || 'GET').toUpperCase();
  searchParams.delete('Submit');
  searchParams.delete('http_method');
  searchParams.delete('auth_to');

  const modalList = {
    LeaveFeedbackModalContainer,
  };

  const reminderIsNotSetOrIsExpired = () => {
    const reminder = localStorage.getItem('pn-remind-later');

    if (!reminder) return true;

    if (reminder !== 'never') {
      const date = new Date(parseInt(reminder));
      const now = new Date();
      if (now > date) {
        return true;
      }
    }

    return false;
  };

  const handleOpenTributeModalEvent = (evt) => {
    // if the tribute is for the current user, don't open the modal
    if (evt.detail.advisorLogin === evt.detail.currentUserLogin) {
      return false;
    }

    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: evt.detail.advisorLogin, amount: evt.detail.amount },
    });

    return true;
  };

  const handleOpenBuyGoodyBagEvent = (evt) => {
    openModal({
      component: BuyGoodyBagModalContainer,
      props: {
        goodyBagId: evt.detail.goodyBagId,
        advisorLogin: evt.detail.advisorLogin,
      },
    });
  };

  const handleOpenSecondaryAuthDialogEvent = (evt) => {
    // allow this to be called from an event listener, or
    //  manually in which case it will use a query param
    const destination = evt ? evt.detail.destination : authTo;

    const navMethod = () => {
      // for simple GET requests, just navigate to the destination
      if (httpMethod === 'GET') {
        window.location.href = destination;
        return;
      }

      // for all other HTTP Verbs, create a form and submit it,
      //  and include a _method param if necessary
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = destination;

      if (httpMethod !== 'POST') {
        const methodInput = document.createElement('input');
        methodInput.type = 'hidden';
        methodInput.name = '_method';
        methodInput.value = httpMethod;
        form.appendChild(methodInput);
      }

      searchParams.forEach((value, key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    };

    openDialog({
      component: SecondaryAuthDialogContainer,
      zIndex: 1000000, // gotta go pretty high to get on top of some of our other dialogs/modals
      props: { navMethod },
    });
  };

  // NOTE:
  // You will need your element to incldue a data attribute called data-open-plus-modal to trigger this code
  // EX: data-open-plus-modal="your-modal-name"
  // See: app/helpers/account_helper.rb
  const handleOpenPlusModalEvent = (evt) => {
    const modal = modalList[evt.detail.openPlusModal];
    if (modal) {
      openModal({
        component: modal,
        props: evt.detail,
      });
    }
  };

  const OpenAllowPushNotificationsDialog = () => {
    openDialog({ component: AllowPushNotificationsDialogContainer });
  };

  const eventListeners = [
    ['openTributeModal', handleOpenTributeModalEvent],
    ['openSecondaryAuthDialog', handleOpenSecondaryAuthDialogEvent],
    ['openBuyGoodyBag', handleOpenBuyGoodyBagEvent],
    ['openPlusModal', handleOpenPlusModalEvent],
  ];

  useEffect(() => {
    if (window.featurePushNotifications && window.currentUserId) {
      const currentSystemPermissions = currentSystemPushPermissions();
      if (currentSystemPermissions === 'default') {
        setTimeout(() => {
          if (reminderIsNotSetOrIsExpired() && !suppressPushNotificationDialog) {
            OpenAllowPushNotificationsDialog();
          }
        }, 2000);
      }
    }

    eventListeners.forEach(([eventName, listener]) => {
      window.addEventListener(eventName, listener);
    });
    if (authTo) {
      handleOpenSecondaryAuthDialogEvent();
    }

    // cleanup function
    return () => {
      eventListeners.forEach(([eventName, listener]) => {
        window.removeEventListener(eventName, listener);
      });
    };
  }, []);

  // we probably will never need
  // to render any UI stuff
  return null;
};

WindowEventsListener.defaultProps = {
  suppressPushNotificationDialog: false,
};

WindowEventsListener.propTypes = {
  suppressPushNotificationDialog: PropTypes.bool,
};

export default WindowEventsListener;
