import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import BehindSecondaryAuth from 'app/containers/BehindSecondaryAuth';
import PersonalInfoPages from './PersonalInfoPages';
import { PERSONAL_INFO_TABS } from '../constants';

const PersonalInfoLayout = () => {
  const location = useLocation();
  const currentTab = PERSONAL_INFO_TABS.find(tab => location.pathname.includes(tab.path));

  return (
    <BehindSecondaryAuth>
      <PersonalInfoPages title={currentTab?.title} index={currentTab?.index}>
        <Outlet />
      </PersonalInfoPages>
    </BehindSecondaryAuth>
  );
};

export default PersonalInfoLayout;
