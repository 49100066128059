import React, { useContext } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { featureFlag } from 'app/util/featureFlag';

import {
  useGetPublicPackagesForAdvisorsQuery,
  useGetGoodyBagsPresenceQuery,
} from 'app/api/mainApi';

import TabContent from 'app/components/TabContent';

// TODO: can we prefix all of these containers with "Profile" or "Advisor" or something?
// this is confusing to work on with these generic names
import AdvisorListingsContainer from 'app/containers/AdvisorListingsContainer';
import GoodyBagsContainer from 'app/containers/GoodyBagsContainer';
import PackagesContainer from 'app/containers/PackagesContainer';
import StorefrontFeedContainer from 'app/containers/StorefrontFeedContainer';
import ProfileContext from 'app/contexts/ProfileContext';
import useDarkerBodyClass from 'app/hooks/useDarkerBodyClass';

const ProfileTabsPos = styled(Box)(() => ({
  '&.MuiBox-root': {
    borderTop: '1px solid var(--color_eee)',
  },
}));

const CustomTabs = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    display: 'flex',
    '& .MuiTab-root': {
      flexGrow: '1',
      minWidth: 'auto',
    },
    '& .MuiButtonBase-root': {
      padding: '0px',
    },
  },
}));

const MarginBox = styled(Box)(() => ({
  '&.MuiBox-root': {
    margin: '-24px -24px 0 -24px',
  },
}));

const ProfileTabsWithContent = React.memo(({
  stickyNavVisible,
  advisor,
  advisorLoading,
  viewingSelf,
  currentUser,
}) => {
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const viewingStorefront = useMatch(`/${encodedAdvisorLogin}`);
  const { tab } = useContext(ProfileContext) || {};
  const anchor = window.location.hash.substring(1);
  const navigate = useNavigate();

  let initialTab;
  switch (tab || anchor) {
  case 'gb':
  case 'goodies':
    initialTab = 1;
    break;
  case 'feed':
    initialTab = 2;
    break;
  case 'packages':
    initialTab = 3;
    break;
  default:
    initialTab = 0;
  }

  const [selectedTab, setSelectedTab] = React.useState(initialTab);
  const [reloadKey, setReloadKey] = React.useState(0);
  const { path } = useParams();

  const {
    data: packages,
    isLoading: packagesLoading,
  } = useGetPublicPackagesForAdvisorsQuery({ advisorIds: advisor?.id });

  const {
    data: goodyBagsPresentResult,
  } = useGetGoodyBagsPresenceQuery({ login: advisor?.login }, { skip: !(advisor?.login) });

  const goodyBagsPresent = (initialTab === 1) || goodyBagsPresentResult?.goody_bags_present;
  const headingSource = viewingStorefront ? 'category' : '';

  const handleTabClick = (tabIndex) => (_e) => {
    if (tabIndex === selectedTab) {
      setReloadKey(prev => prev + 1);
      document.getElementById('body-content').scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleChange = (_e, newValue) => {
    setSelectedTab(newValue);

    // this will change the URL so that a refresh or a bookmark won't lose the current tab
    const basePath = `/${advisor?.login}`;

    const fromProfilePage = window.location.pathname.startsWith('/profile');
    switch (newValue) {
    case 0:
      if (!fromProfilePage) {
        navigate(basePath);
      }
      break;
    case 1:
      if (!fromProfilePage) {
        navigate(`${basePath}/goodies`);
      }
      break;
    case 2:
      navigate(`${basePath}/feed`);
      break;
    case 3:
      navigate(`${basePath}/packages`);
      break;
    default:
      navigate(basePath);
    }
  };

  const navContainerStickyCSS = {
    position: 'sticky',
    top: '43px',
    padding: '0px',
    zIndex: '9',
    backgroundColor: 'var(--body_background)',
  };

  const handleStickyNavCSS = () => {
    const baseCSS = { borderBottom: 1, borderColor: 'divider' };

    if (stickyNavVisible) {
      return { ...navContainerStickyCSS, ...baseCSS };
    } else {
      return baseCSS;
    }
  };

  useDarkerBodyClass(); // This applies the body-content-darkbg class

  return (
    <ProfileTabsPos>
      <Box sx={handleStickyNavCSS()}>
        <CustomTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="Profile Tabs"
        >
          <Tab label="Phone Sex" id="simple-tab-0" aria-controls="simple-tabpanel-0" onClickCapture={handleTabClick(0)} />
          <Tab
            label="Goodies"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
            disabled={!goodyBagsPresent}
            sx={goodyBagsPresent ? {} : { display: 'none' }}
            onClickCapture={handleTabClick(1)}
          />
          {featureFlag.enabled('FEED_TO_STOREFRONT_20571') && advisor?.onboarding_complete && (
            <Tab label="Feed" id="simple-tab-2" aria-controls="simple-tabpanel-2" onClickCapture={handleTabClick(2)} />
          )}
          {featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && !packagesLoading && packages?.length && (
            <Tab label="Packages" id="simple-tab-3" aria-controls="simple-tabpanel-3" onClickCapture={handleTabClick(3)} />
          )}
        </CustomTabs>
      </Box>

      <TabContent value={selectedTab} index={0}>
        <AdvisorListingsContainer advisor={advisor} headingSource={headingSource} />
      </TabContent>

      <TabContent value={selectedTab} index={1}>
        <GoodyBagsContainer
          advisor={advisor}
          advisorLoading={advisorLoading}
          selectedGoodyBag={path}
          viewingSelf={viewingSelf}
          currentUser={currentUser}
          reloadKey={reloadKey}
          titleIsLink
        />
      </TabContent>

      <TabContent value={selectedTab} index={2}>
        <MarginBox>
          <StorefrontFeedContainer advisor={advisor} currentUser={currentUser} path={path} />
        </MarginBox>
      </TabContent>

      <TabContent value={selectedTab} index={3}>
        <PackagesContainer
          advisor={advisor}
          selectedPackage={path}
        />
      </TabContent>
    </ProfileTabsPos>
  );
});

ProfileTabsWithContent.defaultProps = {
  stickyNavVisible: false,
  advisor: {},
  advisorLoading: true,
  currentUser: {},
};

ProfileTabsWithContent.propTypes = {
  stickyNavVisible: PropTypes.bool,
  advisor: PropTypes.object,
  advisorLoading: PropTypes.bool,
  viewingSelf: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default ProfileTabsWithContent;
