import React from 'react';

import { Grid } from '@mui/material';

import CircularProgress from 'app/components/shared/CircularProgress';

const FullPageLoadingSpinner = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default FullPageLoadingSpinner;
