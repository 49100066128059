import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconSubscriptions = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="32px" height="32px" viewBox="0 0 32 32">
    {/* Your SVG Path */}
    <path d="M7.21644966e-15,16.2153996 L3.13572423,16.2700328 C3.16647724,17.7583304 3.49831596,19.2391131 4.14217959,20.7104318 C5.20325481,23.1350975 6.9352145,25.0377113 9.28349045,26.4160596 C13.7030747,29.0101756 18.8443855,28.575215 22.2357476,26.6452755 C21.0187403,25.4884338 19.8020135,24.3319356 18.5454222,23.13754 L27.8768664,23.13754 L27.8768664,32.0000191 C27.7405661,31.8641535 27.6384009,31.7582471 27.5316684,31.6566151 C26.5878227,30.7577653 25.6412125,29.8615106 24.7012931,28.9589207 C24.5913955,28.8533959 24.5185577,28.8453814 24.384461,28.9248017 C22.5490541,30.0120314 20.5628834,30.7316989 18.4243465,31.0259852 C14.3171072,31.5912775 10.5061068,30.8079516 7.06402271,28.5900992 C3.74421629,26.4510564 1.54950857,23.5151012 0.531182461,19.8211994 C0.197811515,18.6118996 0.0216401383,17.40953 7.23900107e-15,16.2153996 Z" id="Combined-Shape" />
    <path d="M4.12285636,0 C4.18631894,0.0577810218 4.2363999,0.101746502 4.28459781,0.147582042 C5.29054391,1.10413655 6.2980926,2.05916448 7.29943125,3.02006975 C7.40900838,3.12521289 7.48400962,3.13677672 7.61806632,3.05739461 C9.48332148,1.95321989 11.5027859,1.23152958 13.6777417,0.945105167 C17.9026913,0.388705222 21.7896545,1.25362682 25.267877,3.60971095 C28.5037077,5.8016115 30.5943271,8.76393838 31.5547997,12.4340255 C31.8849193,13.6955214 32.0227317,14.974528 31.9940328,16.2660796 L28.8737957,16.2118601 C28.9189936,14.3671394 28.505049,12.5392819 27.6094621,10.7326149 C26.4663343,8.42660255 24.7010407,6.64458852 22.377685,5.37184129 C17.9576201,2.95053406 12.9139871,3.52368822 9.7727894,5.33676814 C10.9851091,6.48887744 12.1973487,7.64094858 13.4095882,8.79298155 C13.3990512,8.81019373 13.3884741,8.82736775 13.377897,8.84454176 L4.12285636,8.84454176 L4.12285636,0 Z" id="Combined-Shape" />
    <path d="M7.99772626,16.4283959 C8.42283396,16.0069814 8.85114165,15.5889731 9.27194165,15.1631432 C9.59975703,14.8314867 9.91821857,14.4901794 10.2710186,14.1216861 C11.3616647,15.1792908 12.4356955,16.220874 13.5369263,17.2887602 C15.850957,14.8583573 18.1520647,12.4415789 20.4768032,10.0000114 C21.245234,10.7812777 22.0060955,11.5548486 22.766957,12.3284196 L22.766957,12.3914961 C22.6823416,12.4627726 22.5904032,12.5267323 22.5140955,12.6063349 C19.5975416,15.6489589 16.6822801,18.6929075 13.7683724,21.7381807 C13.6978493,21.8118541 13.6444955,21.9028735 13.5726186,22.0000114 C13.4417878,21.8773275 13.3584647,21.8031495 13.2793263,21.724493 C11.5795724,20.0349874 9.88037242,18.3449141 8.18055703,16.6554715 C8.12246472,16.5977565 8.05877242,16.5460337 7.99772626,16.4914725 C7.99772626,16.470468 7.99772626,16.4494004 7.99772626,16.4283959" id="Fill-1" />
  </SvgIcon>
);

export default IconSubscriptions;
