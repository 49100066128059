import React, { useState } from 'react';
import { FilePlayerProvider } from '../FilePlayerContext';
import { useGetFilePlayerFilesQuery } from 'app/api/assetApi';
import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import { FilesTab } from './FilesTab';
import { FileSearchBox } from './FileSearchBox';
import { FilePlayerTabs } from './FilePlayerTabs';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { useFileSelection } from '../hooks/useFileSelection';
import { FileActionBar } from './FileActionBar';

export const FilePlayerPage = () => {
  const [page, setPage] = useState(1);
  const filesQuery = useGetFilePlayerFilesQuery({ page });
  const appConfig = useGetAppConfigQuery().data;
  const currentUser = appConfig?.current_user;
  const [listView, setListView] = useState(false);

  const allFiles = filesQuery.data?.pages?.flatMap((p) => p.items) || [];
  const hasMoreFiles = !!filesQuery.data?.pages.at(-1)?.nextPage;

  const fileSelection = useFileSelection(allFiles);

  const contextValue = {
    filesQuery,
    allFiles,
    hasMoreFiles,
    listView,
    setListView,
    currentUser,
    page,
    setPage,
    fileSelection,
  };

  return (
    <AppBody>
      <TemplateShell2 header="File Player" showBackArrow>
        <FilePlayerProvider value={contextValue}>
          <FileActionBar />
          <FileSearchBox />
          <FilePlayerTabs />
          <FilesTab />
        </FilePlayerProvider>
      </TemplateShell2>
    </AppBody>
  );
};
