import React from 'react';
import { Route } from 'react-router-dom';

import MailboxesContainer from 'app/features/mail/components/MailboxesContainer';

const routes = (
  <Route path="/messages/:tabName">
    <Route index Component={MailboxesContainer} />
  </Route>
);

export default routes;
