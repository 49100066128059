/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// for sort, values are
//     1 - Relevance
//     2 - Price: Low to High
//     3 - Price: High to Low
//     6 - Rating
//     4 - Newest Arrivals // default
//     11 - Most Popular
// for category, values are
//     839 - Women // default
//     840 - Men
//     770 - Trans
const initialState = {
  filters: {
    text: '',
    price: [],
    media: [],
    rating: [],
    dominance_tags: [],
    age_tags: [],
    race_tags: [],
    content_rating: [],
    publication_date: '',
    kink_tags: [],
  },
  sort: '4', // Newest Arrivals
  category: '839', // Women (default)
  resultCounts: {},
};

const goodyBagFilterSlice = createSlice({
  name: 'goodyBagFilter',
  initialState,
  reducers: {
    clearFilters: (state) => {
      return { ...initialState, category: state.category };
    },

    clearAllButText: (state) => {
      return {
        ...initialState,
        filters: {
          ...initialState.filters,
          text: state.filters.text,
        },
      };
    },

    // Accepts a useSearchParams object and initializes the state from the URL params.
    initializeFromParams: (_state, action) => {
      const newState = { ...initialState };
      const newFilters = { ...newState.filters };
      const paramCategory = action.payload.get('category');
      const paramSort = action.payload.get('sort');
      if (paramCategory) newState.category = paramCategory;
      if (paramSort) newState.sort = paramSort;

      const nonParamFilters = ['category', 'sort', 'goodyBagId', 'action'];
      for (const [key, value] of action.payload) {
        if (value && !nonParamFilters.includes(key)) {
          newFilters[key] = Array.isArray(initialState.filters[key]) ? value.split(',') : value;
        }
      }
      newState.filters = newFilters;

      return newState;
    },

    // changing the search text clears all other filters
    setText: (_state, action) => {
      return {
        ...initialState,
        category: _state.category,
        filters: {
          ...initialState.filters,
          text: action.payload,
        },
      };
    },

    setFilter: (state, action) => {
      state.filters[action.payload.filter] = action.payload.value;
    },

    setResultCounts: (state, action) => {
      state.resultCounts = action.payload;
    },

    setSort: (state, action) => {
      state.sort = action.payload;
    },

    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

const selectors = {
  filtersSelector: (state) => state.goodyBagFilter.filters,
  resultCountsSelector: (state) => state.goodyBagFilter.resultCounts,
  sortSelector: (state) => state.goodyBagFilter.sort,
  categorySelector: (state) => state.goodyBagFilter.category,
  anyFiltersSelector: (state) => {
    if (state.goodyBagFilter?.filters) {
      return Object
        .keys(state.goodyBagFilter.filters)
        .filter(x => {
          if (state.goodyBagFilter.filters[x] === null) return false;
          return state.goodyBagFilter.filters[x].length > 0;
        }) // non-default values
        .length;
    } else {
      return 0;
    }
  },
  advancedFilterCountSelector: (state) => {
    if (state?.goodyBagFilter?.filters) {
      return Object
        .keys(state.goodyBagFilter.filters)
        .filter(x => x !== 'text')
        .filter(x => {
          if (state.goodyBagFilter.filters[x] === null) return false;
          return state.goodyBagFilter.filters[x].length > 0;
        }) // non-default values
        .length;
    } else {
      return 0;
    }
  },
  urlSelector: (state) => {
    const params = new URLSearchParams();
    const slice = state.goodyBagFilter;

    if (slice.category) params.set('category', slice.category);
    if (slice.sort) params.set('sort', slice.sort);

    if (slice.filters) {
      for (const [key, value] of Object.entries(slice.filters)) {
        if (value && value.length) {
          if (Array.isArray(value)) {
            params.set(key, value.join(','));
          } else {
            params.set(key, value);
          }
        }
      }
    }

    return params.toString();
  },
};

export default goodyBagFilterSlice;

export const {
  clearAllButText,
  clearFilters,
  initializeFromParams,
  setCategory,
  setFilter,
  setResultCounts,
  setSort,
  setText,
} = goodyBagFilterSlice.actions;

export const {
  advancedFilterCountSelector,
  anyFiltersSelector,
  categorySelector,
  filtersSelector,
  resultCountsSelector,
  sortSelector,
  urlSelector,
} = selectors;
