import React from 'react';
import PropTypes from 'prop-types';

import {
  Stack, Typography,
} from '@mui/material';
import BackArrow from 'app/components/shared/BackArrow';
import StyledHeader from 'app/components/shared/StyledHeader';

const TitleBackArrowImport = ({ title }) => {
  return (
    <StyledHeader data-test-id="template-shell-header">
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Stack direction="row" alignItems="center" spacing={2}>
          <BackArrow />
          <Typography data-test-id="template-shell-header-title" variant="purpleHeaderTitle">{title}</Typography>
        </Stack>
      </Stack>
    </StyledHeader>
  );
};

TitleBackArrowImport.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleBackArrowImport;
