import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelizeKeys from 'app/util/camelizeKeys';

import { prepareHeaders } from 'app/api/apiHelper';

const accountApi = createApi({
  reducerPath: 'account',

  baseQuery: fetchBaseQuery({ prepareHeaders, baseUrl: '/client_api/account' }),

  endpoints: (builder) => ({
    closeAccount: builder.mutation({
      query: () => {
        return {
          url: '/closure',
          method: 'POST',
        };
      },
    }),

    getAccountClosure: builder.query({
      query: () => {
        return '/closure/new';
      },
      transformResponse: camelizeKeys,
    }),

    getAdvisorFeedbacks: builder.query({
      query: ({ page, perPage, seekerId }) => ({
        url: '/feedbacks',
        params: {
          page: page || 1,
          per_page: perPage || 40,
          seeker_id: seekerId,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getFeaturedListings: builder.query({
      query: () => '/featured_listings',
    }),

    getPayoutRequestInfo: builder.query({
      query: () => '/payout_request',
    }),

    getTaxForms: builder.query({
      query: () => '/tax_forms',
    }),

    getTaxForm: builder.query({
      query: (id) => `/tax_forms/${id}`,
    }),

    verifyAccount: builder.mutation({
      query: ({ data, type }) => {
        return {
          url: '/verifications',
          method: 'POST',
          body: { data, type },
        };
      },
    }),
  }),
});

export default accountApi;

export const {
  useCloseAccountMutation,
  useGetAccountClosureQuery,
  useGetAdvisorFeedbacksQuery,
  useGetFeaturedListingsQuery,
  useGetPayoutRequestInfoQuery,
  useGetTaxFormsQuery,
  useGetTaxFormQuery,
  useVerifyAccountMutation,
} = accountApi;
