import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';

import ListingFeedbackCard from 'app/components/listings/ListingFeedbackCard';
import NumberlessPagination from './NumberlessPagination';
import SortSelector from 'app/components/shared/SortSelector';

const ListingFeedbackDrawer = ({
  open,
  onClose,
  listing,
  listingFeedbacks,
  handleSortChange,
  sorts,
  activeSort,
  page,
  pageCount,
  onPageChange,
  currentUser,
}) => {
  if (!listing || !listingFeedbacks) {
    return null;
  }

  return (
    <Drawer open={open} anchor="right">
      <Box
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: 'var(--body_background)',
            padding: '12px',
            borderBottom: '1px solid var(--color_ccc)',
          }}
        >
          <Grid item xs={1}>
            <a
              role="link"
              tabIndex={0}
              onClick={(e) => onClose(e)}
              onKeyDown={(e) => onClose(e)}
            >
              <img src="/plus_frontend/assets/svg/ico-back-arrow-dark.svg" alt="Back" />
            </a>
          </Grid>
          <Grid item xs={11}>
            <span className="subhead">Feedback</span>
          </Grid>
        </Grid>
        <Box sx={{ mx: { xs: 3, sm: 4 }, my: 2 }}>
          <Grid container>
            <Grid item xs={5}>
              <Typography variant="body2" display="inline" mr={1.5}>
                Rating:
              </Typography>
              <Rating
                value={listing.rating}
                precision={0.1}
                readOnly
                size="small"
                sx={{ verticalAlign: 'middle', mt: -0.5 }}
              />
              <Typography variant="body2">
                {`(${listing.feedbackCount} ${listing.feedbackCount === 1 ? 'Review)' : 'Reviews)'}`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">
                Rating Points:&nbsp;
                {listing.totalRatings}
              </Typography>
              <Typography variant="body3">
                <a
                  href="https://support.niteflirt.com/hc/en-us/articles/212830298-Giving-Ratings-Leaving-Feedback"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SortSelector
                sorts={sorts}
                activeSort={activeSort}
                handleSortChange={handleSortChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          {listingFeedbacks.map((feedback) => {
            return (
              <ListingFeedbackCard
                key={feedback.id}
                feedback={feedback}
                currentUser={currentUser}
              />
            );
          })}
        </Box>
        {pageCount > 1 && (
          <Box p={2}>
            <NumberlessPagination
              page={page}
              hasNext={page < pageCount}
              onChange={onPageChange}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

ListingFeedbackDrawer.defaultProps = {
  currentUser: null,
  pageCount: 0,
};

ListingFeedbackDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  listingFeedbacks: PropTypes.array.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  sorts: PropTypes.array.isRequired,
  activeSort: PropTypes.number.isRequired,
  pageCount: PropTypes.number,
};

export default ListingFeedbackDrawer;
