import React from 'react';
import PropTypes from 'prop-types';

import { BarChart } from '@mui/x-charts/BarChart';

const CustomerBarChart = ({ customer }) => {
  const chartValueFormatter = (num) => {
    const pct = (num * 100).toFixed(1);
    return `${pct}%`;
  };

  return (
    <BarChart
      xAxis={[
        { min: 0, max: 1, tick: false, axisLine: false, label: 'Earnings' },
      ]}
      series={[
        {
          dataKey: 'Calls',
          label: 'Calls',
          stack: 'stack1',
          color: '#4E79A7',
          valueFormatter: chartValueFormatter,
        },
        {
          dataKey: 'Chats',
          label: 'Chats',
          stack: 'stack1',
          color: '#F28E2B',
          valueFormatter: chartValueFormatter,
        },
        {
          dataKey: 'Tribs',
          label: 'Tribs',
          stack: 'stack1',
          color: '#76B7B2',
          valueFormatter: chartValueFormatter,
        },
        {
          dataKey: 'Mail',
          label: 'Mail',
          stack: 'stack1',
          color: '#E15759',
          valueFormatter: chartValueFormatter,
        },
        {
          dataKey: 'Goodies',
          label: 'Goodies',
          stack: 'stack1',
          color: '#59A14F',
          valueFormatter: chartValueFormatter,
        },
        {
          dataKey: 'Recur',
          label: 'Recur',
          stack: 'stack1',
          color: '#EDC948',
          valueFormatter: chartValueFormatter,
        },
      ]}
      width={400}
      height={80}
      layout="horizontal"
      dataset={[
        {
          Calls: customer.pct_call,
          Chats: customer.pct_chat,
          Tribs: customer.pct_trib,
          Mail: customer.pct_mail,
          Goodies: customer.pct_goody,
          Recur: customer.pct_recur,
        },
      ]}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          paddingTop: 5,
          labelStyle: { fontSize: 12 },
          itemMarkWidth: 10,
          itemMarkHeight: 10,
          markGap: 5,
          itemGap: 10,
        },
        yAxis: { label: '', tick: false, axisLine: false },
        popper: {
          sx: {
            '& .MuiChartsTooltip-table thead': { display: 'none' },
          },
        },
      }}
      margin={{ top: 45, right: 10, bottom: 5, left: 5 }}
      sx={{
        '.MuiChartsAxis-root': { display: 'none' },
      }}
    />
  );
};

CustomerBarChart.defaultProps = {
  customer: {},
};

CustomerBarChart.propTypes = {
  customer: PropTypes.object,
};

export default CustomerBarChart;
