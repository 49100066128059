import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { toCurrency } from 'app/helpers/currencyHelpers';
import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';

const StyledName = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#000',
});

const StyledDescription = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 2, // Clamp at 2 lines
  WebkitBoxOrient: 'vertical',
  fontSize: '0.813em',
});

const AdvisorCard = ({ avatarSrc, name, description, price, type }) => {
  const priceUnit = { chat: 'per volley', call: 'per min', gb: null }[type];

  return (
    <StyledCard className="no-border-fav">
      <Stack direction="row">
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: '150px',
              height: '180px',
              position: 'absolute',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1',
            }}
          >
            <img
              src={avatarSrc}
              alt={name}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '150px',
                maxHeight: '180px',
                minWidth: '150px',
                minHeight: '180px',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
            />
          </Box>
          <BlurBox style={{ backgroundImage: `url(${avatarSrc})` }} />
        </Box>
        <Stack
          spacing={2}
          sx={{
            marginLeft: '1rem',
            width: '150px',
            paddingTop: '1rem',
            paddingRight: '1rem',
          }}
        >
          <StyledName variant="h5">{name}</StyledName>
          <StyledDescription>{description}</StyledDescription>
          <Typography variant="body3" sx={{ color: '#444' }}>
            {toCurrency(price)}
            {priceUnit && (
              <span style={{ color: '#999' }}>
                &nbsp;
                {priceUnit}
              </span>
            )}
          </Typography>
        </Stack>
      </Stack>
    </StyledCard>
  );
};

AdvisorCard.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default AdvisorCard;
