import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router';

import { useGetCreatorFeedContentAvailableQuery } from 'app/api/feedsApi';
import { useGetAdvisorQuery } from 'app/api/mainApi';
import FlirtFeedTabs from 'app/components/nav/FlirtFeedTabs';
import FlirtPostsContainer from './FlirtPostsContainer';
import FlirtMediaContainer from './FlirtMediaContainer';

const FlirtContentContainer = ({ showMediaTab }) => {
  const { login: advisorLogin } = useParams();
  const encodedAdvisorLogin = encodeURIComponent(advisorLogin);

  // TODO: simplify this once we remove FEED_TO_STOREFRONT_20571
  // matches /feed/login/media and /login/feed/media, with both encoded and non-encoded login
  const viewingMedia = new RegExp(`/(?:feed/)?(?:${advisorLogin}|${encodedAdvisorLogin})/(?:feed/)?media`).test(document.location.href) || showMediaTab;

  const { data: advisor, isLoading: advisorLoading } = useGetAdvisorQuery({ login: advisorLogin });

  const contentAvailableResult = useGetCreatorFeedContentAvailableQuery(
    { advisorId: advisor?.id },
    { skip: !advisor },
  );

  const contentAvailable = contentAvailableResult?.data?.content_available;
  const contentAvailableLoaded = contentAvailableResult?.status === 'fulfilled';

  if (advisorLoading) { return null; }

  if (!advisor?.active) {
    // there is an assumption here that you can't be viewing your own flirt feed
    // if you were and had no content, you'd already have been redirected to the "new" onboarding flow
    // there used to be logic here to show the (now deprecated) InvitationToPost view
    return <Navigate replace to="/feed/missing/profile" />;
  }

  if (contentAvailableLoaded && !contentAvailable) {
    window.location.href = `/profile/${advisor?.login}`;
  }

  return (
    <>
      <FlirtFeedTabs advisor={advisor} viewingMedia={viewingMedia} />
      {!viewingMedia && <FlirtPostsContainer advisor={advisor} />}
      {viewingMedia && <FlirtMediaContainer advisor={advisor} />}
    </>
  );
};

FlirtContentContainer.defaultProps = {
  showMediaTab: false,
};

FlirtContentContainer.propTypes = {
  showMediaTab: PropTypes.bool,
};

export default FlirtContentContainer;
