import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import MainHome from 'app/components/MainHome';
import { useGetHomepageListingsQuery } from 'app/api/mainApi';
import useFeaturedListingsContext from 'app/hooks/useFeaturedListingsContext';

const MainHomeContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const { data: listingsData } = useGetHomepageListingsQuery({ page, perPage: 8 });
  const { FeaturedListingsContextProvider } = useFeaturedListingsContext({ listingsData });

  const memoizedListingsData = React.useMemo(() => listingsData, [listingsData]);

  const handlePageChange = (event, value) => {
    navigate(`/?page=${value}`);
  };

  return (
    <FeaturedListingsContextProvider>
      <MainHome
        listingsData={memoizedListingsData}
        currentPage={parseInt(page)}
        handlePageChange={handlePageChange}
      />
    </FeaturedListingsContextProvider>
  );
};

export default MainHomeContainer;
