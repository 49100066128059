import React from 'react';
import { Route } from 'react-router-dom';

import { FilePlayerPage } from './components/FilePlayerPage';

const routes = (
  <Route path="account/fileplayer" element={<FilePlayerPage />} id="filePlayer" />
);

export default routes;
