import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import withTooltip from 'app/hoc/withTooltip';

const ListingCallMeTooltip = ({
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
  listing,
  voicePortalPhoneNumber,
  currentUser,
}) => {
  const marginBottom = '5px';

  return (
    <Tooltip
      content={(
        <div
          ref={setTipContentRef}
          className="nfp-tooltip"
          style={{ width: '300px' }}
        >
          <Grid container alignItems="flex-start">
            <Grid item xs={11}>
              <Typography variant="h4" mb={1} mt={1.2}>
                Call&nbsp;
                {listing.login}
                &nbsp;anytime:
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            >
              <a role="link" tabIndex={0} onClick={toggleTip}>
                <Box sx={{ px: 0.5, mr: -1 }}>
                  <img src="/plus_frontend/assets/svg/x-close.svg" alt="close" />
                </Box>
              </a>
            </Grid>
          </Grid>
          <ol style={{ paddingInlineStart: '20px', fontSize: '0.938em' }}>
            <li style={{ marginBottom }}>
              Dial&nbsp;
              {voicePortalPhoneNumber}
              &nbsp;(1-800-TO-FLIRT)
            </li>
            <li style={{ marginBottom }}>
              Enter&nbsp;
              {listing.login}
              &apos;s extension:&nbsp;
              {listing.serviceExtension}
            </li>
            <li style={{ marginBottom }}>
              Enter your PIN
              {currentUser?.pin ? `: ${currentUser.pin}` : ''}
            </li>
            <li style={{ marginBottom }}>Enter your phone number</li>
            <li style={{ marginBottom }}>Enjoy your call!</li>
          </ol>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <IconButton
        onClick={toggleTip}
        ref={setButtonRef}
        sx={{ p: 0.5 }}
      >
        <InfoIcon sx={{ fontSize: '0.875em' }} />
      </IconButton>
    </Tooltip>
  );
};

ListingCallMeTooltip.defaultProps = {
  currentUser: null,
};

ListingCallMeTooltip.propTypes = {
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  voicePortalPhoneNumber: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
};

export default withTooltip(ListingCallMeTooltip);
