import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Mailcheck from 'mailcheck';
import { FormTextField } from 'app/components/form_components';

const emailValidation = {
  required: 'Please enter an email address.',
  pattern: {
    value: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
    message: 'This email address is invalid.',
  },
};

const EmailField = () => {
  const form = useFormContext();
  const [suggestion, setSuggestion] = useState(null);

  const handleMailcheck = (event) => {
    const email = event.target.value;
    const topLevelDomains = [
      'co.jp', 'co.uk', 'com', 'net', 'org', 'info', 'edu', 'gov', 'mil', 'ca',
    ];

    const defaultDomains = [
      'yahoo.com', 'google.com', 'hotmail.com', 'gmail.com', 'me.com', 'aol.com',
      'mac.com', 'live.com', 'comcast.net', 'googlemail.com', 'msn.com',
      'hotmail.co.uk', 'yahoo.co.uk', 'facebook.com', 'verizon.net',
      'sbcglobal.net', 'att.net', 'gmx.com', 'mail.com', 'outlook.com', 'icloud.com',
    ];

    if (email) {
      Mailcheck.run({
        email,
        domains: defaultDomains,
        topLevelDomains,
        suggested: (suggestion) => {
          setSuggestion(suggestion.full);
        },
        empty: () => {
          setSuggestion(null);
        },
      });
    }
  };

  const handleSuggestionClick = () => {
    form.setValue('email', suggestion);
    setSuggestion(null);
  };

  return (
    <>
      <FormTextField
        label="Email Address"
        name="email"
        type="email"
        validation={emailValidation}
        onBlur={handleMailcheck}
      />
      {suggestion && (
        <Typography variant="body2" color="error">
          Did you mean&nbsp;
          <a style={{ color: '#06c', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSuggestionClick}>
            {suggestion}
          </a>
          ?
        </Typography>
      )}
    </>
  );
};

export default EmailField;
