/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Switch, FormControlLabel } from '@mui/material';

const FormControlFloats = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    '& .MuiTypography-root': {
      width: 'auto',
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
    },
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      // minWidth: '240px',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));

export const ToggleSwitch = ({ label, value, setValue }) => {
  return (
    <FormControlFloats
      value={value}
      control={(
        <Switch
          checked={!!value}
          /* name="call-monitor" */
          sx={{ mr: 2 }}
          onChange={setValue}
        />
      )}
      label={label}
      labelPlacement="end"
      sx={{ mt: 1, mr: 0 }}
    />
  );
};

ToggleSwitch.defaultProps = {
  value: false,
};

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};

export default ToggleSwitch;
