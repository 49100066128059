import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, useMatch } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useLazyGetGoodyBagItemsQuery, useLazyGetGoodyBagReviewsQuery } from 'app/api/assetApi';

import Lightbox from 'lightbox-react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import useFlirtMedia from 'app/hooks/useFlirtMedia';
import { goodyBagItemToMediaParams } from 'app/helpers/flirtMediaHelpers';

import { toCurrency } from 'app/helpers/currencyHelpers';
import useGoodyBagActions from 'app/hooks/useGoodyBagActions';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';
import GoodyBagDetailsDrawer from './modals/GoodyBagDetailsDrawer';
import GoodyBagReviewsList from 'app/components/modals/GoodyBagReviewsList';
import ProfileContext from 'app/contexts/ProfileContext';
import GoodySearchContext from 'app/contexts/GoodySearchContext';
import GoodyBagCallToAction from 'app/components/media/GoodyBagCallToAction';
import GoodyBagActionMenu from 'app/components/shared/GoodyBagActionMenu';

const DeprecatedGoodyBagCard = ({
  goodyBag,
  viewingSelf,
  availableBalance,
  currentUser,
  advisorLoading,
  selected,
  advisor,
  viewingSingleAdvisor,
  actions,
}) => {
  const navigate = useNavigate();
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const showContext = useMatch(`/${encodedAdvisorLogin}/*`);

  // Data Fetching
  const [triggerGoodyBagItemsQuery, goodyBagItemsResult] = useLazyGetGoodyBagItemsQuery({ goodyBagId: goodyBag.id });
  const goodyBagItems = goodyBagItemsResult?.data || [];
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get('action'); // only used so we can know whether we need to remove it from the URL
  const selectedGoodyBagId = searchParams.get('goodyBagId') || params.goodyBagId;

  const profileContext = useContext(ProfileContext);
  const goodySearchContext = useContext(GoodySearchContext);
  const returnUrlTemplate = profileContext?.returnUrlTemplate || goodySearchContext?.returnUrlTemplate;
  const pinIconClass = viewingSelf ? 'active' : 'disabled';

  const [triggerGoodyBagReviewsQuery, goodyBagReviewsResult] = useLazyGetGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
  const goodyBagReviews = goodyBagReviewsResult?.data || [];
  const notPreviewableItemCount = goodyBagItems.filter(item => !item.previewable).length;

  // Lightbox Management
  const mediaItems = goodyBagItems
    .filter(item => item.previewable)
    .map((goodyBagItem) => goodyBagItemToMediaParams(goodyBagItem));
  if (notPreviewableItemCount > 0) {
    mediaItems.push({ type: 'thumb', thumb: <GoodyBagCallToAction itemCount={notPreviewableItemCount} /> });
  }

  const {
    currentItem,
    onCloseClick,
    setMediaIndex,
    lightboxIsOpen,
    nextItem,
    prevItem,
    onNextClick,
    onPrevClick,
    setLightboxIsOpen,
    pauseAllVideoPlayers,
    pauseAllAudioPlayers,
    lightboxMediaDecorator,
  } = useFlirtMedia({ mediaItems });

  const onGoodyBagItemClick = (goodyBagItem) => {
    const selectedMediaItemIndex = mediaItems.findIndex(item => item.id === goodyBagItem.id);
    setMediaIndex(selectedMediaItemIndex);
    setLightboxIsOpen(true);
  };

  const pauseAllPlayers = () => {
    pauseAllAudioPlayers();
    pauseAllVideoPlayers();
  };

  const onPreviewClose = () => {
    pauseAllPlayers();
    onCloseClick();
  };

  // Drawer Management
  const drawerAnchor = 'right';

  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(!!selected);
  const [isRatingsDrawerOpen, setIsRatingsDrawerOpen] = useState(false);

  const { onGoodyBagButtonClick, returnUrl } = useGoodyBagActions({
    goodyBag,
    viewingSelf,
    availableBalance,
    currentUser,
    advisorLoading,
    advisor,
    returnUrlTemplate,
  });

  const ignoreCloseDrawerEvents = (event) => (
    (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) ||
      event.target.tagName === 'BUTTON'
  );

  const openDetailsDrawer = () => {
    setIsDetailsDrawerOpen(true);
    const encodedLogin = encodeURIComponent(advisor.login);

    // if we're not on a storefront or profile page, don't navigate- just set a search param
    if (document.location.pathname.match(`/${encodedLogin}/(goodies|gb)`)) {
      navigate(`/${encodedLogin}/goodies/${goodyBag.id}`, { replace: false });
    } else {
      searchParams.append('goodyBagId', goodyBag.id);
      setSearchParams(searchParams);
    }
  };

  const closeDetailsDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    onPreviewClose();
    setIsDetailsDrawerOpen(false);
    searchParams.delete('goodyBagId');
    setSearchParams(searchParams);
  };

  const openRatingsDrawer = () => {
    pauseAllPlayers();
    triggerGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
    setIsRatingsDrawerOpen(true);
  };

  const closeRatingsDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    setIsRatingsDrawerOpen(false);
  };

  useEffect(() => {
    if (isDetailsDrawerOpen) {
      triggerGoodyBagReviewsQuery({ goodyBagId: goodyBag.id });
      triggerGoodyBagItemsQuery({ goodyBagId: goodyBag.id });
    }
  }, [isDetailsDrawerOpen]);

  // If they tried deep linking to this specific goody bag, open the details drawer.
  // But not if they're trying to buy it, because clicking buy in the drawer would
  // close the drawer anyway.
  useEffect(() => {
    if (selectedGoodyBagId && (goodyBag.id === parseInt(selectedGoodyBagId))) {
      openDetailsDrawer();

      if (action === 'buy') {
        onGoodyBagButtonClick();

        // Remove the buy action so that we don't get stuck in a loop of trying
        // to buy the same goody bag.
        searchParams.delete('action');
        setSearchParams(searchParams);
      }
    }
  }, []);

  return (
    <>
      <div className="listing goody-bag-card">
        <div className="container-fluid padding-0-8">
          <div className="row">
            <div className="col-xs-4 padding10 center-xs">
              <div className="pic">
                <img src={goodyBag.thumbnail_url} alt="Goody Bag Thumbnail" />
              </div>
              <div
                className="rating"
                role="button"
                tabIndex={0}
                onClick={() => { if (goodyBag.total_ratings > 0) openRatingsDrawer(); }}
              >
                {goodyBag.total_ratings > 0 ? (
                  <a href="#">
                    {goodyBag.approval}
                    % approval
                    <p>
                      (
                      {goodyBag.up_ratings}
                      /
                      {goodyBag.total_ratings}
                      )
                    </p>
                  </a>
                ) : 'Unrated'}
              </div>
            </div>
            <div className="col-xs-8 start-xs padding10">
              <div className="container-fluid padding-0-8">
                {showContext && (
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {goodyBag.pinned && (
                      <img src="/plus_frontend/assets/svg/ico-pin.svg" title="Pinned" alt="Pinned" className={`icon-pin-post ${pinIconClass}`} />
                    )}
                    {viewingSelf && (
                      <GoodyBagActionMenu actions={actions} />
                    )}
                  </Box>
                )}
                <div className="row name" style={{color: 'black'}}>
                  {viewingSingleAdvisor ? goodyBag.title : advisor.login}
                </div>
                <div className="row description">
                  <a href="#" onClick={openDetailsDrawer}>
                    {viewingSingleAdvisor ? goodyBag.description : goodyBag.title}
                  </a>
                </div>
                <Grid
                  item
                  sm={1}
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                  }}
                >
                  <div className="row details" data-test-id="details-desktop">
                    <div className="col-xs-4 marginlftminus8">
                      <Fragment key={`GB-${goodyBag.id}-desktop`}>
                        <a href="#" onClick={openDetailsDrawer}>Details</a>
                      </Fragment>
                    </div>
                    <div className="col-xs-8 end-xs price">
                      {toCurrency(goodyBag.price)}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                  }}
                >
                  <div className="row details" data-test-id="details-mobile">
                    <div className="col-xs-4 marginlftminus8">
                      <Fragment key={`GB-${goodyBag.id}-mobile`}>
                        <a href="#" onClick={openDetailsDrawer}>Details</a>
                      </Fragment>
                    </div>
                    <div className="col-xs-8 end-xs price">
                      {toCurrency(goodyBag.price)}
                    </div>
                  </div>
                </Grid>
                <GoodyBagDetailsDrawer
                  anchor={drawerAnchor}
                  currentUser={currentUser}
                  goodyBag={goodyBag}
                  goodyBagReviews={goodyBagReviews}
                  isLoadingReviews={goodyBagReviewsResult?.isLoading}
                  goodyBagItems={goodyBagItems}
                  previewableItems={mediaItems}
                  notPreviewableItemCount={notPreviewableItemCount}
                  isOpen={isDetailsDrawerOpen}
                  closeDrawer={closeDetailsDrawer}
                  openRatingsDrawer={openRatingsDrawer}
                  closeRatingsDrawer={closeRatingsDrawer}
                  isRatingsDrawerOpen={isRatingsDrawerOpen}
                  pauseAllPlayers={pauseAllPlayers}
                  advisor={advisor}
                  onGoodyBagItemClick={onGoodyBagItemClick}
                  onBuyGoodyBagButtonClick={onGoodyBagButtonClick}
                  returnUrl={returnUrl}
                />
                <GoodyBagReviewsList
                  goodyBag={goodyBag}
                  currentUser={currentUser}
                  goodyBagReviews={goodyBagReviews}
                  isDrawerOpen={isRatingsDrawerOpen}
                  closeDrawer={closeRatingsDrawer}
                  openDrawer={openRatingsDrawer}
                  isLoadingReviews={goodyBagReviewsResult?.isLoading}
                />
                {/* start there's a better way to handle this for Desktop vs Mobile */}
                <div className="row margintop20">
                  <AuthenticatedLink
                    role="dialog"
                    onClick={onGoodyBagButtonClick}
                    currentUser={currentUser}
                    className="buy-now-button buy-now goody"
                    returnUrl={returnUrl}
                    overrideLoginUrl={`/login?product_type=gb&product_id=${goodyBag.id}`}
                  >
                    <span>Buy Now</span>
                  </AuthenticatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {lightboxIsOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
          // we should always use the image thumbnail; video and other fullsize media
          //  are only available if you've purchased the goody bag and are viewing it
          //  in the file manager
          mainSrc={lightboxMediaDecorator(currentItem, 'thumb')}
          nextSrc={lightboxMediaDecorator(nextItem, 'thumb')}
          prevSrc={lightboxMediaDecorator(prevItem, 'thumb')}
          onCloseRequest={onPreviewClose}
          onMovePrevRequest={onPrevClick}
          onMoveNextRequest={onNextClick}
        />
      )}
    </>
  );
};

DeprecatedGoodyBagCard.defaultProps = {
  availableBalance: 0,
  currentUser: {},
  advisor: {},
  viewingSingleAdvisor: true,
  actions: [],
};

DeprecatedGoodyBagCard.propTypes = {
  goodyBag: GoodyBagShape.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  availableBalance: PropTypes.number,
  currentUser: PropTypes.object,
  advisorLoading: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  viewingSingleAdvisor: PropTypes.bool,
  actions: PropTypes.array,
};

export default DeprecatedGoodyBagCard;
