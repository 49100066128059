import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const CustomTabs = styled(Tabs)(() => ({
  display: 'flex',
  '& .MuiTab-root': {
    flexGrow: 1,
    minWidth: 0,
    padding: '8px 15px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  '& .MuiTab-root:first-child': {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
}));

export default CustomTabs;
