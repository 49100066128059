import React from 'react';
import { PropTypes } from 'prop-types';

import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';
import ChangePackageCard from 'app/components/modals/tributes/ChangePackageCard';
import PerkList from 'app/components/tributes/PerkList';

const DividerWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
}));

const EditPackageSubscription = ({
  activePackageSubscription,
  closeDialog,
  onCancelSubscriptionClick,
  onRenewSubscriptionClick,
  otherPackages,
  changePackageClick,
  useDifferentCardClick,
}) => {
  const activePackage = activePackageSubscription.package;
  const pendingCancel = activePackageSubscription?.status === 'pending_cancel';
  const chargeFailed = activePackageSubscription?.status === 'charge_failed';
  const retiredPackage = activePackageSubscription?.package?.status === 'retired_package';

  return (
    <>
      <DialogTitle>
        Edit Package
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {`Package: ${activePackage.rate} per month`}
            </Typography>
            <Typography variant="h6" className="name" mt={1} mb={-1} pl={1}>Perks:</Typography>
            <PerkList packageData={activePackage} />
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ alignItems: 'center' }}>
              {chargeFailed && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body3" style={{ color: '#666' }}>
                      Renewal Failed
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body3"
                      sx={{
                        color: '#666',
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        role="button"
                        tabIndex={0}
                        onClick={useDifferentCardClick}
                      >
                        Update Card Now
                      </Link>
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography variant="body3" style={{ color: '#666' }}>
                  {
                    pendingCancel || chargeFailed || retiredPackage ?
                      'Expires ' :
                      'Renews '
                  }
                  {activePackageSubscription.renewalDate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography
              variant="body3"
              sx={{
                color: '#666',
                cursor: 'pointer',
              }}
            >
              {!retiredPackage && (
                pendingCancel ? (
                  <Link
                    role="button"
                    tabIndex={0}
                    onClick={onRenewSubscriptionClick}
                  >
                    Resume Package
                  </Link>
                ) : (
                  <Link
                    role="button"
                    tabIndex={0}
                    onClick={onCancelSubscriptionClick}
                  >
                    Cancel Package
                  </Link>
                )
              )}
            </Typography>
          </Grid>
          {otherPackages.length > 0 && (
            <>
              <Grid item xs={12}>
                <DividerWrapper>
                  <Divider />
                </DividerWrapper>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Change Package:
                </Typography>
                {otherPackages?.map((advisorPackage) => (
                  <React.Fragment key={advisorPackage.id}>
                    <ChangePackageCard
                      advisorPackage={advisorPackage}
                      subscribeToPackageClick={changePackageClick}
                    />
                  </React.Fragment>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </>
  );
};

EditPackageSubscription.propTypes = {
  activePackageSubscription: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onCancelSubscriptionClick: PropTypes.func.isRequired,
  onRenewSubscriptionClick: PropTypes.func.isRequired,
  otherPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  changePackageClick: PropTypes.func.isRequired,
  useDifferentCardClick: PropTypes.func.isRequired,
};

export default EditPackageSubscription;
