import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Stack, Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { useConfirm } from 'material-ui-confirm';

import DialogsContext from 'app/contexts/DialogsContext';
import RenameTagDialog from 'app/components/customers/RenameTagDialog';
import CreateNewTagDialog from 'app/components/customers/CreateNewTagDialog';

import {
  useGetCustomTagsQuery,
  useUpdateTagMutation,
  useDestroyTagMutation,
} from 'app/api/customersApi';
import {
  setFiltersToTag,
  setOmnibox,
} from 'app/slices/customersSlice';

const ManageTags = () => {
  const navigate = useNavigate();
  const { data: tags, isLoading: loading } = useGetCustomTagsQuery();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { openDialog } = useContext(DialogsContext);
  const dispatchSetOmnibox = (value) => dispatch(setOmnibox(value));
  const [performUpdateTag, { isSuccess: updateSuccess }] = useUpdateTagMutation(); // eslint-disable-line no-unused-vars
  const [performDestroyTag, { isSuccess: destroySuccess }] = useDestroyTagMutation(); // eslint-disable-line no-unused-vars

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Stack>
    );
  }

  const onClickCreateTag = (evt) => {
    openDialog({ component: CreateNewTagDialog });
    evt.preventDefault();
  };

  const onClickTagName = (tagName) => (evt) => {
    dispatchSetOmnibox([{ name: tagName, type: 'tag' }]);
    dispatch(setFiltersToTag([tagName]));
    navigate('/my_customers');
    evt.preventDefault();
  };

  const onClickRename = (tagName) => (evt) => {
    openDialog({
      component: RenameTagDialog,
      props: {
        performUpdateTag,
        tagName,
      },
    });
    evt.preventDefault();
  };

  const onClickDelete = (tagName) => (evt) => {
    confirm({
      title: 'Confirm delete tag',
      description: 'Tag will be removed from all customers and deleted. Really delete this tag?',
    })
      .then(() => performDestroyTag({ name: tagName }))
      .catch(() => {}); // The "Cancel" action; do nothing

    evt.preventDefault();
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ width: '100%', marginBottom: '10px' }}
    >
      <Stack
        direction="row"
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          alignSelf="auto"
          paddingLeft="10px"
          paddingRight="10px"
          height="50px"
          bgcolor="rgb(211, 211, 211)"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <Stack
            direction="row"
            spacing="10px"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing="10px" alignItems="center">
              <IconButton onClick={handleBack}>
                <ArrowBackIosNewIcon fontSize="large" />
              </IconButton>

              <Typography variant="h5">
                Manage Tags
              </Typography>
            </Stack>

            <IconButton onClick={onClickCreateTag}>
              <AddIcon fontSize="large" />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
      <Stack sx={{ height: '80vh', overflow: 'auto' }} direction="column" spacing={2} px={2}>
        { tags.map((tag) => (
          <Stack
            key={tag.name}
            direction="column"
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '16px',
              borderRadius: '8px',
              border: '2px solid #e0e0e0',
            }}
          >
            <Typography variant="h6">
              <a href="#" onClick={onClickTagName(tag.name)} role="button" tabIndex={0}>
                { tag.name }
              </a>
            </Typography>
            <Stack
              direction="row"
              spacing="10px"
              alignItems="center"
              width="100%"
            >
              <Typography mr="2">
                { tag.count }
                &nbsp;customers
              </Typography>

              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                width="100%"
              >
                <Typography mr="2">
                  <a href="#" onClick={onClickRename(tag.name)} role="button" tabIndex={0}>
                    Rename
                  </a>
                </Typography>

                <Typography mr="2">
                  <a href="#" onClick={onClickDelete(tag.name)} role="button" tabIndex={0}>
                    Delete
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )) }
      </Stack>
    </Stack>
  );
};

export default ManageTags;
