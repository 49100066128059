import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import AdvisorPackagesContext from 'app/contexts/AdvisorPackagesContext';
import withTooltip from 'app/hoc/withTooltip';

const PackageTooltip = ({
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
  packageData,
  currentUser,
}) => {
  const {
    seeCustomerListHandler,
    copyPackageLinkHandler,
    packageStatusHandler,
    deletePackageHandler,
  } = useContext(AdvisorPackagesContext);

  return (
    <Tooltip
      content={(
        <div
          ref={setTipContentRef}
          className="nfp-tooltip"
        >
          <div className="row nowrap-xs">
            <div className="col-xs-11">
              <div className="menu-links">
                <a
                  role="link"
                  tabIndex={0}
                  onClick={seeCustomerListHandler}
                >
                  See Customer List
                </a>
                <br />
                <a
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    toggleTip();
                    copyPackageLinkHandler(packageData.id, currentUser?.login);
                  }}
                >
                  Copy Package Link
                </a>
                <br />
                <a
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    toggleTip();
                    packageStatusHandler(packageData);
                  }}
                >
                  {`Make Package ${packageData?.status === 'public_package' ? 'Private' : 'Public'}`}
                </a>
                <br />
                <a
                  role="link"
                  tabIndex={0}
                  onClick={() => {
                    toggleTip();
                    deletePackageHandler(packageData.id);
                  }}
                >
                  Delete Package
                </a>
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a role="link" tabIndex={0} onClick={toggleTip}>
                <img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" />
              </a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={setButtonRef}
        onClick={toggleTip}
      >
        <img
          src="/plus_frontend/assets/svg/ico-more-menu.svg"
          alt="More"
          className="icon-more-body"
        />
      </a>
    </Tooltip>
  );
};

PackageTooltip.defaultProps = {
  packageData: {},
  currentUser: null,
};

PackageTooltip.propTypes = {
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
  packageData: PropTypes.object,
  currentUser: PropTypes.object,
};

export default withTooltip(PackageTooltip);
