import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import PhoneShape from 'app/shapes/PhoneShape';

const SelectPhoneNumber = ({
  phones,
  setSelectedPhone,
  isLoading,
}) => {
  const handleChange = (event) => {
    setSelectedPhone(phones.find((phone) => phone.id === event.target.value));
  };
  const defaultPhone = phones.find((phone) => phone.default) || phones[0];

  return (
    <Grid
      container
      spacing={2}
      maxWidth={550}
    >
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          Number
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Box
            sx={{ my: 2 }}
          >
            <FormControl
              sx={{ minWidth: 120 }}
              size="small"
            >
              {!isLoading && defaultPhone && (
                <Select
                  onChange={handleChange}
                  defaultValue={defaultPhone.id}
                >
                  {phones?.map((phone) => (
                    <MenuItem
                      key={phone.id}
                      value={phone.id}
                    >
                      <span
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {phone.type_name}
                        :
                      </span>
                      &nbsp;
                      {phone.secure_number}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

SelectPhoneNumber.defaultProps = {
  phones: [],
};

SelectPhoneNumber.propTypes = {
  phones: PropTypes.arrayOf(PhoneShape),
  setSelectedPhone: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SelectPhoneNumber;
