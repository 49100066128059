import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import ChatListingCard from 'app/components/ChatListingCard';

const ChatBrowseResults = ({ results }) => {
  return (
    <Box
      sx={{
        pt: { xs: 2.5, sm: 3.5 },
        pb: { xs: 4, sm: 4 },
        margin: '0 -1px',
      }}
      data-testid="chat-browse-results"
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: { xs: '6px', sm: '10px' },
          justifyContent: { sm: 'flex-start' },
          paddingLeft: { sm: '20px' },
          '@media (max-width: 900px)': {
            justifyContent: 'center',
            paddingLeft: '0px',
          },
        }}
      >
        {results.map((result) => (
          <Box
            key={`chat_listing_${result.id}`}
            sx={{
              width: '330px',
              flex: '0 0 330px',
            }}
          >
            <ChatListingCard advisorLogin={result.login} key={result.id} listing={result} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

ChatBrowseResults.propTypes = {
  results: PropTypes.array.isRequired,
};

export default ChatBrowseResults;
