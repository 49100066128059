import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import { Drawer, Stack, Typography, Box, Grid, Rating } from '@mui/material';
import CardImageBox from 'app/components/shared/CardImageBox';
import BlurBox from 'app/components/shared/BlurBox';
import HorizontalLine from 'app/components/HorizontalLine';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import DrawerHeader from 'app/components/shared/DrawerHeader';
import ListingViewerDetailKebabMenu from './listings/ListingViewerDetailKebabMenu';
import { callButton } from 'app/helpers/listingHelpers';
import { toCurrency } from 'app/helpers/currencyHelpers';
import ListingCallMeTooltip from './tooltips/ListingCallMeTooltip';
import ListingOwnerDetailKebabMenu from './listings/ListingOwnerDetailKebabMenu';

const ListingDetailDrawer = ({
  open,
  onClose,
  listing,
  onFeedbackClick,
  onReportListingClick,
  currentUser,
  onImageClick,
  voicePortalPhoneNumber,
  isOwnedByViewer,
}) => {
  if (!listing) { return null; }

  const [showingRejectionComment, setShowingRejectionComment] = useState(false);

  const showHideRejectionComment = (e) => {
    e.preventDefault();
    setShowingRejectionComment(!showingRejectionComment);
  };

  const advisorDescriptionStyles = {
    'font:not(font font)': {
      fontSize: 'calc(100% / 1.02)',
    },
    'font[size="1"]:not(font font)': {
      fontSize: 'calc(0.75em / 1.02) !important',
    },
    'font[size="2"]:not(font font)': {
      fontSize: 'calc(0.875em / 1.02) !important',
    },
    'font[size="3"]:not(font font)': {
      fontSize: 'calc(1em / 1.02) !important',
    },
    'font[size="4"]:not(font font)': {
      fontSize: 'calc(1.125em / 1.02) !important',
    },
    'font[size="5"]:not(font font)': {
      fontSize: 'calc(1.25em / 1.02) !important',
    },
    'font[size="6"]:not(font font)': {
      fontSize: 'calc(1.5em / 1.02) !important',
    },
    'font[size="7"]:not(font font), font[size="8"]:not(font font)': {
      fontSize: 'calc(2em / 1.02) !important',
    },
    'span[style*="font-size"], div[style*="font-size"], p[style*="font-size"], h1, h2, h3, h4, h5, h6': {
      fontSize: 'calc(100% / 1.02) !important',
    },
    'table, table thead, table th, table tbody, table td, table tr, div, center': {
      width: 'auto !important',
      height: 'auto !important',
      maxWidth: 'calc(100vw - 20px)',
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
    },
  };

  const bannerCopyCreator = (listing) => {
    if (listing.statusName === 'New' || listing.statusName === 'Not Accepted - Edited') {
      return (
        <>
          <WarningAmberIcon sx={{ verticalAlign: 'middle', paddingRight: '10px', color: '#ee7911' }} />
          Pending Review
        </>
      );
    } else if (listing.statusName === 'Accepted' || listing.statusName === 'Accepted - Edited') {
      return (
        <>
          <ThumbUpOffAltIcon sx={{ verticalAlign: 'middle', paddingRight: '10px', color: '#ee7911' }} />
          Accepted: Your listing has been accepted to appear in the NiteFlirt directory
        </>
      );
    } else {
      return (
        <>
          <WarningAmberIcon sx={{ verticalAlign: 'middle', paddingRight: '10px', color: '#ee7911' }} />
          {listing.statusName}
          :&nbsp;
          {listing.listingRejectionReason}
          &nbsp;
          {listing.listingRejectionComment && (
            <Link
              style={{ paddingLeft: '12px' }}
              onClick={showHideRejectionComment}
              role="link"
              tabIndex={0}
            >
              {showingRejectionComment ? 'See less' : 'See more'}
            </Link>
          )}
        </>
      );
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      data-testid="listing-detail-drawer"
    >
      <Box
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <DrawerHeader
          closeDrawer={onClose}
          headerCopy="Listing Details"
        />
        <Box
          sx={{
            backgroundColor: '#500e64',
            color: 'white',
            p: 1,
            a: { color: 'white' },
            fontSize: '0.875em',
          }}
          dangerouslySetInnerHTML={{ __html: listing.breadcrumbs }}
          data-testid="breadcrumbs"
        />
        {isOwnedByViewer && (
          <Box sx={{ backgroundColor: '#f9ec9b', color: '#663c00', p: 1 }}>
            <Typography variant="body3">
              {bannerCopyCreator(listing)}
            </Typography>
            {listing.listingRejectionComment && showingRejectionComment && (
              <Box p={1}>
                <Typography variant="body3">
                  Comments:&nbsp;
                  {listing.listingRejectionComment}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box sx={{ p: 2, pb: 0, backgroundColor: 'var(--body_background)' }}>
          <Grid container>
            <Grid item xs={11.5} data-testid="listing-image">
              <Typography variant="h3" sx={{ mb: 0.5 }} data-testid="listing-title">
                {listing.title}
              </Typography>
              <Link to={`/${listing.login}`} data-testid="advisor-login">
                <Typography variant="body3">
                  {listing.login}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={0.5} sx={{ textAlign: 'right' }}>
              {!listing.blocked && !isOwnedByViewer && (
                <ListingViewerDetailKebabMenu
                  listing={listing}
                  closeDrawer={onClose}
                  currentUser={currentUser}
                />
              )}
              {isOwnedByViewer && <ListingOwnerDetailKebabMenu listing={listing} />}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2, backgroundColor: 'var(--body_background)' }}>
          <Grid container sx={{ maxWidth: '330px', margin: '0 auto' }}>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                <a onClick={onImageClick} role="link" tabIndex={0}>
                  <CardImageBox>
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '150px',
                        maxHeight: '180px',
                        minWidth: '150px',
                        minHeight: '180px',
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                      src={listing.photoUrl}
                      alt={listing.login}
                    />
                  </CardImageBox>
                </a>
                <BlurBox style={{ backgroundImage: `url(${listing.photoUrl})`, zIndex: '0' }} />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              data-testid="listing-cta-button"
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <a onClick={onFeedbackClick} role="link" tabIndex={0}>
                    <Rating
                      value={listing.rating}
                      precision={0.5}
                      readOnly
                      size="small"
                      sx={{ verticalAlign: 'middle' }}
                      data-testid="rating"
                    />
                    <Typography
                      variant="body3"
                      display="inline"
                      sx={{ color: 'grey', verticalAlign: 'middle' }}
                      pl={1}
                      data-testid="total-ratings"
                    >
                      (
                      {listing.totalRatings}
                      )
                    </Typography>
                  </a>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 1,
                    mb: 2,
                  }}
                >
                  {callButton({ listing, currentUser })}
                  <Typography variant="body3" sx={{ mt: 0.5 }} data-testid="listing-price">
                    {toCurrency(listing.pricePerMinute)}
                    {' '}
                    <span style={{ color: 'var(--color_gray_nine)' }}>per min.</span>
                  </Typography>
                </Box>
                {!listing.blocked && (
                  <Stack direction="row" mt={2} data-testid="call-stack">
                    <Stack direction="column" spacing={0.5} sx={{ mr: 1 }}>
                      <Typography variant="body3">
                        Call me directly
                      </Typography>
                      <Typography variant="body3">
                        <a href={`tel:${voicePortalPhoneNumber}`}>
                          {voicePortalPhoneNumber}
                        </a>
                      </Typography>
                      <Typography variant="body3">
                        ext:&nbsp;
                        {listing.serviceExtension}
                      </Typography>
                    </Stack>
                    <ListingCallMeTooltip
                      listing={listing}
                      voicePortalPhoneNumber={voicePortalPhoneNumber}
                      currentUser={currentUser}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <HorizontalLine />
        <Box sx={{ p: 2, backgroundColor: 'var(--body_background)' }} data-testid="listing-description">
          <Box
            sx={advisorDescriptionStyles}
            dangerouslySetInnerHTML={{ __html: listing.description }}
          />
        </Box>
        {currentUser && (
          <Box sx={{ p: 1, pr: 2, backgroundColor: 'var(--body_background)', textAlign: 'right' }}>
            <Typography variant="body3">
              <a onClick={onReportListingClick} role="link" tabIndex={0}>
                Report
              </a>
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

ListingDetailDrawer.defaultProps = {
  listing: null,
  currentUser: null,
  isOwnedByViewer: false,
};

ListingDetailDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  listing: PropTypes.object,
  onFeedbackClick: PropTypes.func.isRequired,
  onReportListingClick: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  voicePortalPhoneNumber: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  isOwnedByViewer: PropTypes.bool,
};

export default ListingDetailDrawer;
