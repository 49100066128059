import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';

import CategoryBreadcrumbs from 'app/components/categories/CategoryBreadcrumbs';
import ListingTypeTabs from 'app/components/listing_search/ListingTypeTabs';
import ListingSearchResults from 'app/components/listing_search/ListingSearchResults';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '50%',
    right: -3,
  },
}));

const StyledCheckboxChip = styled(Chip)(({ selected }) => ({
  marginRight: '8px',
  backgroundColor: selected ? '#e8e9fd' : '#eee',
  color: selected ? '#333' : '#333',
  '&:hover': {
    backgroundColor: '#63177a',
    color: '#fff',
  },
  '&:hover:active': {
    backgroundColor: '#63177a',
    color: '#fff',
  },
}));

const ListingSearch = forwardRef((props, ref) => {
  const {
    activeFilterCount,
    searchQueryText,
    searchError,
    selectedCategory,
    showClearIcon,
    typeId,
    setTypeId,
    page,
    setPage,
    handleSearchChange,
    handleSearchSubmit,
    handleClearClick,
    handleFilterClick,
    handleCategoryClick,
    handleSortChange,
    handlePageChange,
    sort,
    isLoading,
    TABS,
    showTabs,
    searchResults,
    listingsPerPage,
    currentUser,
    queryParams,
  } = props;

  let lastPage;
  if (!showTabs && searchResults) {
    lastPage = Math.ceil(searchResults.total_listings / listingsPerPage);
  }

  return (
    <section className="body-content body-content-darkerbg" ref={ref}>
      <CategoryBreadcrumbs breadcrumbs="Phone Sex" />
      <Grid container style={{ backgroundColor: 'var(--body_background)' }}>
        <Grid item xs={12} md={6} sx={{ px: 3, pt: 3, pb: 1.5 }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Find Flirts for phone sex"
            value={searchQueryText}
            onChange={handleSearchChange}
            onKeyPress={handleSearchSubmit}
            variant="outlined"
            error={!!searchError}
            helperText={searchError}
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            InputProps={{
              name: 'listing-search',
              inputMode: 'search',
              autoComplete: 'off',
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {showClearIcon && (
                    <IconButton
                      data-test-id="clear-button"
                      edge="end"
                      onClick={handleClearClick}
                      size="small"
                      aria-label="clear input"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton
                    data-test-id="filter-button"
                    edge="end"
                    onClick={handleFilterClick}
                    size="small"
                  >
                    <StyledBadge
                      color="secondary"
                      badgeContent={activeFilterCount}
                      max={99}
                    >
                      <TuneIcon />
                    </StyledBadge>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ pl: 3, pb: 1.5 }} style={{ borderBottom: '1px solid var(--color_eee)' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            {['Women', 'Men', 'Trans'].map((categoryLabel) => (
              <StyledCheckboxChip
                key={categoryLabel}
                label={categoryLabel}
                selected={selectedCategory === categoryLabel}
                onClick={() => handleCategoryClick(categoryLabel)}
                data-test-id={`category-${categoryLabel}`}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {showTabs ? (
            <ListingTypeTabs
              TABS={TABS}
              typeId={typeId}
              setTypeId={setTypeId}
              page={page}
              setPage={setPage}
              isLoading={isLoading}
              searchResults={searchResults}
              handleSortChange={handleSortChange}
              sort={sort}
              handlePageChange={handlePageChange}
              listingsPerPage={listingsPerPage}
              currentUser={currentUser}
              queryParams={queryParams}
            />
          ) : (
            <ListingSearchResults
              isLoading={isLoading}
              cardType="live"
              records={searchResults?.listings}
              page={page}
              lastPage={lastPage}
              handlePageChange={handlePageChange}
              listingsPerPage={listingsPerPage}
              currentUser={currentUser}
              queryParams={queryParams}
            />
          )}
        </Grid>

      </Grid>
    </section>
  );
});

// Assign a displayName to the forwardRef component
ListingSearch.displayName = 'ListingSearch';

ListingSearch.defaultProps = {
  searchResults: {},
  listingsPerPage: 8,
  currentUser: null,
};

ListingSearch.propTypes = {
  activeFilterCount: PropTypes.number.isRequired,
  searchQueryText: PropTypes.string.isRequired,
  searchError: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  showClearIcon: PropTypes.bool.isRequired,
  typeId: PropTypes.number.isRequired,
  setTypeId: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  handleClearClick: PropTypes.func.isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  TABS: PropTypes.object.isRequired,
  showTabs: PropTypes.bool.isRequired,
  searchResults: PropTypes.object,
  listingsPerPage: PropTypes.number,
  currentUser: PropTypes.object,
  queryParams: PropTypes.func.isRequired,
};

export default ListingSearch;
