import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const NameTypography = styled(Typography)({
  fontWeight: '300',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  position: 'relative',
  '& a': {
    color: '#444',
    textDecoration: 'none',
    '&:visited': {
      color: '#444',
    },
    '&:active': {
      color: '#444',
    },
    '&:hover': {
      color: 'var(--link_color)',
    },
  },
});

export default NameTypography;
