import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import FollowButtonContainer from './FollowButtonContainer';
import IconLink from 'app/components/shared/IconLink';
import PackageDetails from 'app/components/PackageDetails';
import RecurringTributeDetails from 'app/components/RecurringTributeDetails';
import {
  useGetAdvisorQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';
import useProfileImages from 'app/hooks/useProfileImages';
import useFlirtActions from 'app/hooks/useFlirtActions';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';

const TributeCardContainer = ({ tributeData, follow, currentFilter }) => {
  const { advisor_login: advisorName, advisor_id: advisorId } = tributeData;
  const { onTributeButtonClick } = useFlirtActions({ advisor: { id: advisorId, login: advisorName } });
  const { data: advisor } = useGetAdvisorQuery({ login: advisorName });
  const { acceptedAvatarImage: avatarImage, coverImage } = useProfileImages(advisor);
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const avatarStyles = { backgroundImage: `url('${avatarImage}')` };
  const coverStyles = { backgroundImage: `url('${coverImage}')` };

  const { openDialog } = useContext(DialogsContext);
  const {
    dispatch,
    setCurrentStep,
    setRecurring,
  } = useRecurringTributeManagement({ advisor });

  const onEditClick = () => {
    dispatch(setRecurring({ recurring: true }));
    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: advisorName },
    });

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE,
    }));
  };

  const cardContent = tributeData.package ? (
    <PackageDetails
      packageData={tributeData}
      currentFilter={currentFilter}
      currentUser={currentUser}
      onEditClick={onEditClick}
    />
  ) : (
    <RecurringTributeDetails
      currentFilter={currentFilter}
      currentUser={currentUser}
      onEditClick={onEditClick}
      tributeData={tributeData}
    />
  );

  return (
    <div className="card">
      <div className="nfp-card-hero-bg subscriptions" style={coverStyles}><div className="nfp-hero-opac">&nbsp;</div></div>
      <div className="nfp-card">
        <div className="container-max margintop25">
          <div className="container-fluid container-main-info">
            <div className="row">
              <div className="col-xs-4 center-xs first-part">
                <div className="nfp-avatar-hero" style={avatarStyles}>&nbsp;</div>
              </div>
              <div className="col-xs-8 start-xs second-part">
                <Link to={`/${advisorName}`}>
                  <h2>{advisorName}</h2>
                </Link>
                <ul className="nav-card-hero">
                  <li>
                    <AuthenticatedLink
                      role="link"
                      tabIndex={0}
                      title="Pay a Tribute"
                      currentUser={currentUser}
                      onClick={onTributeButtonClick}
                    >
                      <img
                        src="/plus_frontend/assets/svg/ico-top-tribute.svg"
                        alt="Pay a Tribute"
                        className="nav-hero-icon"
                      />
                    </AuthenticatedLink>
                  </li>
                  <li>
                    <IconLink
                      title="Chat"
                      href={`/chat/${advisor?.slug}`}
                      iconSrc="/plus_frontend/assets/svg/ico-top-chat.svg"
                      altText="Chat"
                      imgClasses="nav-hero-icon"
                    />
                  </li>
                  <li>
                    <IconLink
                      title="Call"
                      href={advisorName ? `/${advisorName}#live` : '#'}
                      iconSrc="/plus_frontend/assets/svg/ico-top-call.svg"
                      altText="Call"
                      imgClasses="nav-hero-icon"
                    />
                  </li>
                  <li>
                    <FollowButtonContainer
                      followData={follow}
                      tributeData={tributeData}
                      fetchStateFromApi={false}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-max">
          {cardContent}
        </div>
      </div>
    </div>
  );
};

TributeCardContainer.defaultProps = {
  follow: null,
};

TributeCardContainer.propTypes = {
  tributeData: PropTypes.object.isRequired,
  follow: PropTypes.object,
  currentFilter: PropTypes.string.isRequired,
};

export default TributeCardContainer;
