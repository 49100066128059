import React from 'react';
import { PropTypes } from 'prop-types';

const GoodyBagButtonPreview = ({
  currentUserId,
  url,
  sender,
}) => {
  const parts = url.split('-');
  const id = parts[parts.length - 1];
  const urlParts = parts[0].split('/');
  const userId = parseInt(urlParts[urlParts.length - 1]);
  const isOwner = userId === currentUserId;

  if (!currentUserId) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  return (
    <iframe title={`Link to goody bag ${id}`} src={`/fm/goody_bags/${id}/preview?current_user_id=${currentUserId}&sender_name=${sender}`} className={isOwner ? 'owner-goody-bag-iframe' : 'goody-bag-iframe'} />
  );
};

GoodyBagButtonPreview.propTypes = {
  url: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  sender: PropTypes.string.isRequired,
};

export default GoodyBagButtonPreview;
