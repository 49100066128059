import React from 'react';
import PropTypes from 'prop-types';

import CallListingShape from 'app/shapes/CallListingShape';

import {
  Grid,
  Stack,
} from '@mui/material';

import Typography from '@mui/material/Typography';

const ListenTime = ({
  listing,
  isLoading,
}) => {
  return (
    <Grid
      container
      spacing={2}
      maxWidth={550}
    >
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          style={{ textAlign: 'right' }}
        >
          Listen Time
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography variant="body1">
            {isLoading ? '-' : `${listing.recording_length} ${listing.recording_length === 1 ? 'Minute' : 'Minutes'}`}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

ListenTime.defaultProps = {
  listing: null,
};

ListenTime.propTypes = {
  listing: CallListingShape,
  isLoading: PropTypes.bool.isRequired,
};

export default ListenTime;
