import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Stack, Box, Typography, TextField, Paper } from '@mui/material';
import { debounce } from 'lodash';

import { toCurrency } from 'app/helpers/currencyHelpers';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {
  useSaveCustomerMutation,
  useGetCustomTagsQuery,
} from 'app/api/customersApi';

import CustomerBarChart from 'app/components/customers/CustomerBarChart';
import CreatableTagSelect from 'app/components/customers/CreatableTagSelect';
import HoverChip from 'app/components/customers/HoverChip';
import CircularProgress from 'app/components/shared/CircularProgress';

const CustomerDetailsOverview = ({ customer }) => {
  const { login } = useParams(); // userId will capture the value of :userId in the URL
  const { data: tags, isLoading: tagsLoading } = useGetCustomTagsQuery();
  const tagNames = tags?.map((tag) => tag.name);
  const [performSaveCustomer] = useSaveCustomerMutation();
  const [note, setNote] = useState(customer.note);

  const tagList = (customer.tag_list || '').split('^').filter((tag) => tag);
  const systemTagList = (customer.system_tag_list || '').split('^').filter((tag) => tag);

  useEffect(() => {
    if (!customer) return null;
    if (customer.note === note) return null;

    const debouncedFunction = debounce(() => {
      performSaveCustomer({ login, note });
    }, 500, { trailing: true });
    debouncedFunction();

    return debouncedFunction.cancel; // cancel existing debounces
  }, [note]);

  if (tagsLoading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="100%"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ px: { xs: 2, sm: 4 } }}>
      <Paper
        variant="outlined"
        sx={{
          mb: '10px',
          p: 2,
        }}
      >
        <Typography
          marginLeft="10px"
          bgcolor="white"
          flexGrow={0}
          marginRight="0px"
          color="text.secondary"
        >
          Relative earnings by channel
        </Typography>
        <div
          style={{
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'stretch',
          }}
        >
          <CustomerBarChart customer={customer} />
        </div>
      </Paper>
      <Box sx={{ pt: 3, pb: 2, px: 3, backgroundColor: '#f8f8f8' }}>
        { customer.package_id && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CurrencyExchangeIcon />
            <Typography
              variant="body2"
              sx={{ marginLeft: 1, color: 'var(--color_gray_six)' }}
            >
              Pkg&nbsp;#
              { customer.package_id }
              :&nbsp;
              <span style={{ color: 'var(--color_gray_two)' }}>
                { toCurrency(customer.rate) }
              </span>
            </Typography>
          </Box>
        )}
        <Stack spacing={1}>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Total Earnings:
            </span>
            <span style={{ float: 'right' }}>{ toCurrency(customer.total_earnings) }</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Earnings last 7D:
            </span>
            <span style={{ float: 'right' }}>{ toCurrency(customer.skr_7D_earnings) }</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Earnings last 30D:
            </span>
            <span style={{ float: 'right' }}>{ toCurrency(customer.skr_30D_earnings) }</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Earnings last 90D:
            </span>
            <span style={{ float: 'right' }}>{ toCurrency(customer.skr_90D_earnings) }</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Total earn days:
            </span>
            <span style={{ float: 'right' }}>{ customer.num_earn_days }</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Last transaction:
            </span>
            <span style={{ float: 'right' }}>
              { customer.days_since_last_earn }
              &nbsp;day
              { customer.days_since_last_earn > 1 ? 's' : ''}
              &nbsp;ago
            </span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Last contact (any):
            </span>
            <span style={{ float: 'right' }}>{ customer.last_contact_date }</span>
          </Typography>
          <Typography variant="body2">
            <span style={{ color: 'var(--color_gray_six)' }}>
              Customer since:
            </span>
            <span style={{ float: 'right' }}>{ customer.customer_since }</span>
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        { systemTagList.length > 0 && (
          <Stack
            direction="row"
            spacing="16px"
            alignItems="center"
            justifyContent="flex-start"
            paddingTop="16px"
            useFlexGap
            sx={{ flexWrap: 'wrap' }}
          >
            {systemTagList.map((tag) => (
              <HoverChip
                key={`system_tag_${tag}`}
                label={tag}
              />
            ))}
          </Stack>
        )}
        <CreatableTagSelect
          label="Tags"
          options={tagNames}
          seekerIds={[customer.seeker_id]}
          value={tagList}
          useSaveButton={false}
        />
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Notes"
          id="outlined-input"
          InputProps={{
            readOnly: false,
            sx: { fontSize: '1em' },
          }}
          disabled={false}
          multiline
          helperText=""
          margin="normal"
          InputLabelProps={{ sx: { fontSize: '1em' } }}
          value={note}
          onChange={(evt) => { setNote(evt.target.value); }}
        />
      </Box>
    </Box>
  );
};

CustomerDetailsOverview.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default CustomerDetailsOverview;
