import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelizeKeys from 'app/util/camelizeKeys';
// import rejectUndefinedKeys from 'app/util/rejectUndefinedKeys';
// import buildTrackingString from 'app/util/buildTrackingString';

import {
  CUSTOMER_LISTS_TAG,
  CUSTOMER_SAVED_SEARCHES_TAG,
  CUSTOMER_TAG,
} from './sharedApiTags';

// import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

// const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/client_api/customers' }),
  tagTypes: [CUSTOMER_SAVED_SEARCHES_TAG, CUSTOMER_LISTS_TAG], // Add tagTypes here
  endpoints: (builder) => ({
    assignTags: builder.mutation({
      invalidatesTags: [CUSTOMER_LISTS_TAG],
      query: (params) => ({
        url: '/tags',
        method: 'POST',
        body: params,
      }),
    }),

    createBlock: builder.mutation({
      // TODO: Add tag invalidation when we have a fetch-single-customer api endpoint
      query: (params) => ({
        url: '/blockings',
        method: 'POST',
        body: params,
      }),
    }),

    destroyBlock: builder.mutation({
      // TODO: Add tag invalidation when we have a fetch-single-customer api endpoint
      query: (params) => ({
        url: '/blockings',
        method: 'DELETE',
        body: params,
      }),
    }),

    destroyTag: builder.mutation({
      invalidatesTags: [CUSTOMER_LISTS_TAG],
      query: (params) => ({
        url: `/tags/${params.name}`,
        method: 'DELETE',
      }),
    }),

    destroySavedSearch: builder.mutation({
      invalidatesTags: [CUSTOMER_SAVED_SEARCHES_TAG],
      query: (params) => ({
        url: `/saved_searches/${params.id}`,
        method: 'DELETE',
      }),
    }),

    getSearchResults: builder.mutation({
      query: (params) => ({
        url: '/search',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelizeKeys,
    }),

    getAllSearchResults: builder.mutation({
      query: (params) => ({
        url: '/search?simple_results=true',
        method: 'POST',
        body: params,
      }),
      transformResponse: camelizeKeys,
    }),

    getLogins: builder.query({
      query: ({ login }) => `/logins${login ? `?login=${login}` : ''}`,
      transformResponse: camelizeKeys,
    }),

    getCustomer: builder.query({
      providesTags: (_result, _error, args) => (
        [{ type: CUSTOMER_TAG, id: args.login }]
      ),
      query: ({ login }) => `/${login}`,
      transformResponse: camelizeKeys,
    }),

    // Tags are just Customer Lists under the hood,
    // but this endpoint only gets tags that are user-generated, not the system ones
    getCustomTags: builder.query({
      providesTags: [CUSTOMER_LISTS_TAG],
      query: () => '/tags',
      transformResponse: camelizeKeys,
    }),

    getDetailsHistory: builder.query({
      query: ({ login }) => `/details_history/${login}`,
      transformResponse: camelizeKeys,
    }),

    getDetailsOffers: builder.query({
      query: ({ login }) => `/details_offers/${login}`,
      transformResponse: camelizeKeys,
    }),

    getPackages: builder.query({
      query: () => '/packages',
      transformResponse: camelizeKeys,
    }),

    getSavedSearches: builder.query({
      providesTags: [CUSTOMER_SAVED_SEARCHES_TAG],
      query: () => '/saved_searches',
      transformResponse: camelizeKeys,
    }),

    saveCustomer: builder.mutation({
      query: ({ login, note }) => ({
        url: `/${login}`,
        method: 'PATCH',
        body: { note },
      }),
    }),

    sendChatMessage: builder.mutation({
      query: (params) => ({
        url: '/chat_messages',
        method: 'POST',
        body: params,
      }),
    }),

    createSearch: builder.mutation({
      invalidatesTags: [CUSTOMER_SAVED_SEARCHES_TAG],
      query: (params) => ({
        url: '/saved_searches',
        method: 'POST',
        body: params,
      }),
    }),

    updateSearch: builder.mutation({
      invalidatesTags: [CUSTOMER_SAVED_SEARCHES_TAG],
      query: (params) => ({
        url: `/saved_searches/${params.id}`,
        method: 'PATCH',
        body: params,
      }),
    }),

    updateTag: builder.mutation({
      invalidatesTags: [CUSTOMER_LISTS_TAG],
      query: (params) => ({
        url: `/tags/${params.tagName}`,
        method: 'PATCH',
        body: { newName: params.newName },
      }),
    }),
  }),
});

export default customersApi;

export const {
  useAssignTagsMutation,
  useCreateBlockMutation,
  useDestroyBlockMutation,
  useDestroySavedSearchMutation,
  useDestroyTagMutation,
  useGetCustomTagsQuery,
  useGetCustomerQuery,
  useGetDetailsHistoryQuery,
  useGetDetailsOffersQuery,
  useGetLoginsQuery,
  useGetPackagesQuery,
  useGetSavedSearchesQuery,
  useGetSearchResultsMutation,
  useGetAllSearchResultsMutation,
  useSaveCustomerMutation,
  useCreateSearchMutation,
  useSendChatMessageMutation,
  useUpdateSearchMutation,
  useUpdateTagMutation,
} = customersApi;
