import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link } from 'react-router-dom';

import {
  useGetPublicPackagesForAdvisorsQuery,
  useGetActiveTributesForAdvisorQuery,
} from 'app/api/mainApi';
import PackagesContainer from './PackagesContainer';
import ActiveSubscriptionDetails from 'app/components/packages/ActiveSubscriptionDetails'; // Import the new component
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';

const FeedPackagesContainer = ({ advisor, currentUser }) => {
  const { data: packages } = useGetPublicPackagesForAdvisorsQuery({ advisorIds: advisor?.id });
  const { data: activeTributes } = useGetActiveTributesForAdvisorQuery({ advisorLogin: advisor?.login });
  const lowestPrice = packages?.filter((pkg) => pkg.feedPerk).find(Boolean)?.rate;
  const [expanded, setExpanded] = useState(false);

  const { openDialog } = useContext(DialogsContext);
  const {
    closeDialogAndResetTributeSlice,
    dispatch,
    setCurrentStep,
    setPackageId,
    setPackageRate,
    setRecurring,
  } = useRecurringTributeManagement({ advisor });

  const onSubscriptionEditClick = (packageId, packageRate) => {
    closeDialogAndResetTributeSlice();
    dispatch(setRecurring({ recurring: true }));
    dispatch(setPackageId({ packageId }));
    dispatch(setPackageRate({ packageRate }));
    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: advisor.login },
    });

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_PACKAGE_SUBSCRIPTION,
    }));
  };

  const handleToggle = (event) => {
    event.preventDefault();
    setExpanded((prev) => !prev);
  };

  if (packages?.length < 1) return null;

  return (
    <Container sx={{ mt: 2, mb: 2 }}>
      {!activeTributes?.packageSubscription ? (
        <Box data-testid="explorePackageBox">
          <strong>Want full Feed access?</strong>
          &nbsp;
          <Link to="#" onClick={handleToggle}>Explore packages</Link>
          {' '}
          from
          {' '}
          {lowestPrice}
          {expanded && (
            <IconButton onClick={handleToggle}>
              <ExpandLessIcon />
            </IconButton>
          )}
          {expanded && <PackagesContainer advisor={advisor} fromFeed />}
        </Box>
      ) : (
        <ActiveSubscriptionDetails
          currentUser={currentUser}
          onSubscriptionEditClick={onSubscriptionEditClick}
          packageSubscription={activeTributes.packageSubscription}
        />
      )}
    </Container>
  );
};

FeedPackagesContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default FeedPackagesContainer;
