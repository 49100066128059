import React from 'react';
import {Button, DialogContent, Stack, Typography} from '@mui/material';
import { navigateReplace } from 'app/util/navigation';

const ResetPasswordCompleteDialog = () => {
  return (
    <DialogContent>
      <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
        <a href="/">
          <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
        </a>
        <Typography variant="h3">
          Email Sent
        </Typography>
        <Typography variant="body1" align="center">
          Thank You! If we have this email on file, we will email instructions to reset your password.
        </Typography>
      </Stack>

      <Stack direction="column" spacing={3}>
        <Button
          variant="outlined"
          size="large"
          fullWidth
          onClick={() => navigateReplace('login')}
        >
          Back to Sign In
        </Button>
      </Stack>
    </DialogContent>
  )
}
export default ResetPasswordCompleteDialog;
