import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const DecisionScreen = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        mt: 3,
        maxWidth: '558px',
        mx: 'auto',
      }}
    >
      <Typography variant="h2" sx={{ mt: 1, ml: 1 }}>
        What&apos;s Next?
      </Typography>
      <Box sx={{ p: 1, mt: 2 }}>
        <Typography variant="body1">
          A.&nbsp;
          <a href={window.location.href}>
            Browse
          </a>
          &nbsp;
          or&nbsp;
          <a href="/search">
            search
          </a>
          &nbsp;
          for more listings&nbsp;
          <em>OR</em>
        </Typography>
      </Box>
      <Box sx={{ p: 1 }}>
        <Typography variant="body1">
          B. Learn more about NiteFlirt in the video below&nbsp;
          <em>OR</em>
        </Typography>
        <Box sx={{ mt: 1 }}>
          <iframe
            allowFullScreen
            allowTransparency="true" // eslint-disable-line react/no-unknown-property
            className="vzaar-video-player"
            frameBorder="0"
            height="306"
            id="vzvd-966101"
            name="vzvd-966101"
            src={`${window.location.protocol}//view.vzaar.com/966101/player?autoplay=1`}
            title="vzaar video player"
            type="text/html"
            style={{ maxWidth: '546px', width: '100%', border: '1px solid black' }}
          />
        </Box>
      </Box>
      <Box sx={{ p: 1 }}>
        <Typography variant="body1">
          C. Register to receive a special offer&nbsp;
          <a href="/signup">
            HERE!
          </a>
        </Typography>
      </Box>
    </Paper>
  );
};

export default DecisionScreen;
