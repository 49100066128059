import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import {
  useUpdateRecurringPackageSubscriptionMutation,
} from 'app/api/mainApi';

import EditPackageSubscription from 'app/components/modals/tributes/EditPackageSubscription';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const EditPackageSubscriptionContainer = ({ advisor }) => {
  const {
    activePackageSubscription,
    closeDialogAndResetTributeSlice,
    currentUserHasCard,
    dispatch,
    publicPackages,
    setCurrentStep,
    setManageCardsStep,
    setPackageId,
    setPackageRate,
    showBillingError,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const [
    updateRecurringPackageSubscription,
    updateRecurringPackageSubscriptionResult,
  ] = useUpdateRecurringPackageSubscriptionMutation();

  const otherPackages = publicPackages.filter((p) => {
    return p.id !== activePackageSubscription?.package?.id;
  });

  const onCancelSubscriptionClick = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CANCEL_PACKAGE_SUBSCRIPTION,
    }));
  };

  const onRenewSubscriptionClick = () => {
    updateRecurringPackageSubscription({
      advisorId: advisor.id,
      packageId: activePackageSubscription?.id,
      precedingPackageSubscriptionId: activePackageSubscription?.id,
      action: 'renew',
    });
  };

  const onUpdateCardClick = () => {
    setManageCardsStep(TRIBUTE_WORKFLOW_STEPS.EXIT_TRIBUTE_WORKFLOW);
    useDifferentCardClick();
  };

  const closeDialog = () => {
    closeDialogAndResetTributeSlice();
  };

  const changePackageClick = (packageId, packageRate) => {
    dispatch(setPackageId({ packageId }));
    dispatch(setPackageRate({ packageRate }));

    if (!currentUserHasCard) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
      }));
    }

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CHANGE_PACKAGE_SUBSCRIPTION,
    }));
  };

  useEffect(() => {
    if (updateRecurringPackageSubscriptionResult.isSuccess) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'Your Package has been resumed.',
        severity: 'success',
      }));
    }

    if (updateRecurringPackageSubscriptionResult.isError) {
      if (updateRecurringPackageSubscriptionResult.error?.data?.error === 'User account blocked') {
        closeDialogAndResetTributeSlice();

        dispatch(showSnackbarMessage({
          message: updateRecurringPackageSubscriptionResult.error?.data?.flash?.message,
          severity: 'error',
        }));
      }

      showBillingError();
    }
  }, [updateRecurringPackageSubscriptionResult]);

  return (
    <EditPackageSubscription
      activePackageSubscription={activePackageSubscription}
      closeDialog={closeDialog}
      onCancelSubscriptionClick={onCancelSubscriptionClick}
      onRenewSubscriptionClick={onRenewSubscriptionClick}
      otherPackages={otherPackages}
      changePackageClick={changePackageClick}
      useDifferentCardClick={onUpdateCardClick}
    />
  );
};

EditPackageSubscriptionContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default EditPackageSubscriptionContainer;
