import React from 'react';
import { Route } from 'react-router-dom';

import CallNowLayout from 'app/features/call_now/components/CallNowLayout';

export const CallNowRoutes = () => (
  <Route
    path="/call/listing/:id"
    element={<CallNowLayout />}
  />
);
