import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, Tab, IconButton, Badge, Stack, Grid, Typography, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';

import { useGoodyBagSearchMutation } from 'app/api/assetApi';
import { useGetAppConfigQuery, useGetAvailableBalanceQuery } from 'app/api/mainApi';
import { toNumber } from 'app/helpers/currencyHelpers';

import DialogsContext from 'app/contexts/DialogsContext';
import CustomTabs from 'app/components/goody_search/CustomTabs';
import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import TabContent from 'app/components/TabContent';
import GenderSelectChips from 'app/components/GenderSelectChips';
import GoodyBagFilters from 'app/components/modals/GoodyBagFilters';
import GoodyBags from 'app/components/goody_search/GoodyBags';
import SortControl from 'app/components/goody_search/SortControl';
import GoodySearchContext from 'app/contexts/GoodySearchContext';
import CircularProgress from 'app/components/shared/CircularProgress';
import useDarkerBodyClass from 'app/hooks/useDarkerBodyClass';

import {
  setText,
  setSort,
  clearFilters,
  clearAllButText,
  initializeFromParams,
  filtersSelector,
  advancedFilterCountSelector,
  anyFiltersSelector,
  setCategory,
  setResultCounts,
  categorySelector,
  sortSelector,
  urlSelector,
} from 'app/slices/goodyBagFilterSlice';
import NumberlessPagination from '../components/NumberlessPagination';
import NoSearchResultsFound from '../components/NoSearchResultsFound';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '90%',
    right: 0,
  },
}));

const GoodiesTabsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(0),
  backgroundColor: 'var(--body_background)',
}));

const StyledTabContent = styled(TabContent)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
}));

const tabIndexFromFragment = (fragment) => {
  switch (fragment) {
  case 'recent_additions':
    return 0;
  case 'top_goodies_this_week':
    return 1;
  case 'most_liked_this_month':
    return 2;
  default:
    return 0;
  }
};

const DEFAULT_PAGE_SIZE = 60;
const FILTERED_PAGE_SIZE = 12;

const GoodySearchContainer = () => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const balanceData = currentUser ? useGetAvailableBalanceQuery() : {};
  const rawAvailableBalance = balanceData?.data?.available_balance;
  const availableBalance = toNumber(rawAvailableBalance);

  const fragment = window.location.hash.substring(1);
  const [selectedTab, setSelectedTab] = useState(fragment);
  const { openDialog } = useContext(DialogsContext);
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector) || {};
  const advancedFilterCount = useSelector(advancedFilterCountSelector);
  const anyFilters = !!useSelector(anyFiltersSelector);
  const category = useSelector(categorySelector);
  const sort = useSelector(sortSelector);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // This is for the functionality of hiding the sort label after the sort changes.
  const [sortChanged, setSortChanged] = useState(false);
  const {
    text,
    price,
    media,
    rating,
    dominance_tags,
    age_tags,
    race_tags,
    content_rating,
    publication_date,
    kink_tags,
  } = filters;

  const PAGE_SIZE = anyFilters ? FILTERED_PAGE_SIZE : DEFAULT_PAGE_SIZE;

  // On page load, initialize the filters from the URL params
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');

  // Get the URL from the urlSelector
  const urlFromQuery = useSelector(urlSelector);

  // we don't need to avoid an expensive calculation- instead we're just trying to avoid excessive
  // re-renders by turning this string that is passed in to a child component into a persistent object
  const contextProps = useMemo(
    () => ({
      action,
      returnUrlTemplate: `/goody/search?${urlFromQuery}&action=buy&goodyBagId=:goodyBagId${fragment ? `#${fragment}` : '#recent_additions'}`,
    }),
    [urlFromQuery, fragment],
  );

  useEffect(() => {
    dispatch(initializeFromParams(searchParams));
  }, []);

  const [
    goodyBagSearch,
    {
      data: goodyBagSearchResults,
      isSuccess: goodyBagSearchResultsSuccess,
      isLoading: goodyBagSearchResultsLoading,
    },
  ] = useGoodyBagSearchMutation();

  const handleTextChange = (evt) => {
    dispatch(setText(evt.target.value));
  };

  const handleFilterClick = () => {
    openDialog({ component: GoodyBagFilters });
  };

  const handleCategoryChange = (category) => {
    dispatch(clearAllButText());
    dispatch(setCategory(category));
  };

  const handleTabChange = (_event, newValue) => {
    const newSelectedTab = [
      'recent_additions',
      'top_goodies_this_week',
      'most_liked_this_month',
    ][newValue] || 'recent_additions';

    window.location.hash = newSelectedTab;
    setPage(1);
    setSelectedTab(newSelectedTab);
  };

  const triggerGoodyBagSearch = (searchPage = 1) => {
    // Business rule: They have to use an advanced filter, or type at least 3 characters
    goodyBagSearch({ ...filters, category, sort, page: searchPage, page_size: PAGE_SIZE });
  };

  const updatePageAndSearch = (newPage) => {
    setPage(newPage);
    triggerGoodyBagSearch(newPage);
  };

  const resultsBox = (results) => (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { sm: 'flex-start' },
          mt: 2,
          '@media (max-width: 900px)': {
            justifyContent: 'center',
          },
        }}
      >
        <GoodyBags
          goodyBags={results}
          currentUser={currentUser}
          availableBalance={availableBalance}
        />
      </Box>
      {
        anyFilters && ((goodyBagSearchResults?.misc?.num_pages || 1) > 1) && (
          <Box display="flex" justifyContent="start" mt={2}>
            <NumberlessPagination onChange={(event, value) => updatePageAndSearch(value)} page={page} hasNext={(goodyBagSearchResults?.misc?.page || 1) < (goodyBagSearchResults?.misc?.num_pages || 1)} />
          </Box>
        )
      }
    </div>
  );

  useDarkerBodyClass(); // This applies the body-content-darkbg class

  useEffect(() => {
    setPage(1);
    const debouncedFunction = debounce(triggerGoodyBagSearch, 500, { trailing: true });
    if (text.length === 0 || text.length >= 3) {
      debouncedFunction();
    }
    return debouncedFunction.cancel; // cancel existing debounces
  }, [
    text,
    price,
    media,
    rating,
    dominance_tags,
    age_tags,
    race_tags,
    content_rating,
    publication_date,
    kink_tags,
    sort,
    category,
  ]);

  // save facet/filter count data when it is returned along with results
  useEffect(() => {
    if (goodyBagSearchResultsSuccess && goodyBagSearchResults) {
      dispatch(setResultCounts(goodyBagSearchResults.facet));

      // Update the URL without reloading the page
      window.history.pushState(null, '', `?${urlFromQuery}`);
    }
  }, [goodyBagSearchResultsSuccess]);

  return (
    <AppBody>
      <TemplateShell2 header="Goodies" showBackArrow={false}>
        <Box style={{ backgroundColor: 'var(--body_background)' }}>
          <Grid
            container
            sx={{
              pt: 2,
              px: { xs: 2, sm: 3 },
              mt: 1.5,
            }}
            md={6}
            sm={12}
            xs={12}
          >
            <Grid item xs={12}>
              <TextField
                variable="outlined"
                autoComplete="off"
                placeholder={isMobile ? 'Find videos, photos, and Goodies' : 'Find videos, photos, and other Goodies'}
                fullWidth
                size="small"
                value={text}
                onChange={handleTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      { anyFilters && (
                        <IconButton
                          edge="end"
                          onClick={() => dispatch(clearFilters())}
                          size="small"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        edge="end"
                        onClick={handleFilterClick}
                        size="small"
                      >
                        <StyledBadge
                          color="secondary"
                          badgeContent={advancedFilterCount}
                          max={99}
                        >
                          <TuneIcon />
                        </StyledBadge>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              px: { xs: 2, sm: 3 },
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <GenderSelectChips state={category} setState={handleCategoryChange} />
                </Box>
                <Box>
                  { goodyBagSearchResults?.results && (
                    <SortControl
                      sort={sort}
                      setSort={(value) => {
                        setSortChanged(true);
                        dispatch(setSort(value));
                      }}
                      hideLabel={sortChanged}
                    />
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <GoodySearchContext.Provider value={contextProps}>
          {
            goodyBagSearchResultsLoading && (
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <CircularProgress />
                  <Typography variant="body1" data-test-id="loading-text" sx={{ mt: 2 }}>
                    Loading...
                  </Typography>
                </Box>
              </Box>
            )
          }

          {
            !goodyBagSearchResultsLoading && goodyBagSearchResults?.results?.length === 0 && (
              <NoSearchResultsFound />
            )
          }

          {goodyBagSearchResults?.results && (
            resultsBox(goodyBagSearchResults.results)
          )}

          {goodyBagSearchResults?.carousels && (
            <>
              <GoodiesTabsBox>
                <CustomTabs
                  value={tabIndexFromFragment(selectedTab)}
                  onChange={handleTabChange}
                  aria-label="Goody Sort Tabs"
                >
                  <Tab label="Recent Goodies" sx={{ flex: 1 }} />
                  <Tab label="Top Goodies" sx={{ flex: 1 }} />
                  <Tab label="Most Liked" sx={{ flex: 1 }} />
                </CustomTabs>
              </GoodiesTabsBox>

              <Box display="flex" justifyContent="center">
                <StyledTabContent value={tabIndexFromFragment(selectedTab)} index={0}>
                  {resultsBox(goodyBagSearchResults.carousels[0].results)}
                </StyledTabContent>
                <StyledTabContent value={tabIndexFromFragment(selectedTab)} index={1}>
                  {resultsBox(goodyBagSearchResults.carousels[1].results)}
                </StyledTabContent>
                <StyledTabContent value={tabIndexFromFragment(selectedTab)} index={2}>
                  {resultsBox(goodyBagSearchResults.carousels[2].results)}
                </StyledTabContent>

              </Box>
            </>
          )}
        </GoodySearchContext.Provider>
      </TemplateShell2>
    </AppBody>
  );
};

export default GoodySearchContainer;
