import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import PackageSubscriptionButton from 'app/components/packages/PackageSubscriptionButton';

import { useGetAppConfigQuery } from 'app/api/mainApi';

import { lowestPricePackageForAdvisorSelector } from 'app/slices/feedSlice';

const PackageSubscriptionButtonContainer = ({ advisor }) => {
  const { openDialog } = useContext(DialogsContext);
  const {
    closeDialogAndResetTributeSlice,
    dispatch,
    setCurrentStep,
    setPackageId,
    setPackageRate,
    setRecurring,
  } = useRecurringTributeManagement({ advisor });

  const { data: config } = useGetAppConfigQuery();
  const currentUser = config?.current_user;
  const lowestPricePackage = useSelector((state) => lowestPricePackageForAdvisorSelector(state, advisor?.id));

  const subscribeToPackageClick = () => {
    closeDialogAndResetTributeSlice();
    dispatch(setRecurring({ recurring: true }));
    dispatch(setPackageId({ packageId: lowestPricePackage.id }));
    dispatch(setPackageRate({ packageRate: lowestPricePackage.rate }));

    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: advisor.login, packageId: lowestPricePackage.id },
    });

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE,
    }));
  };

  return (
    <PackageSubscriptionButton
      advisorPackage={lowestPricePackage}
      currentUser={currentUser}
      subscribeToPackageClick={subscribeToPackageClick}
    />
  );
};

PackageSubscriptionButtonContainer.defaultProps = {
  advisor: null,
};

PackageSubscriptionButtonContainer.propTypes = {
  advisor: PropTypes.object,
};

export default PackageSubscriptionButtonContainer;
