import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import NavContext from 'app/contexts/NavContext';
import SeekerExpandedNav from 'app/components/nav/SeekerExpandedNav';
import AdvisorExpandedNav from 'app/components/nav/AdvisorExpandedNav';
import UnauthorizedExpandedNav from 'app/components/nav/UnauthorizedExpandedNav';
import { setHasUnreadMessages } from 'app/slices/chatSlice';

import { useGetAppConfigQuery } from 'app/api/mainApi';

import {
  userIsSeeker,
  userIsAdvisor,
} from 'app/helpers/whitelistHelpers';

const ExpandedNavContainer = ({ children }) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const txnUrl = appConfig?.txn_url;
  const addMoneyUrl = `${txnUrl}/purchase/new`;

  if (currentUser) {
    dispatch(setHasUnreadMessages({ hasUnreadMessages: currentUser.has_unread_chat_messages }));
  }

  const toggleExpandedNav = () => setIsExpanded(!isExpanded);

  const closeAndNavigateTo = (path, pageRefresh = false, newTab = false) => {
    setIsExpanded(false);

    if (pageRefresh) {
      window.location.href = path;
    } else if (newTab) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  let navComponent;
  if (userIsSeeker(currentUser)) {
    navComponent = (
      <SeekerExpandedNav
        isExpanded={isExpanded}
        closeAndNavigateTo={closeAndNavigateTo}
        toggleExpandedNav={toggleExpandedNav}
        currentUser={currentUser}
        addMoneyUrl={addMoneyUrl}
        balance={appConfig?.available_balance}
      />
    );
  } else if (userIsAdvisor(currentUser)) {
    navComponent = (
      <AdvisorExpandedNav
        isExpanded={isExpanded}
        closeAndNavigateTo={closeAndNavigateTo}
        toggleExpandedNav={toggleExpandedNav}
        currentUser={currentUser}
        addMoneyUrl={addMoneyUrl}
        balance={appConfig?.available_balance}
      />
    );
  } else {
    navComponent = (
      <UnauthorizedExpandedNav
        isExpanded={isExpanded}
        closeAndNavigateTo={closeAndNavigateTo}
        toggleExpandedNav={toggleExpandedNav}
        addMoneyUrl={addMoneyUrl}
      />
    );
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 900px)');

    const handleResize = () => {
      if (mediaQuery.matches) {
        setIsExpanded(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    mediaQuery.addEventListener('change', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <NavContext.Provider value={{ toggleExpandedNav, closeAndNavigateTo }}>
        {navComponent}
        {children}
      </NavContext.Provider>
    </>
  );
};

ExpandedNavContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

export default ExpandedNavContainer;
