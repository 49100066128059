import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  useCreateRecurringPackageSubscriptionMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import ConfirmTribute from 'app/components/modals/tributes/ConfirmTribute';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const ConfirmCreatePackageSubscriptionContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const cardType = currentUser?.default_card?.card_type;
  const lastFour = currentUser?.default_card?.last_four;

  const {
    afterManageCardsStep,
    closeDialogAndResetTributeSlice,
    currentUserHasCard,
    packageId,
    packageRate,
    showBillingError,
    setCurrentStep,
    setDeclined,
    setManageCardsStep,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const [
    createRecurringPackageSubscription,
    createRecurringPackageSubscriptionResult,
  ] = useCreateRecurringPackageSubscriptionMutation();

  const confirmTributeClick = () => {
    createRecurringPackageSubscription({
      advisorId: advisor.id,
      packageId,
    });
  };

  useEffect(() => {
    if (!currentUserHasCard && !afterManageCardsStep.length) {
      dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
      }));
      setManageCardsStep(TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_PACKAGE_SUBSCRIPTION);
    }
  }, []);

  useEffect(() => {
    if (createRecurringPackageSubscriptionResult.isSuccess) {
      dispatch(showSnackbarMessage({
        message: `${packageRate} has been sent to ${advisor.login}.`,
        severity: 'success',
      }));

      closeDialogAndResetTributeSlice();
    }

    if (createRecurringPackageSubscriptionResult.isError) {
      if (createRecurringPackageSubscriptionResult.error?.data?.error === 'gateway_declined') {
        dispatch(setDeclined({ declined: true }));
        showBillingError();
      }

      if (createRecurringPackageSubscriptionResult.error?.data?.error === 'User account blocked') {
        closeDialogAndResetTributeSlice();

        dispatch(showSnackbarMessage({
          message: createRecurringPackageSubscriptionResult.error?.data?.flash?.message,
          severity: 'error',
        }));
      }

      showBillingError();
    }
  }, [createRecurringPackageSubscriptionResult]);

  return (
    <ConfirmTribute
      amount={packageRate}
      cardType={cardType}
      lastFour={lastFour}
      isLoading={createRecurringPackageSubscriptionResult.isLoading}
      confirmTributeClick={confirmTributeClick}
      useDifferentCardClick={useDifferentCardClick}
      closeClick={closeDialogAndResetTributeSlice}
    />
  );
};

ConfirmCreatePackageSubscriptionContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ConfirmCreatePackageSubscriptionContainer;
