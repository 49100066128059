import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

function TabContent(props) {
  const { children, value, index, padding, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: padding }}>{children}</Box>
      )}
    </div>
  );
}

TabContent.defaultProps = {
  padding: 3,
};

TabContent.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  padding: PropTypes.number,
};

export default TabContent;
