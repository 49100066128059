export const productFromParams = () => {
  const queryParameters = new URLSearchParams(window.location.search);

  const productType = queryParameters.get('product_type');
  const productId = queryParameters.get('product_id');

  if (productType && productId) {
    return { productType, productId };
  }

  const listingId = queryParameters.get('listing_id');
  if (listingId) {
    return { productType: 'call', productId: listingId };
  }

  const advisorLogin = queryParameters.get('user_login');
  if (advisorLogin) {
    return { productType: 'chat', productId: advisorLogin };
  }

  return null;
};

export const getAdditionalParams = () => {
  const queryParameters = new URLSearchParams(window.location.search);

  return ['return_to', 'return_url', 'extra'].reduce((acc, key) => {
    const value = queryParameters.get(key);
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const loginParams = (product) => {
  const queryParams = {
    ...(getAdditionalParams()),
    ...(product && {
      product_type: product.productType,
      product_id: product.productId,
    }),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  return queryString ? `?${queryString}` : '';
};
