import { showFlash } from 'app/slices/flashSlice';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { resetTributeSliceState } from 'app/slices/tributesSlice';

const useFlirtActions = ({ advisor }) => {
  const dispatch = useDispatch();
  const { openDialog } = useContext(DialogsContext);
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const myOwnPost = currentUser?.id === advisor?.id;

  const onTributeButtonClick = () => {
    if (myOwnPost) {
      dispatch(showFlash({
        flashType: 'error',
        props: { message: 'We\'re sorry, you cannot send a tribute to yourself.' },
      }));
    } else {
      // Ensure that any state from a previous incomplete tribute is reset, most
      // commonly due to closing out of the `useCreditCardManagement` modal
      dispatch(resetTributeSliceState());

      openDialog({
        component: TributeWorkflowContainer,
        props: { advisorLogin: advisor.login },
      });
    }
  };

  return { onTributeButtonClick };
};

export default useFlirtActions;
