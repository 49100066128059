import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { PlayCircleOutline, Download, FolderZip, PlaylistAdd, Delete } from '@mui/icons-material';
import { useFilePlayerContext } from '../FilePlayerContext';

export const FileActionBar = () => {
  const { fileSelection } = useFilePlayerContext();
  const { selectedCount = 0, clearAll } = fileSelection;

  if (selectedCount === 0) return null;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'grey.200',
      padding: 1,
      borderRadius: 1,
    }}
    >
      {/* Left side: Clear selection button & count */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={clearAll}>
          <span>&lt;</span>
        </IconButton>
        <Typography variant="body1" sx={{ ml: 1 }}>
          {`${selectedCount} selected`}
        </Typography>
      </Box>

      {/* Right side: Action buttons */}
      <Box>
        <IconButton>
          <PlayCircleOutline />
        </IconButton>
        {selectedCount === 1 && (
          <IconButton>
            <Download />
          </IconButton>
        )}
        {selectedCount > 1 && (
          <IconButton>
            <FolderZip />
          </IconButton>
        )}
        <IconButton>
          <PlaylistAdd />
        </IconButton>
        <IconButton>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};
