import React from 'react';
import PropTypes from 'prop-types';

import MenuSelect from 'app/components/shared/MenuSelect';

const sortOptions = {
  0: 'Top Available',
  1: 'Highest Rating',
};

const ListingSort = ({
  handleSortChange,
  sort,
}) => {
  return (
    <MenuSelect
      onSelect={handleSortChange}
      menuItems={sortOptions}
      defaultValue={sort}
    />
  );
};

ListingSort.defaultProps = {
  sort: Object.keys(sortOptions)[1],
};

ListingSort.propTypes = {
  handleSortChange: PropTypes.func.isRequired,
  sort: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ListingSort;
