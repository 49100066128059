import camelize from 'app/util/camelize';

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.entries(obj).reduce((result, [key, value]) => {
      const camelKey = camelize(key);
      const transformedValue = camelizeKeys(value);

      /* eslint-disable no-param-reassign */
      result[camelKey] = transformedValue;
      result[key] = transformedValue;
      /* eslint-enable no-param-reassign */
      return result;
    }, {});
  }
  return obj;
};

export default camelizeKeys;
