import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useGetListingQuery } from 'app/api/mainApi';

const ListingDetailRedirector = () => {
  const { listingIdAndTitle } = useParams();

  if (!listingIdAndTitle) return null;

  const listingId = listingIdAndTitle.split('-')[0];
  const { data: listing, isLoading: listingLoading } = useGetListingQuery({ id: listingId });

  if (listingLoading) return null;

  if (!listing) return <Navigate to="/" />;

  return <Navigate to={`/${listing.login}?listing_id=${listing.id}`} />;
};

export default ListingDetailRedirector;
