import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem, IconButton } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

const SORTS = [
  ['1', 'Relevance'],
  ['2', 'Price: Low to High'],
  ['3', 'Price: High to Low'],
  ['6', 'Rating'],
  ['4', 'Newest Arrivals'],
  ['11', 'Most Popular'],
];

const SortControl = ({ sort, setSort }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (value) => {
    setSort(value);
    handleMenuClose();
  };

  return (
    <Box display="flex" sx={{ ml: 'auto' }}>
      <IconButton onClick={handleMenuOpen} aria-label="Sort options">
        <SortIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'sort-menu-button',
        }}
      >
        {SORTS.map(([key, value]) => (
          <MenuItem
            key={key}
            selected={key === sort}
            onClick={() => handleSortChange(key)}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

SortControl.defaultProps = {
  sort: '4', // Newest Arrivals
};

SortControl.propTypes = {
  sort: PropTypes.string,
  setSort: PropTypes.func.isRequired,
};

export default SortControl;
