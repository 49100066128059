import React, { useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { decodeHtml } from 'app/helpers/htmlHelpers';

import {
  BALANCE_CHANGE,
  PROFILE_IMAGE_CHANGE,
  HAS_UNREAD_CHAT_MESSAGES,
  CALL_COMING,
  CALL_FAILED,
  NEW_CHAT_MESSAGE,
} from 'app/constants/pubnubActionTypes';

import {
  APP_CONFIG_TAG,
  ADVISOR_TAG,
  AVAILABLE_BALANCE_TAG,
} from 'app/api/sharedApiTags';

import {
  useGetAppConfigQuery,
  useGetPubNubConfigQuery,
  ADVISOR_LISTINGS_TAG,
  AVAILABILITY_TAG,
} from 'app/api/mainApi';

import { setHasUnreadMessages } from 'app/slices/chatSlice';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const PubNubContainer = ({ children }) => {
  const pubNubConfig = useGetPubNubConfigQuery().data;
  const appConfig = useGetAppConfigQuery().data;
  const dispatch = useDispatch();
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  const handleCallNotifier = (msg) => {
    if (!msg.message.match || !msg.message.match(/^(call|availability)_\w+/)) return false;

    const [callType, content, login] = msg.message.split('<==>', 3);

    switch (callType) {
    // TODO: these calls to SanitizedHTML pass in options that disable all sanitizing.
    // But after recent enhancements to the component, I think we could delete options:{...}
    // and let it filter to the default of just links and text.  Although maybe we don't care
    // since the content is coming from our own server and is safe.
    case CALL_COMING:
      toast.info(<SanitizedHTML html={content} options={{ allowedTags: false, allowedAttributes: false }} />, { toastId: 'call_notifier_toast' });
      document.getElementById('callAlertSound').play();
      break;
    case CALL_FAILED:
      toast.info(<SanitizedHTML html={content} options={{ allowedTags: false, allowedAttributes: false }} />, { toastId: 'call_notifier_toast' });
      document.getElementById('callFailSound').play();
      break;
    case 'availability_change':
      dispatchInvalidateCacheTagsEvent([{ type: AVAILABILITY_TAG }]);
      break;
    case 'availability_change_chat':
      if (login) {
        dispatchInvalidateCacheTagsEvent([{ type: ADVISOR_LISTINGS_TAG, id: login.toLowerCase() }]);
      }
      break;
    default: return false;
    }

    return true;
  };

  const handlePubNubMessage = (msg) => {
    const { message } = msg;

    // eslint-disable-next-line no-console
    console.log('handlePubNubMessage in PubNubContainer', msg);

    switch (message.action) {
    case BALANCE_CHANGE:
      dispatchInvalidateCacheTagsEvent([APP_CONFIG_TAG, AVAILABLE_BALANCE_TAG]);
      break;
    case PROFILE_IMAGE_CHANGE:
      dispatchInvalidateCacheTagsEvent([ADVISOR_TAG]);
      break;
    case HAS_UNREAD_CHAT_MESSAGES:
      dispatch(setHasUnreadMessages({ hasUnreadMessages: message.payload.has_unread_chat_messages }));
      break;
    case NEW_CHAT_MESSAGE:
      let messageContent;
      switch (message.payload.message.message_class) {
      case 'photo_message':
        messageContent = (
          <>
            { decodeHtml(message.payload.contact.other_user.name) }
            &nbsp;sent you a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>photo!</a>
          </>
        );
        break;
      case 'gift_message':
        messageContent = (
          <>
            { decodeHtml(message.payload.contact.other_user.name) }
            &nbsp;sent you a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>tribute!</a>
          </>
        );
        break;
      case 'gift_request_message':
        messageContent = (
          <>
            { decodeHtml(message.payload.contact.other_user.name) }
            &nbsp;requested a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>tribute!</a>
          </>
        );
        break;
      default:
        messageContent = (
          <>
            You have a&nbsp;
            <a href={`/chat/${message.payload.contact.other_user.slug}`}>new chat message</a>
            &nbsp;from&nbsp;
            { decodeHtml(message.payload.contact.other_user.name) }
            :
            <br />
            { decodeHtml(message.payload.message.content_to_show_in_alert) }
          </>
        );
      }

      toast.info(messageContent);
      document.getElementById('newMessageSound').play();
      break;
    default:
      // if we don't handle the message, we'll log that it was unhandled
      if (!handleCallNotifier(msg)) {
        // eslint-disable-next-line no-console
        console.warn('unhandled pubnub message in PubNubContainer', msg);
      }
    }
  };

  const pubNubClient = useMemo(() => {
    if (!pubNubConfig || !appConfig) return null;

    // we need a userId to initialize the pubnub client,
    // so we'll use either the actual currentUser's id
    // or a placeholder string for an anonymous users
    const pubNubUserId = appConfig.current_user?.id ?
      appConfig.current_user.id.toString() :
      'unauthorized-user';

    const pubNub = new PubNub({
      subscribeKey: pubNubConfig.pubnub_subscribe_key,
      userId: pubNubUserId,
    });

    pubNub.addListener({ message: handlePubNubMessage });

    pubNub.subscribe({
      channels: [
        pubNubConfig.pubnub_private_updates_channel,
        pubNubConfig.pubnub_private_chat_channel,
        pubNubConfig.pubnub_push_notification_channel,
      ],
    });

    return pubNub;
  }, [pubNubConfig, appConfig]);

  useEffect(() => {
    // we're only using this useEffect for cleanup on unmount
    return () => {
      if (pubNubClient) {
        pubNubClient.removeListener(handlePubNubMessage);
        pubNubClient.unsubscribeAll();
      }
    };
  }, []);

  if (!pubNubClient) return null;

  return (
    <PubNubProvider client={pubNubClient}>
      {children}
      <audio id="newMessageSound" style={{ display: 'none' }}>
        <track kind="captions" />
        <source src="/niteflirt/audios/alert_49.mp3" type="audio/mpeg" />
      </audio>
    </PubNubProvider>
  );
};

PubNubContainer.defaultProps = {
  children: null,
};

PubNubContainer.propTypes = {
  children: PropTypes.array,
};

export default PubNubContainer;
