import React from 'react';
import {
  Typography,
  Grid, Stack,
} from '@mui/material';

import {
  toCurrency,
} from 'app/helpers/currencyHelpers';

import PropTypes from 'prop-types';

const Balance = ({ payableBalance, totalBalance }) => {
  return (
    <div>
      <Typography variant="subtitle1" my={1}>Balance</Typography>

      <Stack direction={'row'} mb={1}>
        <Typography variant="body1" gutterBottom sx={{ width: '130px'}}>
          Payable Balance:
        </Typography>
        <Typography variant="body1" gutterBottom align={'left'}>
          { toCurrency(payableBalance) }
        </Typography>
      </Stack>
      <Stack direction={'row'} mb={1} gutterBottom>
        <Typography variant="body1" gutterBottom sx={{ width: '130px'}}>
          Total Balance:
        </Typography>
        <Typography variant="body1" gutterBottom align={'left'}>
          { toCurrency(totalBalance) }
        </Typography>
      </Stack>

      <Typography variant="body1" gutterBottom>
        Your Payable Balance is the money you've earned and is elegible to be paid out.
      </Typography>
      <Typography variant="body1">
        Your Total Balance may include promotional credit or credit card deposits which cannot be paid out.
      </Typography>
    </div>
  )
}

Balance.propTypes = {
  payableBalance: PropTypes.number.isRequired,
  totalBalance: PropTypes.number.isRequired,
}

export default Balance;