import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';

const FilePlayerTabsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(0),
  backgroundColor: 'var(--body_background)',
}));

const CustomTabs = styled(Tabs)(() => ({
  display: 'flex',
  '& .MuiTab-root': {
    flexGrow: 1,
    minWidth: 0,
    padding: '8px 15px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  '& .MuiTab-root:first-child': {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
}));

export const FilePlayerTabs = () => {
  // TODO: last tab doesn't show properly
  return (
    <FilePlayerTabsBox>
      <CustomTabs
        value={0}
      >
        <Tab label="All Files" sx={{ flex: 1 }} />
        <Tab label="Goodies" sx={{ flex: 1 }} />
        <Tab label="Playlists" sx={{ flex: 1 }} />
      </CustomTabs>
    </FilePlayerTabsBox>
  );
};
