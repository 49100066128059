const prepareHeaders = (headers, { _endpoint, type }) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

  // Add a custom header for specific HTTP methods
  if (csrfToken && type === 'mutation') {
    headers.set('Content-Type', 'application/json');
    headers.set('X-CSRF-Token', csrfToken);
  }
  return headers;
};

export { prepareHeaders };
