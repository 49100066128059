import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  useGetAdvisorQuery,
  useGetAppConfigQuery,
  useGetActiveTributesForAdvisorQuery,
} from 'app/api/mainApi';

import BillingErrorContainer from 'app/containers/modals/tributes/BillingErrorContainer';
import ChangePackageSubscriptionContainer from 'app/containers/modals/tributes/ChangePackageSubscriptionContainer';
import ConfirmCancelMonetaryTributeContainer from './ConfirmCancelMonetaryTributeContainer';
import ConfirmCancelPackageSubscriptionContainer from './ConfirmCancelPackageSubscriptionContainer';
import ConfirmChangePackageSubscriptionContainer from './ConfirmChangePackageSubscriptionContainer';
import ConfirmCreateMonetaryTributeContainer from './ConfirmCreateMonetaryTributeContainer';
import ConfirmCreatePackageSubscriptionContainer from './ConfirmCreatePackageSubscriptionContainer';
import ConfirmUpdateTributeContainer from './ConfirmUpdateTributeContainer';
import EditMonetaryTributeContainer from 'app/containers/modals/tributes/EditMonetaryTributeContainer';
import EditPackageSubscriptionContainer from 'app/containers/modals/tributes/EditPackageSubscriptionContainer';
import ExistingTributeFormContainer from 'app/containers/modals/tributes/ExistingTributeFormContainer';
import IndividualPackageContainer from 'app/containers/modals/tributes/IndividualPackageContainer';
import NewTributeFormContainer from 'app/containers/modals/tributes/NewTributeFormContainer';
import TributeSentContainer from 'app/containers/modals/tributes/TributeSentContainer';
import UseDifferentCardContainer from 'app/containers/modals/tributes/UseDifferentCardContainer';

import ModalsContext from 'app/contexts/ModalsContext';
import DialogsContext from 'app/contexts/DialogsContext';

import useCreditCardManagement from 'app/hooks/useCreditCardManagement';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

import {
  setCurrentStep,
  resetTributeSliceState,
} from 'app/slices/tributesSlice';

import { featureFlag } from 'app/util/featureFlag';

const TributeWorkflowContainer = ({ advisorLogin, packageId, amount }) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalsContext);
  const { openDialog } = useContext(DialogsContext);
  const { closeDialog } = useContext(DialogsContext);
  const { data: advisor } = useGetAdvisorQuery({ login: advisorLogin });
  const { data: appConfig } = useGetAppConfigQuery(undefined, { forceRefetch: true });
  const { data: activeTributes } = useGetActiveTributesForAdvisorQuery({ advisorLogin });
  const currentUser = appConfig?.current_user;
  const {
    currentUserHasCard,
    recurringInitialized,
    setAmount,
    setAmountError,
    setManageCardsStep,
  } = useRecurringTributeManagement({ advisor });

  const {
    lastStep,
    recurring,
    currentStep,
    afterManageCardsStep,
    tributeDeficit,
  } = useSelector(state => state.tributes);

  if (currentUser === null) {
    window.location.href = `/login?return_url=${encodeURIComponent(window.location.pathname)}?fromLogin=true`;
    return null;
  }

  const onAddToBalanceSuccess = () => {
    let newStep;
    if (activeTributes.monetaryTribute) {
      newStep = TRIBUTE_WORKFLOW_STEPS.EXISTING_TRIBUTE;
    } else {
      newStep = TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE;
    }

    dispatch(setCurrentStep({ currentStep: newStep }));

    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin },
    });
  };

  let openAddToBalanceModalAlias;

  // showing and hiding the dialog so that we don't lose state,
  // and don't have to worry about resetting the slice either.
  const hideDialog = () => {
    document.getElementsByClassName('MuiDialog-root')[0].style.display = 'none';
  };

  const showDialog = () => {
    document.getElementsByClassName('MuiDialog-root')[0].style.display = 'block';
  };

  // this only runs when the user successfully changes their
  // default card, NOT when the user manually closes the modal
  const onManageCardsSuccess = () => {
    if (afterManageCardsStep.length > 0) {
      dispatch(showSnackbarMessage({
        message: 'Your default card has been updated.',
        severity: 'success',
      }));

      dispatch(setCurrentStep({ currentStep: afterManageCardsStep }));
      setManageCardsStep('');
      showDialog();
    } else if (activeTributes.monetaryTribute) {
      if (activeTributes.monetaryTribute.status === 'charge_failed') {
        dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.EXISTING_TRIBUTE }));
        showDialog();

        dispatch(showSnackbarMessage({
          message: 'Your default card has been updated.',
          severity: 'success',
        }));
      } else if (recurring) {
        dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_EDIT_TRIBUTE }));
        showDialog();
      } else {
        dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.EXISTING_TRIBUTE }));
        showDialog();
      }
    } else if (activeTributes.packageSubscription) {
      dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CHANGE_PACKAGE_SUBSCRIPTION }));
      showDialog();

      dispatch(showSnackbarMessage({
        message: 'Your default card has been updated.',
        severity: 'success',
      }));
    } else if (packageId) {
      dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_PACKAGE_SUBSCRIPTION }));
      showDialog();

      dispatch(showSnackbarMessage({
        message: 'Your default card has been updated.',
        severity: 'success',
      }));
    } else if (recurring) {
      dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE }));
      showDialog();

      dispatch(showSnackbarMessage({
        message: 'Your default card has been updated.',
        severity: 'success',
      }));
    } else {
      openAddToBalanceModalAlias();
    }
  };

  const onTryAgainSuccess = () => { };

  const {
    openManageCardsModal,
    openAddToBalanceModal,
  } = useCreditCardManagement({
    openModal,
    currentUser,
    guard: null,
    setGuard: null,
    onTryAgainSuccess,
    onManageCardsSuccess,
    onAddToBalanceSuccess,
  });

  openAddToBalanceModalAlias = openAddToBalanceModal;

  // we used to just abort the whole flow, but now we show
  // the confirmation UI again (where the user just came from)
  const onAddToBalanceOrManageCardsBailout = () => {
    showDialog();

    // if the current user doesn't have a card and they are trying
    // to close the modal, they will get stuck in a modal loop,
    // so it's better to hide the dialog here,
    // otherwise go back to the last step
    if (!currentUserHasCard) {
      hideDialog();
    } else {
      dispatch(setCurrentStep({ currentStep: lastStep }));
    }
  };

  useEffect(() => {
    if (currentStep === TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS) {
      hideDialog();
      openManageCardsModal(false, onAddToBalanceOrManageCardsBailout);
    }
    if (currentStep === TRIBUTE_WORKFLOW_STEPS.ADD_TO_BALANCE) {
      hideDialog();

      openAddToBalanceModal(
        tributeDeficit,
        null,
        'tribute',
        onAddToBalanceOrManageCardsBailout,
      );
    }
  }, [currentStep]);

  const hasActiveTributes = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537')
    ? Object.values(activeTributes || {}).some(activeTribute => activeTribute !== null)
    : activeTributes?.monetaryTribute !== null;

  useEffect(() => {
    if (currentStep === TRIBUTE_WORKFLOW_STEPS.INITIALIZING && activeTributes) {
      dispatch(setCurrentStep({
        currentStep: hasActiveTributes
          ? TRIBUTE_WORKFLOW_STEPS.EXISTING_TRIBUTE
          : TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE,
      }));
    } else if (packageId && !activeTributes) {
      dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.SHOW_INDIVIDUAL_PACKAGE }));
    } else if (currentStep === TRIBUTE_WORKFLOW_STEPS.EXIT_TRIBUTE_WORKFLOW) {
      closeDialog();
    }
  }, [activeTributes, currentStep, dispatch, hasActiveTributes]);

  useEffect(() => {
    // when we mount, simply make sure the MUI dialog is visible
    showDialog();

    return () => {
      // reset the UI state, and the slice when we unmount this container
      dispatch(setCurrentStep({ currentStep: TRIBUTE_WORKFLOW_STEPS.INITIALIZING }));
      dispatch(resetTributeSliceState());
    };
  }, []);

  useEffect(() => {
    if (amount && [TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE, TRIBUTE_WORKFLOW_STEPS.EXISTING_TRIBUTE].includes(currentStep)) {
      setAmount(amount);
      setAmountError('');
    }
  }, [amount, currentStep]);

  const modalContent = () => {
    switch (currentStep) {
    case TRIBUTE_WORKFLOW_STEPS.NEW_TRIBUTE:
      return <NewTributeFormContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_MONETARY_TRIBUTE:
      return <ConfirmCreateMonetaryTributeContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CONFIRM_EDIT_TRIBUTE:
      return <ConfirmUpdateTributeContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_PACKAGE_SUBSCRIPTION:
      return <ConfirmCreatePackageSubscriptionContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.TRIBUTE_SUCCESS:
      return <TributeSentContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.BILLING_ERROR:
      return <BillingErrorContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.USE_DIFFERENT_CARD:
      return <UseDifferentCardContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.EXISTING_TRIBUTE:
      return <ExistingTributeFormContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.EDIT_MONETARY_TRIBUTE:
      return <EditMonetaryTributeContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CONFIRM_CANCEL_MONETARY_TRIBUTE:
      return <ConfirmCancelMonetaryTributeContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CONFIRM_CANCEL_PACKAGE_SUBSCRIPTION:
      return <ConfirmCancelPackageSubscriptionContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.EDIT_PACKAGE_SUBSCRIPTION:
      return <EditPackageSubscriptionContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CHANGE_PACKAGE_SUBSCRIPTION:
      return <ChangePackageSubscriptionContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.CONFIRM_CHANGE_PACKAGE_SUBSCRIPTION:
      return <ConfirmChangePackageSubscriptionContainer advisor={advisor} />;

    case TRIBUTE_WORKFLOW_STEPS.SHOW_INDIVIDUAL_PACKAGE:
      return <IndividualPackageContainer advisor={advisor} packageId={packageId} />;

    default:
      return null;
    }
  };

  if (!advisor || !activeTributes || !recurringInitialized) return null;

  return modalContent();
};

TributeWorkflowContainer.defaultProps = {
  packageId: null,
  amount: null,
};

TributeWorkflowContainer.propTypes = {
  advisorLogin: PropTypes.string.isRequired,
  packageId: PropTypes.number,
  amount: PropTypes.string,
};

export default TributeWorkflowContainer;
