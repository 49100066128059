import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Stack } from '@mui/material';

import ToggleSwitch from 'app/components/form_components/ToggleSwitch';

const SavedSearch = ({
  savedSearch,
  toggleDoNoAutoSuggest,
  onClickName,
  showActions,
  onClickDeleteSearch,
  onClickEdit,
}) => {
  const [doNotAutoSuggest, setDoNotAutoSuggest] = useState(savedSearch.doNotAutoSuggest);

  const onClickAutoSuggest = () => {
    toggleDoNoAutoSuggest(!doNotAutoSuggest);
    setDoNotAutoSuggest(!doNotAutoSuggest);
  };

  return (
    <Stack
      key={savedSearch.name}
      direction="column"
      spacing={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        borderRadius: '8px',
        border: '2px solid #e0e0e0',
      }}
    >
      <Typography variant="h6">
        <a href="#" onClick={onClickName} role="button" tabIndex={0}>
          { savedSearch.name }
        </a>
      </Typography>
      <Stack
        direction="row"
        spacing="10px"
        alignItems="center"
        width="100%"
      >

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          width="100%"
        >
          <ToggleSwitch
            label="Auto-suggest"
            value={!doNotAutoSuggest}
            setValue={onClickAutoSuggest}
          />
        </Stack>
        { showActions && (
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            width="100%"
          >
            <Typography mr="2">
              <a href="#" onClick={onClickEdit} role="button" tabIndex={0}>
                Edit
              </a>
            </Typography>

            <Typography mr="2">
              <a href="#" onClick={onClickDeleteSearch} role="button" tabIndex={0}>
                Delete
              </a>
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

SavedSearch.defaultProps = {
  showActions: true,
  onClickDeleteSearch: () => {},
  onClickEdit: () => {},
};

SavedSearch.propTypes = {
  savedSearch: PropTypes.object.isRequired,
  toggleDoNoAutoSuggest: PropTypes.func.isRequired,
  onClickName: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
  onClickDeleteSearch: PropTypes.func,
  onClickEdit: PropTypes.func,
};

export default SavedSearch;
