import React from 'react';
import { PropTypes } from 'prop-types';

import ChangePackageSubscription from 'app/components/modals/tributes/ChangePackageSubscription';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';

const ChangePackageSubscriptionContainer = ({ advisor }) => {
  const {
    closeDialogAndResetTributeSlice,
    dispatch,
    setCurrentStep,
  } = useRecurringTributeManagement({ advisor });

  const onChangeSubscriptionClick = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CHANGE_PACKAGE_SUBSCRIPTION,
    }));
  };

  return (
    <ChangePackageSubscription
      closeDialog={closeDialogAndResetTributeSlice}
      onChangeSubscriptionClick={onChangeSubscriptionClick}
    />
  );
};

ChangePackageSubscriptionContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ChangePackageSubscriptionContainer;
