import React, { useContext, useEffect } from 'react';

import {
  DialogTitle,
  DialogContent,
  TextField,
  Stack,
  Button,
} from '@mui/material';

import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';

import {
  useAssignTagsMutation,
} from 'app/api/customersApi';

const CreateNewTagDialog = () => {
  const { closeDialog } = useContext(DialogsContext);
  const [newTag, setNewTag] = React.useState('');
  const [assignTags, { isLoading, isSuccess }] = useAssignTagsMutation();

  const onClickOK = () => {
    assignTags({ seekerIds: [], tags: [newTag] });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickOK();
    } else if (e.key === 'Escape') {
      closeDialog();
    }
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess]);

  return (
    <>
      <DialogTitle>
        Create New Tag
      </DialogTitle>

      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <TextField
          autoFocus
          id="name"
          type="text"
          fullWidth
          variant="outlined"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <Stack direction="row" justifyContent="flex-end" mt={2} spacing={1}>
          <Button variant="text" size="small" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" size="small" disabled={isLoading} onClick={onClickOK}>
            OK
          </Button>
        </Stack>

      </DialogContent>
    </>
  );
};

export default CreateNewTagDialog;
