import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import TitleBackArrowImport from 'app/components/shared/TitleBackArrowImport';
import { featureFlag } from 'app/util/featureFlag';

import {
  useGetPostQuery,
} from 'app/api/feedsApi';

import {
  useGetAppConfigQuery,
  useGetAdvisorQuery,
  useGetPublicPackagesForAdvisorsQuery,
} from 'app/api/mainApi';

import PostContainer from 'app/containers/PostContainer';
import CommentsComposeBoxContainer from 'app/containers/CommentsComposeBoxContainer';
import usePostUtilities from 'app/hooks/usePostUtilities';
import { setLowestPricePackages } from 'app/slices/feedSlice';

const PostDetailContainer = ({ selectedPostId, hideHeader }) => {
  const dispatch = useDispatch();
  const { login, postId } = useParams();
  const postIdentifier = postId || selectedPostId;
  const { data: advisor, isLoading: advisorLoading } = useGetAdvisorQuery({ login });
  const { data: post } = useGetPostQuery({ postId: postIdentifier });
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const { isScheduled } = usePostUtilities({ post });
  const feedToStoreFrontEnabled = featureFlag.enabled('FEED_TO_STOREFRONT_20571');

  const { data: packages } = useGetPublicPackagesForAdvisorsQuery(
    { advisorIds: advisor?.id },
  );

  useEffect(() => {
    if (packages && packages.length > 0) {
      const lowestPrice = packages.filter((pkg) => pkg.feedPerk).find(Boolean);
      const lowestPackagesByAdvisor = {};
      lowestPackagesByAdvisor[advisor?.id] = lowestPrice;
      dispatch(setLowestPricePackages(lowestPackagesByAdvisor));
    }
  }, [packages, dispatch]);

  if (advisorLoading) { return null; }

  if (!advisor?.active) {
    return <Navigate replace to="/feed/missing/profile" />;
  }

  if (!postIdentifier && feedToStoreFrontEnabled) {
    return <Navigate replace to={`/${advisor.login}/feed`} />;
  }

  if (!post) { return null; }

  // the app used to just show the post even if it did not belong to the
  // advisor in the url, but now we just redirect to plus/{creator}/{postId}
  // post viewability permission is checked on the backend, and not necessary here
  if (post.account.login !== decodeURIComponent(login)) {
    return <Navigate to={`/feed/${post.account.login}/${post.id}`} />;
  }

  const canComment = currentUser && post.published_at && !isScheduled && post.unlocked;

  return (
    <AppBodyWithSidebar>
      {!hideHeader && (
        <TitleBackArrowImport title="Post" />
      )}
      <div className="nfp-chat-body">
        <PostContainer
          key={post.id}
          post={post}
          advisor={advisor}
          showComments
          showLink={false}
        />
      </div>
      {canComment && (<CommentsComposeBoxContainer post={post} />)}
    </AppBodyWithSidebar>
  );
};

PostDetailContainer.propTypes = {
  hideHeader: PropTypes.bool,
  selectedPostId: PropTypes.number,
};

PostDetailContainer.defaultProps = {
  hideHeader: false,
  selectedPostId: null,
};

export default PostDetailContainer;
