import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Box } from '@mui/material';

import { useGetNotificationQuery, useHideNotificationMutation } from 'app/api/mainApi';

const BoxSuperWarning = styled(Box)(() => ({
  '&.MuiBox-root': {
    position: 'relative',
    backgroundColor: '#f9ec9b',
  },
}));

const SuperBannerContainer = () => {
  const [showAlert, setShowAlert] = useState(true);
  const notification = useGetNotificationQuery().data;

  const [hideNotification] = useHideNotificationMutation();

  const onHideNotification = () => {
    hideNotification({ code: notification.code });
    setShowAlert(false);
  }

  if (notification?.content) {
    return (
      <BoxSuperWarning className="warning-alert">
        <div style={{ margin: '0 auto', maxWidth: '950px' }}>
          {showAlert && (
            <Alert severity="warning" sx={{ borderRadius: '0px' }} onClose={onHideNotification}>
              <div dangerouslySetInnerHTML={{__html: notification.content}} />
            </Alert>
          )}
        </div>
      </BoxSuperWarning>
    );
  }
  return null;
};

export default SuperBannerContainer;
