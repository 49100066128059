import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Box,
  Typography,
} from '@mui/material';

import SubscriptionDetails from './shared/SubscriptionDetails';

const RecurringTributeDetails = ({
  currentUser,
  onEditClick,
  tributeData,
}) => {
  return (
    <Box mt={1}>
      <Typography variant="h4" pl={1}>Recurring Tributes</Typography>
      <SubscriptionDetails
        status={tributeData.status}
        rate={tributeData.rate}
        renewalDate={tributeData.renewalDate}
        onEditClick={onEditClick}
        currentUser={currentUser}
        editTitle="Edit Recurring Tribute"
      />
      <Typography pl={1} mt={2}>There are no perks associated with this tribute.</Typography>
    </Box>
  );
};

RecurringTributeDetails.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  tributeData: PropTypes.object.isRequired,
};

export default RecurringTributeDetails;
