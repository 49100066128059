import React from 'react';
import { useParams } from 'react-router';

const TransactionDetails = () => {
  const { id } = useParams();
  const idPathElement = id ? `/${id}` : '';

  const newPath = `/embedded/account/detail${idPathElement}${window.location.search}`;

  return (
    <iframe
      id="mainIframe"
      title="Transactions"
      className="iframe-responsive"
      style={{ width: '100%', border: 0 }}
      src={newPath}
    />
  );
};

export default TransactionDetails;
