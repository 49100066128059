import React from 'react';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import SeekerAccount from './SeekerAccount';
import AppBody from 'app/components/layout/AppBody';

import { userIsSeeker, userIsAdvisor } from 'app/helpers/whitelistHelpers';

const AccountContainer = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;

  let content = null;
  if (userIsSeeker(currentUser)) {
    content = (<SeekerAccount appConfig={appConfig} />);
  } else if (userIsAdvisor(currentUser)) {
    content = (<div>Coming soon</div>);
  }

  return (
    <AppBody>
      {content}
    </AppBody>
  );
};

export default AccountContainer;
