import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';
import { useGetListingQuery, useGetAppConfigQuery } from 'app/api/mainApi';
import PhoneListingCard from 'app/components/PhoneListingCard';
import DeprecatedPhoneListingCard from 'app/components/DeprecatedPhoneListingCard';
import useListingDetailsAndFeedback from 'app/hooks/useListingDetailsAndFeedback';

// TODO: I think this is a container for showing favorites only...
// can we give it a more descriptive name?
// there are so many containers that display listings, it would be nice to
// disambiguate them
const ListingCardContainer = ({ listingId, style }) => {
  const { data: listing, isLoading: listingLoading } = useGetListingQuery({ id: listingId });
  const useNewCards = featureFlag.enabled('NEW_CARDS_20908');
  const appConfig = useGetAppConfigQuery().data;
  const currentUser = appConfig?.current_user;

  const {
    onListingDetailsClick,
  } = useListingDetailsAndFeedback({
    listingDetailsFlagEnabled: featureFlag.enabled('LISTING_DETAILS_20955'),
  });

  if (listingLoading) return (<div>Loading...</div>);

  if (useNewCards) {
    return (
      <PhoneListingCard
        listing={listing}
        style={style}
        onDetailsClick={onListingDetailsClick(listing)}
        viewingFavorites
        currentUser={currentUser}
      />
    );
  }

  return (
    <DeprecatedPhoneListingCard listing={listing} style={style} />
  );
};

ListingCardContainer.defaultProps = {
  style: {},
};

ListingCardContainer.propTypes = {
  listingId: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default ListingCardContainer;
