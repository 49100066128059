import React, { useState, useEffect } from 'react';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import Balance from 'app/components/payout/Balance';
import RequestPayout from 'app/components/payout/RequestPayout';
import {styled} from '@mui/material/styles';
import { Box } from '@mui/material';
import PayeeForm from '../../components/payout/PayeeForm';
import { PayoutOnHoldWarning, SaveSuccessAlert, ErrorAlert } from 'app/components/payout/Alerts';

import { useGetPayoutRequestInfoQuery } from 'app/api/accountApi';

const CustomStyledBox = styled(Box)(({ theme }) => ({
  [ theme.breakpoints.up('xs') ]: {
    padding: theme.spacing(2, 3), // top-bottom, left-right
  },
  [ theme.breakpoints.up('sm') ]: {
    padding: theme.spacing(3, 3), // top-bottom, left-right
  },
  pb: {
    xs: theme.spacing(10), // bottom padding for xs
    sm: theme.spacing(10), // bottom padding for sm
  },
}));

const PayoutInfoContainer = () => {
  const { data: payoutRequestData } = useGetPayoutRequestInfoQuery();
  const [balance, setBalance] = useState({
    payableBalance: 0,
    totalBalance: 0,
  });

  const [payee, setPayee] = useState({
    payeeName: '',
    country: 'US',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    taxableStatus: '0',
    payerTaxId: '',
  });

  const [payout, setPayout] = useState({
    paymentPeriod: '',
    bankName: '',
    accountType: '0', // 0 -> checking, 1 -> savings
    routingNumber: '',
    bankAccountNumber: '',
    accountNumberConfirmation: '',
    email: '',
    amountLeftInAccount: 0,
    paxumId: null,
  });

  const [showPayoutOnHoldWarning, setShowPayoutOnHoldWarning] = useState(false);
  /* eslint-disable camelcase */
  useEffect(() => {
    if (payoutRequestData) {
      const {
        payment_request: {
          payee_name = '',
          country,
          address_line1 = '',
          address_line2 = '',
          city = '',
          state = '',
          zip_code = '',
          taxable_status = '0',
          plain_text_tax_id = '',
          payment_type_and_period = '',
        } = {},
        bank_account: {
          routing_number = '',
          account_number = '',
          bank_account_type = 0,
          bank_name = '',
        } = {},
        show_payout_warning,
        user_paxum: {
          email: paxumEmail = '',
          id: paxumId,
        } = {},
      } = payoutRequestData;

      setBalance({
        payableBalance: payoutRequestData.payable_balance,
        totalBalance: payoutRequestData.total_balance,
      });

      setPayee({
        payeeName: payee_name,
        country,
        addressLine1: address_line1,
        addressLine2: address_line2,
        city,
        state,
        zipCode: zip_code,
        taxableStatus: String(taxable_status),
        payerTaxId: plain_text_tax_id?.replaceAll('-', ''),
      });

      setPayout({
        paymentPeriod: String(payment_type_and_period),
        bankName: bank_name,
        accountType: String(bank_account_type),
        routingNumber: routing_number,
        bankAccountNumber: account_number,
        accountNumberConfirmation: account_number,
        email: paxumEmail,
        paxumId,
      });

      setShowPayoutOnHoldWarning(show_payout_warning);
    }
  }, [payoutRequestData]);
  /* eslint-disable camelcase */

  return (
    <AppBodyWithSidebar>
      <TemplateShell2 header="Payout Info">
        <CustomStyledBox>
          { showPayoutOnHoldWarning && <PayoutOnHoldWarning /> }
          <br />
          <SaveSuccessAlert />
          <br />
          <ErrorAlert errors={['Payee Name can\'t be blank', 'Bank Account Number required']} />
          <br />
          <Balance payableBalance={balance.payableBalance} totalBalance={balance.totalBalance} />
          <PayeeForm payee={payee} setPayee={setPayee} />
          <RequestPayout payout={payout} setPayout={setPayout} />
        </CustomStyledBox>
      </TemplateShell2>
    </AppBodyWithSidebar>
  );
};

export default PayoutInfoContainer;