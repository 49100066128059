import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Checkbox } from '@mui/material';
import { useFilePlayerContext } from '../FilePlayerContext';

const FileThumbnail = ({ file }) => {
  const { fileSelection } = useFilePlayerContext();
  const { isChecked, toggleItem } = fileSelection;

  return (
    <Grid
      item
      xs={6} // 2 items per row on mobile (12 / 6 = 2)
      sm={3} // 4 items per row on medium screens (12 / 3 = 4)
      lg={2.4} // 5 items per row on large screens (12 / 2.4 = 5, only if space allows)
      sx={{ position: 'relative' }}
    >
      <Checkbox
        checked={isChecked(file.id)}
        onChange={() => toggleItem(file.id)}
        sx={{ position: 'absolute', top: 8, left: 8 }}
      />
      <Box
        component="img"
        src={file.thumbnailUrl}
        alt={file.fileName}
        sx={{
          width: '100%',
          aspectRatio: '1 / 1',
          objectFit: 'cover',
          borderRadius: '4px',
          boxShadow: 1,
        }}
      />
    </Grid>
  );
};

FileThumbnail.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string,
    fileName: PropTypes.string.isRequired,
  }).isRequired,
};

export const FileGridGroup = ({ files }) => (
  <Box sx={{ width: '100%', overflowX: 'hidden' }}>
    <Grid
      container
      spacing={2}
      mt={2}
      sx={{
        width: '100%', // Ensures grid stays within parent bounds
        flexWrap: 'wrap', // Prevents overflow
      }}
    >
      {files.map((file) => (
        <FileThumbnail key={file.id} file={file} />
      ))}
    </Grid>
  </Box>
);

FileGridGroup.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      thumbnailUrl: PropTypes.string,
      fileName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
