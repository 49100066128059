import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Rating, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThumbUpOffAlt, ThumbDownOffAlt } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useGetAdvisorFeedbacksQuery } from 'app/api/accountApi';
import { FULL_DATE_FORMAT } from 'app/helpers/timestampHelpers';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import RoundedPagination from 'app/components/shared/RoundedPagination';

const rowsPerPage = 40;

const StyledTypography = styled(Typography)(() => ({
  margin: '2px 0 0 8px', // Adjust margin as needed
}));

const StyledThumbUpOffAlt = styled(ThumbUpOffAlt)(({ theme }) => ({
  fontSize: '1.5em',
  color: theme.palette.grey[ 500 ], // You can adjust the color here
}));

const StyledThumbDownOffAlt = styled(ThumbDownOffAlt)(({ theme }) => ({
  fontSize: '1.5em',
  color: theme.palette.grey[ 500 ], // You can adjust the color here
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, -3), // Equivalent to my: 2, mx: -3
}));

const CustomStyledBox = styled(Box)(({ theme }) => ({
  [ theme.breakpoints.up('xs') ]: {
    padding: theme.spacing(2.5, 3), // top-bottom, left-right
  },
  [ theme.breakpoints.up('sm') ]: {
    padding: theme.spacing(3.5, 4), // top-bottom, left-right
  },
  pb: {
    xs: theme.spacing(10), // bottom padding for xs
    sm: theme.spacing(10), // bottom padding for sm
  },
}));

const BoxPagination = styled(Box)(() => ({
  '&.MuiBox-root': {
    margin: '0 -10px', // adjust this as needed
    display: 'flex',
    justifyContent: 'flex-end',
    '& nav.MuiPagination-root': {
      height: 'auto',
      maxWidth: 'calc(100% - 12px)',
      display: 'flex',
      alignSelf: 'flex-end',
      border: '1px solid transparent',
    },
  },
}));

// TODO: Matt- are the nested grids in here correct?  It wraps when we have small viewports, but even
// on modest phones, the "Average Feedback [stars]" doens't require half the width, so the wrapping
// seems unnecessary.
const SummaryBox = ({ feedbackData }) => {
  return (
    <Grid data-test-id="summary" container mt={0.5} mb={1} pb={0} px={2}>
      <Grid item sm={6} xs={12} sx={{ pt: 0 }}>
        <Stack direction="row">
          <Typography>
            Average Feedback
          </Typography>
          <StyledTypography>
            <Rating value={feedbackData?.average || 0} readOnly size="small" precision={0.5} />
          </StyledTypography>
        </Stack>
      </Grid>
      <Grid data-test-id="number-responses" item sm={6} xs={12} textAlign={{ xs: 'left', sm: 'right' }} sx={{ pt: 0.2 }}>
        {feedbackData?.pagination.total_entries}
        &nbsp;Responses
      </Grid>
    </Grid>
  );
};

const FeedbackRating = ({ feedback }) => {
  const { rating } = feedback;

  if (rating === 10) {
    return <StyledThumbUpOffAlt />;
  } else if (rating === -10) {
    return <StyledThumbDownOffAlt />;
  } else if (rating === 0) {
    return null;
  } else {
    return <Rating value={rating} size="small" readOnly />;
  }
};

const FeedbackRow = ({ feedback }) => (
  <>
    <Grid data-test-id="feedback-row" container>
      <Grid item xs={7}>
        <Typography data-test-id="user-login" variant="body3" sx={{ color: '#999' }}>
          {feedback.user_login}
        </Typography>
      </Grid>
      <Grid item xs={5} className="pull-right">
        <Typography data-test-id="created-at" variant="body3" sx={{ color: '#999' }}>
          {DateTime.fromISO(feedback.created_at).toFormat(FULL_DATE_FORMAT)}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={0.5}>
        <Stack direction="row" spacing={1}>
          <FeedbackRating data-test-id="rating" feedback={feedback} />
          <Typography data-test-id="category-name" variant="body3" pt={0.3} sx={{ color: '#999' }}>
            {feedback.category_name}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} pb={1} mt={2}>
        <Typography data-test-id="comment">
          {feedback.comment}
        </Typography>
      </Grid>
    </Grid>
    <StyledDivider />
  </>
);

function AdvisorFeedbacksContainer() {
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const page = parseInt(searchParams.get('page')) || 1;
  const { data: feedbackData } = useGetAdvisorFeedbacksQuery({ page: parseInt(page), perPage: rowsPerPage }, { forceRefetch: true });
  if (!feedbackData) {
    return null;
  }
  const pageCount = Math.ceil(feedbackData.pagination.total_entries / rowsPerPage);
  const handlePageChange = (newPage) => {
    navigate(`/account/feedbacks?page=${newPage}`);
  };

  return (
    <AppBodyWithSidebar>
      <TemplateShell2 header="Feedback Summary">
        <CustomStyledBox>
          <SummaryBox feedbackData={feedbackData} />
          <StyledDivider />
          {feedbackData?.feedbacks.map((feedback, index) => (
            <FeedbackRow key={index} feedback={feedback} />
          ))}
          <BoxPagination>
            <RoundedPagination count={pageCount} onPageChange={handlePageChange} />
          </BoxPagination>
        </CustomStyledBox>
      </TemplateShell2>
    </AppBodyWithSidebar>
  );
}

SummaryBox.propTypes = {
  feedbackData: PropTypes.shape({
    average: PropTypes.number,
    pagination: PropTypes.shape({
      total_entries: PropTypes.number,
    }),
  }).isRequired,
};

FeedbackRow.propTypes = {
  feedback: PropTypes.shape({
    user_login: PropTypes.string,
    created_at: PropTypes.string,
    rating: PropTypes.oneOfType([ PropTypes.oneOf([ 10, -10, 0, 1, 2, 3, 4, 5 ]) ]).isRequired,
    category_name: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};

export { SummaryBox, FeedbackRating, BoxPagination };
export default AdvisorFeedbacksContainer;
