import React from 'react';
import PropTypes from 'prop-types';

import CallListingShape from 'app/shapes/CallListingShape';

import {
  Grid,
  Stack,
} from '@mui/material';

import Typography from '@mui/material/Typography';

import { toNumber } from 'app/helpers/currencyHelpers';

const TalkTime = ({
  balance,
  listing,
  isLoading,
  canCall,
}) => {
  const talkTime = listing && balance && Math.floor(toNumber(balance.available_balance) / listing.price_per_minute);
  const advisorFreeMinutes = toNumber(listing?.advisor_free_minutes);
  const systemFreeMinutes = toNumber(listing?.system_free_minutes);
  const freeMinutes = advisorFreeMinutes + systemFreeMinutes;
  const totalTalkTime = talkTime + freeMinutes;

  return (
    <Grid
      container
      spacing={2}
      maxWidth={550}
    >
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          style={{ textAlign: 'right' }}
        >
          Talk Time
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography variant="body1">
            {isLoading ? '-' : `${totalTalkTime} ${totalTalkTime === 1 ? 'Minute' : 'Minutes'}`}
            &nbsp;
            {canCall && freeMinutes > 0 ? `(first ${freeMinutes} ${freeMinutes === 1 ? 'Minute' : 'Minutes'} free)` : null }
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

TalkTime.defaultProps = {
  balance: null,
  listing: null,
};

TalkTime.propTypes = {
  balance: PropTypes.object,
  listing: CallListingShape,
  isLoading: PropTypes.bool.isRequired,
  canCall: PropTypes.bool.isRequired,
};

export default TalkTime;
