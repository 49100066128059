import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, Grid, Link, Rating, Typography } from '@mui/material';

import { timestampInTimezone } from 'app/helpers/timestampHelpers';

const ListingFeedbackCard = ({ feedback, currentUser }) => {
  const [showMore, setShowMore] = useState(false);
  const maxChars = 80;

  const leftBy = (createdBy, currentUser) => {
    if (currentUser) { return createdBy; }
    return `${createdBy.charAt(0)}...${createdBy.charAt(createdBy.length - 1)}`;
  };

  const showMoreLinkText = () => {
    if (!feedback.comment || feedback.comment === '') { return ''; }
    if (showMore) { return 'See less'; }
    return 'See more';
  };

  const maybeTruncateComment = (comment, showMore) => {
    if (!comment || comment === '') { return ''; }
    if (showMore) { return comment; }
    if (comment.length <= maxChars) { return comment; }
    return `${comment.substring(0, maxChars)}...`;
  };

  return (
    <Card
      sx={{
        mx: { xs: 3, sm: 4 },
        mb: 2,
        p: { xs: 2, sm: 3 },
        fontSize: '0.875em',
      }}
      elevation={0}
    >
      <Grid container>
        <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
          <Rating
            value={feedback.rating}
            precision={0.1}
            readOnly
            size="small"
            sx={{ verticalAlign: 'middle' }}
          />
          <Typography
            variant="body2"
            sx={{ ml: 1.5, color: 'var(--color_gray_nine)' }}
          >
            {leftBy(feedback.created_by, currentUser)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          textAlign="right"
        >
          <Typography
            variant="body2"
            sx={{ color: 'var(--color_gray_nine)' }}
          >
            {timestampInTimezone(
              feedback.created_at,
              currentUser?.canonical_iana_timezone,
              'LLL d yyyy',
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} pt={1.3} sx={{ overflow: 'hidden' }}>
          <Typography variant="body2">
            {maybeTruncateComment(feedback.comment, showMore)}
          </Typography>
          <Typography variant="body2">
            <Link
              onClick={() => setShowMore(!showMore)}
              role="link"
              tabIndex={0}
            >
              {feedback.comment?.length > maxChars &&
                showMoreLinkText()}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

ListingFeedbackCard.defaultProps = {
  currentUser: null,
};

ListingFeedbackCard.propTypes = {
  feedback: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default ListingFeedbackCard;
