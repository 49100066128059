import React from 'react';
import PropTypes from 'prop-types';
import PhoneShape from 'app/shapes/PhoneShape';
import CallListingShape from 'app/shapes/CallListingShape';

import {
  Box,
  Typography,
} from '@mui/material';

import CallFailed from 'app/components/call/callInProgress/Icons/CallFailed';
import CallNowActionButton from 'app/components/call/CallNowActionButton';

const DeclinedStep = ({
  selectedPhone,
  listing,
  balance,
  isLoading,
  triggerCall,
}) => {
  return (
    <Box textAlign="center">
      <Box mt={3} mb={3}>
        <CallFailed />
      </Box>
      <Typography variant="body1" gutterBottom>
        {`We had an issue calling ${listing.login}.`}
      </Typography>
      <CallNowActionButton
        canCall
        selectedPhone={selectedPhone}
        listing={listing}
        balance={balance}
        isLoading={isLoading}
        triggerCall={triggerCall}
      />
    </Box>
  );
};

DeclinedStep.defaultProps = {
};

DeclinedStep.propTypes = {
  selectedPhone: PhoneShape.isRequired,
  listing: CallListingShape.isRequired,
  balance: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  triggerCall: PropTypes.func.isRequired,
};

export default DeclinedStep;
