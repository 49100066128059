import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import ModalsContext from 'app/contexts/ModalsContext';
import MediaModalContainer from 'app/containers/MediaModalContainer';
import FeedHeroTooltip from './tooltips/FeedHeroTooltip';
import FollowButtonContainer from 'app/containers/FollowButtonContainer';
import IconLink from './shared/IconLink';
import MobileSubscriptions from './subscriptions/MobileSubscriptions';
import BackArrow from 'app/components/shared/BackArrow';
import FlirtAvatarImage from './profile/FlirtAvatarImage';
import FlirtCoverImage from './profile/FlirtCoverImage';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { ADVISOR_TAG } from 'app/api/sharedApiTags';
import AuthenticatedLink from './shared/AuthenticatedLink';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const FlirtHeader = ({
  advisor,
  includeSubscriptions,
  onTributeButtonClick,
}) => {
  const appConfig = useGetAppConfigQuery();
  const { openModal } = useContext(ModalsContext);
  const currentUser = appConfig?.data?.current_user;
  const viewingSelf = (currentUser?.id === advisor?.id);
  const [stickyNavVisible, setStickyNavVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  const openSelectFilesModal = (mode) => {
    openModal({
      component: MediaModalContainer,
      props: {
        selectedItems,
        setSelectedItems,
        advisor,
        profileImageMode: mode,
      },
    });
  };

  useEffect(() => {
    // cross-slice cache tag invalidation
    dispatchInvalidateCacheTagsEvent([ADVISOR_TAG]);
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.scrollTop > 180) {
        setStickyNavVisible(true);
      } else {
        setStickyNavVisible(false);
      }
    };

    const bodyContent = document.getElementById('body-content');
    if (bodyContent) { bodyContent.addEventListener('scroll', onScroll); }

    return () => {
      bodyContent.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      <div className="nfp-hero">
        <div className="container-max">
          <div className="container-fluid container-stats">
            <div className="row middle-xs between-xs">
              <BackArrow />
              <div>
                <FeedHeroTooltip advisor={advisor} currentUser={currentUser} />
              </div>
            </div>
          </div>
          <div className="container-fluid container-main-info">
            <div className="row">
              <div className="col-xs-4 center-xs first-part">
                <FlirtAvatarImage
                  viewingSelf={viewingSelf}
                  openSelectFilesModal={openSelectFilesModal}
                  advisor={advisor}
                />
              </div>
              <div className="col-xs-8 start-xs second-part">
                <h2>{advisor ? advisor.login : ''}</h2>
                <ul className="nav-hero">
                  <li>
                    <AuthenticatedLink
                      role="link"
                      tabIndex={0}
                      title="Pay a Tribute"
                      currentUser={currentUser}
                      onClick={onTributeButtonClick}
                    >
                      <img
                        src="/plus_frontend/assets/svg/ico-top-tribute.svg"
                        alt="Pay a Tribute"
                        className="nav-hero-icon"
                      />
                    </AuthenticatedLink>
                  </li>
                  { advisor?.chat_eligible && (
                    <li>
                      <IconLink
                        title="Chat"
                        href={`/chat/${advisor?.slug}`}
                        iconSrc="/plus_frontend/assets/svg/ico-top-chat.svg"
                        altText="Chat"
                        imgClasses="nav-hero-icon"
                      />
                    </li>
                  )}
                  <li>
                    <IconLink
                      title="Call"
                      href={advisor ? `/profile/${advisor.login}#live` : '#'}
                      iconSrc="/plus_frontend/assets/svg/ico-top-call.svg"
                      altText="Call"
                      imgClasses="nav-hero-icon"
                    />
                  </li>
                  <li>
                    <FollowButtonContainer fetchStateFromApi />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FlirtCoverImage
        viewingSelf={viewingSelf}
        openSelectFilesModal={openSelectFilesModal}
        advisor={advisor}
      />
      {/* start sticky header */}
      <div
        className="container-hdr-sticky"
        id="sticky-nav"
        style={{ display: stickyNavVisible ? 'block' : 'none' }}
      >
        <div className="row middle-xs start-xs nowrap-xs">
          <div className="col-xs-5">
            <div className="nav-icon-sticky">
              <a
                onClick={window.history.back}
                role="link"
                tabIndex={0}
              >
                <img src="/plus_frontend/assets/svg/ico-back-arrow.svg" alt="Back" />
              </a>
            </div>
            <h2>{advisor?.login}</h2>
          </div>
          <div className="col-xs-7 end-xs padding0">
            <div className="container-fluid container-hdr-sticky-icons">
              <div className="row nowrap-xs end-xs">
                <div>
                  <AuthenticatedLink
                    role="link"
                    tabIndex={0}
                    title="Pay a Tribute"
                    currentUser={currentUser}
                    onClick={onTributeButtonClick}
                  >
                    <img
                      src="/plus_frontend/assets/svg/ico-top-tribute.svg"
                      alt="Pay a Tribute"
                      className="nav-hero-icon"
                    />
                  </AuthenticatedLink>
                </div>
                { advisor?.chat_eligible && (
                  <div>
                    <IconLink
                      title="Chat"
                      href={`/chat/${advisor?.slug}`}
                      iconSrc="/plus_frontend/assets/svg/ico-top-chat.svg"
                      altText="Chat"
                      imgClasses="nav-hero-icon"
                    />
                  </div>
                )}
                <div>
                  <IconLink
                    title="Call"
                    href={advisor ? `/profile/${advisor.login}#live` : '#'}
                    iconSrc="/plus_frontend/assets/svg/ico-top-call.svg"
                    altText="Call"
                    imgClasses="nav-hero-icon"
                  />
                </div>
                <div className="margintop5">
                  <FeedHeroTooltip advisor={advisor} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {includeSubscriptions && (
        <MobileSubscriptions advisorLogin={advisor?.login} />
      )}
    </>
  );
};

FlirtHeader.defaultProps = {
  advisor: null,
  includeSubscriptions: true,
};

FlirtHeader.propTypes = {
  advisor: PropTypes.object,
  includeSubscriptions: PropTypes.bool,
  onTributeButtonClick: PropTypes.func.isRequired,
};

export default FlirtHeader;
