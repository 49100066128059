import React from 'react';
import CallListingShape from 'app/shapes/CallListingShape';

import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import StyledCard from 'app/components/shared/StyledCard';
import BlurBox from 'app/components/shared/BlurBox';

import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { toCurrency } from 'app/helpers/currencyHelpers';

const StyledName = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#000',
});

const StyledDescription = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 2, // Clamp at 2 lines
  WebkitBoxOrient: 'vertical',
});

const AdvisorCallCard = ({
  listing,
}) => {
  let status;
  if (listing.advisor_busy) {
    status = 'Busy';
  } else if (listing.advisor_availability === 1) {
    status = 'Taking Calls';
  } else {
    status = 'Away';
  }
  const green = '#409840';
  const statusColor = status === 'Taking Calls' ? green : '#999';
  return (
    <StyledCard className="no-border-fav">
      <Stack direction="row">
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: '150px',
              height: '180px',
              position: 'absolute',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1',
            }}
          >
            <img
              src={listing.photo_url}
              alt="Card"
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '150px',
                maxHeight: '180px',
                minWidth: '150px',
                minHeight: '180px',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
            />
          </Box>
          <BlurBox style={{ backgroundImage: `url(${listing.photo_url})` }} />
        </Box>
        <Stack
          spacing={2}
          sx={{
            marginLeft: '1rem',
            width: '150px',
            paddingTop: '1rem',
            paddingRight: '1rem',
          }}
        >
          <StyledName>
            {listing.login}
          </StyledName>
          <StyledDescription className="description">
            <SanitizedHTML html={listing.title} />
          </StyledDescription>
          <Typography variant="body3" sx={{ color: '#444' }}>
            <strong>
              Status:&nbsp;
            </strong>
            <span style={{ color: statusColor }}>
              {status}
            </span>
          </Typography>
          <Typography variant="body3" sx={{ color: '#444' }}>
            {toCurrency(listing.price_per_minute)}
            <span style={{ color: '#999' }}>
              &nbsp;per minute
            </span>
          </Typography>
        </Stack>
      </Stack>
    </StyledCard>
  );
};

AdvisorCallCard.propTypes = {
  listing: CallListingShape.isRequired,
};

export default AdvisorCallCard;
