import { useEffect } from 'react';

const useDarkerBodyClass = () => {
  useEffect(() => {
    const bodyContent = document.getElementById('body-content');
    if (!bodyContent) return;
    bodyContent.classList.add('body-content-darkerbg');
  }, []);

  return undefined;
};

export default useDarkerBodyClass;
