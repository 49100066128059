import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const CountrySelect = ({ value, onChange, countries }) => {
  const currentValue = countries.find((country) => country.iso_country_code === value);

  return (
    <Autocomplete
      id="country-select"
      sx={{ width: 300 }}
      options={countries}
      value={currentValue}
      onChange={(e, value) => {
        onChange(value.iso_country_code);
      } }
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );

};

export default CountrySelect;