/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, TextField, Typography, InputAdornment } from '@mui/material';
// import RemoveIcon from '@mui/icons-material/Remove';
// import AddIcon from '@mui/icons-material/Add';

const NumberField = ({ label, value, setValue, min, max, width, margin, boxSx }) => {
  const customOnChange = (event) => {
    const inputValue = Math.max(min, Math.min(max, Number(event.target.value) || 0)); // Clamp on blur
    setValue(inputValue);
  };
  return (
    <Box
      component="form"
      sx={[{
        display: 'flex',
        // width enough to fit all the digits of the max value
        '& .MuiTextField-root': { m: margin, width },
      }, boxSx]}
      noValidate
      autoComplete="off"
    >
      {
        label.length > 0 && (
          <Typography
            sx={{
              mr: 1,
              display: 'flex',
              alignItems: 'center', // Vertically center the text
            }}
          >
            {label}
          </Typography>
        )
      }

      <TextField
        type="number"
        value={value}
        sx={{
          '& .MuiInputBase-root': { height: '32px' }, // Adjust the outer height
          '& .MuiInputBase-input': { padding: '4px 8px' }, // Adjust inner padding
        }}
        onChange={customOnChange}
        onBlur={customOnChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

NumberField.defaultProps = {
  width: '10ch',
  margin: 1,
  label: '',
  boxSx: {},
};

NumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  width: PropTypes.string,
  margin: PropTypes.number,
  boxSx: PropTypes.object,
};

export default NumberField;
