import React, { useRef, useState, useEffect } from 'react';

import { PropTypes } from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { usePerformReportContentMutation } from 'app/api/mainApi';
import { useDispatch } from 'react-redux';

import { showFlash } from 'app/slices/flashSlice';

const ReportContentModal = ({ closeModal, contentId, contentType }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [errors, setErrors] = useState({});
  const maximumDescriptionLength = 500;
  const minimumDescriptionLength = 4;
  const textAreaRef = useRef();

  const updateDescriptionLength = (newDescription) => {
    // Count new lines as 2 characters
    const newLines = newDescription.match(/\n/g);
    setDescriptionLength(newDescription.length + (newLines ? newLines.length : 0));
  };

  const setDescriptionAndClearTempState = (description) => {
    setDescription(description);
    setErrors({});
  };

  const handleChange = (e) => {
    updateDescriptionLength(e.target.value);
    setDescriptionAndClearTempState(e.target.value);
  };

  const errorMessage = errors && errors.length > 0 && (
    <div className="text-error">
      {errors.join(' ')}
    </div>
  );

  const validBodyLength = (
    descriptionLength >= minimumDescriptionLength &&
      descriptionLength <= maximumDescriptionLength
  );

  const [
    performReportContent,
    {
      isSuccess: isReportContentSuccess,
      error: reportContentError,
    },
  ] = usePerformReportContentMutation();

  useEffect(() => {
    setErrors(reportContentError?.data?.errors);
  }, [reportContentError]);

  useEffect(
    () => {
      if (isReportContentSuccess) {
        closeModal();

        dispatch(showFlash({
          flashType: 'success',
          props: { message: 'Your report has been submitted. Thank you for letting us know.' },
        }));
      }
    },
    [isReportContentSuccess],
  );

  return (
    <div className="container-textarea">
      <div className="modal-header">
        <h4>
          Report this&nbsp;
          {contentType}
          &nbsp;to Play Fair
        </h4>
      </div>
      <div className="modal-body">
        <p>
          You may report&nbsp;
          {contentType}
          s that you believe are in violation of our Policies. Please provide a brief description why you are reporting this&nbsp;
          {contentType}
          .
        </p>
        <div className={`label-float ${(errors && errors.length > 0) ? 'has-error' : ''}`}>
          <TextareaAutosize
            value={description}
            onChange={handleChange}
            minLength={minimumDescriptionLength}
            maxLength={maximumDescriptionLength}
            data-lpignore="true"
            ref={textAreaRef}
            className="textarea-grow"
          />
          {errorMessage}
        </div>
        <div className="container-fluid padding-0-8 margintopminus10">
          <div className="row middle-xs">
            <div className="col-xs-5 start-xs" />
            <div className="col-xs-7 end-xs">
              <span className="char-remaining" style={{ color: (validBodyLength ? '#999' : 'red') }}>
                {descriptionLength}
                &nbsp;/&nbsp;
                {maximumDescriptionLength}
                &nbsp;characters
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={() => { closeModal(); }}
        >
          Cancel
        </a>
        <button
          type="button"
          disabled={!validBodyLength}
          className="btn"
          onClick={() => performReportContent({ contentId, contentType, description })}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

ReportContentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contentId: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired,
};
export default ReportContentModal;
