import React from 'react';
import { Box } from '@mui/material';
import AuthenticatedLink from './AuthenticatedLink';
import PropTypes from 'prop-types';

function SubscriptionDetails({
  status,
  rate,
  renewalDate,
  onEditClick,
  currentUser,
  editTitle = 'Edit Subscription',
  updateCardTitle = 'Update Card',
}) {
  return (
    <Box mt={1} pl={1} pr={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}>
      <Box>
        <AuthenticatedLink
          role="link"
          tabIndex={0}
          title={editTitle}
          currentUser={currentUser}
          onClick={onEditClick}
        >
          {rate}
          {' '}
          per month
        </AuthenticatedLink>
      </Box>
      {status === 'active' && (
        <Box>
          Renews
          {' '}
          {renewalDate}
        </Box>
      )}
      {status === 'charge_failed' && (
        <Box>
          Renewal Failed.&nbsp;
          <AuthenticatedLink
            role="link"
            tabIndex={0}
            title={updateCardTitle}
            currentUser={currentUser}
            onClick={onEditClick}
          >
            Update Card
          </AuthenticatedLink>
          <br />
          or expires
          {' '}
          {renewalDate}
        </Box>
      )}
      {status === 'pending_cancel' && (
        <Box>
          Expires
          {' '}
          {renewalDate}
        </Box>
      )}
      {status === 'cancelled' && (
        <Box>
          Expired
          {' '}
          {renewalDate}
        </Box>
      )}
    </Box>
  );
}

SubscriptionDetails.defaultProps = {
  editTitle: '',
  updateCardTitle: '',
};

SubscriptionDetails.propTypes = {
  status: PropTypes.string.isRequired,
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  renewalDate: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  editTitle: PropTypes.string,
  updateCardTitle: PropTypes.string,
};

export default SubscriptionDetails;
