import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import PhoneShape from 'app/shapes/PhoneShape';

import {
  Grid,
  Typography,
} from '@mui/material';

import NeedsPhoneError from 'app/components/call/errors/NeedsPhoneError';
import DialogsContext from 'app/contexts/DialogsContext';
import AddPhoneDialog from 'app/components/modals/AddPhoneDialog';

const AddPhoneLink = ({
  currentUser,
  phones,
  afterSaveCallBack,
}) => {
  const { openDialog } = useContext(DialogsContext);

  const clickEvent = () => {
    openDialog({
      component: AddPhoneDialog,
      props: {
        title: 'Add Phone',
        currentUser,
        phones,
        setShowAddPhoneNumber: () => {},
        afterSaveCallBack,
      },
    });
  };

  return (
    <Grid
      container
      spacing={2}
      maxWidth={550}
      alignItems="flex-start"
    >
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          style={{ textAlign: 'right' }}
        >
          Number
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Typography>
          <a href="#" onClick={clickEvent}>
            Add Phone
          </a>
        </Typography>
        <NeedsPhoneError
          phones={phones}
        />
      </Grid>
    </Grid>
  );
};

AddPhoneLink.defaultProps = {
  phones: [],
  afterSaveCallBack: () => {},
};

AddPhoneLink.propTypes = {
  currentUser: PropTypes.object.isRequired,
  phones: PropTypes.arrayOf(PhoneShape),
  afterSaveCallBack: PropTypes.func,
};

export default AddPhoneLink;
