import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  DialogTitle,
  DialogContent,
  Button,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';
import AutoCompleteMultiCheckbox from 'app/components/form_components/AutoCompleteMultiCheckbox';

import {
  setSort,
  sortSelector,
  sortOptions,
} from 'app/slices/customersSlice';

const CustomerSortDialog = () => {
  const dispatch = useDispatch();
  const { closeDialog } = useContext(DialogsContext);
  const { sortColumn, sortDirection } = useSelector(sortSelector);
  const [localSort, setLocalSort] = useState({ sortColumn, sortDirection });
  const dispatchSetSort = (value) => dispatch(setSort(value));

  const setSortColumn = (position) => (sortColumn) => {
    const newSortColumn = [...localSort.sortColumn];
    newSortColumn[position] = sortColumn;
    setLocalSort({ ...localSort, sortColumn: newSortColumn });
  };

  const setSortDirection = (position) => (evt) => {
    const sortDirection = evt.target.value;
    const newSortDirection = [...localSort.sortDirection];
    newSortDirection[position] = sortDirection;
    setLocalSort({ ...localSort, sortDirection: newSortDirection });
  };

  const onApply = () => {
    dispatchSetSort(localSort);
    closeDialog();
  };

  return (
    <>
      <DialogTitle>
        Choose a Sort
      </DialogTitle>

      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <AutoCompleteMultiCheckbox
          single
          label="Sort by"
          options={sortOptions}
          value={localSort.sortColumn[0]}
          setValue={setSortColumn(0)}
        />

        <RadioGroup
          aria-label="order 1"
          value={localSort.sortDirection[0]}
          onChange={setSortDirection(0)}
          sx={{ mt: 1, mx: 2, mb: 3 }}
        >
          <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
          <FormControlLabel value="desc" control={<Radio />} label="Descending" />
        </RadioGroup>

        <AutoCompleteMultiCheckbox
          single
          label="Then sort by"
          options={sortOptions}
          value={localSort.sortColumn[1]}
          setValue={setSortColumn(1)}
        />

        <RadioGroup
          aria-label="order 2"
          value={localSort.sortDirection[1]}
          onChange={setSortDirection(1)}
          sx={{ mt: 1, mx: 2, mb: 3 }}
        >
          <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
          <FormControlLabel value="desc" control={<Radio />} label="Descending" />
        </RadioGroup>

        <Box display="flex" justifyContent="right" mt={2}>
          <Button variant="text" onClick={closeDialog} sx={{ mr: 2 }}>Cancel</Button>
          <Button variant="contained" onClick={onApply}>Apply</Button>
        </Box>
      </DialogContent>
    </>
  );
};

export default CustomerSortDialog;
