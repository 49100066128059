import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';

import {
  useGetPublicPackagesForAdvisorsQuery,
  useGetActiveTributesForAdvisorQuery,
} from 'app/api/mainApi';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import PackageCtaCard from 'app/components/tributes/PackageCtaCard';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';

const PackagesContainer = ({
  advisor,
  fromFeed,
}) => {
  const { data: packages, isLoading: packagesLoading } = useGetPublicPackagesForAdvisorsQuery({ advisorIds: advisor?.id });
  const { data: activeTributes } = useGetActiveTributesForAdvisorQuery({ advisorLogin: advisor?.login });
  const { openDialog } = useContext(DialogsContext);
  const { packageId } = useParams();
  const {
    closeDialogAndResetTributeSlice,
    dispatch,
    setCurrentStep,
    setPackageId,
    setPackageRate,
    setRecurring,
  } = useRecurringTributeManagement({ advisor });

  const filteredPackages = fromFeed ? packages?.filter((advisorPackage) => advisorPackage.feedPerk) : packages;

  useEffect(() => {
    if (packageId && advisor?.login) {
      openDialog({
        component: TributeWorkflowContainer,
        props: { advisorLogin: advisor.login, packageId: parseInt(packageId) },
      });
    }
  }, [advisor, packagesLoading, packages]);

  const subscribeToPackageClick = (packageId, packageRate) => {
    closeDialogAndResetTributeSlice();
    dispatch(setRecurring({ recurring: true }));
    dispatch(setPackageId({ packageId }));
    dispatch(setPackageRate({ packageRate }));

    openDialog({
      component: TributeWorkflowContainer,
      props: { advisorLogin: advisor.login, packageId },
    });

    if (activeTributes?.packageSubscription) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_PACKAGE_SUBSCRIPTION,
      }));
    }

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_PACKAGE_SUBSCRIPTION,
    }));
  };

  if (packagesLoading) return (<div>Loading...</div>);

  return (
    <Grid container rowSpacing={1} spacing={2} data-testid="packageContainer">
      {filteredPackages.map((advisorPackage) => (
        <Grid item xs={12} sm={6} key={`listing_${advisorPackage.id}`} data-testid="packageGrid">
          <PackageCtaCard
            advisor={advisor}
            advisorPackage={advisorPackage}
            subscribeToPackageClick={() => subscribeToPackageClick(advisorPackage.id, advisorPackage.rate)}
            headingSource="category"
          />
        </Grid>
      ))}
    </Grid>
  );
};

PackagesContainer.defaultProps = {
  advisor: {},
  fromFeed: false,
};

PackagesContainer.propTypes = {
  advisor: PropTypes.object,
  fromFeed: PropTypes.bool,
};

export default PackagesContainer;
