import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useGetChatProfileQuery, useGetListingQuery, useLazyGetAdvisorQuery } from 'app/api/mainApi';
import { useGetGoodyBagQuery } from 'app/api/assetApi';
import AdvisorCard from 'app/components/AdvisorCard';

const AdvisorInfoCardContainer = ({ product }) => {
  const [fetchAdvisor, { data: advisorData }] = useLazyGetAdvisorQuery();

  const { productType, productId } = product || {};

  if (!productType || !productId || productId === 'gb') return null;

  const { data: listingData } = useGetListingQuery(
    { id: productId },
    { skip: productType !== 'call' },
  );

  const { data: chatProfileData } = useGetChatProfileQuery(
    { login: productId },
    { skip: productType !== 'chat' },
  );

  const { data: goodyBagData } = useGetGoodyBagQuery(
    productId,
    { skip: productType !== 'gb' },
  );

  // TODO: remove this when owner_name is populating from goodybag BEGIN
  const [advisorName, setAdvisorName] = useState(null);

  useEffect(() => {
    if (goodyBagData && !goodyBagData.owner_name && goodyBagData.user_id) {
      fetchAdvisor({ login: goodyBagData.user_id });
    }
  }, [goodyBagData, fetchAdvisor]);

  useEffect(() => {
    if (advisorData) {
      setAdvisorName(advisorData.login);
    }
  }, [advisorData]);
  // END

  if (!listingData && !chatProfileData && !goodyBagData) return null;

  const avatarSrc = listingData?.photo_url || chatProfileData?.image_url || goodyBagData?.thumbnail_url;
  const name = listingData?.login || chatProfileData?.user_login || goodyBagData?.title;
  const ownerName = goodyBagData?.owner_name ?? advisorName;
  const gbDescription = ownerName ? `by ${ownerName}` : '';
  const description = listingData?.title || chatProfileData?.description || gbDescription;
  const gbFormattedPrice = goodyBagData?.price && `${parseFloat(goodyBagData?.price).toFixed(2)}`;
  const price = listingData?.price_per_minute || gbFormattedPrice || window.VOLLEY_PRICE;

  return (
    <Stack mb={3} mt={1} data-testid="listing">
      <AdvisorCard
        avatarSrc={avatarSrc}
        name={name}
        description={description}
        price={price}
        type={productType}
      />
    </Stack>
  );
};

AdvisorInfoCardContainer.propTypes = {
  product: PropTypes.shape({
    productType: PropTypes.oneOf(['call', 'chat']),
    productId: PropTypes.string,
  }),
};

AdvisorInfoCardContainer.defaultProps = {
  product: null,
};
export default AdvisorInfoCardContainer;
