const validateEmailFormat = (email) => {
  const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
  return emailRegex.test(email);
};

const validateRoutingNumber = (routingNumber) => {
  const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1];
  let sum = 0;

  routingNumber.split('').forEach((digit, index) => {
    sum += parseInt(digit, 10) * weights[index];
  });

  let checkDigit = 10 - (sum % 10);
  if (checkDigit === 10) {
    checkDigit = 0;
  }

  return checkDigit === 0;
};

export {
  validateEmailFormat,
  validateRoutingNumber,
};
