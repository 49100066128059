import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  DialogContent,
  Button,
  Box,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';

/* eslint-disable quotes */
// Cribbed from frontend/src/javascripts/modules/redesign/my_customer.js
const SUGGESTED_REPLIES = [
  "hi :)", "hey", "hey there", "heyy", "hii", "hey there!", "what's up?", "what up", "whats up", "what's up??",
  ":)", ":D", ";)", ":*", "how's it going", "hows it going?", "how's it going?", "how have u been?", "how have you been", "how have yoou been?",
  "how are you?", "how r u", "how are u ", "how are u?", "helloo", "hello", "hi!", "Hey you", "hey U", "what's new? ",
  "whats new", "yo", "Yo! ", "hay", "hiii", "hiii!", "heyyy!", "hey hey", "hello? ", "hello there! ",
  "sup?", "sup", "u around?", "you free? ", "how're you doing?", "how u doin", "Miss you!", "miss u", "miss ya", "hiya!",
  "howdy!", "heya", "whassup", "where you been?", "where've you been?", "hola!", "hola", "long time", "you OK?", "U OK?",
  "you around?", "u ok?", "bored?", "lonely?",
];
/* eslint-enable quotes */

const SendChatMessageDialog = ({ sendChatMessage, seekerIds }) => {
  const { closeDialog } = useContext(DialogsContext);
  const [chatMessage, setChatMessage] = useState('');

  const onSuggest = () => {
    const randomIndex = Math.floor(Math.random() * SUGGESTED_REPLIES.length);
    setChatMessage(SUGGESTED_REPLIES[randomIndex]);
  };

  const onClickSendMessage = () => {
    sendChatMessage({
      message: chatMessage,
      ids: seekerIds,
    });
    closeDialog();
  };

  return (
    <>
      <DialogTitle>
        Send Chat Message
      </DialogTitle>

      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <Stack direction="column">
          <Typography sx={{ mb: 1 }}>Type your message. Need inspiration? Try the Suggest button.</Typography>
          <TextField
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            inputProps={{ maxLength: 1000 }}
          />
        </Stack>
        <Box display="flex" justifyContent="right" mt={2}>
          <Button variant="text" onClick={onSuggest} sx={{ mr: 2 }}>Suggest</Button>
          <Button variant="contained" onClick={closeDialog} sx={{ mr: 2 }}>Cancel</Button>
          <Button variant="contained" onClick={onClickSendMessage}>Send Chat Message</Button>
        </Box>
      </DialogContent>
    </>
  );
};

SendChatMessageDialog.propTypes = {
  sendChatMessage: PropTypes.func.isRequired,
  seekerIds: PropTypes.array.isRequired,
};

export default SendChatMessageDialog;
