import AccountInfoContainer from 'app/containers/account_info/AccountInfoContainer';
import PasswordSecurityContainer from 'app/containers/account_info/PasswordSecurityContainer';
import IdVerificationContainer from 'app/containers/account_info/IdVerificationContainer';
import NotificationsContainer from 'app/containers/account_info/NotificationsContainer';
import CloseAccountContainer from 'app/containers/account_info/CloseAccountContainer';

export const PERSONAL_INFO_TABS = [
  { index: 0, title: 'Account Info', path: 'account/account_info', component: AccountInfoContainer, routeId: 'accountInfo' },
  { index: 1, title: 'Password and Security', path: 'account/security', component: PasswordSecurityContainer, routeId: 'passwordSecurity' },
  { index: 2, title: 'ID Verification', path: 'account/verification_status', component: IdVerificationContainer, routeId: 'idv', hidden: true },
  { index: 3, title: 'Push Notifications', path: 'account/notifications', component: NotificationsContainer, routeId: 'pushNotifications' },
  { index: 4, title: 'Close Account', path: 'account/close', component: CloseAccountContainer, routeId: 'closeAccount', hidden: true },
];
