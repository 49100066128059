import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Typography, Stack, Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CircularProgress from '@mui/material/CircularProgress';
import { useConfirm } from 'material-ui-confirm';

import SavedSearch from 'app/components/customers/SavedSearch';

import {
  useDestroySavedSearchMutation,
  useGetSavedSearchesQuery,
  useUpdateSearchMutation,
} from 'app/api/customersApi';
import {
  setFilters,
  setOmnibox,
} from 'app/slices/customersSlice';

const ManageSavedSearches = () => {
  const navigate = useNavigate();
  const { data: savedSearches, isLoading: loading } = useGetSavedSearchesQuery();
  const userSavedSearches = savedSearches?.filter((ss) => ss.type === 'user_saved_search') || [];
  const systemSavedSearches = savedSearches?.filter((ss) => ss.type === 'system_saved_search') || [];
  const [updateSearch] = useUpdateSearchMutation();
  const [destroySavedSearch] = useDestroySavedSearchMutation();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const dispatchSetOmnibox = (value) => dispatch(setOmnibox(value));

  const toggleDoNoAutoSuggest = (savedSearch) => (newValue) => {
    updateSearch({
      id: savedSearch.id,
      do_not_auto_suggest: newValue,
    });
  };

  const loadSavedSearch = (savedSearch) => {
    dispatchSetOmnibox([savedSearch]);
    dispatch(setFilters(savedSearch.filters));
  };

  const onClickName = (savedSearch) => (evt) => {
    loadSavedSearch(savedSearch);
    navigate('/my_customers');
    evt.preventDefault();
    // load the query into the omnibox and go to that page
  };

  const onClickEdit = (savedSearch) => (evt) => {
    loadSavedSearch(savedSearch);
    // got to customers and pop open the filters modal
    navigate('/my_customers?filters=1');
    evt.preventDefault();
  };

  const onClickDeleteSearch = (savedSearch) => (evt) => {
    confirm({
      title: 'Confirm delete search',
      description: 'Really delete this search?',
    }).then(() => {
      destroySavedSearch(savedSearch);
    });

    evt.preventDefault();
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ width: '100%', marginBottom: '10px' }}
    >
      <Stack
        direction="row"
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          alignSelf="auto"
          paddingLeft="10px"
          paddingRight="10px"
          height="50px"
          bgcolor="rgb(211, 211, 211)"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <Stack
            direction="row"
            spacing="10px"
            alignItems="center"
            width="100%"
          >
            <IconButton onClick={handleBack}>
              <ArrowBackIosNewIcon fontSize="large" />
            </IconButton>

            <Typography variant="h5">
              Manage Saved Searches
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack sx={{ height: '80.5vh', overflow: 'auto' }} direction="column" spacing={2} px={2}>
        { userSavedSearches.length > 0 && (
          <>
            <Typography variant="h6" pt={2}>Your Searches</Typography>
            { userSavedSearches.map((savedSearch) => (
              <SavedSearch
                key={savedSearch.id}
                savedSearch={savedSearch}
                toggleDoNoAutoSuggest={toggleDoNoAutoSuggest(savedSearch)}
                onClickDeleteSearch={onClickDeleteSearch(savedSearch)}
                onClickName={onClickName(savedSearch)}
                onClickEdit={onClickEdit(savedSearch)}
                showActions
              />
            )) }
          </>
        ) }
        <Typography variant="h6" pt={2}>System Searches</Typography>
        { systemSavedSearches.map((savedSearch) => (
          <SavedSearch
            key={savedSearch.id}
            savedSearch={savedSearch}
            toggleDoNoAutoSuggest={toggleDoNoAutoSuggest(savedSearch)}
            onClickName={onClickName(savedSearch)}
            showActions={false}
          />
        )) }
      </Stack>
    </Stack>
  );
};

export default ManageSavedSearches;
