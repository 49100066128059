import React from 'react';
import {
  Typography,
  TextField,
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from '@mui/material';
import CountrySelect from '../CountrySelect';
import StateField from './StateField';
import SocialSecurityField from './SocialSecurityField';
import EINField from './EINField';
import PropTypes from 'prop-types';
import { useGetCountriesQuery, useGetAppConfigQuery } from '../../api/mainApi';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ActionList from '../shared/ActionList';

const PayeeForm = ({
  payee,
  setPayee,
}) => {
  const appConfig = useGetAppConfigQuery().data;

  const { data: countries } = useGetCountriesQuery('payment');
  const selectedCountry = countries && countries.find((country) => country.iso_country_code === payee.country);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: appConfig.googlePlacesApiKey,
    debounce: 400,
  });

  const changeAddress = (address) => {
    setPayee({...payee, addressLine1: address});
    getPlacePredictions({
      input: address,
      componentRestrictions: { country: payee.country.toLowerCase() },
    });
  }

  const changeCountry = (country) => {
    setPayee({...payee, country });
    getPlacePredictions({
      input: payee.addressLine1,
      componentRestrictions: { country: country.toLowerCase() },
    });
  }

  const payeeIsEmpty = payee.payeeName === '';
  const addressIsEmpty = payee.addressLine1 === '';
  const cityIsEmpty = payee.city === '';
  const zipCodeIsEmpty = payee.zipCode === '';
  const zipcodeLabel = selectedCountry ? selectedCountry.postal_code_name : 'ZIP Code';

  const extractAddressDetails = (index) => {
    const otherData = placePredictions[index].structured_formatting.secondary_text.split(', ');

    placesService.getDetails({
      placeId: placePredictions[index].place_id
    }, (place, status) => {
      let zipCode = '';

      if (status === 'OK') {
        for (const component of place.address_components) {
          if (component.types.includes('postal_code')) {
            zipCode = component.short_name;
            break;
          }
        }
      }

      setPayee({
        ...payee,
        addressLine1: placePredictions[index].structured_formatting.main_text,
        city: otherData[0],
        state: otherData[1],
        zipCode
      });
      getPlacePredictions({input: ''});
    });
  }

  return (
    <div>
      <Typography variant="subtitle1" my={1}>Payee Information</Typography>
      <Stack direction="column" spacing={3}>
        <TextField
          label="Payable to"
          size="small"
          value={payee.payeeName}
          onChange={(e) => setPayee({...payee, payeeName: e.target.value})}
          error={payeeIsEmpty}
          helperText={payeeIsEmpty ? 'Please enter your Payee Name' : null}
        />
        {
          countries && (
            <CountrySelect
              value={payee.country}
              onChange={(country) => changeCountry(country)}
              countries={countries}
            />
          )
        }

        <ActionList
          options={placePredictions.map((prediction) => ({label: prediction.description}))}
          onSelect={(index) => extractAddressDetails(index)}
          showIcon
          icon={<LocationOnIcon />}
          component={(
            <TextField
              label="Address Line 1"
              size="small"
              value={payee.addressLine1}
              onChange={(e) => changeAddress(e.target.value)}
              error={addressIsEmpty}
              helperText={addressIsEmpty ? 'Address can\'t be blank' : null}
              fullWidth
            />
          )}
          onOutsideClick={() => getPlacePredictions({input: ''})}
        />

        <TextField
          label="Address Line 2 (Optional)"
          size="small"
          value={payee.addressLine2}
          onChange={(e) => setPayee({...payee, addressLine2: e.target.value})}
        />

        <TextField
          label="City"
          size="small"
          value={payee.city}
          onChange={(e) => setPayee({...payee, city: e.target.value})}
          error={cityIsEmpty}
          helperText={cityIsEmpty ? 'City can\'t be blank' : null}
        />

        <StateField
          value={payee.state}
          onChange={(value) => setPayee({...payee, state: value})}
          states={selectedCountry ? selectedCountry.states : []}
        />

        <TextField
          label={zipcodeLabel}
          size="small"
          value={payee.zipCode}
          onChange={(e) => setPayee({...payee, zipCode: e.target.value})}
          error={zipCodeIsEmpty}
          helperText={zipCodeIsEmpty ? `${zipcodeLabel} can't be blank` : null}
        />

        <FormControl>
          <RadioGroup
            aria-labelledby=""
            value={payee.taxableStatus}
            onChange={(e) => setPayee({...payee, taxableStatus: e.target.value})}
            name="radio-buttons-group"
          >
            <FormControlLabel value="0" control={<Radio/>} label="I am not eligible to work in the US and my address is outside of the US"/>
            <FormControlLabel value="10" control={<Radio/>} label="I am eligible to work in the US"/>
            {
              payee.taxableStatus === '10' && (
                <Box sx={{mx: 4}}>
                  <SocialSecurityField value={payee.payerTaxId} onChange={(payerTaxId) => setPayee({...payee, payerTaxId })} />
                </Box>
              )
            }
            <FormControlLabel value="20" control={<Radio/>} label="The money that I earned is payable to a company."/>
            {
              payee.taxableStatus === '20' && (
                <Box sx={{mx: 4}}>
                  <EINField value={payee.payerTaxId} onChange={(payerTaxId) => setPayee({...payee, payerTaxId })} />
                </Box>
              )
            }

          </RadioGroup>
        </FormControl>

      </Stack>
    </div>
  );
}

PayeeForm.propTypes = {
  payee: PropTypes.object.isRequired,
  setPayee: PropTypes.func.isRequired,
}

export default PayeeForm;