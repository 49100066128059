import { useState, useContext } from 'react';
import { DateTime } from 'luxon';

import PostScheduleModal from 'app/components/modals/PostScheduleModal';
import GenericModal from 'app/components/modals/GenericModal';
import ReportContentModal from 'app/components/modals/ReportContentModal';
import EditCaptionModal from 'app/components/modals/EditCaptionModal';
import ModalsContext from 'app/contexts/ModalsContext';

// Post Actions Hooks
import usePublishPost from './usePublishPost';
import useUpdatePost from './useUpdatePost';
import useDeletePost from './useDeletePost';
import usePinPost from './usePinPost';
import { useNavigate } from 'react-router';

const usePostMenuActions = ({
  post,
  currentUser,
  advisor,
  afterPinCallback = () => null,
  afterDeleteCallback = () => null,
  afterPublishCallback = () => null,
  afterUpdateCallback = () => null,
  showViewPost = false,
}) => {
  const { publishPost } = usePublishPost();
  const { setScheduledAtChanged, updatePost } = useUpdatePost(post);
  const { deletePost } = useDeletePost();
  const { pinPost } = usePinPost(post);
  const [copyLinkText, setCopyLinkText] = useState('Copy link');
  const viewingSelf = (currentUser?.id === advisor?.id);
  const { openModal } = useContext(ModalsContext);
  const navigate = useNavigate();

  const isPublishable = post.moderation_status !== 'not_accepted';

  // In case the logic needs to be moved (maybe to the props), it is done in one place.
  // It was added because we want to remove the PIN option when the advisor is
  // viewing the post in the Post Queue (which sets showViewPost to true).
  const showPinPost = !showViewPost;

  let isScheduled = false;
  if (post.scheduled_at) {
    const currentScheduledAt = DateTime.fromISO(
      post.scheduled_at,
      { zone: currentUser?.canonical_iana_timezone || 'utc' },
    );

    isScheduled = currentScheduledAt > DateTime.local().setZone(currentUser?.canonical_iana_timezone || 'utc');
  }

  const openSchedulingModal = () => {
    openModal({
      component: PostScheduleModal,
      props: {
        post,
        onSave: (newScheduledAt) => {
          setScheduledAtChanged(true);
          updatePost({
            postId: post.id,
            advisorId: post.main_user_id,
            cursorPage: post.cursorPage,
            post: { scheduled_at: newScheduledAt },
          }).then(afterUpdateCallback);
        },
        currentDate: post.scheduled_at,
        timezone: currentUser.canonical_iana_timezone,
      },
      noDefaultBody: true,
    });
  };

  const postActions = [];

  if (showViewPost) {
    postActions.push({
      label: 'View Post',
      onClick: (toggleTip) => {
        navigate(`/feed/${encodeURIComponent(advisor.login)}/${post.id}`);
        toggleTip();
      },
    });
  }

  // if a post is publishable, or if it has not been reviewed, then the caption can be edited
  if (viewingSelf && isPublishable) {
    postActions.push({
      label: 'Edit Caption',
      onClick: (toggleTip) => {
        openModal({
          component: EditCaptionModal,
          props: { post },
          hideCloseButton: true,
        });
        toggleTip();
      },

    });
  }

  if (viewingSelf && isScheduled && isPublishable) {
    postActions.push({
      label: 'Edit Schedule',
      onClick: (toggleTip) => {
        openSchedulingModal();
        toggleTip();
      },
    });

    postActions.push({
      label: 'Publish Now',
      onClick: (toggleTip) => {
        openModal({
          component: GenericModal,
          props: {
            callToAction: () => {
              publishPost(
                { postId: post.id, advisorId: post.main_user_id, cursorPage: post.cursorPage },
              ).then(afterPublishCallback);
            },
            ctaButtonLabel: 'Publish',
            header: 'Ready to Publish?',
            body: 'Your post will be published pending review.',
          },
        });
        toggleTip();
      },
    });
  }

  if (navigator.share && isPublishable) {
    postActions.push({
      label: 'Share',
      onClick: (toggleTip) => {
        navigator.share({ text: `Check out this post on NF+: https://${window.location.host}/feed/${encodeURIComponent(advisor.login)}/${post.id}` });
        toggleTip();
      },
    });
  }

  if (post.post_type === 'free' && viewingSelf && !isScheduled && isPublishable && showPinPost) {
    postActions.push({
      label: post.pinned ? 'Unpin' : 'Pin',
      onClick: (toggleTip) => {
        pinPost({ postId: post.id, pinned: !post.pinned }).then(afterPinCallback);
        toggleTip();
      },
    });
  }

  if (isPublishable && (post.post_type === 'subscriber' || post.post_type === 'welcome_kit') && viewingSelf) {
    let header = 'Make visible to all subscribers';
    let body = 'Let current & future subscribers see this post.';
    let label = 'Make visible to all subscribers';
    let newPostType = 'welcome_kit';

    if (post.post_type === 'welcome_kit') {
      header = 'Limit to preexisting subscribers?';
      body = 'This post will no longer be visible to those who subscribed after the post date.';
      label = 'Limit to preexisting subscribers';
      newPostType = 'subscriber';
    }

    postActions.push({
      label,
      onClick: (toggleTip) => {
        openModal({
          component: GenericModal,
          props: {
            callToAction: () => {
              updatePost({
                postId: post.id,
                advisorId: post.main_user_id,
                cursorPage: post.cursorPage,
                post: { post_type: newPostType },
              }).then(afterUpdateCallback);
            },
            ctaButtonLabel: label,
            header,
            body,
          },
        });
        toggleTip();
      },
    });
  }

  if (isPublishable) {
    postActions.push({
      label: copyLinkText,
      onClick: (toggleTip) => {
        navigator.clipboard.writeText(`https://${window.location.host}/feed/${encodeURIComponent(advisor.login)}/${post.id}`);
        setCopyLinkText('Copied!');
        setTimeout(() => {
          toggleTip();
          setCopyLinkText('Copy link');
        }, 1000);
      },
    });
  }

  if (isPublishable && !viewingSelf) {
    postActions.push({
      label: 'Report',
      onClick: (toggleTip) => {
        openModal({
          component: ReportContentModal,
          props: {
            contentId: post.id,
            contentType: 'post',
          },
        });
        toggleTip();
      },
    });
  }

  if (viewingSelf) {
    const deletePostBody = 'Are you sure you want to delete this post? You won\'t be able to bring it back.';

    postActions.push({
      label: 'Delete',
      onClick: (toggleTip) => {
        openModal({
          component: GenericModal,
          props: {
            callToAction: () => {
              deletePost({ postId: post.id }).then(afterDeleteCallback);
            },
            ctaButtonLabel: 'Delete Post',
            header: 'Delete Post?',
            body: deletePostBody,
          },
        });
        toggleTip();
      },
    });
  }

  return {
    isScheduled,
    postActions,
  };
};

export default usePostMenuActions;
