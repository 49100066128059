import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '0px',
  borderRadius: '0px',
  backgroundColor: 'var(--body_background)',
  color: 'var(--body_color)',
  border: '1px solid var(--color_ccc)',
});

export default StyledCard;
