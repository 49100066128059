import React from 'react';

import Typography from '@mui/material/Typography';

const CallInfo = () => {
  return (
    <>
      <Typography variant="h3">
        We’ll call you at the number below.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          mt: 0.5
        }}
      >
        Your number is never revealed.
      </Typography>
    </>
  );
};

export default CallInfo;
