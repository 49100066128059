import React from 'react';
import PropTypes from 'prop-types';

import {
  Stack, Typography,
} from '@mui/material';
import StyledHeader from 'app/components/shared/StyledHeader';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';

const TitleWithBackNavigation = ({ title }) => {
  return (
    <StyledHeader data-test-id="template-shell-header">
      <Stack direction="row" spacing={2} alignItems="center">
        <span role="button" onClick={() => window.history.back()} tabIndex={0} data-test-id="ico-back-arrow">
          <ArrowBackIosSharpIcon htmlColor="white" sx={{ ml: 0.5, mt: 0.5 }} />
        </span>
        <Typography
          data-test-id="template-shell-header-title"
          variant="purpleHeaderTitle"
          sx={{ pl: 0 }}
        >
          { title }
        </Typography>
      </Stack>
    </StyledHeader>
  );
};

TitleWithBackNavigation.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleWithBackNavigation;
