import React, { useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import CategoryListings from 'app/components/categories/CategoryListings';
import { useGetCategoryListingsQuery } from 'app/api/mainApi';
import StickyHeader from 'app/components/categories/StickyHeader';
import NumberlessPagination from 'app/components/NumberlessPagination';
import DecisionScreen from 'app/components/DecisionScreen';

import {
  setCategoryListingSort,
  categoryListingSortSelector,
} from 'app/slices/miscUIStateSlice';

import useFeaturedListingsContext from 'app/hooks/useFeaturedListingsContext';

const CategoryListingsContainer = () => {
  const TABS = [
    { label: 'Live Phone Sex', value: 1 },
    { label: 'Recorded Phone Sex', value: 2 },
    { label: 'Chat', value: 3 },
  ];

  const SORTS = [
    { label: 'Featured Listings', value: 0 },
    { label: 'Top Available', value: 1 },
    { label: 'Highest Rated', value: 4 },
    { label: 'Less than $3', value: 2 },
    { label: 'Less than $2', value: 3 },
  ];

  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const decisionScreen = searchParams.get('decision_screen');
  const page = parseInt(searchParams.get('page')) || 1;
  const listingType = parseInt(searchParams.get('listing_type')) || 1;
  const dispatch = useDispatch();
  const sort = useSelector(categoryListingSortSelector);
  const navigate = useNavigate();

  const categoryListingsParams = {
    category,
    page,
    listingType,
    perPage: 12,
    sort,
  };

  if (decisionScreen) {
    categoryListingsParams.decisionScreen = decisionScreen;
  }

  const {
    data: listingsData,
    isLoading,
    isSuccess,
  } = useGetCategoryListingsQuery(categoryListingsParams);

  const { FeaturedListingsContextProvider } = useFeaturedListingsContext({ listingsData });

  const handleTabChange = (_event, newListingType) => {
    navigate(`?listing_type=${newListingType}&page=1`);
  };

  const handlePageChange = (_event, newPage) => {
    navigate(`?listing_type=${listingType}&page=${newPage}`);
  };

  const handleSortChange = (sort) => {
    dispatch(setCategoryListingSort({ categoryListingSort: sort.value }));
  };

  useEffect(() => {
    dispatch(setCategoryListingSort({ categoryListingSort: 0 }));
  }, [category]);

  return (
    <FeaturedListingsContextProvider>
      <Box
        sx={{
          backgroundColor: 'var(--color_gray_efour)',
          borderRight: '1px solid var(--body_content_line)',
          width: '100%',
          overflow: 'scroll',
          height: {
            xs: 'calc(100vh - 90px)',
            sm: 'calc(100vh - 40px)',
          },
        }}
      >
        {listingsData?.listings && (
          <>
            <StickyHeader
              breadcrumbs={listingsData.category_breadcrumbs}
              tabs={TABS}
              currentTab={listingType}
              handleTabChange={handleTabChange}
              sorts={SORTS}
              activeSort={sort}
              handleSortChange={handleSortChange}
            />
            <CategoryListings
              tabs={TABS}
              sorts={SORTS}
              activeSort={sort}
              currentTab={listingType}
              handleTabChange={handleTabChange}
              listingsData={listingsData}
              handleSortChange={handleSortChange}
              showHeader={false}
              categoryName={category}
            />
          </>
        )}

        {(isSuccess && listingsData?.listings?.length === 0) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            No listings found
          </Box>
        )}

        {isLoading && (
          <Box
            data-test-id="loading-text"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading...
          </Box>
        )}

        {listingsData?.decision_screen && (
          <DecisionScreen />
        )}

        {(listingsData?.has_next || listingsData?.current_page > 1) && (
          <Grid container sx={{ mt: 1, mb: 0, pr: 1 }}>
            <Grid item xs={12} container justifyContent="flex-end">
              <NumberlessPagination
                hasNext={listingsData?.has_next}
                page={listingsData?.current_page}
                onChange={handlePageChange}
              />
            </Grid>
          </Grid>
        )}
        {/* Spacer to add extra space */}
        <Box sx={{ height: 200 }} />
      </Box>
    </FeaturedListingsContextProvider>
  );
};

export default CategoryListingsContainer;
