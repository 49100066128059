import React from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import MailboxShape from 'app/shapes/MailboxShape';

import FullPageLoadingSpinner from 'app/features/mail/components/FullPageLoadingSpinner';

import {
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Mailbox from 'app/features/mail/components/Mailbox';

const CustomTabs = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    display: 'flex',

    '& .MuiTab-root': {
      flexGrow: '1',
      minWidth: 'auto',
      maxWidth: 'none',
    },
    '& .MuiButtonBase-root': {
      padding: '0px',
    },
  },
}));

const tabMapping = {
  0: 'inbox',
  1: 'notifications',
  2: 'sent',
};

const Mailboxes = ({
  isLoading,
  mailbox,
  currentUser,
  selectTab,
  selectedTab,
  setPage,
}) => {
  let selectedTabIndex;
  switch (selectedTab) {
  case 'inbox':
    selectedTabIndex = 0;
    break;
  case 'notifications':
    selectedTabIndex = 1;
    break;
  case 'sent':
    selectedTabIndex = 2;
    break;
  default:
    selectedTabIndex = 0;
  }

  const navigate = useNavigate();
  const handleChange = (_event, newValue) => {
    selectTab(tabMapping[newValue]);
    navigate(`/messages/${tabMapping[newValue]}`);
    setPage(1);
  };

  // There has to be a better way to get the inner content to scroll.
  // It would be so nice to make the document just fall into place instead of arm wrestling it.
  // Header, Tabs, and 5 for margin of error.
  const headerHeight = 44;
  const mobileHeaderHeight = 36;
  const mailboxTabsHeight = 48;
  const paginationControlsHeight = 48;
  const footerHeight = 55;
  const marginOfError = 2;

  const headerAndFooterHeight = headerHeight + mailboxTabsHeight + paginationControlsHeight + marginOfError;
  // Header, Tabs, Footer, Pagination Controls and 5 for margin of error.
  const mobileHeaderAndFooterHeight = mobileHeaderHeight + mailboxTabsHeight + footerHeight + paginationControlsHeight + marginOfError;
  const isAdvisor = currentUser.user_type === 'advisor';

  return (
    <>
      <CustomTabs
        value={selectedTabIndex}
        onChange={handleChange}
        aria-label="Profile Tabs"
      >
        <Tab label="Inbox" />
        <Tab
          label="Notifications"
          sx={{
            display: isAdvisor ? 'flex' : 'none',
          }}
        />
        <Tab label="Sent" />
      </CustomTabs>
      <Box
        sx={{
          maxHeight: {
            xs: `calc(100vh - ${mobileHeaderAndFooterHeight}px)`,
            md: `calc(100vh - ${headerAndFooterHeight}px)`,
          },
          overflowY: 'scroll',
        }}
      >
        { isLoading ? (<FullPageLoadingSpinner />) : (
          mailbox && (
            <Mailbox
              mailbox={mailbox}
              currentUser={currentUser}
              selectedTab={selectedTab}
            />
          )
        )}
      </Box>
    </>
  );
};

Mailboxes.defaultProps = {
  mailbox: null,
};

Mailboxes.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  mailbox: MailboxShape,
  selectedTab: PropTypes.string.isRequired,
  selectTab: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Mailboxes;
