import React from 'react';
import { Route } from 'react-router-dom';

import PersonalInfoLayout from './components/PersonalInfoLayout';
import { PERSONAL_INFO_TABS } from './constants';

export const PersonalInfoRoutes = () => (
  <Route element={<PersonalInfoLayout />}>
    {PERSONAL_INFO_TABS.map((tab) => (
      <Route
        key={tab.path}
        path={tab.path}
        id={tab.routeId}
        element={<tab.component />}
      />
    ))}
  </Route>
);
