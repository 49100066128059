import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import CircularProgress from 'app/components/shared/CircularProgress';
import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import ChatListingCard from 'app/components/ChatListingCard';

import NumberlessPagination from 'app/components/NumberlessPagination';
import useListingDetailsAndFeedback from 'app/hooks/useListingDetailsAndFeedback';
import { featureFlag } from 'app/util/featureFlag';

const LoadingContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '25vh',
}));

const ListingSearchResults = ({
  isLoading,
  cardType,
  records,
  page,
  lastPage,
  handlePageChange,
  currentUser,
  queryParams,
}) => {
  const hasNext = page !== lastPage;
  const {
    onListingDetailsClick,
    onListingFeedbackClick,
  } = useListingDetailsAndFeedback({
    listingDetailsFlagEnabled: featureFlag.enabled('LISTING_DETAILS_20955'),
  });

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <LoadingContainer>
            <CircularProgress />
            <Typography data-test-id="loading-text" variant="body1" sx={{ marginTop: 2 }}>
              Finding Flirts
            </Typography>
          </LoadingContainer>
        </Box>
      </Grid>
    );
  }

  if (records && records.length !== 0) {
    return (
      <Grid item xs={12}>
        <Box
          pt={{ xs: 2.5, sm: 3.5 }}
          pb={{ xs: 4, sm: 4 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: { xs: '6px', sm: '10px' },
              justifyContent: { sm: 'flex-start' },
              paddingLeft: { sm: '25px' },
              '@media (max-width: 900px)': {
                justifyContent: 'center',
                paddingLeft: '0px',
              },
            }}
          >
            {records?.map((listing) => {
              return (
                <Box
                  key={`listing_${listing.id}`}
                  sx={{
                    width: '330px',
                    flex: '0 0 330px',
                  }}
                >
                  {cardType === 'chat' && (
                    <ChatListingCard
                      key={listing.id}
                      listing={listing}
                      advisorLogin={listing?.advisor.login}
                      onDetailsClick={onListingDetailsClick(listing)}
                      onFeedbackClick={onListingFeedbackClick(listing)}
                      currentUser={currentUser}
                    />
                  )}
                  {cardType === 'live' && (
                    <PhoneListingContainer
                      key={listing.id}
                      listing={listing}
                      advisorLogin={false}
                      onDetailsClick={onListingDetailsClick(listing)}
                      onFeedbackClick={onListingFeedbackClick(listing)}
                      currentUser={currentUser}
                      queryName="getListingSearchResults"
                      queryArgs={queryParams()}
                      queryDataKey="search_results"
                      queryDataKeyTwo="listings"
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>

        {lastPage > 1 && (
          <NumberlessPagination
            hasNext={hasNext}
            page={page}
            onChange={handlePageChange}
          />
        )}

      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Box data-test-id="no-results" sx={{ textAlign: 'center', mt: 2, mb: 8, p: 2 }}>
        <h2>No results found</h2>
        <p>
          We can&apos;t find any results based on your search. Try adjusting or resetting your filters to display better results.
        </p>
      </Box>
    </Grid>
  );
};

ListingSearchResults.defaultProps = {
  records: [],
  lastPage: 1,
  currentUser: null,
};

ListingSearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cardType: PropTypes.string.isRequired,
  records: PropTypes.array,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number,
  handlePageChange: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  queryParams: PropTypes.func.isRequired,
};

export default ListingSearchResults;
